import {useRef} from 'react';
//MUI
import { Box, useMediaQuery } from '@mui/material';
// SHARED COMPONENTS
import Drawer from 'components/organisms/Drawer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import DialogNutritionModulePresentation from 'components/organisms/DialogNutritionModulePresentation';
import { handleDemoUserPresentation, handleNutritionPresentation, handleSubscriptionPresentation } from 'features/appSlice';
import DialogSubscriptionPresentation from 'components/organisms/DialogSubscriptionPresentation';
import { DESKTOP } from 'utils/constants';
import classes from './styles';
import DialogDemoUserAdded from 'components/molecules/DialogDemoUserAdded';


/********** MAIN COMPONENT ****** */

export default function DashboardLayout({children}:any) {

    const dispatch = useDispatch<AppDispatch>()
    const openNutrition:boolean = useSelector((state:RootState) => state.app.openNutritionPresentation, shallowEqual)
    const openSubscription:boolean = useSelector((state:RootState) => state.app.openSubscriptionPresentation, shallowEqual)
    const openDemoUser:boolean = useSelector((state:RootState) => state.app.openUserDemoPresentation, shallowEqual)
    const desktop = useMediaQuery(DESKTOP);
    const daysLabel = useRef(null)


    return (

        <>
            <Box sx={{display:"flex", flexDirection:"row", padding: "0 !important"}}>

                <Drawer />

                <Box sx={[classes.container, !desktop && {paddingLeft: 0}]} ref={daysLabel}>
                    {children}
                </Box>
            </Box>
            
          

            <DialogNutritionModulePresentation
                open={openNutrition}
                onClose={()=>dispatch(handleNutritionPresentation({open: false}))}
            />

            <DialogSubscriptionPresentation
                open={openSubscription}
                onClose={()=>dispatch(handleSubscriptionPresentation({open: false}))}
            />


            <DialogDemoUserAdded
                open={openDemoUser}
                onClose={()=> dispatch(handleDemoUserPresentation({open:false}))}
            />
        </>

    )
}

import  Workout, { WorkoutExercice,  WorkoutBlock, GroupExercice } from "interfaces/Workout";



/**
 * Calcule le nombre de séries d'une séance
 * 
 * @params {Workout} La séance
 * @return {number} Le nombre de séries
 * 
 * */

export default function calcSets(workout: Workout):number {

    let totalSets:number = 0


    workout.blocks.forEach((block:WorkoutBlock) => {
        block.content.forEach((group: GroupExercice) => {
       
            group.exercices.forEach((exercice:WorkoutExercice) => {
                totalSets = totalSets + exercice.sets.length
            })
            
        })
    })


    return totalSets
}
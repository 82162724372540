
import { darken, lighten } from "@mui/material"
import { PROGRAM_COLORS } from "utils/constants"
import { theme } from "utils/theme"

const classes = {

    /*program : (left:string, index:number, borderRadius:string,width:string,color:string)=>({
        zIndex: 2,
        marginLeft: left,
        height: 30,
        backgroundColor: color || PROGRAM_COLORS[0],
        width: width,
        display: "flex",
        justifyContent:"flex-start",
        alignItems:"center",
        paddingLeft: 1,
        borderRadius: borderRadius,
        "&:hover" : {
            backgroundColor: lighten(color || PROGRAM_COLORS[0], 0.3),
            cursor: "pointer"
        },
        marginBottom: 2,
        
        
    }),*/

    program:{
        height: 24,
        backgroundColor: "#e3eef6",
        fontWeight: 500,
        display:"flex",
        alignItems:"center",
        paddingLeft: 1,
        fontSize: theme.typography.pxToRem(13),
        opacity: 1,
        cursor:"pointer",
        marginBottom:1,
        marginTop: {
            xs:2,
            sm: 0,
        },
        "&:hover" : {
            opacity: 0.8
        }
    },
    

    
    programStart:{
        borderRadius: "10px 0px 0px 10px",
        marginLeft: 1,
        width: "calc(100% - 8px)", 
    },
    
    programEnd:{
        borderRadius: "0px 10px 10px 0px",
        marginRight: 1,
        width: "calc(100% - 8px)", 
    },
    
    programName:{
        fontSize: 13, 
        fontWeight: 500,
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap",
        display:"block",
        width: '100%'
    },
}

export default classes
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { deleteMultipleWorkouts, getWorkouts } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import { fr } from "date-fns/locale";
import Workout from "interfaces/Workout";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useEffect, useMemo, useState } from "react";
import Program from "interfaces/Program";


// REDUX SELECTORS

interface Props{
    open: boolean,
    onClose: ()=> void,
    program: Program
}

export default function DialogDeleteProgramWorkouts ({open, onClose, program}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [workouts, setWorkouts] = useState<Workout[]>([])
    const pending = useSelector((state:RootState)=> state.workouts.requests.deleteMultiple === "pending")
    const user = useSelector((state:RootState) => state.planning.user._id,)
    const {startDate, endDate, name} = program

    useEffect(()=>{
        if(open){
            dispatch(getWorkouts({
                start: startDate, 
                end: endDate, 
                createdFor: user, 
            })).unwrap().then((res)=>{
                setWorkouts(res.workouts)
            })
        }
    },[open])


    const filtered = useMemo(()=>{
        if(workouts.length>0){
            return [...workouts.filter((elem)=> !elem.performed)]
        } else {
            return []
        }
    },[workouts])

    
    const onSubmit = ()=>{


        dispatch(deleteMultipleWorkouts({workouts: filtered})).unwrap().then(()=>{
            onClose()
        })
    }

    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer les séances du programme
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer l'intégralité des {filtered.length} séances du programme <strong>{name}</strong> allant du <strong>{format(new Date(startDate), "dd MMMM yyyy",{locale:fr})}</strong> au <strong>{format(new Date(endDate), "dd MMMM yyyy",{locale:fr})}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={pending}
                >
                    Annuler
                </Button>
                <LoadingButton 
                    onClick={onSubmit} 
                    variant="contained" 
                    disabled={pending} 
                    loading={pending}
                >
                    {(pending) ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
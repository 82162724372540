// MUI
import { Avatar as MuiAvatar, IconButton, Box, lighten} from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material"
// Styles
import classes from "./styles"

/********* INTERFACES ******* */

interface Props{
    icon: SvgIconComponent,
    width: number,
    height: number,
    url: string,
    disableButton?: boolean,
    onClick: ()=> void
}

/********* MAIN COMPONENT ******* */

export default function ProfileAvatar({icon, width, height, onClick, url, disableButton}:Props){

    const Icon = icon

    return(
    <Box sx={{position: "relative"}}>
            <MuiAvatar src={url} sx={{ width: width, height: height}}/>
            <IconButton 
                disabled={disableButton}
                onClick={onClick}
                sx={classes.iconButton}>
                    <Icon />
            </IconButton>
        </Box>
    )
}
import { lighten } from "@mui/material"
import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {
    card: {
        display: "flex", 
        alignItems: "center", 
        flexDirection: "column", 
        backgroundColor: "white", 
        paddingTop: 3, 
        paddingBottom: 3,
        border: `solid 1px ${BORDER_COLOR}`,
    },
}

export default classes
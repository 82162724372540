

const classes={
    content:{
        display: "flex", 
        width: {
            xs:"100%",
            md: "300px"
        }, 
        alignItems: "center", 
        justifyContent: "center",
        top: 0, 
        left: 0, 
        flexDirection:"column"
    }
}

export default classes
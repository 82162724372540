import { Box, Button, Popover, Typography } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import { addFilterTag, clearRecipeTagsFilter, removeFilterTag } from "features/recipesSlice"
import { useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import { RECIPE_TAGS } from "utils/constants"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"



interface Props{
    open: boolean,
    onClose: ()=>void,
    anchorEl: HTMLButtonElement
}

export default function RecipesFilters({onClose, open, anchorEl}:Props){
    const dispatch = useDispatch<AppDispatch>()
    const tags = useSelector((state:RootState)=> state.recipes.tagsFilter)

    const onClearFilter = () => {
        dispatch(clearRecipeTagsFilter())
    }


    const handleFilterTags = (newTag:string) => ()=>{

        const exist = tags.find((elem)=> elem === newTag)

        if(!exist){ 
            dispatch(addFilterTag({tag:newTag}))
        } else {
            dispatch(removeFilterTag({tag: newTag}))
        }
    }




    return(
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            disableScrollLock={true}
            sx={classes.popover}
        >
            <Box 
                sx={[classes.filterMenu]}
            >
                <Box 
                    sx={{position: "relative"}}>

                    {/**** FILTER HEADER **** */}
                    <Box 
                        sx={classes.filterHeader}>
                        <Typography 
                            sx={{fontWeight: 600, fontSize: 15}}>
                                Filtrer les recettes
                        </Typography>
                        <Button 
                            sx={{textTransform: "none"}} 
                            onClick={onClearFilter}
                        >
                            Décocher
                        </Button>
                    </Box>

                    {/**** LISTE DES TAGS **** */}
                    {RECIPE_TAGS.map((tag)=> {
                        const checked = tags.find((elem)=> elem === tag.id)

                        return(
                            <Box 
                                key={tag.id} 
                                sx={classes.filter} 
                                onClick={handleFilterTags(tag.id)}
                            >

                                {checked && <CheckBox sx={[classes.checkbox, classes.checboxChecked]}/>}
                                {!checked && <CheckBoxOutlineBlank sx={classes.checkbox}/>}
                                
                                <Typography 
                                    sx={classes.group}>
                                        {tag.label.fr}
                                </Typography>
                            </Box>
                        )

                    })}
                </Box>
            </Box>

        </Popover>
    
    )
}
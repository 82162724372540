import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {

    infosContainer:{
    minWidth: 150, 
    display:"flex", 
    flexDirection: "row", 
    alignItems:"center", 
    justifyContent:"flex-end", 
    flex:1, 
    flexWrap: "nowrap"
    },


    indicators : {
        minWidth: 250, 
        display:"flex", 
        flexDirection: "row", 
        alignItems:"center", 
        justifyContent:"flex-end", 
        flex:1, 
        flexWrap: "nowrap"
    },


    container:{
        width: "100%",
        marginBottom: 2,
        backgroundColor: "white",
        padding: 3,
        border: `solid 1px ${BORDER_COLOR}`,
    
    },

    fullHeight:{
        height: "calc(100% - 24px)"
    },


    imageFood : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },


    imageContainer : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: 200
    },


mealButtons:{
    display:"flex", 
    flexDirection: "row",
    marginTop: 3
},

sectionLabel:{
    width: "100%",
    marginBottom: 2,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18)
},




step : {
    display:"flex",
    flexDirection: "row",
    alignItems:"flex-start",
    marginTop: 3
},

stepIndex : {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginRight: 1,
    width: 50
},

chips:{
    display:"flex",
    flexDirection: "row",
    alignItems:"center",
    flexWrap: "wrap"
},

portions:{
    display:"flex",
    flexDirection: "row",
    alignItems:"center",
    marginTop: 2
},

videoContainer:{
    width: "100%",  
    backgroundColor: "#f9f9f9", 
    marginTop: 2, 
    position: "relative"
},

thumbnail:{
    maxWidth: "100%",
    cursor: "pointer",
    opacity: 0.6,
    border: "solid 3px white",
    backgroundColor: "whitesmoke",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    overflow: "hidden"
},


thumbnailSelected: {
    border: "solid 3px black",
    cursor: "pointer",
    opacity: 1,
    boxSizing: "border-box",
    overflow: "hidden"
},

thumbnailItem: {
    backgroundColor: "red",
    width: "100%"
},

uploadField:{
    width: "100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    backgroundColor: "whitesmoke",
    fontSize: 13,
    padding: 1,
    textAlign: "center",
    cursor: "pointer",
    textTransform: "none"
},


deleteImageButton:{
    position: "absolute",
    top: 25,
    right: 0,
    backgroundColor: "black",
    width: 40,
    height: 40,
    zIndex: 2
},

// Section Equipement - Muscles - type
section: {
    minHeight: 80, 
    padding: 3,
    display:"flex", 
    flexDirection: "column", 
    alignItems:"flex-start", 
    justifyContent:"space-between", 
    marginBottom: 3,
    border: `solid 1px ${BORDER_COLOR}`,
    backgroundColor: "white"
},

}

export default classes
import { theme } from "utils/theme"


export const classes = {
    item:{
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        paddingTop: 1,
        paddingBottom: 1
    },

    itemName : {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        marginLeft: 1.5,
        lineHeight: "34px",
        flex: 1
    },

    icon: {
        width: 40,
        height: 40,
        backgroundColor: "#c3eef6",
        borderRadius: "100px",
        marginRight: 1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    itemInfos : {
        display:"flex",
        flexDirection: "row",
        flex: 1
    }
}

export default classes

const classes = {
    loader:{
        display: "flex", 
        height: "100vh", 
        width: "100vw", 
        alignItems: "center", 
        justifyContent: "center", 
        position: "fixed", 
        top: 0, 
        left: 0 
    }
}

export default classes
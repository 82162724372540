
import { Box, Grid, useMediaQuery } from "@mui/material";
import PageTitle from "components/atoms/Typography/PageTitle";
import Header from "components/molecules/Header";
import SearchTextfield from "components/molecules/Search";
import Tabs from "components/molecules/Tabs";
import Toolbar from "components/molecules/Toolbar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import { MOBILE, TABLET } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import classes from "./styles";
import MainContent from "components/molecules/MainContent";
import { ViewportList } from "react-viewport-list";
import FoodRow from "components/molecules/FoodRow";
import EmptySearch from "components/molecules/EmptySearch";
import FoodType from "interfaces/Food";
import DialogFood from "components/organisms/DialogFood";
import DialogAddFood from "components/organisms/DialogAddFood";
import FoodInterface from "interfaces/Food";
import DialogDeleteFood from "components/molecules/DialogDeleteFood";
import { searchFood } from "features/foodSlice";
import { selectDrawerItem } from "features/appSlice";
import DialogSimplifiedFood from "components/organisms/DialogSimplifiedFood";

const LISTS: string[] = ["app", "ciqual", "custom"];


export default function Food(){
    
    const mobile = useMediaQuery(MOBILE)
    const dispatch = useDispatch<AppDispatch>()
    const [search, setSearch] = useState<string>("");
    const [tabs, setTabs] = useState(0);
    const food = useSelector((state:RootState) => state.food.filtered)
    const IS_TABLET = useMediaQuery(TABLET);
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const listRef = useRef(null);
    const [foodSelected, setFoodSelected] = useState<FoodType>(null)
    const [foodSelectedToDelete, setFoodSelectedToDelete] = useState<FoodType>(null)
    const [openFoodDialog, setOpenFoodDialog] = useState<boolean>(false)
    const [openAddFood, setOpenAddFood] = useState<boolean>(false)
    const [openDeleteFood, setOpenDeleteFood] = useState<boolean>(false)
    const pending = useSelector((state:RootState) => state.food.requests.getAllFood === "pending")
    const [foodToSimplify, setFoodToSimplify] = useState<FoodType>(null)
    const[openSimplifyFood, setOpenSimplifyFood] = useState<boolean>(false)

    
    useEffect(()=>{
        dispatch(selectDrawerItem(3))
    },[dispatch])


    const onOpenDialogDelete = (food:FoodInterface) => () => {
        setFoodSelectedToDelete(food)
    }


    useEffect(()=>{
        if(Boolean(foodSelectedToDelete)) {
            setOpenDeleteFood(true)
        }
    },[foodSelectedToDelete])


    useEffect(()=>{
        if(!openDeleteFood && foodSelectedToDelete){
            setFoodSelectedToDelete(null)
        }
    },[openDeleteFood])


    {/***********************
        Fermeture du dialog
    ***********************/}

    useEffect(()=>{
        if((!openFoodDialog && foodSelected)){
            setTimeout(()=>{
                setFoodSelected(null)
            },200)
        }
    },[openFoodDialog,openDeleteFood])


    /*************** 
     * TABS LABELS 
     * ************* */

    const tabsLabels = useMemo(() => {
        return [
            `Simplifié (${food.app.length})`, 
            `Ciqual (${food.ciqual.length})`,
            `Mes aliments (${food.custom.length})`
        ];
    }, [food]);

    /************** 
     * RECHERCHE 
     * ********** */

    const onSearch = (e: any) => {
        setSearch(e.currentTarget.value);
        dispatch(searchFood({ search: e.currentTarget.value }));
    };


    /******************* 
     * GESTION DES TABS 
     * **************** */

    const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        console.log('new value', newValue)
        setTabs(newValue);
    };


    const FOOD_LIST = useMemo(()=>{
        const tabSelected = LISTS[tabs] as keyof typeof food
        console.log('changement', food[tabSelected])
        return food[tabSelected]
    },[tabs, JSON.stringify(food), pending])



    const onClickFood = (food:FoodType) => ()=>{
        setFoodSelected(food)
        setOpenFoodDialog(true)
    }


    const onSelectFoodToSimplify = (food:FoodType) =>{
        setFoodToSimplify(food)
    }


    useEffect(()=>{
        if(foodToSimplify){
            setOpenSimplifyFood(true)
        }else{
            setOpenSimplifyFood(false)
        }
    },[foodToSimplify])

    



    return(
        <React.Fragment>
            <Header 
                expanded={true}>
                <Grid 
                    container 
                    justifyContent="center"
                >
                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                            <Toolbar 
                                expanded={true}>
                                <PageTitle 
                                    expanded={true}>
                                        Aliments
                                </PageTitle>
                        
                                <Box 
                                    sx={classes.tabs}
                                >
                                    <Tabs 
                                        tabs={tabsLabels} 
                                        value={tabs} 
                                        onChange={onChangeTabs} 
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            flex: 2,
                                            flexDirection: "row",
                                            marginBottom: 1,
                                        }}
                                    >
                                        

                                        {!mobile && (
                                            <SearchTextfield
                                                onChange={onSearch}
                                                placeholder={"Rechercher un aliment"}
                                            />
                                        )}

                                        {!IS_TABLET && (
                                            <ContainedPrimaryButton
                                                variant="contained"
                                                onClick={() => setOpenAddFood(true)}
                                                disableElevation={true}
                                            >
                                                Ajouter un aliment
                                            </ContainedPrimaryButton>
                                        )}
                                    </Box>
                                </Box>
                            </Toolbar>
                       
                    </Grid>
                </Grid>
            </Header>
            <MainContent
                header="withTabs"
            >
                {/***************************************************************
                *          LISTE DES ALIMENTS AVEC MENU DE FILTRAGE
                ***************************************************************/}

                    {(FOOD_LIST.length > 0 && !pending) && (
                        <Grid
                            container
                            justifyContent="center"
                            sx={{ paddingBottom: 10 }}
                        >
                            {/********************* 
                             * LISTE DES ALIMENTS 
                             * *******************/}

                            {/********************* 
                             * BOUTON AJOUT (TABLETTE)
                             * *******************/}

                            <Box 
                                sx={classes.listHeader}
                            >
                                {IS_TABLET && (
                                    <ContainedPrimaryButton
                                        variant="contained"
                                        onClick={() => setOpenAddFood(true)}
                                        disableElevation={true}
                                    >
                                        Ajouter un aliment
                                    </ContainedPrimaryButton>
                                )}
                            </Box>


                            {/*******
                             * LISTE
                             * ******/}

                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >
                                {/*********** 
                                 * RESULTATS 
                                 * ******** */}

                                <Box 
                                    ref={listRef}
                                    sx={classes.container}
                                >
                                    <ViewportList
                                        ref={listRef}
                                        items={FOOD_LIST}
                                        initialPrerender={15}
                                        itemSize={95}
                                    >
                                        {(item) => (
                                            <Box
                                                key={item._id}
                                                onClick={onClickFood(item)}>

                                                <FoodRow
                                                    item={item}
                                                    hideButton={true}
                                                    showBackground={true}
                                                    canDelete={tabs === 2}
                                                    openDialogDelete={onOpenDialogDelete(item)}
                                                    onSelectFoodToSimplify={onSelectFoodToSimplify}
                                                />
                                            </Box>
                                        )}
                                    </ViewportList>
                                </Box>
                            

                                
                            </Grid>
                        </Grid>
                    )}

                        {/**************** 
                         * AUCUN RESULTAT 
                         * ************* */}

                        {(!pending && FOOD_LIST.length === 0) && (
                            <EmptySearch 
                                title={"Aucun aliment"}
                                description={"Aucun aliment trouvé."} 
                            />
                        )}
                    
         
            </MainContent>


            <DialogAddFood
                open={openAddFood}
                onClose={()=> setOpenAddFood(false)}
            />


            <DialogFood
                open={openFoodDialog}
                onClose={()=>setOpenFoodDialog(false)}
                item={foodSelected}
            />



            <DialogDeleteFood
                open={openDeleteFood}
                onClose={()=> setOpenDeleteFood(false)}
                item={foodSelectedToDelete}
            />

            {foodToSimplify && (
                <DialogSimplifiedFood
                    open={openSimplifyFood}
                    onClose={()=>setFoodToSimplify(null)}
                    originalFood={foodToSimplify}
                />

            )}

        </React.Fragment>
    )
}
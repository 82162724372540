import { lighten } from "@mui/material"

const classes = {
    iconButton:{
        backgroundColor: "primary.main", 
        borderRadius:"100% !important", 
        position:"absolute", 
        bottom: 0, 
        right: "-15px",
        color: "white",
        "&:hover" : {
            backgroundColor: (theme:any)=> lighten(theme.palette.primary.main, 0.1)
        }
    }
}


export default classes
import { getPlanning } from "api/clients";
import { AppDispatch, RootState } from "app/store";
import PageTitle from "components/atoms/Typography/PageTitle";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import Planning from "components/organisms/Planning";
import { resetPlanning } from "features/planningSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectDrawerItem } from "features/appSlice";
import Toolbar from "components/molecules/Toolbar";


const weeks = [{_id: "01"},{_id: "02"},{_id: "03"},{_id: "04"},{_id: "05"},{_id: "06"}]




export default function CoachPlanning(){

    const user = useSelector((state:RootState)=> state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const planningStart:string = useSelector((state:RootState) => state.planning.builder.startDate)
    const planningEnd:string = useSelector((state:RootState) => state.planning.builder.endDate)
    const planningIsready:boolean = useSelector((state:RootState) => state.planning.builder.isReady)
    const location = useLocation<{tabs: number, planningRange: {start:Date, end: Date}}>()


    useEffect(()=>{
        dispatch(selectDrawerItem(6))
        if(!planningIsready && user?._id){
            dispatch(getPlanning({
                userId: user._id,
                start: location?.state?.planningRange?.start ? location.state.planningRange.start : new Date(planningStart), 
                end: location?.state?.planningRange?.end ? location.state.planningRange.end : new Date(planningEnd), 
            }))
        }
    },[user, planningIsready])





    /******** CLEANUP ****** */

    useEffect(()=>{
        return function cleanup() {
            dispatch(resetPlanning())
        };
    },[dispatch])


    return(
        <>
                <Header>
                    <Toolbar>
                        <PageTitle>
                            Mon planning
                        </PageTitle>
                    </Toolbar>
                </Header>

                <MainContent>
                    <Planning 
                        startDate={new Date(planningStart)}
                        endDate={new Date(planningEnd)}
                        weeks={weeks}
                        coachProfile={true}
                    />
                </MainContent>
                       

        </>
    )
}
import { Typography } from "@mui/material";

interface Props {
    children: React.ReactNode
}

export default function RowCardSubTitle({children}:Props){

    return(
        <Typography sx={{fontSize: 13, color: "rgba(0,0,0,0.85)"}}>
            {children}
        </Typography>
    )
}
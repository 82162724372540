import { lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {
    programHeader:{
        marginBottom: 3
    },

    name : {
        display:"flex", 
        flexDirection: "row", 
        width: "100%", 
        alignItems:"center", 
        justifyContent:"space-between"
    },

    pageHeader:{

    },

    pageHeaderWithMargin:{
        
    },

    planning: {
        width: "100%",
        position:"relative",
        overflowX: {
            xs: "scroll",
            lg: "hidden"
        },
        marginTop: 3
    },


    week: {
        width: {
            xs: "200%",
            lg: "100%",
        },
        minHeight: 200,
        display:"flex",
        flexDirection: "row",
        position: "relative",
        overflow: "hidden"
    },

    duration : {
        fontWeight: 700, 
        backgroundColor: lighten(theme.palette.primary.main, 0.92)
    }

}


export default classes
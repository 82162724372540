import { theme } from "utils/theme"

const classes = {
    signinLayout:{
        width: "100vw", 
        minHeight: "100vh", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        //backgroundColor: "#ebebeb", 
        flexDirection: "column",
        backgroundColor: "rgb(247 250 252)",
        paddingTop: 6,
        paddingBottom: 6
    },

    card : {
        padding: 12,
        paddingTop: 8,
        paddingBottom: 10,
        width: {
            xs: "100%",
            sm: 720,
            md: 720,
            lg: 720,
        },
        borderRadius:{
            xs:  0,
            sm: "8px"
        }
    },

    textfieldLabel : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(15),
        marginBottom: 1, 
        color: "#545454"
    },

    title: {
        fontSize: 24, 
        fontWeight: 700,
        marginBottom: 3,
        color: "#363636"
    },

    subtitle: {
        fontSize: 16, 
        fontWeight: 500,
        marginBottom: 4,
        color: "#646464"
    },

    passwordHelper: {
        display:"flex", 
        flexDirection: "row"
    },

    passwordHelperElem:{
        marginRight: 2,
        display:"flex",
        alignItems:"center"
    },

    passwordHelperIcon:{
        fontSize:theme.typography.pxToRem(14),
        marginRight: 0.5,
        color: "rgb(203 203 203 / 60%)"
    }
}


export default classes
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import classes from "./styles";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { useMemo } from "react";
import { FoodPortion } from "interfaces/Food";
import { DEFAULT_PORTIONS } from "utils/constants";
import styled from "@emotion/styled";
import { theme } from "utils/theme";
import { onChangeMealRecipeIngredientLabel, onChangeMealRecipeIngredientPortion } from "features/mealPlanSlice";

interface Props{
    ingredientIndex: number,
    recipeIndex: number,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}

const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))

export default function MealRecipeIngredient({ingredientIndex, recipeIndex, mealIndex, day}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const portion = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.portion)
    const foodPortions = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.food.portions)
    const name = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.food.name.fr)
    const label = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.label)
 


    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeMealRecipeIngredientPortion({
            recipeIndex,
            mealIndex,
            ingredientIndex,
            portion: event.target.value,
            day,
            foodPortions
        }))
    }

    const changePortionLabel = (event: SelectChangeEvent<string>) => {
        dispatch(onChangeMealRecipeIngredientLabel({
            recipeIndex,
            mealIndex,
            ingredientIndex,
            newLabel: event.target.value,
            day,
            foodPortions
        }))
    }


    const onDelete = () => {
       
    }

    const onChangeStep = () => {}



    const PORTIONS:FoodPortion[] = useMemo(()=>{
        const allPortions:FoodPortion[] =  [...DEFAULT_PORTIONS]

        
        if(foodPortions){
            return allPortions.concat(foodPortions)
        }else{
            return allPortions
        }
    },[foodPortions])


    return(
        <Box
            sx={classes.item}
        >   
            <Box
                sx={classes.itemInfos}
            >

                <Box
                    sx={classes.infos}
                >

                    <TextField
                        value={portion}
                        sx={classes.portionInput}
                        size="small"
                        onFocus={event => {
                            event.target.select();
                        }}
                        onChange={onChangePortion}
                    />

                    <Select
                        size="small"
                        value={label}
                        onChange={changePortionLabel}
                        sx={classes.labelInput}
                    >
                        {PORTIONS.map((elem, index)=> {
                            return(
                                <MenuItem 
                                    key={index}
                                    value={elem.label.fr}
                                >
                                    {elem.label.fr}
                                </MenuItem>
                            )
                        })}
                        
                        
                            
                    </Select>

                </Box>

                <Typography
                    sx={classes.itemName}
                >
                    {name}
                </Typography>
            </Box>
        </Box>
    )
}
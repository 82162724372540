import { useState, useEffect } from "react";
// MUI
import { TextField, InputAdornment, IconButton, DialogTitle, DialogContent, DialogActions, Button, Dialog, useMediaQuery } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// REDUX
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// INTERFACES
import { AppDispatch, RootState } from "app/store";
// CONSTANTS
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateUserData } from "api/user";



/*********** REDUX SELECTORS *************** */ 



interface Props {
    open: boolean,
    onClose: ()=> void,
}


/*********** MAIN COMPONENT *************** */ 

export default function DialogEmail({open,onClose}:Props){
    
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const [newEmail, setNewEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    useEffect(()=>{
        if(open){
            setNewEmail(user.email)
            setPassword('')
        }
    },[open])


    const submit = () => {
        dispatch(updateUserData({newEmail,currentPassword: password})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
        
     
    }

    return(

        <Dialog 
            open={open} 
        >
                <DialogTitle>
                    Modifier ton adresse email
                </DialogTitle>
                <DialogContent sx={{width:mobile ? "100%" : DIALOG_WIDTH}}>
                    <TextField
                        fullWidth
                        label="Nouvelle adresse email"
                        onChange={(e)=> setNewEmail(e.target.value)}
                        value={newEmail}
                        sx={{marginTop: 1, marginBottom: 2}}
                    />

                    <TextField
                        fullWidth
                        label="Indique ton mot de passe"
                        onChange={(e)=> setPassword(e.target.value)}
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        sx={{marginTop: 1, marginBottom: 2}}
                        autoComplete="off"
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=> setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={pending}>
                        Annuler
                    </Button>
                    <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                        {pending? "Modification ..." : "Valider"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
      
    )
}
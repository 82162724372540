import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { deleteClient } from "api/clients";
import { AppDispatch, RootState } from "app/store";
import User from "interfaces/User";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";

interface Props{
    open: boolean,
    onClose: ()=>void,
    client: User
}

export default function DialogDeleteClient({open, onClose, client}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.clients.requests.deleleteClient === "pending")

    
    /*************** SUPPRRESSION D'UN CLIENT ************ */
    const onDeleteClient = () => {
        dispatch(deleteClient(client._id)).unwrap().then(()=>{
            onClose()
        })
    }


    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer un client
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Veux-tu supprimer <strong>{client?.firstname} {client?.lastname}</strong> de ta liste de clients ? Tu ne pourras plus lui créer de programme ou consulter ses statistiques
                </Typography>
            </DialogContent>
            <DialogActions>
                    <Button onClick={onClose}>
                        Annuler
                    </Button>
                    <LoadingButton disabled={pending} loading={pending} onClick={onDeleteClient}>
                        {pending? "Suppression ..." : "Confirmer"}
                    </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { Block, Check, ContentCopy, CopyAll, Delete, MoreVert } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/store";
import { addDays, isSameDay, isWithinInterval } from "date-fns";
import { addWeeks, differenceInCalendarWeeks } from "date-fns/esm";
import Workout from "interfaces/Workout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import classes from "./styles"
import { copyWeekPlanning } from "features/planningSlice";
import DialogDeleteWeek from "components/organisms/DialogDeletePlanningWeek";
import DialogActivateWorkouts from "components/molecules/DialogActivateWorkouts";
import SuccessPopper from "components/molecules/SuccessPopper";
import { pasteMultiplesWorkouts } from "api/workouts";
import removePerformedSets from "function/removePerformedSets";


interface Props{
    weekStart: string,
    weekEnd: string,

}


const weekWorkoutsSelector = createSelector([
    (state:RootState) => state.planning.builder,
    (_, weekStart:string) => new Date(weekStart),
    (_, __, weekEnd:string) => new Date(weekEnd),
],
    (planning, weekStart, weekEnd) => planning.workouts.filter((workout:Workout) => isWithinInterval(new Date(workout.schedule), {start: weekStart, end: addDays(weekEnd,1)}))
)


export default function PlanningWeekButton({weekStart, weekEnd}:Props){
    
   
    const [anchorEl, setAnchorEl] = useState(null)
    const dispatch = useDispatch<AppDispatch>()
    const weekCopied = useSelector((state:RootState) => state.planning.builder.weekCopied)
    const user = useSelector((state:RootState)=> state.planning.user)
    const coach = useSelector((state:RootState)=> state.user.data)
    const workouts:Workout[] = useSelector((state:RootState)=> weekWorkoutsSelector(state,weekStart,weekEnd))
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [openActivateWorkouts, setOpenActivateWorkouts] = useState<boolean>(false)
    const [action,setAction] = useState<"enable" | "disable">("enable")
    

     /******** GESTION DU MENU ******* */
     const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    /***** COPIE DE LA SEMAINE ****** */
    const onCopyWeek = (event: React.MouseEvent) => {

        dispatch(copyWeekPlanning({workouts, weekStart, weekEnd}))
        setPopoverAnchorEl(event.currentTarget)
        handleCloseMenu()
    }


    /***** COLLAGE- DE LA SEMAINE ****** */
    const onPasteWeek = (enabled:boolean) => ()=>{
        const noti = toast.loading("Copie des séances ...")
        handleCloseMenu()
        const newWorkoutsList:Workout[] = []
        const weeksDifference:number = differenceInCalendarWeeks(new Date(weekStart), new Date(weekCopied.weekStart))


        weekCopied.workouts.forEach((workout)=>{

            const newDate = new Date(addWeeks(new Date(workout.schedule), weeksDifference)).toDateString()

            const thereIsAWorkoutThisDay = workouts.find((elem)=> isSameDay(new Date(elem.schedule), new Date(newDate)))


            if(!thereIsAWorkoutThisDay){
                const purgedWorkout = removePerformedSets({...workout})
                newWorkoutsList.push({
                    ...purgedWorkout,
                    _id: undefined,
                    schedule: newDate,
                    status:enabled? "enabled" : "disabled",
                    template: false,
                    performed: null,
                    createdFor: user?._id,
                    createdBy: coach?._id
                })
            }
        })

        try{

            dispatch(pasteMultiplesWorkouts({workouts: newWorkoutsList})).unwrap().then(()=>{
                toast.update(noti, {type: toast.TYPE.SUCCESS, render:"Séances dupliquées", isLoading: false, autoClose:1500})
            })
           
            
        }catch(err){
            toast.update(noti, {type: toast.TYPE.ERROR, render:"Erreur dans la copie de la semaine", isLoading: false, autoClose:1500})
        }
    }


    const onDelete = () => {
        setOpenDelete(true)
        handleCloseMenu()
    }


    const onOpenHandleeWorkoutsStatus = (toDo:"enable"|"disable") => ()=>{
        setAction(toDo)
        setOpenActivateWorkouts(true)
        handleCloseMenu()
    }

    
    /***** Fermeture auto du popover ****** */
    useEffect(()=>{
        if(popoverAnchorEl){
            setTimeout(()=>{
                setPopoverAnchorEl(null)
            },800)
        }
    },[popoverAnchorEl])



    return(
        <React.Fragment>

            {/***** BOUTON *********/}
            <Button sx={classes.weekButtons} onClick={openMenu}>
                <MoreVert sx={{color: "primary.main"}} />
            </Button>
            
            {/***** AFFICHAGE DU MESSAGE "COPié" *********/}

            <SuccessPopper
                open={Boolean(popoverAnchorEl)}
                anchorEl={popoverAnchorEl}
                onClose={()=> setPopoverAnchorEl(null)}
                text="Séances de la semaine copiées !"
            />

            {/***** MENU *********/}
            <Menu disableScrollLock={true} 
                id="week-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >

                {/****** Séances ****** */}
                <Typography sx={[classes.menuLabel]}>
                    Semaine
                </Typography>

                {/*** PLANNING PERSONNALISE ***** */}
                {user && (
                <MenuItem onClick={onOpenHandleeWorkoutsStatus("enable")}>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText>
                        Activer les séances
                    </ListItemText>
                </MenuItem>
                )}

                {/*** PLANNING PERSONNALISE ***** */}
                {user && (
                    <MenuItem onClick={onOpenHandleeWorkoutsStatus('disable')}>
                        <ListItemIcon>
                            <Block />
                        </ListItemIcon>
                        <ListItemText>
                            Désactiver les séances
                        </ListItemText>
                    </MenuItem>
                )}
                
                <MenuItem onClick={onCopyWeek}>
                    <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                    <ListItemText>
                        Copier les séances
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onPasteWeek(true)} disabled={!weekCopied}>
                    <ListItemIcon>
                        <ContentCopy />
                    </ListItemIcon>
                    <ListItemText>
                        Coller les séances (activées)
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onPasteWeek(false)} disabled={!weekCopied}>
                    <ListItemIcon>
                        <ContentCopy />
                    </ListItemIcon>
                    <ListItemText>
                        Coller les séances (désactivées)
                    </ListItemText>
                </MenuItem>
                
                <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer les séances
                    </ListItemText>
                </MenuItem>
               
                   
            </Menu>

            {/***** DIALOG SUPPRESSION *********/}
            <DialogDeleteWeek
                open={openDelete}
                onClose={()=> setOpenDelete(false)}
                weekStart={weekStart}
                weekEnd={weekEnd}
            />

            <DialogActivateWorkouts
                open={openActivateWorkouts}
                onClose={()=> setOpenActivateWorkouts(false)}
                dateRange={{start:weekStart, end: weekEnd}}
                action={action}
            />

        </React.Fragment>
    )
}
import { lighten } from "@mui/material"
import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR} from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    header: {
        display: "flex", 
        justifyContent:"flex-start", 
        alignItems:"flex-start",
        paddingBottom:0,
        flexDirection: "column",
        overflow: "hidden",
        width: "100%"
    },

    userData:{
        display:"flex", 
        flexDirection:"row",
        marginBottom: 0,
        //padding: 3,
        //paddingBottom: 3,
        justifyContent: "space-between",
        flex: 1,
        alignItems:"center",
        width: "100%"
    },

   avatarContainer: {
        borderRadius: "100%",
        border: `dashed 3px ${theme.palette.primary.main}`,
        marginRight: {
            xs: 2,
            md: 3,
        }, 
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "relative",
        overflow: "visible",
        width: {
            xs: 52,
            sm: 65,
        }, 
        height: {
            xs: 52,
            sm: 65,
        }, 
   },
        
    avatar:{
        width: {
            xs: 45,
            sm: 60,
        }, 
        height: {
            xs: 45,
            sm: 60,
        }, 
    },

    email: {
        textOverflow: "ellipsis"
    },

    clickableAvatar:{
        cursor: "pointer"
    },

    deleteAvatarButton: {
        backgroundColor: LIGHT_BUTTON_COLOR,
        borderRadius: "100px !important",
        color: "black",
        position: "absolute",
        bottom: -15,
        right: -15,

        "&:hover":{
            backgroundColor: HOVER_COLOR
        }
    },

    infos:{
        display: "flex", 
        flexDirection: "column" ,
        flexGrow: 1,
        //overflow: "hidden",
        justifyContent: {
            xs: "center",
            md: "flex-start"
        }
    },

    tabs :{
        display:"flex", 
        flexDirection:"row", 
        width: "100%", 
        borderBottom: `solid 1px ${BORDER_COLOR}`
    },

    tabSelected: {
        backgroundColor: LIGHT_BUTTON_COLOR, 
        color: "primary.main", 
        borderRadius: "8px 8px 0px 0px !important",

    },

    tabItem :{
        backgroundColor:"transparent", 
        color: "#dddddd", 
        height: 50,
        width: 200,


        "&:hover":{
            backgroundColor: lighten(theme.palette.primary.main, 0.95)
        }
    },

    userName: {
        fontWeight: 700, 
        fontSize: {
            xs: theme.typography.pxToRem(18),
            md: theme.typography.pxToRem(30),
        }
    }
    
}   

export default classes
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateClientMealPlan } from "api/clients";
import MealPlan from 'interfaces/MealPlan';
import { mealPlanIsSaving } from "features/mealPlanSlice";

/***** INTERFACES ****** */

interface Props{
    open: boolean,
    onClose: ()=> void,
}



export default function DialogActivateMealPlan({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.mealPlans.requests.update === "pending")
    const mealPlan = useSelector((state:RootState) => state.mealPlans.mealPlan)
    const client = useSelector((state:RootState) => state.clients.profile)

    /***** CURRENT STATUS ****** */
    const enabled = useMemo(()=>{
        if(mealPlan?.status === "enabled") return true
        return false
    },[mealPlan?.status])


    /***** SUBMIT ****** */
    const onSubmit = () => {
        const newStatus = enabled ? "disabled" : "enabled"
        var newMealPlan:MealPlan = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
            status:newStatus
        }
        
        if(mealPlan){
            newMealPlan =  {
                ...mealPlan,
                status:newStatus
            }
        }

        dispatch(updateClientMealPlan({mealPlan: {...mealPlan, status:newStatus}, clientId:client._id})).unwrap().then((res:any)=>{
            if(!res.client){
                toast.error("Impossible d'activer le plan alimentaire")
            }else{
                if(newStatus === "disabled"){
                    toast.success('Plan alimentaire désactivé')
                }else{
                    toast.success('Plan alimentaire activé')
                }   
                
            }
            onClose()

        })
  
    }

    return(
        <Dialog 
            open={open}
        >
            <DialogTitle>
                {enabled ? "Désactiver le plan alimentaire" : "Activer le plan alimentaire"}
            </DialogTitle>
                
      
            <DialogContent>
                Souhaites-tu {enabled ? "désactiver" : "activer"} le plan alimentaire de {client.firstname} ? Ton client pourra consulter le plan alimentaire via l'application mobile et enregistrer ses repas.
            </DialogContent>
           
            <DialogActions>
       
                <Button 
                    onClick={onClose} 
                    disabled={pending}
                >
                    Annuler
                </Button>
                
                <LoadingButton 
                    variant="contained" 
                    onClick={onSubmit} 
                    loading={pending} 
                    disabled={pending}
                >
                    Confirmer
                </LoadingButton>
            </DialogActions>
        
        </Dialog>
    )
}
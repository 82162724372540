
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material"
import { AppDispatch } from "app/store"
import Checkup, { CheckupQuestion } from "interfaces/Checkup"
import { useDispatch } from "react-redux"
import classes from "./styles"
import { CheckBox, CheckBoxOutlineBlankOutlined, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material"
import { theme } from "utils/theme"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { MOBILE } from "utils/constants"


interface Props{
    name: string,
    questions: CheckupQuestion[],
    open: boolean,
    onClose: ()=> void,
    date: Date
}


export default function DialogQuestionnaireResume({questions, name, open, onClose, date}:Props){


    const mobile = useMediaQuery(MOBILE)

    return(
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={"sm"}
            fullWidth={true}
            fullScreen={mobile? true : false}
        >
            <DialogTitle>
                {name}
                <Typography sx={classes.meta}>
                    Le {date ? format(new Date(date), "eeee dd MMM yyyy", {locale: fr}) : ""}
                </Typography>
            </DialogTitle>

            <DialogContent>

                

                {questions?.map((question,index)=>{
                    return(
                        <Box key={index} sx={classes.questionContainer}>
                            <Typography sx={classes.question}>
                                {index+1} - {question.question}
                            </Typography>

                            {question.type==="comment" && (
                                <Typography sx={classes.option}>
                                    {question.answer ? question.answer[0] : "Aucune réponse"}
                                </Typography>
                            )}

                            {(question.type==="checkbox" || question.type === "radio") && question.options.map((option, optionIndex)=>{

                                const checked = Boolean(question.answer?.find((elem)=> elem === option))

                                return(
                                    <Box key={optionIndex} sx={classes.optionContainer}>
                                        {(question.type === "checkbox" && checked) && (
                                            <CheckBox 
                                                sx={{marginRight: 1, color: theme.palette.primary.main}} 
                                                
                                            />
                                        )}

                                        {(question.type === "checkbox" && !checked) && (
                                            <CheckBoxOutlineBlankOutlined
                                                sx={{marginRight: 1, color: "#aaaaaa"}} 
                                            />
                                        )}

                                        {(question.type === "radio" && checked) && (
                                            <RadioButtonChecked 
                                                sx={{marginRight: 1, color: theme.palette.primary.main}} 
                                            />
                                        )}

                                        {(question.type === "radio" && !checked) && (
                                            <RadioButtonUnchecked
                                                sx={{marginRight: 1, color: "#aaaaaa"}} 
                                            />
                                        )}

                                        <Typography style={classes.option} >
                                            {option}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
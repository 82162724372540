import { theme } from "utils/theme"


export const classes = {

    sectionLabel:{
        width: "100%",
        marginBottom: 2,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18)
    },
}

export default classes
import { useState} from "react";
import { Box, Typography, Dialog, DialogContent, IconButton, CircularProgress, Button } from "@mui/material";
import { NUTRITION_MODULE_FEATURES } from "utils/constants"
import { CheckCircle, Close } from "@mui/icons-material";
import { createSelector } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createCustomerId } from "api/user";
import StripePrice from "interfaces/StripePrice"; 
import LoadingButton from "components/atoms/Buttons/LoadingButton";
//import { enableNutritionModule } from "features/userSlice";
import { addDays } from "date-fns";
import { createNewModuleTrial } from "api/subscriptions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { handleNutritionPresentation } from "features/appSlice";

// REDUX SELECTOR //

const userSelector = createSelector(
    (state:RootState) => state.user,
    (user) => user.data
)


/*const priceSelector = createSelector(
    (state:RootState) => state.subscriptions,
    (subscriptions) => {
        return subscriptions.list.find((sub:StripePrice)=> {
                return sub.product.metadata.trial === "1" && sub.product.metadata.nutrition === "1"
        })
    }
)*/

// INTERFACES //

interface DialogTrialProps{
    open: boolean,
    onClose: ()=> void
}


export default function DialogNutritionModulePresentation({open, onClose}:DialogTrialProps){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector(userSelector)
    //const price:StripePrice = useSelector(priceSelector)
    const [submiting, setSubmiting] = useState(false)
    

    /********** DEMARRAGE DU TRIAL ******** */

    const onSubmit = async() => {

        setSubmiting(true)

        /***** CREATION D'UN CUSTOMER ID (le cas échéant) ******/
        if(!user.customerId){
            await dispatch(createCustomerId())
        }


        /*dispatch(createNewModuleTrial(price)).unwrap().then((res:any)=>{
            if(res.module){
                const end:Date = res.module.nutrition.end
                dispatch(enabledNutritionModule({end: end.toString()}))
                toast.success(`Période d'essai au module Nutrition commencée`)
                
            } else {
                toast.error("Impossible d'activer le module Nutrition")
            }

            dispatch(handleNutritionPresentation({open: false}))
            setSubmiting(false)
     
        })*/
        
        
    }



    return(

        <Dialog open={open}>
            <DialogContent sx={{width: 620,overflow: "hidden"}}>
                
                <Box sx={{padding: 5}}>
                    {/*<Typography variant='h4' sx={{fontWeight: 700, marginBottom: 3, textAlign:"center"}}>
                        {user.subscription?.current?.subscriptionId && (!user.modules?.nutrition || !user.modules?.nutrition?.trialIsDone) ? "Essayer le module nutrition" : "Module Nutrition nécessaire"}
                    </Typography>
                    <Box 
                        component="img" 
                        src='/assets/nutrition_module.svg' 
                        sx={{width: 500, marginBottom: 3}} 
                    />
                    <Typography sx={{fontWeight: 400, marginTop: 2, fontSize: 18}}>
                        {user.subscription?.current?.subscriptionId && (!user.modules?.nutrition || !user.modules?.nutrition?.trialIsDone) ? "Testez gratuitement les fonctionnalités de notre module nutrition et proposez à vos clients un suivi nutritionnel optimal et journalier." : "Votre période d'essai du module Nutrition est terminée. Pour continuer, vous devez souscrire à un abonnement comprenant le module nutrition. Ce module comprend les fonctionnalités suivantes :"}
                    </Typography>
    */}

                    <Box sx={{mt:2}}>
                        {NUTRITION_MODULE_FEATURES.map((feature, index)=> {
                            return(
                                <Box sx={{display:"flex", flexDirection:"row", height: 48, alignItems:"center"}} key={index}>
                                    <CheckCircle sx={{color: "primary.main", marginRight: 1}} />
                                    <Typography sx={{fontWeight: 500, fontSize: 18}}>
                                        {feature.label}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>

                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", mt:3, flexDirection: "column"}}>
                        {(user.subscription?.current?.subscriptionId && (!user.modules?.nutrition)) && (
                            <Button 
                                startIcon={submiting ? <CircularProgress sx={{color: "white"}} size={20} /> : null}
                                variant="contained" 
                                sx={{height: 48, borderRadius: "20px !important", fontSize: 18, textTransform: "none", pl: 4, pr:4}} 
                                onClick={onSubmit}
                                disabled={submiting}>
                                    {submiting ? "Activation..." : "Commencer l'essai"}
                            </Button>
                        )}


                        <Button 
                            sx={{height: 40, marginTop: 2, borderRadius: "20px !important", fontSize: 18, textTransform: "none", pl: 4, pr:4}} 
                            onClick={()=> {
                                history.push('/subscriptions')
                                onClose()
                            }} 
                            disabled={submiting}>
                                Découvrir nos abonnements
                        </Button>
                    </Box>

                    {/****** BOUTON CLOSE ****** */}
                    <Box sx={{position: "absolute", right: 10, top: 10}}>
                        <IconButton sx={{backgroundColor: "#eaeaea", borderRadius: "100% !important"}} onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>

            
        </Dialog>
            
    )
}
import { darken, lighten } from "@mui/material"
import { HOVER_COLOR } from "utils/constants"
import { LIGHT_BUTTON_COLOR } from "utils/constants"
import { BORDER_COLOR } from "utils/constants"
import { theme, primaryColor } from "utils/theme"

const classes = {


    title: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },

    header:{
        display:"flex", 
        justifyContent:"flex-start", 
        width: "100%", 
        padding: 2, 
        marginBottom: 3, 
        marginTop: 3,
        border: `solid 1px ${BORDER_COLOR}`,
    },
    
    section: {
        border: `solid 1px ${BORDER_COLOR}`,
        marginBottom: 3,
        padding: 2,
        backgroundColor: "white"
    },

    sectionTitle:{
        
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(22),
        marginBottom: 2
    },

    sectionRow:{
        display:"flex", 
        padding: 2, 
        flexDirection: {
            xs: "column",
            md:"row",
        }
    },

    clickable:{
        cursor: "pointer",
        "&:hover":{
            backgroundColor: "whitesmoke"
        }
        
    },

    rowLabel:{
        width: 200, 
        textTransform: "uppercase", 
        color: "#a3a3a3", 
        fontSize: theme.typography.pxToRem(14)
    },


    rowValue:{
        display:"flex", 
        flex: 1
    },

    subscriptionName: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        color: darken(theme.palette.primary.main, 0.3)
    },

    subscriptionPrice:{
        fontSize: theme.typography.pxToRem(30),
        fontWeight: 700,
        color: "black"
    },

    button: {
        backgroundColor: LIGHT_BUTTON_COLOR,
        marginRight: 1,
        "&: hover":{
            backgroundColor: HOVER_COLOR
        },
        textTransform: "none"
    },

    buttonDelete:{
        backgroundColor: "#ffa9a9",
        color: "white",
        "&: hover":{
            backgroundColor: "#ff9292"
        }
    },

    buttonsContainer:{
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        mt:2,
        mb:1
    },

    subscriptionRenew: {
        fontSize: theme.typography.pxToRem(15),
        marginTop: 1
    }
}

export default classes
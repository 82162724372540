import { Typography } from "@mui/material";
import React from "react";

interface Props{
    children: React.ReactNode
}

export default function ContextMenuCategory({children}:Props){
    return(
        <Typography sx={{ml: 3, mt:2, mb:1, color: "#83b4eb", fontSize: "1rem", fontWeight: 700}}>
            {children}
        </Typography>
    )
}

import { 
    DRAWER_PADDING, 
    DRAWER_WIDTH, 
    DRAWER_WIDTH_MIN
} from "utils/constants";
import { theme } from "utils/theme";


const transitionDuration = 200
const ITEM_HEIGHT = DRAWER_WIDTH_MIN - (DRAWER_PADDING*8) * 2

const classes = {

    /*************
        LOGO
    **************/

    logoContainer:{
        height: ITEM_HEIGHT,
        width:"100%",
        alignItems:"center",
        justifyContent:"flex-start",
        display:"flex",
        marginBottom: 3,
        marginTop: 3,
    },

    logo:{
        height: 24,
        width:150,
        marginLeft: 1
    },

   

    bottomNav : {
        position: "fixed", 
        height: {
            xs: 60,
            sm: 80
        }, 
        bottom: 0, 
        zIndex: 1000, 
        backgroundColor: "white", 
        width: "100vw", 
        paddingLeft: {
            xs: 1,
            md:3
        }, 
        paddingRight:  {
            xs: 1,
            md:3
        }, 
    },

    drawer:{
        width: 0,
        position: "fixed",
        backgroundColor: "white",
        top: 0,
        left: 0,
        height: "100vh",
        display:"flex",
        justifyContent:"space-between",
        minWidth: DRAWER_WIDTH_MIN,
        zIndex: 1199,
        transition: theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: transitionDuration,
        }),
        overflow: "hidden",
        paddingLeft: 1.5,
        paddingRight: 1.5,

        "&:hover":{
            width: DRAWER_WIDTH - (DRAWER_PADDING * 8) * 2,
        }
        
    },


    openDrawer: {
        width: DRAWER_WIDTH - (DRAWER_PADDING * 8) * 2,
    },

    paper:{

        borderRight: 0,
        position: "relative !important",
        overflow: "hidden",
        transition:(theme:any)=> theme.transitions.create(['width', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: transitionDuration,
        }),
        width: "100%",
        paddingBottom: 4,
    },
    

    
}


export default classes
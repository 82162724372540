import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { createMealPlanTemplate } from "api/mealPlans";
import { AppDispatch, RootState } from "app/store";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import MealPlan from "interfaces/MealPlan";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


interface Props{
    open: boolean,
    onClose: ()=> void,
}


export default function DialogCreateMealTemplate({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [templateName, setTemplateName] = useState<string>("")
    const pending = useSelector((state:RootState) => state.mealPlans.requests.create === "pending")
    const createdBy = useSelector((state:RootState) => state.user.data._id)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value)
    }

    const saveTemplate = () => {
        const newMealPlan:MealPlan = {
            name: templateName,
            createdBy,
            status: "disabled",
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
            template: true
        }


        if(templateName === ""){
            toast.error('Le modèle doit avoir un nom')
        } else{
            dispatch(createMealPlanTemplate({
                mealPlan: newMealPlan, 
                name:templateName, 
                createdBy
            })).unwrap().then((res:any)=>{
                if(res.mealPlan){
                    toast.success('Modèle créé')
                    onClose()
                } else {
                    toast.error("Erreur serveur - Impossible de créer un nouveau modèle.")
                }
            })
        }
    }

    return(
        <Dialog
            open={open}
            fullWidth 
            maxWidth={"xs"}
        >
            <DialogTitle>
               Nouveau modèle
            </DialogTitle>
            <DialogContent>
                <TextField 
                    value={templateName}
                    onChange={onChange}
                    label={"Nom du modèle"}
                    fullWidth
                    sx={{mt:1}}

                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={pending}
                >
                    Fermer
                </Button>
                <LoadingButton 
                    variant="contained" 
                    onClick={saveTemplate}
                    loading={pending}
                >
                    Valider
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
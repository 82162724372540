import { darken, lighten } from "@mui/material"
import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"
import { theme, primaryColor } from "utils/theme"


const classes = {
    chip:{
        fontWeight: 500, 
        width: 150, 
        fontSize: 15, 
        marginLeft: 1, 
        display:"flex",
        justifyContent:"flex-start",
        color:"#adadad", 
        border: `solid 1px ${BORDER_COLOR}`,
        backgroundColor:  "#f7f7f7",
        "&:hover" : {
            backgroundColor: darken("#f7f7f7",0.01)
        }
    },

    selected: {
        fontWeight: 700, 
        color: primaryColor,
        border: `solid 1px ${lighten(theme.palette.primary.main,0.85)}`,
        backgroundColor: HOVER_COLOR
    }
    
}

export default classes
import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    card:{
        width: "100%", 
        padding: {xs: 2, sm:3}, 
        marginBottom: 3,
        border: {xs: 0, sm:`solid 1px ${BORDER_COLOR}`},
        borderTop: `solid 1px ${BORDER_COLOR}`,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        borderRadius: {xs: 0, sm:2},
        backgroundColor: "white",
        position: "relative"
    },

    questionIcon : {
        marginRight: 2,
        backgroundColor: "whitesmoke",
        
    },


    questionContainer : {
        display:"flex",
        alignItems:"center",
        justifyContent: "flex-start",
        marginBottom: 2,
        flex: 1,
        maxWidth: "90%"
    },

    header:{
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        width: "100%", 
        alignItems: "center" 
    },

    radioGroup:{
        display: "flex", 
        flexDirection: {
            xs: "column",
            sm: "row"
        },
        alignItems:{
            xs: "flex-start",
            sm: "center"
        },
        marginTop: 2, 
        marginBottom: 2
    },

    option:{
        display:"flex", 
        flexDirection: "row", 
        alignItems:"center", 
        mb: 1.5
    },

    respondeTypeLabel:{
        marginRight: 2,
        fontWeight: 400,
        color: "#a2a2a2",
        fontSize: "1rem",
        marginBottom: {
            xs: 1,
            sm: 0
        }
    },

    fakeTextArea: {
        backgroundColor: "#f8f8f8",
        width: {
            xs: "100%",
            sm: 400,
        },
        height: 70,
        padding: 1.5,
        color: "#b9b9b9",
        border: "solid 1px #ededed",
        fontSize: "0.9rem",
        borderRadius: "8px"
    },

    deleteButton : {
        position: "absolute",
        top: 0,
        right: 0,
        backgroundColor: "whitesmoke",
        borderRadius: "0px 5px 0px 5px"
    }
}


export default classes

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App';


const root = createRoot(document.getElementById('root'));
root.render(<App />);

root.render(
    <Provider store={store} >
        <Router basename={process.env.REACT_APP_BASE_URL}>
            <App />
        </Router>
    </Provider>
)

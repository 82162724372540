import { darken, lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {


    card: {
        cursor: "pointer",
        position: "relative",
        height: "100%"
    },

    nameContainer : {
        padding: 2,
        paddingTop: 0.5,
        paddingBottom: 0,
        display:"flex",
        flexDirection: "row"
    },

    recipeName: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        display:"flex",
        alignItems:"center",
        width: "100%",
        marginTop:1
    },

    imageContainer : {
        width: "100%",
        backgroundColor: "#d5e0ea",
        position: "relative",
        overflow: "hidden"
        
    },

    tags:{
        display:"flex",
        flexDirection: "row",
        paddingLeft: 2,
        paddingRight: 2,
        flexWrap: "wrap",
        paddingBottom: 3
    },

    tag : {
        display:"flex",
        backgroundColor: "whitesmoke",
        fontSize: theme.typography.pxToRem(12),
        padding: 0.5,
        paddingLeft:1,
        paddingRight:1,
        marginTop: 1,
        marginRight: 2,
        borderRadius: 1,
        fontWeight: 600
    },

    cover : {
        objectFit: "cover",
    
    },


    moreButton: {
        width: 48,
        height: 48, 
        display:"flex", 
        //position: "absolute", 
        borderRadius: "100px !important",
        backgroundColor: "white",
        //top: 15, 
        //right: 15,
        zIndex: 3
    },

    videoIndicator:{
        position: "absolute",
        bottom: 15,
        zIndex: 2,
        right: 15
    }
    
}


export default classes
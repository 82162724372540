import { BODY_BGCOLOR, HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {

    header: {
        //paddingBottom: 3,
        flexDirection: "column", 
        display:"flex",
        justifyContent:"space-between", 
        alignItems:"flex-start", 
    },


    toolbar : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginBottom:0.5
    },

    filtersChips : {
        flexDirection: "row",
        paddingTop: 1,
        
    },

    exerciceRow:{
        display: "flex",
        flexDirection: "row",
        height: 80,
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "solid 1px #eeeeee",
        paddingLeft: 3,
        paddingRight: 3,
        "&:hover": {
            backgroundColor: HOVER_COLOR
        }
    },

    exerciceInfos : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    title:{
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
       
    },

    thumbnail : {
        width: 120, 
        marginRight: 2, 
        backgroundColor: "whitesmoke" 
    },

    thumbnailContainer:{
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center"
    },

    dialogContent:{
        padding: 0, 
        position: "relative", 
        overflow: "hidden", 
        boxSizing: "border-box",
        maxWidth: "100%",
        minHeight: {
            xs: "auto",
            md:"calc(100vh - 64px - 120px - 55px)"
        },
        backgroundColor: BODY_BGCOLOR
    },

    searchContainer : {
        display:"flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        justifyContent:"space-between",
        alignItems:"center"
    },

    scrollContainer:{
        paddingRight: {xs: 1.5, sm: 3},
        paddingTop: {xs: 1.5, sm: 3}, 
        paddingLeft: {xs: 1.5, sm: 3},
        overflowY: "scroll", 
        maxHeight: {
            xs: `calc(100vh - 32px - 48px)`,
            sm: `calc(100vh - 121px - 64px - 53px)`
        },
        position: "relative",
        display:"flex",
        flexDirection :"column",
        flex: 1
    }
}

export default classes
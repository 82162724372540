import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { searchRecipe } from "features/recipesSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import Tabs from "components/molecules/Tabs";
import SearchTextfield from "components/molecules/Search";
import { ViewportList } from "react-viewport-list";
import Thumbnail from "components/atoms/Thumbnail";
import Meal from "interfaces/Meal";
import calcMacros from "function/calcMacros";
import { Add, Check, FilterList } from "@mui/icons-material";
import RecipesFilters from "../RecipesFilters";
import { MOBILE, RECIPE_TAGS } from "utils/constants";
import Recipe from "interfaces/Recipe";
import { onAddRecipeToMeal } from "features/mealPlanSlice";

interface Props{
    open: boolean,
    onClose: ()=> void,
    mealIndex?: number,
    day?: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}

export default function DialogRecipesList({open, onClose, mealIndex, day}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const meal = useSelector((state:RootState)=> state.clients.profile?.mealPlan && day ? state.clients.profile?.mealPlan[day][mealIndex] : null)
    const mealRef= useRef(meal)
    const listRef = useRef(null);
    const recipesList = useSelector((state:RootState)=> state.recipes.filtered)
    const mobile = useMediaQuery(MOBILE)
    
    const [search, setSearch] = useState<string>('')
    const [tabs, setTabs] = useState(0)
    const tagsFilter = useSelector(
        (state: RootState) => state.recipes.tagsFilter
    );
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);


    useEffect(()=>{
        mealRef.current = meal
    },[JSON.stringify(meal)])

    {/************************
        OUVERTURE DES FILTRES
    **************************/}

    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };

    {/************************
        Fermeture du dialog
    **************************/}

    const closeDialog = () => {
        setTimeout(()=>{
            setSearch("")
            dispatch(searchRecipe({search:""}))
        },200)
        onClose()
    }

    {/************************
        Recherche
    **************************/}

    const onSearch = (e:any) => {
        setSearch(e.currentTarget.value)
        dispatch(searchRecipe({search:e.currentTarget.value}))
    }

    /******************* 
     * GESTION DES TABS 
     * *****************/

    const handleTabs = (_: React.SyntheticEvent, value: number) => {
        setTabs(value)
    }

    /********** TABS LABELS ********** */

    const tabsLabels = useMemo(() => {
        return [
            `Mes recettes (${recipesList.custom.length})`,
        ]
    }, [recipesList])


    const onAdd = (recipe:Recipe) => {
        dispatch(onAddRecipeToMeal({
            day,
            index: mealIndex,
            recipe
        }))
    }




    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            disableScrollLock={true}
            fullScreen={mobile}
        >
            <DialogTitle>
                Ajouter une recette {meal ? "à " + meal.name : "au menu"}
            </DialogTitle>

            <DialogContent 
                sx={classes.dialogContent}
            >

                {/***************
                 *   HEADER
                 ***************/}

                <Box
                    sx={classes.header}
                >
                    {/**************** 
                     * SEARCH + TABS 
                     * ************ */}

                    <Box 
                        sx={classes.searchContainer}
                    >
                        <Tabs 
                            tabs={tabsLabels} 
                            value={tabs} 
                            onChange={handleTabs} 
                        />
                                
                        <Box sx={{display:"flex", flexDirection: "row"}}>

                            <IconButton
                                sx={{ marginRight: 1 }}
                                onClick={onOpenFilterList}
                            >
                                <Badge
                                    badgeContent={tagsFilter.length} 
                                    color="primary"
                                >
                                <FilterList />
                                </Badge>
                            </IconButton>
                            
                            <SearchTextfield
                                placeholder="Rechercher une recette"
                                onChange={onSearch}
                                fullWidth={true}
                                borderRadius={0}
                            />
                        </Box>
                    </Box>

                </Box>


                {/**********************
                 *   LISTE DES RECETTES
                 ***********************/}
                <Box 
                    ref={listRef} 
                    className="scroll-container" 
                    sx={classes.scrollContainer}
                >
                    <ViewportList
                        ref={listRef}
                        items={recipesList.custom}
                        initialPrerender={15}
                        itemSize={95}
                    >
                        {(item, index)=> {

                            //const found = meal.content.findIndex((elem)=> elem.recipe?._id === item._id) > -1
                            //console.log('FOUND', found)

                            const found = false

                            // Calcul des macros
                            const meals:Meal[] = [{
                                name:item.name.fr,
                                time:"00:00",
                                content:[]
                            }]

                            item.ingredients.forEach((elem)=> {
                                meals[0].content.push({
                                    type: "food",
                                    food: elem.ingredient.food,
                                    portion: elem.ingredient.portion,
                                    label: elem.ingredient.label,
                                    grammage: elem.ingredient.grammage
                                })
                            })

                            const macros = calcMacros(meals)
                         

                            return(

                                <Box
                                    key={index}
                                    sx={classes.recipeItem}
                                >
                                    <Thumbnail
                                        image={item.cover?.url ? item.cover : {url: "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/a5158a4a-2ee9-40d6-fe0b-84b8b5fb3d00/public"}}
                                        onClick={null}
                                    />

                                    {/***************
                                     * RECIPE INFOS
                                     ****************/}

                                    <Box
                                        sx={classes.itemLabels}
                                    >
                                        {/**************
                                         * RECIPE NAME
                                         **************/}

                                        <Typography
                                            sx={classes.foodItemName}
                                        >
                                            {item.name.fr}
                                        </Typography>

                                        {/**************
                                         * RECIPE MACROS
                                         **************/}

                                        <Box
                                            sx={classes.macros}
                                        >
                                            <Box
                                                sx={classes.macroItem}
                                            >
                                                <Typography>
                                                    {item.portions} portion{item.portions > 1 && "s"} : 
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={classes.macroItem}
                                            >
                                                <Typography
                                                    sx={classes.macroItemTitle}
                                                >
                                                        Protéines: {macros?.proteins} g
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={classes.macroItem}
                                            >
                                                <Typography
                                                        sx={classes.macroItemTitle}
                                                    >
                                                        Glucides: {macros?.carbs} g
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={classes.macroItem}
                                            >
                                                <Typography
                                                    sx={classes.macroItemTitle}
                                                >
                                                    Lipides: {macros?.lipids} g
                                                </Typography>
                                            </Box>  

                                        </Box>

                                        {/**************
                                         * RECIPE TAGS
                                         **************/}
                                         <Box
                                            sx={classes.tags}
                                        >
                                            {item.tags.map((tag,tagIndex)=>{
                                                return(
                                                
                                                        <Box 
                                                        key={tagIndex}
                                                        sx={[
                                                            classes.tag,
                                                            {backgroundColor: RECIPE_TAGS.find((elem)=> elem.id === tag).color}
                                                        ]}>
                                                            {RECIPE_TAGS.find((elem)=> elem.id === tag).label.fr}
                                                        </Box>
                                                
                                                )
                                            })}


                                        </Box>
                                    </Box>

                                    {found && (
                                        <Box
                                            sx={{marginRight: 2}}
                                        >
                                            <Icon>
                                                <Check 
                                                    sx={{color : "green"}}
                                                />
                                            </Icon>
                                        </Box>
                                    )}

                                    <Box
                                        sx={classes.addButton}
                                    >
                                        <IconButton
                                            onClick={()=> onAdd(item)}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Box>

                                </Box>
                            )
                        }}
                    </ViewportList>


                </Box>


            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                >
                    Fermer
                </Button>
            </DialogActions>

            <RecipesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />


        </Dialog>
    )
}
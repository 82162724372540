import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


export const classes = {
    item:{
        display:"flex",
        flexDirection: "column",
        alignItems:"center",
        paddingTop: 2,
        paddingBottom: 0,
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        position: "relative",
        overflow: 'hidden',
        
        
    },


    itemHeader: {
        display:"flex",
        flexDirection: "row",
        alignItems:"flex-start",
        width: "100%",
        zIndex: 1,
        position: "relative",
        backgroundColor: {xs: "whitesmoke", sm: "white"},
        padding: {
            xs: 2, sm: 0
        }
    },

    itemName : {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        lineHeight: "34px",
        top: -5,
        position: "relative",
        order: 1
    },

    icon: {
        width: 40,
        height: 40,
        backgroundColor: "#e8e8e8",
        borderRadius: "100px",
        marginRight: 1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    itemInfos : {
        display:"flex",
        flexDirection: {xs: "column", sm: "row"},
        flex: 1,
    },

    thumbnail : {
        //display: "flex",
        position: "relative",
        width: {xs: "100%", sm: 130},
        overflow: "hidden",
        marginRight: 3,
        cursor: "pointer",
        marginBottom: {xs: 1, sm: 0}
    },

    cover: {
        width: "100%",
        height: "auto",
        borderRadius: 1
    },

    recipeInfos: {
        display:"flex",
        flexDirection: "column",
        justifyContent:"center"
    },

    dropDownButton: (expand:boolean)=>({
        transform: expand ? 'rotate(180deg)' : '',
        transition: 'transform 150ms ease', // smooth transition
        //border:"solid 1px #f7f7f7",
        //backgroundColor:"#fbfbfb",
        borderRadius: "100px !important",
    }),

    expandButton: {
        borderRadius: "100px !important",
        marginRight: {
            xs: 0,
            sm:1,
        }
    },

    ingredients:{
        width: "100%",
        paddingTop: 2,
        position: "relative",
        backgroundColor: {xs: "whitesmoke", sm: "white"},

    },


    step : {

    },

    stepIndex: {

    },

    lineIndicator : {
        position: "absolute",
        width: "1px",
        height: "calc(100% - 32px)",
        borderRight: "dashed 3px #698baa",
        top:"0px",
        left: 50,
        zIndex:0,
        display:{xs: "none", sm: "block"},
    }
}

export default classes
import  Workout, { WorkoutExercice,  WorkoutBlock, GroupExercice } from "interfaces/Workout";



/**
 * Calcule le nombre de séries éffectuées d'une séance
 * 
 * @params {Workout} La séance
 * @return {number} Le nombre de séries éffectuées
 * 
 * */

export default function calcSetsDone(workout: Workout):number {

    let totalSets:number = 0


    workout.blocks.filter((block)=> block.track).forEach((block:WorkoutBlock) => {
        block.content.forEach((group: GroupExercice) => {
            group.exercices.forEach((exercice:WorkoutExercice) => {
                exercice.sets.forEach((set)=>{
                    if(set.performances?.performed){
                        totalSets = totalSets +1
                    }
                })
            })
        })
    })


    return totalSets
}
import React,{ useState, useMemo, useEffect } from "react"
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState } from "app/store"
import Youtube from 'react-player/youtube'
import Exercice, { Equipment } from "interfaces/Exercice"
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import calcBoxHeight from "function/calcBoxHeight"
import { EXERCICE_GROUPS_TRAD, MOBILE } from "utils/constants"
import classes from "./styles"

const exerciceSelector = createSelector([
    (state:RootState) => state.exercices,
    (_:RootState, exerciceId: string) => exerciceId
],
    (exercices, exerciceId) => {
        const exercice = 
            exercices.list.app.find((exercice:Exercice)=> exercice._id === exerciceId) ||
            exercices.list.custom.find((exercice:Exercice)=> exercice._id === exerciceId)
        return exercice
    }   
)


interface Props{
    open: boolean,
    onClose: ()=> void,
    exerciceId: string,
}



export function DialogExercice({open,onClose, exerciceId}:Props){

    const exercice = useSelector((state:RootState)=>exerciceSelector(state, exerciceId))
    const [playingVideo, setPlayIngVideo] = useState(false)
    const [loading, setLoading] = useState(false)
    const mobile = useMediaQuery(MOBILE)

    
    useEffect(()=>{
        if(open) setLoading(true)
    },[open])

    /******** CONVERSION DU HTML ********** */

    const markup = useMemo(()=>{
        
        if(exercice?.description){
            const contentState = convertFromRaw(JSON.parse(exercice?.description?.fr))
            const editorState = EditorState.createWithContent(contentState);
            const toConvert = convertToRaw(editorState.getCurrentContent())

            const htmlDraft = draftToHtml(
                toConvert,
                {
                    trigger: '#',
                    separator: ' ',
                },
            )

            return htmlDraft
        }
        else return null

    },[exercice])


    const playerIsReady = () => {
        setTimeout(()=>{
            setLoading(false)
        },200)
    }

    
    const playVideo = ()=>{
        setPlayIngVideo(true)
    }

    return(
        <Dialog 
            open={open} 
            onClose={onClose} 
            fullScreen={mobile}
            disableScrollLock={true}
        >
            <DialogContent sx={{padding:0, width: mobile ? "100%" : 640}}>

                {/**** Vidéo ***** */}

                <Box sx={[classes.video, {height: calcBoxHeight(1080,1920,640)}]}>
                    {(exercice?.video) && (
                        <Youtube
                            url={exercice?.video.url}
                            width={'100%'}
                            playing={playingVideo}
                            controls={true}
                            onReady={playerIsReady}
                            style={{
                                opacity: loading ? 0 : 1
                            }}
                        />
                    )}

                    {(exercice?.video && loading) && (
                        <Box sx={[classes.loading,{height: calcBoxHeight(1080,1920,640)}]}>
                            <CircularProgress size={50} />
                        </Box>
                        
                    )}
                </Box>


                <Box sx={{padding: 3, paddingTop: 0, paddingBottom: 0}}>
                    
                    {/**** Exercice name ***** */}
                    <Box  sx={classes.header}>
                        <Typography sx={classes.name}>
                            {exercice?.name?.fr}
                        </Typography>
                        <Typography sx={classes.type}>
                            {exercice?.type?.name?.fr}
                        </Typography>
                    </Box>
                    
                    {/**** Groupes musculaires ***** */}

                    <Box sx={classes.section}>
                        <Typography sx={classes.sectionTitle}>
                            Groupes musculaires
                        </Typography>
                        <Box>
                            {exercice && exercice.groups.map((group:string)=>{
                                const groupName = group as keyof Record<string, {fr:string, en:string}>
                                return(
                                    <Chip 
                                        label={EXERCICE_GROUPS_TRAD[groupName].fr} 
                                        sx={{marginRight: 1}}  
                                        key={group}
                                    />
                                )
                            })}
                           
                        </Box>
                    </Box>
                    


                    {/**** Equipements ***** 

                    <Box sx={{display:"flex", flexDirection: "row", borderBottom: "solid 1px whitesmoke",height: 70, alignItems:"center", justifyContent:"space-between"}}>
                        <Typography variant="body1" sx={{marginRight: 2}}>
                            Matériel
                        </Typography>
                        <Box>
                            {exercice && exercice.equipments.map((equipment:Equipment)=>{
                                return(
                                    <Chip label={equipment.name} sx={{marginLeft: 1}} key={equipment.name}/>
                                )
                            })}
                            {Number(exercice?.equipments.length) === 0 && (
                                <Chip label={"Aucun"}/>
                            )}
                        </Box>
                    </Box>
                    */}

                    {/**** Description ***** */}

                    {markup && (
                        <Box sx={[classes.description]}>
                            
                            <Box>
                                <div dangerouslySetInnerHTML={{ __html: markup }} />
                            </Box>
                        </Box>
                    )}

                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    )
}

function areEqual(prev:Props, next:Props){
    return prev.exerciceId === next.exerciceId && prev.open === next.open
}

export default React.memo(DialogExercice, areEqual)
import { MACROS_COLORS } from "utils/constants"

const classes = {

    mealMacros:{
        display: "flex",
        flexDirection: {xs: "column",sm:"row"},
        marginBottom: 3,
        width: {
            xs: 'auto',
            sm: "700px",
        },
        maxWidth: {
            xs: 'auto',
            sm: "700px",
        }
        
    },

    macroItem: {
        display:"flex",

    },

    mealMacroItem: {
        marginRight: 3,
        padding: 0.5,
        paddingLeft:1,
        paddingRight: 1,
        borderRadius: 1,
        display:"flex",
        flex: 1,
        alignItems:"flex-start",
        flexDirection:"column"
        
        //backgroundColor: "whitesmoke"
    },

    macro: {
        display:"flex",
        flex: 1
    },


    macro_left: {
        display:"flex",
        flexDirection:"row",
        flex: 1,
        marginBottom: {
            xs: 2, sm: 0
        },
    
    },

    macro_right: {
        display:"flex",
        flexDirection:"row",
        flex: 0.5,
        marginBottom: {
            xs: 2, sm: 0
        }
    
    },

    mealMacroItemName : {
        fontSize: 14,
        marginBottom: 1,
        fontWeight: 600
    },

    proteins:{
        color: MACROS_COLORS.proteins,
        //backgroundColor: "#e6fbfb",

    },
    carbs:{
        color: MACROS_COLORS.carbs,
        //backgroundColor: "#fce9ff",
    },
    lipids:{
        color: MACROS_COLORS.lipids,
        //backgroundColor: "#e6f2ff",
    },
    energie:{
        color: MACROS_COLORS.calories,
        //backgroundColor: "#ffe2d0",

    },
    fibers:{
        color:"#a27806",
        //backgroundColor: "#fff0c5",

    }
}


export default classes
import SyncLoader from "react-spinners/SyncLoader";
import { Box } from "@mui/material";
import { primaryColor } from "utils/theme";
import classes from "./styles"

export default function Loader() {
    return (
        <Box sx={classes.loader}>
            <SyncLoader color={primaryColor} loading={true} size={15} />
        </Box>
    )
}
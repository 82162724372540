import Checkup from "interfaces/Checkup"

const checkQuestionnaireFormIsValid = (questionnaire:Checkup) => {
    var valid:boolean = true

        questionnaire.questions.forEach((elem, index:number)=>{
            const needOptions:Boolean = elem.type === "checkbox" || elem.type === "radio"

            // Une des questions n'a pas de titre
            if(elem.question === ""){
                valid = false
            } else if(needOptions){
                if(elem.options.length === 0){
                    valid = false
                } 
                else if(elem.options.length === 1){
                    valid = false
                }
                else{
                    elem.options.forEach((option, indexOption:number)=>{
                        if(option === ""){
                            valid = false
                        }
                    })
                }
            }
        })
    

    return valid
}


export default checkQuestionnaireFormIsValid
import { Button, styled } from '@mui/material';
import { ButtonProps as MuiButtonProps } from "@mui/material/Button"



const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    height: 40,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    textTransform: "none",
    flexWrap: "nowrap"
 }))




export default function ContainedButton(buttonProps:MuiButtonProps){

        return(
            <StyledButton {...buttonProps} disableElevation>
                {buttonProps.children}
            </StyledButton>
        )
    
}
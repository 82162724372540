const classes = {



    pageHeader : {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"space-between",
        marginBottom: 3

    },

    header:  {
        display:"flex", 
        flexDirection: "row", 
        flexGrow:1, 
        justifyContent:"space-between",
        alignItems:"center", 
        paddingBottom: 3
    },


    headerButton: {
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
    },

     
    listHeader : {
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"flex-end",
        width: "100%",
        mb: 3
    },



    tabs: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },


    rowInfos: {
        display:"flex", 
        flexDirection:"row", 
        alignItems:"center", 
        justifyContent:"center",
    },

    speedDialTooltip : {
        width: 150,
        backgroundColor: "red",
        justifyContent:"flex-end"
    }
}

export default classes
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"
import CreditCard from 'interfaces/CreditCard'
import SubscriptionProduct from 'interfaces/SubscriptionProduct'
import { toast } from 'react-toastify'
import Subscription from 'interfaces/Subscription'


/************** RECUPERATION DES ABONNEMENTS ****************/

export const getProducts = createAsyncThunk('subscriptions/getProducts', async () => {
    try{
      
      const response = await axios.get(`subscriptions/products`)
      const products:SubscriptionProduct[] = response.data.products
      return({products})
      
  
    }catch(err:any){
        return {message: "Impossible de récupérer la liste des abonnements"}
    } 
})


export const postCheckout = createAsyncThunk('subscriptions/postCheckout', async(data:{priceId:string})=>{

    const {priceId} = data

    try{
      const response = await axios.post(`subscriptions/checkout`,{priceId})
      const url:string = response.data.url
      return {url}

    }catch(err:any){
      toast.error('Impossible de sélectionner cet abonnement')
    }
})


export const postSessionPortal = createAsyncThunk('subscriptions/postSessionPortal', async(data?:{flow?:"subscription_cancel" | "subscription_update_confirm", priceId?:string})=>{

  const {flow, priceId} = data

  try{
    const response = await axios.post(`subscriptions/sessionPortal`, {flow, priceId})
    const url:string = response.data.url
    return {url}

  }catch(err:any){
    toast.error('Impossible de sélectionner cet abonnement')
  }
})


/************** CREATION D'UNE PERIODE D'ESSAI ****************/

export const startTrial = createAsyncThunk('subscriptions/trial/start', async (price:string) => {

  try{
    
    const response = await axios.post(`subscriptions/trial`, {priceId:price})
    const result = response.data
    const trial:Subscription = result.trial

    return {trial}

  }catch(err:any){
    return {message: err.message}
  } 
})


  /************** Récupération de la méthode de paiement par défaut de l'abonnement ****************/
  
  export const getDefaultPaymentMethod = createAsyncThunk('subscriptions/getDefaultPaymentMethod', async(subscriptionId:string) => {

    try{
  
      const response = await axios.get(`subscriptions/${subscriptionId}/paymentMethod`)
      const paymentMethod:CreditCard = response.data.paymentMethod
  
      return {paymentMethod}
  
    }catch(err:any){
      return {message: err.message}
    } 
  })


  /************ Gestion du status de l'abonnement ********* */
  export const handleSubscriptionStatus = createAsyncThunk('subscriptions/cancelSubscription', async (data:{status?:string}) => {
    try{
      const response = await axios.put(`subscriptions/status`,{status: data.status})
      const cancel_at_period_end:boolean = response.data.cancel_at_period_end
      
      return {
        cancel_at_period_end
      }
  
    }catch(err:any){
      return {message: err.message}
    } 
  })
   




  /************ RECUPERATION DE LA PROCHAINE FACTURE ********* */
  export const getNextInvoice = createAsyncThunk('subscriptions/get/nextInvoice', async() => {
      try{
        const result = await axios.get(`subscriptions/nextInvoice`)
        return {invoice: result.data.invoice}
      }catch(err:any){
        return {message: err.message}
      }
  })


  /************ Création d'une période d'essaie ********* */
  export const createNewModuleTrial = createAsyncThunk('subscriptions/modules/trial', async (price) => {
    try{
  
      const response = await axios.post(`subscriptions/modules/trial`, {price})
      const result = response.data
  
      return {
        module : result.module,
      }
  
    }catch(err:any){
      return {message: err.message}
    } 
})




    




import { theme } from "utils/theme"


const classes = {

    questionContainer: {
        marginBottom: 4,
        borderBottom: "solid 1px #e3e3e3",
        paddingBottom: 4
    },


    meta : {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        marginBottom: 3
    },

    question: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        marginBottom: 1,
        color: "rgba(0,0,0,0.85)"
    },


    optionContainer: {
        flexDirection: "row",
        display:"flex",
        alignItems:"center",
        marginBottom: 2,
        marginTop:2
    },

    option : {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400
    }
}

export default classes
import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


export const classes = {
    item:{
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        paddingTop: 2,
        paddingBottom: 2,
        //borderBottom: `solid 1px ${BORDER_COLOR}`,
        width: "100%",
        zIndex: 3,
        position: "relative",
        padding: {
            xs: 2, sm: 0
        },
        marginBottom: 1.5
       
    },

    itemName : {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 500,
        marginLeft: {xs: 0, sm: 1.5},
        marginTop: {
            xs: 2,
            sm:0        
        },
        lineHeight: "34px",
        order: {xs: 1, sm : 2},
        marginBottom: {
            xs: 1, sm: 0
        }
    },


    portionInput: {
        width: {
            xs: 70,
            sm: 100,
        }, 
        minWidth: {
            xs: 70,
            sm: 100,
        }, 
        marginRight: 1, 
        height: 40, 
        maxHeight: 40
    },

    labelInput: {
        width: {
            xs: 140,
            sm: 170,
        }, 
        minWidth: {
            xs: 140,
            sm: 170,
        }, 
        marginRight: 1, 
        height: 40, 
        maxHeight: 40
    },

    icon: {
        width: 40,
        height: 40,
        backgroundColor: "#e8e8e8",
        borderRadius: "100px",
        marginRight: 1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    itemInfos : {
        display:"flex",
        flexDirection: {
            xs: 'column',
            sm: "row",
        },
        flexWrap: "nowrap",
        flex: 1,
        backgroundColor: {xs: "whitesmoke", sm: "white"},
    },

    infos: {
        
        flexDirection: "row",
        order: {xs: 2, sm : 1},
        minWidth: {
            xs: 290
        },
        marginBottom: {
            xs: 2, sm: 0
        }
    }
}

export default classes
import { theme } from "utils/theme"

const classes = {
    video:{
        width: "100%",  
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
         position: "relative"
    },


    loading:{
        display:"flex", 
        position: "absolute", 
        width: "100%", 
        alignItems:"center", 
        justifyContent:"center" 
    },

    section:{
        padding: 3,
        paddingTop: 2,
        paddingBottom: 2
    },

    ingredient:{
        fontSize: theme.typography.pxToRem(16),
        marginLeft: 1
    
    },

    tag : {
        display:"flex",
        backgroundColor: "whitesmoke",
        fontSize: theme.typography.pxToRem(12),
        padding: 0.5,
        paddingLeft:1,
        paddingRight:1,
        marginTop: 1,
        marginRight: 2,
        borderRadius: 1,
        fontWeight: 600
    },

    tags: {
        display:"flex",
        flexDirection: "row",
    },

    ingredientContainer:{
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginTop: 1.5
    },

    sectionTitle : {
        fontWeight: 700
    },

    stepIndex: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 700,
        width: 50, 
        minWidth: 50,
        maxWidth: 50,
        marginRight: 1
    },

    step : {
        fontWeight: 700,
        display:"flex",
        flexDirection: "row",
        marginTop: 2
    },

    cover : {
        width: "100%",
        height: "auto",
        backgroundColor: "gray"
    }
}

export default classes
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { deleteExerciceImage } from "api/exercices";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogDeleteExerciceImage({open, onClose}:Props){

    const deletePending:boolean = useSelector((state:RootState) => state.exercices.requests.deleteImage === "pending")
    const exerciceId = useSelector((state:RootState) => state.exercices.builder._id)
    const dispatch = useDispatch<AppDispatch>()

    const onDeleteImage = () => {
        dispatch(deleteExerciceImage({exerciceId})).unwrap().then(()=>{
            onClose()
        })
    }

    return(
        <Dialog 
            open={open}
            disableScrollLock
        >
            <DialogTitle>
                Supprimer une image téléchargée
            </DialogTitle>
            <DialogContent>
                Voulez-vous supprimer l'image téléchargée de cet exercice ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={deletePending}>Annuler</Button>
                <LoadingButton loading={deletePending} disabled={deletePending} onClick={onDeleteImage}>
                    {deletePending ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
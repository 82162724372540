
import {Typography } from "@mui/material";
import React from "react";
import classes from "./styles"

interface Props{
    children: React.ReactNode,
    noMargin?: boolean,
    expanded?: boolean
}

export default function PageTitle({children,expanded = false}:Props){
    return(
        <Typography sx={[classes.title, {paddingTop: expanded? 2:0}]}>
            {children}
        </Typography>
    )
}
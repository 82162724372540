import { BORDER_COLOR, WORKOUT_BUILDER_TEXTFIELD_RADIUS } from "utils/constants"
import { theme } from "utils/theme"


const classes = {
    header: {
        flexDirection: "column", 
        display:"flex",
        justifyContent:"space-between", 
        alignItems:"flex-start", 
        borderBottom: `solid 1px ${BORDER_COLOR}`,
       
    },

    toolbar : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginBottom:0.5,
        
    },

    content: {
        backgroundColor: "#fbfbfb",
    },


    title:{
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
       
    },

    exerciceName : {
        fontSize: "1rem",
        marginBottom: 2,
        fontWeight: 700,
        marginTop: 3
    },

    metricTextField:{
        //backgroundColor:WORKOUT_BUILDER_HEADER_TEXTFIELD_BGCOLOR,
        borderRadius: WORKOUT_BUILDER_TEXTFIELD_RADIUS,
        paddingLeft: "0 !important",
        backgroundColor: "white",
        marginRight: 2,
        
    
        "& .MuiOutlinedInput-root": {
            fieldset: {
                borderColor: BORDER_COLOR,
                //border: `solid 1px ${BORDER_COLOR}`,
                //border: "none"
            },
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            color: "rgba(0,0,0,0.85)",
            //textTransform: "uppercase",

            "&.Mui-disabled":{
                fieldset: {
                    border: `solid 1px ${BORDER_COLOR}`,

                },
            },

            "& .Mui-disabled":{
                fieldset: {
                    border: "solid 1px transparent",
                },
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(15),
                //textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.87)",
                "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87)"
                
            }
        },

        
    },

    metric:{
        flex: 1,
        width: "100%",
        marginBottom: 2,
        display:"flex",
        flexDirection: "row"
    },



}


export default classes
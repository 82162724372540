import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { deleteAvatar } from "api/user";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


interface Props{
    open: boolean,
    onClose: () => void
}

export default function DialogDeleteAvatar({open,onClose}:Props){


    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.user.requests.update === "pending")


    const onDeleteAvatar = () => {
        dispatch(deleteAvatar()).unwrap().then((res)=>{
            if(res.avatarDeleted) onClose()
        })
    }


    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer ta photo de profil
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer ta photo de profil ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>Annuler</Button>
                <LoadingButton loading={pending} disabled={pending} onClick={onDeleteAvatar}>
                {pending? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import Food from "interfaces/Food";


function convert(nutriment:string){
    const value = nutriment?.replace(",", ".").replace('<',"")
    if(parseFloat(value)) return +parseFloat(value)
    else return 0
}


export default async function generateCiqualTable():Promise<Food[]>{

    const requestOptions = {
        method: 'GET'
    };

    const response = await fetch('http://localhost:3000/ciqual.json', requestOptions)
    const json:any = await response.json()
    const data:[] = json.compo
    const newList:Food[] = []

    data.forEach((aliment:any)=> {

        const name = {
            fr: aliment.alim_nom_fr,
        }

        const proteins = convert(aliment['Protéines, N x 6.25 (g/100 g)'])
        const carbs = convert(aliment['Glucides (g/100 g)'])
        const lipids = convert(aliment['Lipides (g/100 g)'])
        const water = convert(aliment['Eau (g/100 g)'])
        const sugar = convert(aliment['Sucres (g/100 g)'])
        const fructose= convert(aliment['Fructose (g/100 g)'])
        const glucose= convert(aliment['Glucose (g/100 g)'])
        const lactose = convert(aliment['Lactose (g/100 g)'])
        const maltose = convert(aliment['Lactose (g/100 g)'])
        const sucrose = convert(aliment['Saccharose (g/100 g)'])
        const starch = convert(aliment['Amidon (g/100 g)'])
        const ashes = convert(aliment['Ashes (g/100 g)'])
        const alcool = convert(aliment['Alcool (g/100 g)'])
        const fibers = convert(aliment['Fibres alimentaires (g/100 g)'])
        const sodium = convert(aliment['Sodium (mg/100 g)'])
        const polyols = convert(aliment['Polyols (g/100 g)'])
        const organicAcids = convert(aliment['Acides organiques (g/100 g)'])
        const SFA = convert(aliment['AG saturés (g/100 g)'])
        const MUFA = convert(aliment['AG monoinsaturés (g/100 g)'])
        const PUFAs = convert(aliment['AG polyinsaturés (g/100 g)'])
        const calcium = convert(aliment['Calcium (mg/100 g)'])
        const chloride = convert(aliment['Chlorure (mg/100 g)'])
        const cooper = convert(aliment['Cuivre (mg/100 g)'])
        const iron = convert(aliment['Fer (mg/100 g)'])
        const cholesterol = convert(aliment['Cholestérol (mg/100 g)'])
        const iodine = convert(aliment['Iode (µg/100 g)'])
        const magnesium = convert(aliment['Magnésium (mg/100 g)'])
        const phosphorus = convert(aliment['Phosphore (mg/100 g)'])
        const potassium =  convert(aliment['Potassium (mg/100 g)'])
        const selenium =  convert(aliment['Sélénium (µg/100 g)'])
        const zinc = convert(aliment['Zinc (mg/100 g)'])
        const retinol = convert(aliment['Rétinol (µg/100 g)'])
        const vitaminD = convert(aliment['Vitamine D (µg/100 g)'])
        const vitaminE = convert(aliment['Vitamine E (mg/100 g)'])
        const vitaminC = convert(aliment['Vitamine C (mg/100 g)'])
        const thiamine = convert(aliment['Vitamine B1 ou Thiamine (mg/100 g)'])
        const riboflavin = convert(aliment['Vitamine B2 ou Riboflavine (mg/100 g)'])
        const niacine = convert(aliment['Vitamine B3 ou PP ou Niacine (mg/100 g)'])
        const pantothenicAcid = convert(aliment['Vitamine B5 ou Acide pantothénique (mg/100 g)'])
        const vitaminB6 = convert(aliment['Vitamine B6 (mg/100 g)'])
        const vitaminB12 = convert(aliment['Vitamine B12 (µg/100 g)'])
        const folate = convert(aliment['Vitamine B9 ou Folates totaux (µg/100 g)'])

        const kcal = ((proteins * 4) + (carbs *4) + (lipids * 9)).toFixed(2)
        const kj = (parseFloat(kcal) * 4.184).toFixed(2)

        const calories = {
            kcal: Math.round(parseFloat(kcal)),
            kj: Math.round(parseFloat(kj))
        }

        newList.push({
            name,
            macros:{
                proteins,
                carbs,
                lipids,
            },
            micros:{
                cholesterol,
                water,
                sugar,
                fructose,
                glucose,
                lactose,
                maltose,
                sucrose,
                starch,
                ashes,
                alcool,
                fibers,
                sodium,
                polyols,
                organicAcids,
                SFA,
                MUFA,
                PUFAs,
                calcium,
                chloride,
                cooper,
                iron,
                iodine,
                magnesium,
                phosphorus,
                potassium,
                selenium,
                zinc,
                retinol,
                vitaminD,
                vitaminE,
                vitaminC,
                thiamine,
                riboflavin,
                niacine,
                pantothenicAcid,
                vitaminB6,
                vitaminB12,
                folate
            },
            calories: calories,
            source: "Ciqual 2020, ANSES"
        })
    })

    return newList
        
}
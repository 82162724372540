import { theme } from "utils/theme"


export const classes = {


    form:{
        display: "flex", 
        flexDirection: "column", 
        mt: 1,
        position: "relative"
    },

    colorChoice: {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginTop: 1,
        marginBottom: 3
 
    },

    color: (color:string, selected:boolean)=>({
        borderRadius: "100px",
        backgroundColor: color,
        width: 25, 
        height: 25,
        border: `solid 2px ${selected ? "#989898" : "white"}`,
        cursor:"pointer",
        marginRight: 1
    }),

    error : {
        color: theme.palette.error.main,
        marginTop: 2
    },

    sectionLabel:{
        width: "100%",
        marginBottom: 2,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18)
    },
}

export default classes
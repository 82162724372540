import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { onPasteMealPlan } from "features/mealPlanSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


interface Props{
    open: boolean,
    onClose :()=> void,
}

export default function DialogPasteMealPlan({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const client = useSelector((state:RootState) => state.clients.profile)

    const pasteMealPlan = () => {
        dispatch(onPasteMealPlan())
        toast.success('Programme alimentaire collé')
        onClose()
    }
    return(
        <Dialog
            open={open}
        >
            <DialogTitle>
                Coller un plan alimentaire
            </DialogTitle>
            <DialogContent>
                Souhaites-tu dupliquer le plan alimentaire que tu as copié dans celui de <strong>{client.firstname}</strong> ? Cette action <strong>écrasera la totalité du plan alimentaire en cours</strong>.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
                <LoadingButton 
                    variant="contained" 
                    onClick={pasteMealPlan}
                >
                    Dupliquer le plan copié
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
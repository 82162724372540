


const classes = {
    button:{
        textTranform: "none",
        backgroundColor: "red",
        color: "white",
        "&: hover":{
            backgroundColor: "red"
        }
    },

    buttonsContainer:{
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"center",
        mt:2,
        mb:1
    },

}   


export default classes
import { Box, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import DialogTitleLabel from "components/atoms/Typography/DialogTitle";
import { MOBILE } from "utils/constants";
import classes from "./styles";
import { Close } from "@mui/icons-material";

interface Props{
    title: string,
    onClose: () => void
}

export default function DialogTitleFullScreen({title, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)

    return(
        <DialogTitle>
            <Box sx={classes.toolbar}>
                {mobile && (
                    <IconButton 
                        sx={{marginRight: 2}} 
                        onClick={onClose}
                    >
                        <Close sx={{color: "black"}} />
                    </IconButton>
                )}

                <DialogTitleLabel>
                    {title}
                </DialogTitleLabel>
            </Box>
        </DialogTitle>
    )
}
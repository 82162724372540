import classes from "./styles"
import { Box } from "@mui/material"


interface Props{
    children: React.ReactNode,
    separator?: boolean,
    expanded?: boolean
}

export default function Toolbar({children, expanded = false}:Props){
    return(
        <Box sx={[
            classes.toolbar, 
            {
                flexDirection: expanded ? "column" : "row",
                alignItems: expanded? "flex-start" : "center"
            }
        ]}>
            {children}
        </Box>
    )
    
}
import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {
    content:{
        display:"flex",
        flexGrow: 2,
        flexDirection: "row",
        justifyContent:"space-between",
        marginTop: 2,
        width: "100%"
    },

    macro: {
        marginBottom: 2,
        width: {
            sx: 150,
            sm: 220
        }
    },


    macrosList : {
        display:"flex",
        flexDirection: {
            xs: "column",
            sm: "row"
        },
        width: {
            xs: "auto",
            sm: "100%"
        },
        justifyContent: "space-between"
    },

    measures: {
        display:"flex",
        flexDirection: "row",
        marginTop: 3,
        alignItems:"center",
        justifyContent:"space-between"
    },

    measuresList : {
        display:"flex",
        flexDirection: "column",
        width: "100%"
    },

    equivalent : {
        fontWeight: 600,
        fontSize: 22,
        marginLeft: 3,
        marginRight: 3
    },

    labelForm: {
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
    },

    measureItem : {
        display:"flex",
        alignItems:"center",
        marginTop: 2,
        borderTop: `solid 1px ${BORDER_COLOR}`,
        paddingTop:3,
        paddingBottom: 3
    },

    variantName: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 2
    }
}


export default classes
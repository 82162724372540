import { Box, Tooltip } from "@mui/material"
import classes from "./styles"


interface Props {
    valid: boolean,
    message?:string
}


export default function QuestionnaireStatus({valid, message}:Props){

    return(
        <Tooltip title={message ? message : "Indique si ce fomulaire possède tous les champs recquis pour être programmé dans les profils de tes clients"}>
            <Box sx={[
                    classes.questionnaireStatus, 
                    valid && classes.questionnaireStatusValid, 
                    !valid && classes.questionnaireStatusIsNotValid
                ]}>
                    {!valid ? "Formulaire invalide" : "Fomulaire valide"}
            </Box>
        </Tooltip>
    )
}
import {createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"
import sleep from "function/sleep"
import Exercice, { ExerciceType } from "interfaces/Exercice"


/************** API ****************/

// Récupération des clients
export const getExercices = createAsyncThunk('exercices/getAll', async () => {
    try {
      const response = await axios.get(`exercices`)
      const exercices = response.data.exercices
      const types = response.data.types
      return { exercices, types }
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })
  
  // Création d'un nouveau brouillon
  export const createCustomExercice = createAsyncThunk('exercices/create', async (data:{exercice:Exercice}) => {

    const form = {
      ...data.exercice,
      type: data.exercice.type._id
    }

    try {
      const response = await axios.post(`exercices`, form)
      const exercice = response.data.exercice
      return { exercice: {
        ...exercice,
        type: data.exercice.type
      }}
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })
  
  

  
  export const deleteExercice = createAsyncThunk('exercices/deleteExercice', async (data:{exercice:Exercice}) => {
    try {
      const response = await axios.delete(`exercices/exercice/${data.exercice._id}`)
      const exercice = response.data.exercice
      await sleep(500)
      return { exercice }
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


   /****************** RECUPERATION DES DONNEES **************** */
  
   export const getExercice = createAsyncThunk('exercices/getExercice', async (id: string) => {
    try {
      const response = await axios.get(`exercices/exercice/${id}`)
      const exercice:Exercice = response.data.exercice
  
      return { exercice }
  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  /****************** ENREGISTREMENT DE L'EXERCICE DANS DANS LA LISTE **************** */
  
  export const saveExercice = createAsyncThunk('exercices/save', async (data:{exercice:Exercice}) => {

    const exercice:Exercice = data.exercice

    try {
      
      // Vérification du nom de l'exercice
      if(exercice.name.fr === ""){
        return {error: "Ton exercice n'a pas de nom"}
      }

      // Enregistrement des données
      const exerciceSaved = await axios.put(`exercices/${exercice._id}`, { ...exercice})

      await sleep(500)
      return {
        exercice: {
          ...exerciceSaved.data.exercice,
          type: exercice.type
      }}

  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


  /****************** ENREGISTREMENT DE L'image de l'exercice **************** */

  export const uploadExerciceImage = createAsyncThunk('exercices/upload/image', async (data:{exerciceId:string, file:Blob}) => {

    var formData = new FormData();
    formData.append('file', data.file)

    try {
      
     // On poste l'image pour téléchargement sur cloudflare
     const result = await axios.post(`exercices/exercice/${data.exerciceId}/image`, formData)
     const image:{url:string,cloudFlareId:string} = result.data.image
     return {image}

  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })

  /****************** SUPPRESSION DE L'image de l'exercice **************** */

  export const deleteExerciceImage = createAsyncThunk('exercices/deleteImage', async (data:{exerciceId:string})=>{

    try{
      const result = await axios.delete(`exercices/exercice/${data.exerciceId}/image`) // Suppression du fichier sur cloudflare ET dans la BDD
      const image = result.data.image
      return {image}
    }
    catch(err:any){
      return ({error: err.response.data?.message[0]})
    }
    
  })


    /********************     AUTOSAVE EXERCICE    *************************** */
    export const autoSaveExercice = createAsyncThunk('exercices/autoSave', async (data:{exercice:Exercice}) => {

      const formatedData = {
        ...data.exercice,
        type: data.exercice.type._id
      }

      try {
          const response = await axios.put(`exercices/exercice/${data.exercice._id}`, formatedData)
          const exercice: Exercice = response.data.exercice
          return { 
            exercice: {
              ...exercice, 
              type: data.exercice.type
            }
          }

      } catch (err: any) {
          return {
              error: err.response.data?.message[0]
          }
      }
    })

    export const manualSaveExercice = createAsyncThunk('exercices/manualSave', async(data:{exercice:Exercice})=>{
      const formatedData = {
        ...data.exercice,
        type: data.exercice.type._id,
        draft: false
      }

      try {
        const response = await axios.put(`exercices/exercice/${data.exercice._id}`, formatedData)
        const exercice: Exercice = response.data.exercice
        
        await sleep(1500)
        return { 
          exercice: {
            ...exercice, 
            type: data.exercice.type
          }
        }

    } catch (err: any) {
        return {
            error: err.response.data?.message[0]
        }
    }

    })
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface Props {
    open: boolean,
    name: string,
    email:string,
    onClose: ()=>void
}

export default function DialogInvitationDescription({open, name, email, onClose}:Props){

    return(
        <Dialog 
            open={open} 
            maxWidth={"xs"}
            disableScrollLock
        >
            <DialogTitle>
                Invitation envoyée
            </DialogTitle>
            <DialogContent>
                <strong>{name}</strong> n'a pas encore accepté ton invitation à utiliser Traener. Tu pourras consulter son profil une fois l'invitation acceptée.
                <br/>
                <br/>
                L'invitation a été envoyé à l'adresse email <strong>{email}</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
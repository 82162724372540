
import { Box, Typography, Dialog, DialogContent, Button, IconButton, CircularProgress, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AppDispatch, RootState } from "app/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createCustomerId, createDemoUser } from "api/user";
import { useHistory } from "react-router-dom";
import classes from "./styles"
import { startTrial } from "api/subscriptions";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { onTrialActivated } from "features/userSlice";
import { handleDemoUserPresentation } from "features/appSlice";
import { MOBILE } from "utils/constants";


//const PROD_TRIAL = "price_1KSUFzCrAsb0XaqYdHMb2TYU"
//const DEV_TRIAL =  "price_1MWLmFCrAsb0XaqYWrLMYKmW"

// INTERFACES //

interface DialogTrialProps{
    open: boolean,
    onClose: ()=> void
}


export default function DialogSubscriptionPresentation({open, onClose}:DialogTrialProps){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const history = useHistory()
    const mobile = useMediaQuery(MOBILE)


    /********************************** */
    // Activation de la période d'essaie
    /*********************************** */

    const activateTrial = async () => {
        const price:string = "price_1KSUFzCrAsb0XaqYdHMb2TYU" //Prod
        //const price:string = "price_1MWLmFCrAsb0XaqYWrLMYKmW" //dev
        

        dispatch(startTrial(price)).unwrap().then(()=>{
            dispatch(createDemoUser()).unwrap().then(()=>{
                dispatch(onTrialActivated())
                setTimeout(()=>dispatch(handleDemoUserPresentation({open:true})), 500)
            })
            onClose()
        })
        

    }
    

    /********** DEMARRAGE DU TRIAL ******** */

    const onSubmit = async() => {

        /***** CREATION D'UN CUSTOMER ID (le cas échéant) ******/
        if(!user.customerId){
            dispatch(createCustomerId()).unwrap().then(()=>{
                activateTrial()
            })
        } else {
            activateTrial()
        }
    }


    const goToSubscriptions = ()=> {
        history.push('/subscriptions')
        onClose()
    }



    return(

        <Dialog 
            open={open} 
            fullScreen={mobile}
        >
            <DialogContent 
                sx={{width: mobile ? "100%" : 620, overflowY: "auto", padding : mobile? 1 : 3}}
            >
                
                <Box 
                    sx={classes.content}
                >
                    <Typography 
                        variant='h5' 
                        sx={{fontWeight: 700, marginBottom: 3, textAlign:"center"}}
                    >
                        {!user.trialIsDone ? "Démarre ta période d'essai" : "Abonnement nécessaire"}
                    </Typography>

                    {/*<Box component="img" src='/assets/subscription_needed.jpg' sx={{width: mobile ? "100%" : 500, marginBottom: 3}} />*/}

                    {/************ DESCRIPTION PERIODE D'ESSAI *********** */}

                    {!user.trialIsDone && (
                        <Typography sx={classes.description}>
                            {user.firstname}, découvre l'ensemble des fonctionnalités de notre abonnement premium <strong>Pro</strong> <strong>gratuitement pendant 14 jours</strong>. C'est sans engagement, aucune carte de paiement ne te sera demandée !
                        </Typography>
                    )}


                    {user.trialIsDone && (
                        <Typography sx={classes.description}>
                            Ta période d'essai est terminé. Tu dois souscrire à un abonnement premium pour exploiter tout le potentiel de l'application Traener.
                        </Typography>
                    )}

                    {/************ DESCRIPTION PERIODE D'ESSAI *********** */}

                    <Box 
                        sx={classes.buttonsContainer}>
                        {!user.trialIsDone && (
                            <LoadingButton 
                                startIcon={pending ? <CircularProgress sx={{color: "white"}} size={20} /> : null}
                                sx={{backgroundColor: "primary.main", width: 340, textTransform: "none", borderRadius: "20px !important", fontSize: 16,   }} 
                                onClick={onSubmit} 
                                disabled={pending}>
                                    {pending ? "Activation ..." : "Commencer l'essai"}
                            </LoadingButton>
                        )}
                       
                        <Button 
                            sx={classes.button} 
                            onClick={goToSubscriptions} 
                            disabled={pending}>
                                Découvre nos abonnements
                        </Button>
                    </Box>

                    {/****** BOUTON CLOSE ****** */}
                    <Box sx={{position: "absolute", right: 10, top: 10}}>
                        <IconButton 
                            sx={{backgroundColor: "#eaeaea", borderRadius: "100% !important"}} 
                            onClick={onClose} 
                            disabled={pending}>
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>

            
        </Dialog>
            
    )
}
// MUI
import {  Dialog, DialogTitle, DialogActions, Button, DialogContent } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// INterfaces
import { AppDispatch, RootState } from "app/store";
// Shared components
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import MealPlan from "interfaces/MealPlan";
import { deleteMealPlan } from "api/mealPlans";
import { toast } from "react-toastify";


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    startDate?: Date,
    mealPlan?: MealPlan,
}


//***************** COMPONENT ********* */

export default function DialogDeleteMealPlan({ open, onClose, mealPlan}: Props) {

    const dispatch = useDispatch<AppDispatch>()
    const pendingDeleteTemplate: boolean = useSelector((state:RootState) => state.mealPlans.requests.delete === "pending")


    /********** MISE A JOUR D'UN PROGRAMME ************* */

    const onDelete = () => {
        dispatch(deleteMealPlan({mealPlan})).unwrap().then((res:any) => {
            onClose()
            toast.success('Plan alimentaire supprimé')
        })
    }




    /********** JSX ************* */

    return (

        <Dialog 
            open={open} 
            fullWidth maxWidth={'xs'}
            disableScrollLock={true}
        >

            <DialogTitle>
                Supprimer un modèle de plan alimentaire
            </DialogTitle>
            <DialogContent>
                Confirmer la suppression du modèle de plan alimentaire <strong>{mealPlan?.name}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={pendingDeleteTemplate}
                >
                    Annuler
                </Button>
                <LoadingButton 
                    onClick={onDelete} 
                    disabled={pendingDeleteTemplate} 
                    loading={pendingDeleteTemplate}
                >
                    {pendingDeleteTemplate ? "Suppression ..." : "Supprimer"}
                </LoadingButton>
                
            </DialogActions>
        </Dialog>
    )
}
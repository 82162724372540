import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch } from "app/store";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { onDeleteMeal } from "features/mealPlanSlice";
import { useDispatch } from "react-redux";


interface Props{
    open: boolean,
    onClose: () => void,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}

export default function DialogDeleteMeal({open, onClose, mealIndex, day}:Props){

    const deletingMeal = false
    const dispatch = useDispatch<AppDispatch>()

    const onSubmit = () => {
        dispatch(onDeleteMeal({day, index: mealIndex}))
        onClose()
    }

    return(
        <Dialog 
            open={open}
        >
            <DialogTitle>
                Supprimer un repas
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer ce repas ?
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={deletingMeal}
                >
                    Annuler
                </Button>
                <LoadingButton 
                    onClick={onSubmit} 
                    variant="contained" 
                    disabled={deletingMeal} 
                    loading={deletingMeal}
                >
                    {(deletingMeal) ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch } from "app/store";
import { deleteWorkoutBlock } from "features/workoutSlice";
import React from "react";
import { useDispatch } from "react-redux";



interface Props{
    blockId: string,
    open: boolean,
    onClose: ()=> void,
    blockName: string
}

export function DialogBlockDelete({open,onClose, blockName, blockId}:Props){


    const dispatch = useDispatch<AppDispatch>()

    const onSubmit = () => {
        dispatch(deleteWorkoutBlock({blockId}))
        onClose()
    }

    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer un bloc de séance
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer le bloc <strong>{blockName}</strong> et tous les exercices qui le compose de cette séance ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Annuler
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                    Supprimer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function areEqual(prev:Props, next:Props){
    return prev.blockId === next.blockId && prev.open === next.open
}

export default React.memo(DialogBlockDelete, areEqual)
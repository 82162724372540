import { Box, LinearProgress, Typography } from "@mui/material";
import calcMacros from "function/calcMacros";
import MealInterface, { MealContent } from "interfaces/Meal";
import { useMemo } from "react";
import classes from "./styles";
import { Ingredient } from "interfaces/Recipe";
import { MACROS_COLORS } from "utils/constants";


interface Props{
    meal?: MealInterface,
    ingredients?: {
        ingredient: Ingredient
    }[]
}


const calcProteins = (macros:any) => {
    return macros[0].value * 4 / macros[4].value * 100
}

export default function MacrosList({meal, ingredients}:Props){


    const MACROS = useMemo(()=>{

        var total = null

        // MEAL
        if(meal){
            const meals:MealInterface[] = [meal]
            total = calcMacros(meals)
        } 
        
        // RECIPE
        else if(ingredients){

            var content:MealContent[] = []

            ingredients.forEach((elem)=> {
                content.push({
                    type: "food",
                    food: elem.ingredient.food,
                    
                    portion: elem.ingredient.portion, // 1, 2, 3 ... (Food et Recipe)
                    label: elem.ingredient.label, // grammes, Gros, Moyen, ml, ... (Uniquement Food)
                    grammage: elem.ingredient.grammage, // Equivalence en gramme (Uniquement Food)
                })
            })

            const newMeal:MealInterface[] = [{
                    name: "recipe",
                    time: "00:00",
                    content
            }]

            
            const meals:MealInterface[] = newMeal
             total = calcMacros(meals)
        }

        const labels = [
            { value: total.proteins, label: 'Protéines'},
            { value: total.lipids, label: 'Lipides'},
            { value: total.carbs, label: 'Glucides'},
            { value: total.fibers, label: `Fibres - ${total.fibers}g`},
            {value: total.kcal, label: "Kcal"},
        ]

        return labels

    },[meal, ingredients])



    return(

    <Box
        sx={classes.mealMacros}
    >

        <Box
            sx={classes.macro_left}
        >
            <Box
                sx={[
                    classes.mealMacroItem,
                    classes.proteins
                ]}
            >
                
                    <Typography
                        sx={classes.mealMacroItemName}
                    >
                        Protéines
                    </Typography>
                    <LinearProgress 
                        variant="determinate" 
                        value={MACROS[0].value === 0 ? 0 : MACROS[0].value * 4 / MACROS[4].value * 100} 
                        color={"inherit"}
                        sx={{height: 10, width: "100%", color: MACROS_COLORS.proteins, borderRadius: 5}}
                    
                    />
                    <Typography>
                        {MACROS[0].value}g
                    </Typography>
            
            </Box>

            <Box
                sx={[
                    classes.mealMacroItem,
                    classes.lipids
                ]}
            >
                <Typography
                    sx={classes.mealMacroItemName}
                >
                    Lipides
                </Typography>
                    <LinearProgress 
                        variant="determinate" 
                        value={MACROS[1].value === 0 ? 0 : MACROS[1].value * 9 / MACROS[4].value * 100} 
                        color={"inherit"}
                        sx={{height: 10, width: "100%", color: MACROS_COLORS.lipids,borderRadius: 5}}
                    
                    />
                    <Typography>
                        {MACROS[1].value}g
                    </Typography>
                

            </Box>

           
        
             
            <Box
                sx={[
                    classes.mealMacroItem,
                    classes.carbs
                ]}
            >
                <Typography
                    sx={classes.mealMacroItemName}
                >
                    Glucides
                </Typography>
                <LinearProgress 
                        variant="determinate" 
                        value={MACROS[2].value === 0 ? 0 : MACROS[2].value * 4 / MACROS[4].value * 100} 
                        color={"inherit"}
                        sx={{height: 10,width: "100%", color: MACROS_COLORS.carbs, borderRadius: 5}}
                    
                    />
                <Typography>
                    {MACROS[2].value}g
                </Typography>
            

            </Box>

            
            
            </Box>
</Box>
    )
}
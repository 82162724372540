import { Box, Typography } from "@mui/material";
import classes from "./styles";

interface Props{
    showDraft?: boolean,
}

export default function AutosaveIndicator({showDraft}:Props) {
    
    return (
        <Box 
            sx={classes.autoSave}> 
                <Box 
                    component="span" 
                    sx={classes.indicator} 
                />
                <Typography sx={classes.typo}>
                    Sauvegarde auto {showDraft? "(brouillon)" : ""}
                </Typography>
        </Box>
        
    )
}
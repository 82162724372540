import {  Delete, Restaurant } from "@mui/icons-material";
import { Box, Radio } from "@mui/material";
import { RootState } from "app/store";
import RowCard from "components/atoms/Box/RowCard";
import RowCardTitle from "components/atoms/Typography/RowCardTitle";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ViewportList } from "react-viewport-list";
import classes from "./styles"
import MoreButton from "components/atoms/Buttons/MoreButton";
import { useHistory } from "react-router-dom";
import MealPlan from "interfaces/MealPlan";
import DialogDeleteMealPlan from "components/molecules/DialogDeleteMealPlan";


interface Props{
    fromImport?: boolean,
    onSelectMealPlan?: (mealPlan:MealPlan)=> void ,
    mealPlanSelectedForImport?:MealPlan
}

export default function TemplateMealPlans({fromImport, onSelectMealPlan, mealPlanSelectedForImport}:Props){

    const navigate = useHistory()
    const listRefTemplates = useRef(null);
    const templates:MealPlan[] = useSelector((state:RootState) => state.mealPlans.templates.filtered)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [mealPlanSelected, setMealPlanSelected] = useState<MealPlan>(null)


    /********** SUPPRESSION ********** */

    const selectMealPlanToDelete = (mealPlan:MealPlan) => ()=>{
        //e.stopPropagation()
        setOpenDeleteDialog(true)
        setMealPlanSelected(mealPlan)
    }

    /********** REDIRECTION ********** */

    const onClick = (mealPlan: MealPlan) => {
        if(!fromImport){
            navigate.push(`/mealplans/${mealPlan._id}`)
        } else {
            onSelectMealPlan(mealPlan)
        }
    }

    return(
        <Box ref={listRefTemplates}>
            <ViewportList
                ref={listRefTemplates}
                items={templates}
                initialPrerender={15}
                itemSize={95}
            >
                {(item)=> (
                    <RowCard 
                        key={item._id}
                        onClick={()=>onClick(item)}
                    >
                        <Box sx={classes.rowInfos}>
                            <Restaurant 
                                sx={{marginRight: 2, color: "primary.main", fontSize:30}}
                            />
                            <Box>
                                <RowCardTitle>
                                    {item.name || "Aucun nom"}
                                </RowCardTitle>
                                
                            </Box>
                        </Box>

                        {/**** TEMPLATE - Options horizontales **** */}
                        {!fromImport && (
                            <MoreButton
                                menuList={[
                                    {
                                        label:"Supprimer",
                                        icon:<Delete/>,
                                        callback: selectMealPlanToDelete(item)
                                    }
                                ]}
                            />
                        )}

                        {/**** IMPORT - CHECKBOX **** */}
                        {fromImport && (
                            <Radio 
                                checked={mealPlanSelectedForImport?._id === item._id} 
                            />
                        )}
                    </RowCard>
                        
                )}
            </ViewportList>


            {/*** DIALOG DELETE PROGRAM **** */}
            {!fromImport && (
                <DialogDeleteMealPlan
                    open={openDeleteDialog}
                    onClose={()=> setOpenDeleteDialog(false)}
                    mealPlan={mealPlanSelected}
                />
            )}
        </Box>
    )
}
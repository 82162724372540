import { AppDispatch, RootState } from "app/store"
import { useDispatch, useSelector } from "react-redux"
// REDUCER
// API
import { deleteExercice } from "api/exercices";
import Exercice from "interfaces/Exercice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import Food from "interfaces/Food";
import { deleteCustomFood } from "api/food";


/******* INTERFACES ****** */

interface Props{
    open: boolean,
    onClose: () => void,
    item: Food
}


/******* MAIN COMPONENT ****** */

export default function DialogDeleteFood({open,onClose, item}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state: RootState) => state.food.requests.delete === "pending")


    /********** Suppression d' un exercice ********** */
    const onDeleteFood = async () => {
        console.log('a delete', item)
        dispatch(deleteCustomFood(item._id)).unwrap().then(()=>{
            onClose()
        })
    }


    return(

        <Dialog 
            open={open}
            disableScrollLock={true}
        >
            <DialogTitle>Supprimer un aliment</DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer l'aliment <strong>{item?.name?.fr || "sans nom"}</strong> de ta liste d'aliments ? Ce dernier restera disponible dans les plans alimentaires et les recettes que tu as déjâ créé.
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    disabled={pending}>
                        Annuler
                </Button>
                <LoadingButton 
                    loading={pending} 
                    disabled={pending} 
                    onClick={onDeleteFood}>
                        Supprimer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    filterMenu : {
        backgroundColor: "white", 
        //border:`solid 1px ${BORDER_COLOR}`, 
        padding: 3,
        
    },

    filterMenuFixed : {
        position: "fixed",
        top: theme.spacing(3)
    },

    group :{
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(15)
    },

    filter: {
        display:"flex",
        paddingTop: 1,
        paddingBottom: 1,
        flexDirection: "row",
        cursor: "pointer",

    },


    filterHeader : {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom: 2
    },

    checkbox:{
        marginRight: 2,
        color: "#e2e2e2"
    },

    checboxChecked:{
        color: "primary.main"
    },

    popover : {
        
    },
}


export default classes
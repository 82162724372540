import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import Food from "interfaces/Food";
import { MOBILE } from "utils/constants";
import { MacroPieChart } from "../MacroPieChart";
import { useMemo } from "react";
import Meal from "interfaces/Meal";
import calcMacros from "function/calcMacros";

interface Props{
    open: boolean,
    onClose: ()=> void,
    item: Food
}


export default function DialogFood({item, open, onClose}:Props){

    const food:Food = null
    const mobile = useMediaQuery(MOBILE)

    const DATA = useMemo(()=>{

        var labels = [
            { value: 0, label: `Protéines - 0g`},
            { value: 0, label: `Lipides - 0g`},
            { value: 0, label: `Glucides - 0g`},
            { value: 0, label: `Fibres - 0g`},
        ]


        if(item){
            const fakeMeal:Meal[] = [{
                name: "food",
                time: "00:00",
                content: [{
                    type: "food",
                    food: item,
                    portion: 100,
                    label: "grammes",
                    grammage: 100
                }]
            }] 

            const total = calcMacros(fakeMeal)


       
  
            labels = [
                { value: total.proteins, label: `Protéines - ${total.proteins}g`},
                { value: total.lipids, label: `Lipides - ${total.lipids}g`},
                { value: total.carbs, label: `Glucides - ${total.carbs}g`},
                { value: total.fibers, label: `Fibres - ${total.fibers}g`},
            ]
        }
  
        return labels
  
      },[item]);

    return(
    <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        fullScreen={mobile}
        disableScrollLock={true}
    >
        <DialogTitle>
            {item?.name.fr}
        </DialogTitle>
        <DialogContent>

            {/*food && Object.entries(food.macros).map((elem,index)=>{
                return(
                    <Box
                        sx={classes.listItem}
                    >
                        <Typography
                            key={index}
                        >
                            {TRAD(elem[0])}
                        </Typography>
                    </Box>
                )
            })*/}
            {item && (
                <MacroPieChart
                    data={DATA}
                />
            )}
            

        </DialogContent>
        <DialogActions>
            <Button
                onClick={onClose}
            >
                Fermer
            </Button>
        </DialogActions>

    </Dialog>
    )
}
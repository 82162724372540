import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import Food from "interfaces/Food";
import classes from "./styles";
import { ViewportList } from "react-viewport-list"
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import Tabs from "components/molecules/Tabs";
import SearchTextfield from "components/molecules/Search";
import { searchFood } from "features/foodSlice";
import { onAddFoodToRecipe } from "features/recipesSlice";
import { onAddIngredient } from "features/mealPlanSlice";
import FoodRow from "components/molecules/FoodRow";
import { toast } from "react-toastify";
import { DESKTOP, MOBILE, TABLET } from "utils/constants";




interface Props{
    open: boolean,
    onClose: ()=> void,
    mealIndex?: number,
    day?: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
    fromRecipe?: boolean
}


export default function DialogFoodList({open, onClose, mealIndex, day, fromRecipe}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const meal = useSelector((state:RootState)=> state.mealPlans.mealPlan && day ? state.mealPlans.mealPlan[day][mealIndex] : null)
    const recipe = useSelector((state:RootState) => state.recipes?.builder)
    const listRef = useRef(null);
    const foodList = useSelector((state:RootState)=> state.food.filtered)
    const [search, setSearch] = useState<string>('')
    const [tabs, setTabs] = useState(0)
    const tablet = useMediaQuery(TABLET)
    const mobile = useMediaQuery(MOBILE)
    const desktop = useMediaQuery(DESKTOP)

    const closeDialog = () => {
        setTimeout(()=>{
            setSearch("")
            dispatch(searchFood({search:""}))
        },200)
        onClose()
    }




    const onAdd = (food:Food) => {
        if(!fromRecipe){
            dispatch(onAddIngredient({
                day,
                index: mealIndex,
                food
            }))
            if(tablet && !desktop){
                toast.success('Aliment ajouté')
            }
        } else {
            dispatch(onAddFoodToRecipe({food}))
        }
    }


    const onSearch = (e:any) => {
        setSearch(e.currentTarget.value)
        dispatch(searchFood({search:e.currentTarget.value}))
    }


    /********** TABS LABELS ********** */

    const tabsLabels = useMemo(() => {
        return [
            `Simplifié (${foodList.app.length})`,
            `Ciqual (${foodList.ciqual.length})`,
            `Mes aliments (${foodList.custom.length})`,
        ]
    }, [foodList.ciqual, foodList.custom])


    
    /*********** GESTION DES TABS ******* */

    const handleTabs = (_: React.SyntheticEvent, value: number) => {
        setTabs(value)
    }


    const LIST = useMemo(()=>{
        switch (tabs) {
            case 0 : {
                return foodList.app
            }
            case 1 : {
                return foodList.ciqual
            }
            case 2 : {
                return foodList.custom
            }
        }
    },[foodList, tabs])
    


    return(
    <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableScrollLock={true}
        fullScreen={mobile}
    >
        <DialogTitle>
            Ajouter un aliment à {meal ? meal.name : "la recette"}
        </DialogTitle>
        <DialogContent 
                sx={classes.dialogContent}
            >
                <Box
                    sx={classes.header}
                >
                    {/***** SEARCH + TABS **** */}

                    <Box 
                            sx={classes.searchContainer}
                        >

                                <Tabs 
                                    tabs={tabsLabels} 
                                    value={tabs} 
                                    onChange={handleTabs} 
                                />
                                
                                <Box sx={{display:"flex", flexDirection: "row"}}>
                                 
                                    <SearchTextfield 
                                        placeholder="Rechercher un aliment"
                                        onChange={onSearch}
                                        fullWidth={true}
                                        borderRadius={0}
                                    />
                                </Box>
                            

                        </Box>
                    
                </Box>
                <Box 
                    ref={listRef} 
                    className="scroll-container" 
                    sx={classes.scrollContainer}
                >
                    
                    <ViewportList
                        ref={listRef}
                        items={LIST}
                        initialPrerender={15}
                        itemSize={95}
                    >
                        {(item)=> {

                            const total = item.macros.carbs + item.macros.lipids + item.macros.proteins
                            const found = fromRecipe ? recipe.ingredients.findIndex((elem)=> elem.ingredient?.food._id === item._id) : meal.content.findIndex((elem)=> elem.food?._id === item._id)

                            return(
                                <FoodRow
                                    key={item._id}
                                    item={item}
                                    onClick={onAdd}
                                    selected={found > -1}
                                />
                            )
                        }}
                        
                    </ViewportList>
                </Box>
            </DialogContent>
        <DialogActions>
            <Button
                onClick={closeDialog}
            >
                Fermer
            </Button>
        </DialogActions>

    </Dialog>
    )
}
import { theme } from "utils/theme";


const classes = {
    header :{
        height: 60,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginBottom: 4,
    },

    button: {
        fontSize: theme.typography.pxToRem(20), 
        width: 230,
        color: "black",
        fontWeight: 600,
        textTransform: "none"
        
    },

    calendarItem:{
        width: "100%", 
        height: 60, 
        display:"flex", 
        alignItems:"center", 
        fontSize: theme.typography.pxToRem(16), 
        color: "#bfbfbf",
        textTransform: "none"
    },


    calendarItemSelected:{
        color: "primary.main"
    },
}


export default classes
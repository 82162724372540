import { lighten } from "@mui/material";
import { DRAWER_WIDTH, DRAWER_WIDTH_MIN, DRAWER_ACTIVE_ITEM, DRAWER_ACTIVE_ITEM_TEXT_COLOR, DRAWER_ITEM_LABEL_COLOR, DRAWER_ICON_SIZE, DRAWER_PADDING, LIGHT_BUTTON_COLOR} from "utils/constants";
import { theme} from "utils/theme";

const ITEM_HEIGHT = DRAWER_WIDTH_MIN - (DRAWER_PADDING*8) * 2
const transitionDuration = 200

const classes = {


    drawerContent: {
        //width: DRAWER_WIDTH_MIN - (DRAWER_PADDING * 8) * 2,
        display:"flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        //backgroundColor: "green",
        position: "relative",
        width: "100%"
    },

    // OUVERT
    drawerContentExtended: {
        width: DRAWER_WIDTH - (DRAWER_PADDING * 8) * 2
    },


    horizontalDrawer: {
        flexDirection: "row",
        width: "100%"
    },

    horizontalList : {
        display:"flex", 
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems:"center"
    },

    itemContainer: {
        height: 40,
        overflow: "hidden",
        alignItems:"center", 
        justifyContent: "flex-start",
        display:"flex",
        borderRadius: "8px !important",
        marginBottom: 2,
        flex: 1,
        position: "relative",
        
    },


    itemContainerSelected: {
        borderRadius: "8px !important",
        backgroundColor: {
            lg: LIGHT_BUTTON_COLOR
        }
    },

   

    itemContainerHorizontal : {
        height: 80,
        width: 100,
        marginBottom: 0,
        borderRadius: "8px !important",
        justifyContent:"center",
        flexDirection: "column",
        alignItems:"center"
    },

    item:{
        textDecoration: "none", 
        color: "transparent", 
        position: "relative",
        borderRadius: "8px !important",
        //minWidth: DRAWER_WIDTH - (DRAWER_PADDING * 8) * 2,
        height: ITEM_HEIGHT,
        alignItems:"center",
        paddingLeft: 0,
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: transitionDuration,
        }),
        overflow: "hidden",
        maxWidth: "100%",
        left: 0,
    },

    horizontalItem : {
        display:"flex", 
        flex: 1, 
        alignitems:"center", 
        justifyContent:"center",
        flexDirection: "column",
        height : 80,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        overflow: "visible",
        padding: 0,
        paddingRight :0,
        paddingLeft: 0,
    },

    itemClose : {
        minWidth: `${ITEM_HEIGHT}px`,
    },


    listItemButton:{
       
        backgroundColor: "transparent",
        "&:hover":{
            backgroundColor: LIGHT_BUTTON_COLOR
        },
        
    },


    iconContainer : {
        //height: ITEM_HEIGHT,  
        minWidth: ITEM_HEIGHT,
        display:"inline-flex", 
        alignItems:"center",
        justifyContent:"center",
        borderRadius: "8px !important"
    },


    itemLabel:{
        color: DRAWER_ITEM_LABEL_COLOR, 
        textDecoration: "none",
        display:"flex",
        flex: 1,
        paddingLeft: {xs: 0, lg: 1},
        //height: 40,
        justifyContent:"flex-start",
        alignItems:"center",
        textWrap: "nowrap",
        marginTop: {
            xs: 0,
            sm: 1.5,
            lg: 0
        },
        
        
        '& span' : {
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(14),
        }
    },


    itemLabelHorizontal: {
        marginLeft: 0,
        justifyContent:"center",
        alignItems:"center",
    },

    itemLabelActive : {
        color: DRAWER_ACTIVE_ITEM_TEXT_COLOR, 
        /*backgroundColor: {
            lg: LIGHT_BUTTON_COLOR
        },*/
        '& span' : {
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(14),
        }
    }
}


export default classes
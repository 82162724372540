import  Workout, { GroupExercice, WorkoutBlock,  WorkoutExercice } from "interfaces/Workout";

/**
 * Calcule le nombre d'exercices d'une séance
 * 
 * @params {Workout} La séance
 * @return {number} Le nombre d'exercices
 * 
 * */


export default function calcExercices(workout: Workout):number {

    let totalExercices:number = 0


    workout.blocks.forEach((block:WorkoutBlock) => {
        block.content.forEach((group: GroupExercice) => {
        group.exercices.forEach((_: WorkoutExercice) => {
            totalExercices = totalExercices + 1
        })
    
        })
    })


    return totalExercices
}


export default async function cacheImages(srcArray:string[]){
    const promises = srcArray.map((src)=>{
        return new Promise((resolve,reject)=> {
            const image = new Image()

            image.src= src
            image.onload = () => {
                resolve(null)
            }
            image.onerror = () => reject(null)
        })
    })

    await Promise.all(promises)
}
import { BORDER_COLOR } from "utils/constants"

const classes = {
    toolbar : {
        display:"flex", 
        alignItems:"center", 
        width: "100%",
        justifyContent:"space-between",
        flexDirection: "row",
        //paddingLeft: 3,
        //paddingRight: 3,
    },
}

export default classes
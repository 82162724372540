import { useState, useEffect } from "react";
//MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery } from "@mui/material";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
// INTERFACES
import { AppDispatch, RootState } from "app/store";
// REDUCERS
// DATE PICKER
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// CONSTANTS
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateUserData } from "api/user";


/***** INTERFACES ***** */

interface Props {
    open: boolean,
    onClose: ()=> void,
}


// MAIN CONTENT

export default function DialogBirthday({open,onClose}:Props){
    
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const [birthday, setBirthday] = useState<Date>(new Date())
    const mobile = useMediaQuery(MOBILE)
    

    useEffect(()=>{
        if(open){
            setBirthday(new Date(user.birthday))
        }
    },[open])


    const submit = () => {
        dispatch(updateUserData({birthday})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
    }


    const handleChange = (newValue: Date | null) => {
        setBirthday(newValue);
    };

    return(
        <Dialog 
            open={open} 
        >
                <DialogTitle>
                    Modifie ta date de naissance
                </DialogTitle>
                <DialogContent sx={{width:mobile ? "100%" : DIALOG_WIDTH}}>
                <DesktopDatePicker
                    label="Date de naissance"
                    inputFormat="dd/MM/yyyy"
                    value={birthday}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} fullWidth sx={{mt:1}}/>}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={pending}>
                        Annuler
                    </Button>
                    <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                        {pending? "Modification" : "Valider"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
    )
}
import React, { useState } from "react"
// MUI
import { Card, Box, RadioGroup, FormControlLabel, IconButton, Button, Typography, useMediaQuery, Menu, MenuItem, ListItemIcon, ListItemText} from "@mui/material";
import { Close, Delete, Notes, RadioButtonChecked, CheckBox, CheckBoxOutlineBlankOutlined, RadioButtonUnchecked, Add} from "@mui/icons-material";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// REDUCERS
import { deleteQuestion, removeOption, handleQuestionType, pushOption} from "features/checkupSlice";
// Interfaces
import { AppDispatch, RootState } from "app/store";
import { CheckupQuestion as CheckupQuestionType } from "interfaces/Checkup";
// STYLES
import classes from "./styles";
import { createSelector } from "@reduxjs/toolkit";
import QuestionTextField from "components/molecules/CheckupQuestionTextField";
import OptionTextField from "components/molecules/CheckupOptionTextField";
import { MOBILE, TABLET } from "utils/constants";


/******** INTERFACES ******* */

interface Props{
    questionId: string,
    questionIndex: number
}



/*********** FUNCTIONS ********** */

function getQuestion (checkupBuilder:CheckupQuestionType[], questionId:string){
    const question:CheckupQuestionType = checkupBuilder.find((elem:CheckupQuestionType)=> elem.questionId === questionId)
    return question
}



/****** REDUX SELECTORS ***** */

const questionSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.question
    }
)


const optionsSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.options
    }
)

const responseTypeSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.type
    }
)



  /******** MAIN COMPONENT ******* */

export function Question({questionId,questionIndex}:Props){

    // Gestion du nom du checkup
    const dispatch = useDispatch<AppDispatch>()
    const options:string[] = useSelector((state:RootState)=> optionsSelector(state,questionId))
    const responseType:string = useSelector((state:RootState)=> responseTypeSelector(state,questionId))
    const question:string = useSelector((state:RootState)=> questionSelector(state,questionId))
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const [anchorEl, setAnchorEl] = useState<any>(null)


    /****** CHANGEMENT DU TYPE DE QUESTION ******* */

    const onChangeType = (questionType:"comment" | "radio" | 'checkbox') => ()=>{
        dispatch(handleQuestionType({questionIndex, questionType}))
        setAnchorEl(null)
    }

    /************** AJOUT d'UNE OPTION ********* */

    const onAddOption = () => {
        dispatch(pushOption({questionIndex }))
    }

    
    /************** SUPPRESSION d'UNE OPTION ********* */

    const onRemoveOption = (optionIndex:number) => ()=>{
        dispatch(removeOption({questionIndex, optionIndex }))
    }

    const onDeleteQuestion = () => {
        dispatch(deleteQuestion({questionId}))
    }

    const onOpenMenu = (e:any)=> {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        
    }


    const onCloseMenu = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null)
        
    } 


    return(
   
            <Box sx={classes.card}>

                {/******** HEADER ******** */}
                <Box sx={classes.header}>

                    <Box sx={classes.questionContainer}>

                        {/**** SELECTION DU TYPE DE QUESTION ***** */}

                        <IconButton
                            sx={classes.questionIcon}
                            onClick={onOpenMenu}
                        >
                            {responseType === "comment" && (
                                <Notes sx={{color: "primary.main"}} />
                            )}

                            {responseType === "radio" && (
                                <RadioButtonChecked sx={{color: "primary.main"}}/>
                            )}

                            {responseType === "checkbox" && (
                                <CheckBox sx={{color: "primary.main"}}/>
                            )}
                            
                        </IconButton>

                        <Menu disableScrollLock={true}  
                            open={Boolean(anchorEl)}
                            onClose={onCloseMenu}
                            anchorEl={anchorEl}>
                            <MenuItem onClick={onChangeType("comment")}>
                                <ListItemIcon>
                                    <Notes />
                                </ListItemIcon>
                                <ListItemText>
                                    Réponse libre
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={onChangeType("checkbox")}>
                                <ListItemIcon>
                                    <CheckBox />
                                </ListItemIcon>
                                <ListItemText>
                                    Choix multiple
                                </ListItemText>
                            </MenuItem>
                            <MenuItem onClick={onChangeType("radio")}>
                                <ListItemIcon>
                                    <RadioButtonChecked />
                                </ListItemIcon>
                                <ListItemText>
                                    Choix unique
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                        

                        {/*** QUESTION ***** */}
                        <QuestionTextField 
                            question={question}
                            questionIndex={questionIndex}
                        />
                
                    </Box>
                    {/*** BUTTON DELETE ***** */}
                    <Box sx={classes.deleteButton}>
                        <IconButton onClick={onDeleteQuestion}>
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>

                {/******** SELECTION DU TYPE DE REPONSE MULTIPLES ******** 

                <Box sx={classes.radioGroup}>
                    <Typography sx={classes.respondeTypeLabel}>
                        Réponse : 
                    </Typography>
                    <RadioGroup
                        row
                        defaultValue="comment"
                        name="radio-buttons-group"
                        onChange={onChangeType}
                    >
                        <FormControlLabel value="comment" control={<Radio size="small" checked={responseType === "comment"} />} label="Champ de texte" sx={{opacity: responseType === "comment" ? 1 :0.7, fontSize: "1rem"}} />
                        <FormControlLabel value="radio" control={<Radio size="small" checked={responseType === "radio"}/>} label="Choix unique" sx={{opacity: responseType === "radio" ? 1 :0.7,  fontSize: "1rem"}}/>
                        <FormControlLabel value="checkbox" control={<Radio size="small" checked={responseType === "checkbox"}/>} label="Choix multiple" sx={{opacity: responseType === "checkbox" ? 1 :0.7,  fontSize: "1rem"}} />

                    </RadioGroup>
                </Box>
                */}

                    {/******** CHAMP DE TEXTE ******** */}

                    {responseType === "comment" && (
                        <Box sx={classes.fakeTextArea}>
                            Réponse libre de ton client
                        </Box>
                    )}
                                
                    {/******** RADIO ******** */}

                    {responseType === "radio" && (
                        options.map((label:string, index: number)=> (
                            <Box 
                                sx={classes.option} 
                                key={index}
                            >
                                <RadioButtonUnchecked 
                                    //size="small" 
                                    //disabled 
                                    sx={{mr: 3, padding: 0, ml:1, color: "#aaaaaa"}}
                                />

                                <OptionTextField
                                    optionIndex={index}
                                    optionLabel={label}
                                    questionIndex={questionIndex}
                                />

                                <IconButton sx={{ml: 3}} onClick={onRemoveOption(index)}>
                                    <Close />
                                </IconButton>
                            </Box>
                        ))
                    )}

                    {/******** CHECKBOX ******** */}

                    {responseType === "checkbox" && (
                        options.map((label:string, index: number)=> (
                            <Box sx={classes.option} key={index}>
                                <CheckBoxOutlineBlankOutlined 
                                    //size="small" 
                                    //disabled 
                                    sx={{mr: 3, padding: 0, ml:1, color: "#aaaaaa"}}
                                />

                                <OptionTextField
                                    optionIndex={index}
                                    optionLabel={label}
                                    questionIndex={questionIndex}
                                />

                                <IconButton 
                                    sx={{ml: 3}} 
                                    onClick={onRemoveOption(index)}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        ))
                    )}


                    {/******** BOUTON AJOUT D'UNE OPTION ******** */}

                    {(responseType === "checkbox" || responseType === "radio") &&(
                        <Button sx={{padding: 0.1, paddingTop: 1, paddingBottom: 1}} onClick={onAddOption}>
                 
                                <Add
                                sx={{mr: 3, ml: 1, padding: 0}}/>
                            
                            Ajouter une option de réponse
                        </Button>
                    )}
        
            </Box>
       
    )
}


function areEqual(prev:Props, next:Props){
    return prev.questionIndex === next.questionIndex && prev.questionId === next.questionId
}

export default React.memo(Question, areEqual)
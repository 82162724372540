import { Delete, Event, ListAlt } from "@mui/icons-material";
import { Box, Checkbox, Radio } from "@mui/material";
import { RootState } from "app/store";
import RowCard from "components/atoms/Box/RowCard";
import RowCardSubTitle from "components/atoms/Typography/RowCardSubTitle";
import RowCardTitle from "components/atoms/Typography/RowCardTitle";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ViewportList } from "react-viewport-list";
import classes from "./style"
import MoreButton from "components/atoms/Buttons/MoreButton";
import { useHistory } from "react-router-dom";
import Workout from "interfaces/Workout";
import calcExercices from "function/calcExercices";
import DialogDeleteWorkout from "components/molecules/DialogDeleteWorkout";

interface Props{
    fromImport?: boolean,
    onSelectWorkout?: (workout:Workout)=> void ,
    workoutSelectedForImport?:Workout
}

export default function TemplateWorkouts({fromImport, onSelectWorkout, workoutSelectedForImport}:Props){

    const navigate = useHistory()
    const listRef = useRef(null);
    const workouts:Workout[] = useSelector((state:RootState) => state.workouts.templates.filtered)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [workoutSelected, setWorkoutSelected] = useState<Workout>(null)


    /********** SUPPRESSION ********** */

    const selectWorkoutToDelete = (workout:Workout) => ()=>{
        //e.stopPropagation()
        setOpenDeleteDialog(true)
        setWorkoutSelected(workout)
    }

    /********** REDIRECTION ********** */

    const onClick = (workout: Workout) => {
        if(!fromImport){
            navigate.push(`/workout/${workout._id}`)
        } else {
            onSelectWorkout(workout)
        }
    }


    return(
        <Box ref={listRef}>
            <ViewportList
                ref={listRef}
                items={workouts}
                initialPrerender={15}
                itemSize={95}
            >
                {(item)=> (
                    <RowCard 
                        key={item._id}
                        onClick={()=>onClick(item)}
                    >
                        <Box sx={classes.rowInfos}>
                            <ListAlt sx={{marginRight: 2, color: "primary.main", fontSize:30}}/>
                            <Box>
                                <RowCardTitle>
                                    {item.name || "Aucun nom"}
                                </RowCardTitle>
                                <RowCardSubTitle>
                                    {item.blocks.length} blocs - {calcExercices(item)} exercices
                                </RowCardSubTitle>
                            </Box>
                        </Box>

                        {/**** TEMPLATE - Options horizontales **** */}
                        {!fromImport && (
                            <MoreButton
                                menuList={[
                                    {
                                        label:"Supprimer",
                                        icon:<Delete/>,
                                        callback: selectWorkoutToDelete(item)
                                    }
                                ]}
                            />
                        )}

                        {/**** IMPORT - CHECKBOX **** */}
                        {fromImport && (
                            <Radio checked={workoutSelectedForImport?._id === item._id} />
                        )}

                    </RowCard>
                        
                )}
            </ViewportList>


            {/*** DIALOG DELETE PROGRAM **** */}
            
            {!fromImport && (
                <DialogDeleteWorkout
                    open={openDeleteDialog}
                    onClose={()=> setOpenDeleteDialog(false)}
                    workout={workoutSelected}
                />
            )}
        </Box>
    )
}
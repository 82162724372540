import { theme } from "utils/theme"


const classes = {
    sectionTitle:{
        
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        color: "rgba(0,0,0,0.85)"
    },
}

export default classes
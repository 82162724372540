import { BODY_BGCOLOR, BORDER_COLOR, HOVER_COLOR } from "utils/constants";
import { theme } from "utils/theme";


const classes = {
  
    foodItem : {
        minHeight: 120,
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        width: "100%",
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        paddingLeft: 3

        
    },

    nameContainer : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
    },

    itemLabels : {
        display:"flex",
        justifyContent:"flex-start",
        flexDirection: "column",
        flex: 1
    },

    macroItem:{
        marginRight: 3,
        flexDirection: "row",
        alignItems:"center",
        marginTop: "3px",
        display: {
            xs: "none",
            sm: "flex"
        }
    },

    macroItemTitle:{
        fontSize: {
            xs: 13,
            sm: 15
        }
    },

    foodItemName : {
        textAlign: "left",
        flex: 2,
        paddingBottom: 0.5,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600
    },

    macros: {
        display:"flex",
        flex: 1,
        alignItems:"flex-end",
        justifyContent:"flex-start",
        flexDirection: "row",
    },


    withBackground : {
        backgroundColor: " white",
        cursor: "pointer",
        minHeight: 110,
        marginBottom: 3,
        paddingRight: 3,
        border : `solid 1px ${BORDER_COLOR}`,
        "&:hover" : {
            backgroundColor: HOVER_COLOR
        }

    },


}


export default classes
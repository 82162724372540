import { DRAWER_WIDTH_MIN } from "utils/constants";

const classes = {


    fixed: {
        position: "fixed",
        backgroundColor: "white",
        top: 0,
        zIndex: 0,
        left: {
            xs: 0,
            lg:DRAWER_WIDTH_MIN
        },
        width: {
            xs: "100vw",
            lg:`calc(100vw - ${DRAWER_WIDTH_MIN}px) !important`,
        },
        paddingLeft: 3,
        paddingRight: 3,
        borderBottom: "solid 1px #e6e6e6",
        height: 48,
       
    },

    openFixed:{
        top: 85,
        zIndex: 200,
    },

    openFixedCoach:{
        top: 70,
    },


    daysList : {
        display:"flex",
        flexDirection:"row",
        width: "100%"
        
    },

    
    day: {
        flex: 1,
        width:"auto",
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        height: 48
    },

}

export default classes
import { Box, Button, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import classes from "./styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import Meal from "../Meal";
import calcMacros from "function/calcMacros";
import debounce from "lodash.debounce";
import { AUTOSAVE_TIMER, MOBILE, TABLET } from "utils/constants";
import { updateClientMealPlan } from "api/clients";
import { CheckCircle, ContentCopy, CopyAll, Download, Edit, HighlightOff, MoreHoriz, Save } from "@mui/icons-material";
import DialogActivateMealPlan from "components/molecules/DialogActivateMealPlan";
import MacroPieChart from "../MacroPieChart";
import MealInterface from "interfaces/Meal";
import NutritionHeaderDays from "components/molecules/NutritionHeaderDays";
import MealPlan from 'interfaces/MealPlan';
import AutosaveIndicator from "components/molecules/AutosaveIndicator";
import { toast } from "react-toastify";
import DialogPasteMealPlan from "../DialogPasteMealPlan";
import DialogSaveMealPlanTemplate from "../DialogSaveMealPlanTemplate";
import DialogImportMealPlan from "../DialogImportMealPlan";
import { mealPlanIsSaving, onAddMeal, onCopyDayMeals, onCopyMealPlan, onPasteDayMeals, onPastMeal } from "features/mealPlanSlice";
import { updateMealPlan } from "api/mealPlans";

const days = ['monday', "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]


interface Props{
    template?: boolean
}

export default function Nutrition({template}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const clientId = useSelector((state:RootState) => state.clients?.profile?._id)
    const [dayIndex, setDayIndex] = useState<number>(0)
    const mealPlan = useSelector((state:RootState) => state.mealPlans?.mealPlan)
    const mealPlanCopied = useSelector((state:RootState) => state.mealPlans.mealPlanCopied)
    const mealCopied = useSelector((state:RootState) => state.mealPlans.mealCopied)
    const dayCopied = useSelector((state:RootState) => state.mealPlans.dayCopied)
    const [openActivateMealPlan, setOpenActivateMealPlan] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [openPasteMealPlan, setOpenPasteMealPlan] = useState<boolean>(false)
    const [openTemplateSave, setOpenTemplateSave] = useState<boolean>(false)
    const [openImportTemplate, setOpenImportTemplate] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)


    useEffect(()=>{
        console.log('mealplan change', mealPlan)
    },[mealPlan])


    const copyMealsDay = () => {
        dispatch(onCopyDayMeals(mealPlan[SELECTED_DAY]))
        toast.success('Journée copiée')
        setAnchorEl(null)
    }

    const copyMealPlan = () => {
        dispatch(onCopyMealPlan())
        toast.success('Programme alimentaire copié')
        setAnchorEl(null)
    }

    const pasteMealPlan = () => {
        setOpenPasteMealPlan(true)
        setAnchorEl(null)
    }


    const saveAsTemplate = () => {
        setOpenTemplateSave(true)
        setAnchorEl(null)

    }


    const importTemplate = () => {
        setOpenImportTemplate(true)
        setAnchorEl(null)

    }


    /***************
     *  AUTOSAVE
     ***************/

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((mealPlanData,isTemplate)=> {
        if(!isTemplate){
            dispatch(updateClientMealPlan({
                mealPlan:mealPlanData, 
                clientId
            }))
        } else if(isTemplate){
            dispatch(updateMealPlan({
                mealPlan:mealPlanData, 
            }))
        }
       
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(mealPlan){
            const emptyMealPlan:MealPlan = {
                name: template? undefined: "",
                status: "disabled",
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: []
            } 

            const updatedMealPlan:MealPlan = mealPlan? mealPlan : emptyMealPlan
            
            dispatch(mealPlanIsSaving())
            debounceAutoSave(updatedMealPlan, template)
        }
       
        // eslint-disable-next-line
    }, [JSON.stringify(mealPlan), template])






    /********************
     * Ajout d'un repas
     *******************/

    const addMeal = () => {
        dispatch(onAddMeal({day: SELECTED_DAY}))
    }

    const pasteMeal = () => {
        dispatch(onPastMeal({day: SELECTED_DAY}))
    }

    const pasteDay = () => {
        dispatch(onPasteDayMeals({day: SELECTED_DAY}))
    }


    const SELECTED_DAY = useMemo(()=>{
        return days[dayIndex] as 'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday"
    },[dayIndex])


    /*******************************
     * DONNEES DU PIE CHART
     *******************************/

    const CHART_DATA = useMemo(()=>{

        if(mealPlan){
            var meals:MealInterface[] = []

            // Client
            if(mealPlan){
                meals = mealPlan[SELECTED_DAY]
            } 
            else {
                meals = []
            }

            const total = calcMacros(meals)
    
            
            const labels = [
                { value: total.proteins, label: `Protéines - ${total.proteins}g`},
                { value: total.lipids, label: `Lipides - ${total.lipids}g`},
                { value: total.carbs, label: `Glucides - ${total.carbs}g`},
                { value: total.fibers, label: `Fibres - ${total.fibers}g`},
            ]
  
            return labels
        }else{
            const labels = [
                { value: 0, label: `Protéines - 0g`},
                { value: 0, label: `Lipides - 0g`},
                { value: 0, label: `Glucides - 0g`},
                { value: 0, label: `Fibres - 0g`},
            ]
            return labels
        }
  
      },[
        SELECTED_DAY, 
        JSON.stringify(mealPlan), 
        template
    ]);





    
    return(
        <Box 
            sx={classes.nutrition}>
                <Box
                    sx={classes.header}
                >

                    {/********************************
                    * ACTIVATION DU PLAN ALIMENTAIRE 
                    ********************************/}
                    {(!template) && (
                        <Box 
                            sx={[
                                classes.tagInfo,
                                classes.tagInfoClickable,
                                mealPlan?.status === "enabled" && classes.tagInfoEnabled
                            ]} 
                            onClick={()=> setOpenActivateMealPlan(true)}
                        >
                            {mealPlan?.status === "enabled"? 
                                <CheckCircle sx={[
                                    classes.tagInfoIcon, 
                                    classes.tagInfoIconEnabled
                                ]} /> : 
                                <HighlightOff sx={[
                                    classes.tagInfoIcon, 
                                    classes.disabledIcon
                                ]} />
                            }

                            
                            
                            <Typography 
                                sx={classes.tagInfoValue}
                            >
                                {mealPlan?.status=== "enabled"? "Plan alimentaire activé" : "Plan alimentaire désactivé"}
                            </Typography>
                            <Edit 
                                sx={{fontSize: "1rem", marginLeft: 1, color: "rgba(0,0,0,0.85)"}}
                            />
                        </Box>
                    )}

                    {template && (
                        <Box>

                        </Box>
                    )}

                    

                    <Box>


                       

                        {/*****************************************
                        * ACTIONS SUR LE PLAN ALIMENTAIRE (CLIENT)
                        ******************************************/}
                    
                        {(mealPlan && !template) && (
                            <React.Fragment>
                                 <AutosaveIndicator/>
                                <IconButton 
                                    sx={classes.buttonMore} 
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                                >
                                    <MoreHoriz />
                                </IconButton>

                                <Menu disableScrollLock={true} 
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={()=>setAnchorEl(null)}
                                >
                                    <MenuItem 
                                        onClick={copyMealsDay}
                                    >
                                        <ListItemIcon>
                                            <CopyAll />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Copier cette journée
                                        </ListItemText>
                                    </MenuItem> 
                                    <MenuItem 
                                        onClick={copyMealPlan}
                                    >
                                        <ListItemIcon>
                                            <CopyAll />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Copier ce plan alimentaire
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={pasteMealPlan}
                                        disabled={!Boolean(mealPlanCopied)}
                                    >
                                        <ListItemIcon>
                                            <ContentCopy />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Coller un plan alimentaire
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={importTemplate}
                                    >
                                        <ListItemIcon>
                                            <Download 
                                                fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Importer un modèle
                                        </ListItemText>
                                    </MenuItem>  
                                    <MenuItem 
                                        onClick={saveAsTemplate}
                                    >
                                        <ListItemIcon>
                                            <Save 
                                                fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Enregistrer comme modèle
                                        </ListItemText>
                                    </MenuItem>  
                                </Menu>
                            
                            </React.Fragment>
                        )}
                     </Box>
                    </Box>

                

                {/******************
                 * LISTE DES JOURS
                 *******************/}

     
                    <NutritionHeaderDays
                        dayIndex={dayIndex}
                        daySelected={SELECTED_DAY}
                        onChangeDaySelected={(index)=> ()=> setDayIndex(index)}
                    />
              

                
                <Box
                    sx={classes.content}>
                        <Grid 
                            container 
                            spacing={3}
                        >

                            {/******************
                             * Liste des repas
                             ******************/}

                            <Grid 
                                item xs={12} 
                                lg={7}
                                xl={8}
                                order={{xs:2, lg:1}}
                            >

                                {mealPlan && mealPlan[SELECTED_DAY]?.map((_, mealIndex) =>{
                                    return(
                                        <Meal 
                                            day={SELECTED_DAY}
                                            mealIndex={mealIndex}
                                            key={mealIndex}
                                        />
                                    )
                                })}

                                {(!mealPlan || !mealPlan[SELECTED_DAY] || mealPlan[SELECTED_DAY].length === 0 ) && (

                                    <Box
                                        sx={classes.noMealContainer}
                                    >
                                        <Typography
                                            sx={classes.noMealContainerLabel}
                                        >
                                            Aucun repas programmé
                                        </Typography>
                                    </Box>

                                )}

                                <Box
                                    sx={classes.button}>

                                    <Button 
                                        onClick={()=> addMeal()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                    >
                                        Ajouter un repas
                                    </Button>

                                    <Button 
                                        disabled={!mealCopied}
                                        onClick={()=> pasteMeal()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginLeft: {xs: 0, sm: 3}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                    >
                                        Coller un repas
                                    </Button>

                                    <Button 
                                        disabled={!dayCopied}
                                        onClick={()=> pasteDay()} 
                                        sx={{textTransform: "none", width: {xs: "100%", sm: 200}, marginLeft: {xs: 0, sm: 3}, marginBottom: {xs: 1.5, sm: 0}}} 
                                        variant="contained"
                                        disableElevation={true}
                                    >
                                        Coller une journée
                                    </Button>
                                </Box>

                               
                            </Grid>

                            {/****************
                             * Statistiques
                             ****************/}

                            <Grid 
                                item 
                                xs={12} 
                                lg={5}
                                xl={4}
                                order={{xs:1, lg:2}}
                            >

                                {/************
                                 * PIE CHART
                                 ************/}
                                {!mobile && (
                                    <Box
                                        sx={classes.chartContainer}>
                                            <MacroPieChart 
                                                data={CHART_DATA}
                                                
                                            />
                                    </Box>
                                )}

                                {/*!mobile && (
                                <Box
                                    sx={classes.stats}
                                >
                                    <Typography
                                        sx={classes.statsTitle}
                                    >
                                        Micronutriments
                                    </Typography>
                                    <Typography
                                        sx={{marginTop: 1}}
                                    >
                                        Bientôt disponible
                                    </Typography>
                                </Box>
                                )*/}

                            </Grid>
                        </Grid>
                        
                </Box>

                {!template && (
                    <DialogPasteMealPlan
                        open={openPasteMealPlan}
                        onClose={()=>setOpenPasteMealPlan(false)}
            
                    />
                )}

                {!template && (
                    <DialogSaveMealPlanTemplate
                        open={openTemplateSave}
                        onClose={()=> setOpenTemplateSave(false)}
                    />
                )}

                {!template && (
                    <DialogImportMealPlan
                        open={openImportTemplate}
                        onClose={()=>setOpenImportTemplate(false)}
                    />
                )}

{!template && (
                    <DialogActivateMealPlan
                        open={openActivateMealPlan}
                        onClose={()=> setOpenActivateMealPlan(false)}
                    />
                )}

       
        </Box>
    )
}
import React, { useState, useEffect} from 'react';
// MUI
import { Drawer as MuiDrawer, Box, useMediaQuery} from '@mui/material';
// SHARED COMPONENTS
// ROUTER
import { useHistory} from 'react-router-dom'
// REDUX
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
// Interfaces
import {RootState } from 'app/store';
// Styles
import classes from "./styles"
import DialogLoader from 'components/molecules/DialogLoader';
import DrawerItems from 'components/molecules/DrawerItems';
import { DESKTOP } from 'utils/constants';

/************* REDUX SELECTORS ********** */

const isSavingSelector = createSelector(
    (state:RootState) => state,
    (state) => {
        const workoutPending = state.workouts.requests.update === "pending"
        const checkupPending = state.checkups.requests.update === "pending"
        const exercicePending = state.exercices.requests.autoSave === "pending"
        const programPending = state.planning.requests.update === "pending"
        const mealPlanPending = state.mealPlans.requests.update === "pending"

        if(workoutPending || checkupPending || exercicePending || programPending || mealPlanPending){
            return true
        } else {
            return false
        }
    }
)


/************* MAIN COMPONENT ********** */

export function Drawer() {

    const history = useHistory()
    const saving = useSelector(isSavingSelector, shallowEqual)
    const [showIsSaving, setShowIsSaving] = useState(false)
    const [redirect, setRedicret] = useState(null) // Chemin de redirection lorsqu'on affiche une alerte
    const desktop = useMediaQuery(DESKTOP);



    /********* REDIRECTION LORS D'UN CLICK ********* */

    const redirectTo = (path:string) => ()=>{
        // Sauvegarde en cours - Affichage du dialog de sauvegarde
        if(saving){
            setShowIsSaving(true)
            setRedicret(path)
        } 
        
        else{
            history.push(path)
        }


    }
   


    /********* ALERT SI LA PAGE SAUVEGARDE ********* */

    useEffect(()=>{
        if(!saving && showIsSaving){
            setShowIsSaving(false)
            history.push(redirect)
        }
    },[saving])


    return (

        <React.Fragment>
                
                {/****************** 
                 * DRAWER DESKTOP 
                 * *************** */}
                {desktop && (
                    <MuiDrawer
                        sx={[
                            classes.drawer, 
                        ]}
                        variant="permanent"
                        open={true}
                        PaperProps={{
                            elevation: 0,
                            sx: [
                                classes.paper
                            ]
                        }}
                        elevation={1}
                    >       

                        {/***** MENU  + LOGO ***** */}  

                            <Box 
                                sx={classes.logoContainer}
                            >
                                <Box 
                                    component="img" 
                                    src='assets/logo.svg' 
                                    sx={classes.logo} 
                                />
                            </Box>
                            
                                    
                            <DrawerItems 
                                redirectTo={redirectTo}
                                drawerIsOpen={true}
                            />

                            
                    </MuiDrawer>
                )}
                
               {/***************************** 
                * DRAWER MOBILE / TABLETTE 
                * *************** ***********/}
                {!desktop && (
                    <Box sx={classes.bottomNav}>
                        <DrawerItems 
                            redirectTo={redirectTo}
                            drawerIsOpen={true}
                            horizontal={true}
                        />
                    </Box>
                )}
          
           {/***************************** 
            * DIALOG SAUVEGARDE EN COURS 
            * *************** ***********/}

            <DialogLoader 
                open={showIsSaving} 
                text={'Sauvegarde en cours ...'}
            />
           
        </React.Fragment>
    )
}

export default React.memo(Drawer)
const classes = {
    rowInfos: {
        display:"flex", 
        flexDirection: {xs: "column", sm: "row"}, 
        alignItems:{xs: "flex-start", sm:"center"}, 
        justifyContent: {xs: "flex-start", sm:"space-between"},
        flex: 1
    }
}


export default classes
import axios from "utils/axios"
import sleep from "function/sleep"
import { createAsyncThunk } from "@reduxjs/toolkit"
import Checkup,{CheckupQuestion} from "interfaces/Checkup"
import { toast } from "react-toastify"
import {v4 as uuid} from "uuid"
import User from "interfaces/User"

/************** API ****************/

  // Création d'un checkup
export const createCheckup = createAsyncThunk('checkup/create', async (data:{checkup:Checkup}) => {

  const {checkup} = data

  const newCheckup:Checkup = {
    name: checkup.name,
    template: checkup.template,
    status: checkup.status ? checkup.status : "disabled",
    questions:[{
      question: "",
      type: "comment",
      questionId: uuid()
    },
    {
      question: "",
      type: "comment",
      questionId: uuid()
    },
    {
      question: "",
      type: "comment",
      questionId: uuid()
    },
    {
      question: "",
      type: "comment",
      questionId: uuid()
    }],
    
  }



    try {
        const response = await axios.post(`checkups`, { ...newCheckup})
        const checkup:Checkup = response.data.checkup
        if(checkup.template){
          await sleep(500)
        }
        return {checkup}
        
    } catch (err: any) {
  
        return {
            error: err.response.data?.message[0]
        }
    }
})

export const pasteCheckup = createAsyncThunk('checkup/paste', async (data:{checkup:Checkup}) => {

  const {checkup} = data

  try {
    const response = await axios.post('checkups', {...checkup})
    const newCheckup = response.data.checkup
    return { checkup: newCheckup }

  } catch (err: any) {

    return {
      error: err.response.data?.message[0]
    }
  }
})
  

// Récupération des checkups
export const getCheckups = createAsyncThunk('checkups/get', async(data:{start:string, end: string, createdFor: string, status: "enabled" | "disabled" | "done" | "missed"}) => {
  const {start, end, createdFor, status} = data 

  try {
    const result = await axios.get(`checkups/?start=${start}&end=${end}&createdFor=${createdFor}&status=${status}`)
    const checkups:Checkup[] = result.data.checkups
    return { checkups }

  } catch (err: any) {
    return {
      message: err.response.data?.message[0] || "Impossible de récupérer la liste des questionnaires"
    }
  }
})
  
  

// Suppresion d'un checkup
export const deleteCheckup = createAsyncThunk('checkup/delete', async (data:{checkup: Checkup}) => {
  try {
    const result = await axios.delete(`checkups/${data.checkup._id}`)
    const checkupDeleted:Checkup = result.data.checkup
    await sleep(500)
    return { checkup: checkupDeleted }
  } catch (err: any) {
    return {
      error: err.response.data?.message
    }
  }
})
  

// RECUPERATION d'un checkup
export const getCheckup = createAsyncThunk('checkup/get', async (checkupId: string) => {
  try {
    const result = await axios.get(`checkups/${checkupId}`)
    return { checkup: result.data.checkup }
  } catch (err: any) {
    return {
      error: err.response.data?.message[0] || "Impossible de récupérer les données utilisateurs"
    }
  }
})
  

// RECUPERATION de plusieurs questionnaires 
export const getCheckupsCompleted = createAsyncThunk('checkups/get', async(data:{clientId:string}) => {

  const {clientId} = data

  try {
    const result = await axios.get(`clients/${clientId}/checkups/`)
    const checkups:Checkup[] = result.data.checkup
    return { checkups }
  } catch (err: any) {
    return {
      error: err.response.data?.message[0] || "Impossible de récupérer les questionnaires complétés par l'utilisateur"
    }
  }
})



export const duplicateCheckup = createAsyncThunk('checkup/duplicate', async (data: {checkup: Checkup}) => {

  const noti: any = toast.loading("Copie en cours ...")
  const questionsList:CheckupQuestion[] = []

  // On supprime les réponses des questions dans le cas ou un bilan est destinnée à un utilisateur

  data.checkup.questions.forEach((question:CheckupQuestion)=> {
    questionsList.push({
      ...question,
      answer: undefined
    })
  })
  

  const newData: Checkup = { 
      ...data.checkup, 
      name: data.checkup.name + ' (copie)', 
      _id: undefined, 
      template: true,
      questions:questionsList,
  }


  try {
      const response = await axios.post(`checkups`, {...newData, createdAt:new Date()})
      const checkup = response.data.checkup
      await sleep(500)
      toast.update(noti, { render: "Bilan dupliqué", type: "success", isLoading: false, autoClose: 1500 });
      return { checkup }

  } catch (err: any) {
      toast.update(noti, { render: err.response.data?.message[0], type: "error", isLoading: false, autoClose: 1500 });

      return {
          error: err.response.data?.message[0]
      }
  }
})


  /********************     AUTOSAVE CHECKUP    *************************** */

export const updateCheckup = createAsyncThunk('checkup/update', async (payload:{checkup:Checkup}) => {


  const data:Checkup = payload.checkup

  try {
      const response = await axios.put(`checkups/${data._id}`, data)
      const result:Checkup = response.data.checkup
      return { checkup: result}

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }

  
})


const classes = {
    toolbar : {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        marginBottom:0.5,  
     },
    
}


export default classes
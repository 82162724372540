import { createSlice, current } from '@reduxjs/toolkit'
import Checkup, {CheckupQuestion } from "interfaces/Checkup"
import {v4 as uuid} from "uuid"
// API
import {
  createCheckup,
  deleteCheckup,
  duplicateCheckup,
  getCheckup,
  updateCheckup,
} from "api/checkups"
import { addIdsToCheckup } from 'function/addIdsToCheckup'
import { getTemplates } from 'api/templates'
import { toast } from 'react-toastify'
import fuseSearch from 'function/fuseSearch'


const defaultCheckup:Checkup ={
  _id: null,
  name: "",
  template: false,
  questions:[],
  createdBy: null,
  status: "disabled"
}


/************** INTERFACES  ****************/

interface CheckupsSlice {
  builder: Checkup,
  templates:{
    list: Checkup[],
    filtered: Checkup[]
  },
  requests:{
    get:"pending"|"idle",
    create:"pending"|"idle",
    update: "pending" | "idle",
    delete: "pending" | "idle",
    deleteMultiple: "pending" | "idle",
    duplicate: "pending" | "idle"
  },
  unSavedChanges: boolean,
  openBuilderDialog?: boolean, // Ouverture du checkup builder dans un dialog fullscreen lorsque le checkup est dans un planning
}

/************** INIT SLICE ****************/

const initialState = {
    builder: defaultCheckup,
    templates:{
      list: [],
      filtered: []
    },
    requests:{
      get:"idle",
      create:"idle",
      update:"idle",
      delete:"idle",
      deleteMultiple: "idle",
      duplicate: "idle"
    },
    unSavedChanges: false,
    openBuilderDialog: false,
} as CheckupsSlice



/************** SLICE  ****************/

export const checkupsSlice = createSlice({
  name: 'checkups',
  initialState,

  /*****************  REDUCERS  ******************* */
  reducers: {

    resetCheckupBuilder : (state) => {
      state.builder = defaultCheckup
    },

    handleCheckupStatus : (state,action) => {
      state.builder.status = action.payload.status
    },

    checkupIsUnsaved: (state) => {
      state.unSavedChanges = true
    },

    checkupIsSaved: (state) => {
      state.unSavedChanges = false
    },

    checkupIsSaving:(state) => {
      state.requests.update = "pending"
    },

    /*********** LORSQUE LE CHECKUP BUILDER FAIT PARTI D'UN PROGRAMME ******************* */
    editCheckup: (state, action) => {
      const { checkup} = action.payload
      state.builder = addIdsToCheckup(checkup)
    },

    
    // ouverture du dialog
    handleCheckupBuilderDialog: (state, action) => {
      state.openBuilderDialog = action.payload.open
    },
    


    /*********** IMPORT DES TEMPLATES ************* */

    importTemplate: (state,action) => {
      const checkup:Checkup = action.payload.template
      const isTemplate = state.builder.template

      state.builder = {
        ...addIdsToCheckup(checkup),
        _id: state.builder._id, // récupération de l'ID en cours
        template: isTemplate,
      }
    },

    handleName: (state, action) => {
      state.builder.name = action.payload.value
    },

    handleQuestion : (state,action) => {
      const questionIndex:number = action.payload.questionIndex
      const question:string = action.payload.question
      state.builder.questions[questionIndex].question = question
    },

    addQuestion: (state) => {
        const newQuestion:CheckupQuestion = {
          question: "",
          type: "comment",
          options: [],
          questionId: uuid()
        }
        state.builder.questions.push(newQuestion)
    },

    deleteQuestion: (state, action) => {
      const {questionId} = action.payload
      const questions:CheckupQuestion[] = current(state.builder.questions)
      const index:number = questions.findIndex((elem:CheckupQuestion)=> {
        return elem.questionId === questionId
      })

      state.builder.questions.splice(index,1)
    },

    pushOption: (state,action) => {
        const questionIndex:number = action.payload.questionIndex
        state.builder.questions[questionIndex].options.push('')
    },

    removeOption: (state,action) => {
      const questionIndex:number = action.payload.questionIndex
      const optionIndex:number = action.payload.optionIndex
      state.builder.questions[questionIndex].options.splice(optionIndex, 1)
    },

    handleOptionLabel: (state,action) => {
      const questionIndex:number = action.payload.questionIndex
      const optionIndex:number = action.payload.optionIndex
      const optionLabel:string = action.payload.optionLabel
      state.builder.questions[questionIndex].options[optionIndex] = optionLabel
    },

    handleQuestionType: (state,action) => {
      const questionIndex:number = action.payload.questionIndex
      const type:'checkbox'|'radio'|'comment' = action.payload.questionType
      state.builder.questions[questionIndex].type = type
      if(type === "checkbox" || type ==="radio"){
          const currentOptions:string[] = state.builder.questions[questionIndex].options
          state.builder.questions[questionIndex].options = currentOptions || []
      }
    },


    // FUSE SEARCH
    searchCheckupsTemplates: (state, action) => {
      const search:string = action.payload.search

      const keys:string[] = [
        "name",
      ]

      state.templates.filtered = search !== "" ? fuseSearch(current(state.templates.list), search, keys) : state.templates.list
    },

    resetSearchCheckups: (state) => {
      state.templates.filtered = state.templates.list
    }
  },

  /*****************  EXTRA REDUCERS  ******************* */

  extraReducers: (builder) => 
  builder

  /********* GET TEMPLATES ********* */
  .addCase(getTemplates.pending, (state)=> {
    state.requests.get = "pending"
  })
  .addCase(getTemplates.fulfilled, (state, {payload})=>{
      const templates = payload.templates
      if(templates.checkups){
        state.templates.list = templates.checkups
        state.templates.filtered = templates.checkups
      }
  })

  /********* GET CHECKUP ******** */
  .addCase(getCheckup.pending, (state)=> {
    state.requests.get = "pending"
  })
  .addCase(getCheckup.fulfilled, (state, {payload})=> {
      state.requests.get = "idle"
      const checkup:Checkup = payload.checkup
      if(checkup){
        state.builder = addIdsToCheckup(checkup)
      } else {
        toast.error('Impossible de récupérer le questionnaire')
      }
  })

  .addCase(getCheckup.rejected, (state) => {
    state.requests.get = "idle"
    toast.error('Impossible de récupérer les données du questionnaire pour le moment')
  })

  /******** CREATE CHECKUP ******* */
  .addCase(createCheckup.pending,(state)=>{
    state.requests.create = "pending"
  })

  .addCase(createCheckup.fulfilled,(state,{payload})=>{
    state.requests.create = "idle"
    const {checkup} = payload

    if(checkup?.template){
      const customList = [...current(state.templates.list)]
      customList.push(checkup)
      const sortedList = customList.sort((a,b)=> {
        if(a.name > b.name){
          return 1
        } else if(a.name < b.name){
          return -1
        }else return 0
      })
      state.templates.list = sortedList
      state.templates.filtered = sortedList
    }
    else{
      toast.error(payload.error)
    }
  })

  .addCase(createCheckup.rejected, (state) => {
    state.requests.create = "idle"
    toast.error('Impossible de créer un nouveau questionnaire pour le moment')
  })


  /******** UPDATE CHECKUP ******* */
  .addCase(updateCheckup.pending, (state) => {
    state.requests.update = "pending"
  })

  .addCase(updateCheckup.fulfilled, (state,{payload}) => {
    state.requests.update = "idle"
    const checkup:Checkup = {...payload.checkup, createdAt: payload.checkup.createdAt}
    if(checkup?.template){ // template
        const indexList = state.templates.list.findIndex((elem:Checkup)=> elem._id === checkup._id)
        state.templates.list[indexList] = checkup
        const indexFiltered = state.templates.filtered.findIndex((elem:Checkup)=> elem._id === checkup._id)
        state.templates.filtered[indexFiltered] = checkup
    }
    if(state.builder?._id){ // Mise à jour dans lle builder
      state.builder.status=checkup.status
    }
  })


  /******** DELETE CHECKUP ******* */
  .addCase(deleteCheckup.pending, (state) => {
    state.requests.delete = "pending"
  })
  .addCase(deleteCheckup.fulfilled, (state,{payload}) => {
    state.requests.delete = "idle"
    const {checkup} = payload
      if(checkup?.template){ // template
        const listIndex = state.templates.list.findIndex((elem:Checkup)=> elem._id === checkup._id)
        state.templates.list.splice(listIndex,1)
        const filteredIndex = state.templates.filtered.findIndex((elem:Checkup)=> elem._id === checkup._id)
        state.templates.filtered.splice(filteredIndex,1)
      }else{
        toast.error(payload.error)
      }
  })


  .addCase(deleteCheckup.rejected, (state) => {
    state.requests.delete = "idle"
    toast.error('Impossible de supprimer cet questionnaire')
  })


  /******** DUPLICATE CHECKUP ******* */
  .addCase(duplicateCheckup.pending, (state)=>{
    state.requests.duplicate = "pending"
  })

  .addCase(duplicateCheckup.fulfilled, (state,{payload})=>{
    state.requests.duplicate = "idle"
    const {checkup} = payload

    if(checkup){
      if(checkup.template){
        state.templates.list.splice(0,0,checkup)
        state.templates.filtered.splice(0,0,checkup)
      }
    }else{
      toast.error(payload.error)
    }
  })

  .addCase(duplicateCheckup.rejected, (state)=>{
    state.requests.duplicate = "pending"
    toast.error('Impossible de dupliquer cet questionnaire pour le moment')
  })




})

// REDUCERS
export const {
  resetCheckupBuilder,
  editCheckup,
  handleName,
  handleCheckupBuilderDialog,
  addQuestion,
  deleteQuestion,
  handleQuestion,
  pushOption,
  removeOption,
  handleOptionLabel,
  handleQuestionType,
  importTemplate,
  checkupIsUnsaved,
  checkupIsSaved,
  checkupIsSaving,
  handleCheckupStatus,
  searchCheckupsTemplates,
  resetSearchCheckups
} = checkupsSlice.actions

export default checkupsSlice.reducer
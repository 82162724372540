import { theme } from "utils/theme"


const classes = {

    week :{
        /*width: {
            xs: "200%",
            lg: "100%",
        },*/
        width: "100%",
        minHeight: 200,
        display:"flex",
        flexDirection: "row",
        position: "relative",
        overflow: "hidden"
        
    },

    programs :{
        width: "calc(100%)",
        display:"flex",
        justifyContent:"space-between",
        flexDirection: "row",
        zIndex: 2,
        position: "absolute", 
        top: 60, 
    },


    weekIndicator: {
        position :"absolute",
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
        color: "primary.main",
        left: 48,
        top: 20,
        zIndex: 2
    },

    days : {
        display:"flex", 
        flexDirection: {
            xs: "column",
            sm:"row"
        },
        width: "100%",
    }
}


export default classes
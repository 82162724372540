import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios"


export const uploadCover = createAsyncThunk('covers/add', async(file:Blob)=>{

    var formData = new FormData();
    formData.append('file', file)

    try {
      const result = await axios.post("images/cover", formData)
      const cover:{url: string} = result.data.cover
      return { cover }

    } catch (err: any) {
      return {
        message: err.response.data?.message[0] || "Impossible de télécharger votre image"
      }
    }
})

export const getCustomCovers = createAsyncThunk("images/covers", async()=>{

  try {
    const result = await axios.get("images/covers")
    const covers:{url: string} = result.data.covers
    return { covers }

  } catch (err: any) {
    return {
      message: err.response.data?.message[0] || "Impossible de télécharger votre image"
    }
  }
})



export const deleteCustomCover= createAsyncThunk('covers/delete', async(cloudflareId:string)=>{
  try {
    await axios.delete(`images/cover/${cloudflareId}`)
    return { deleted : true }

  } catch (err: any) {
    return {
      message: err.response.data?.message[0] || "Impossible de supprimer votre image"
    }
  }
})

import { BORDER_COLOR } from "utils/constants"
import { theme} from "utils/theme"

const smallHeight = "100px"

const classes ={
    workout:{
        width: "100%",
        position: "relative",
        "&:hover" : {
            cursor: "pointer"
        },
        marginBottom: 2,
        display:"flex",
        flexDirection: {
            xs: "row",
            sm: "column"
        },
        marginTop: {
            xs: 3,
            sm: 0
        },
        minHeight: 60
        /*border: {
            xs: `solid 1px ${BORDER_COLOR}`
        }*/
    },


    statusStarted : {
        backgroundColor: "primary.main",
        paddingLeft: 1,
        paddingRight: 1,
        position: "absolute",
        zIndex:3,
        top: 2,
        right: 5,
        color: "white",
        fontWeight: 500
    },

    statusIndicator:{
        position: "absolute",
        zIndex:3,
        top: 2,
        right: 5,
        fontSize: theme.typography.pxToRem(14),
        color: "white",
        fontWeight: 700,
        borderRadius: 100,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: 25,
        height: 25,

    },


    workoutIsDone:{
        backgroundColor: "#4eba54",
    },

    workoutIsMissed:{
        backgroundColor: "#d44848",
    },


    workoutCover:{
        position: "relative",
        overflow: "hidden",
        padding: 0,
        margin:0,
        display:"flex",
        marginRight: {
            xs: 1.5,
            sm: 0
        },
        //backgroundColor: "rgba(0,0,0,0.85)",
        
        

        "& img":{
            width: {
                xs: 110,
                sm: "100%",
            },
            height: "auto",
        
            margin: 0,
            padding: 0,
            transform: "scale(1)",
            transition: "transform 0.3s",
            "&:hover":{
                transform: "scale(1.05)",
                transition: "transform 0.3s",
            },
            
        }
    },


    workoutInfos:{
        padding: {
            xs: 1,
            xl:1.5
        }, 
        backgroundColor: {
            xs: "white",
            sm: "rgba(0,0,0,0.85)",
        },
        width: {
            xs: "auto",
            md: "100%"
        },
        alignItems:"flex-start",
        justifyContent:"center",
        display:"flex", 
        flexDirection:"column",
        
    },


    workoutName:{
        fontSize: {
            xs: theme.typography.pxToRem(12),
            lg:theme.typography.pxToRem(15),
            xl:theme.typography.pxToRem(16),
        }, 
        color: {
            xs: "rgba(0,0,0,0.85)",
            sm: "white",
        },
        fontWeight: {
            xs: 600,
            md: 700
        },
        //textShadow: '1px 1px 3px rgba(0, 0, 0, 0.85)',
        textTransform: {
            xs: "none",
            //md:"uppercase",
        },
        //fontStyle: "italic",
        fontFamily: "Lexend,Roboto,sans-serif",
        overflow: "hidden",
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap",
        display:"block",
        width: '100%'
    },

    tonnage: {
        color: "white",
        fontFamily: "Lexend,Roboto,sans-serif",
        fontSize: 15
    },


    workoutLevel : {
        fontSize: {
            xs: theme.typography.pxToRem(10),
            xl:theme.typography.pxToRem(12),
            textAlign: "center",
        }, 
        borderRadius: "3px",
        //color: "#C6FF99",
        fontWeight: 700,
        fontFamily: "Poppins",
        textTransform: "uppercase",
        color: "white",
        paddingLeft: "10px",
        paddingRight: "10px"
    },





    activationIndicator:{
        borderRadius: "5px", 
        marginTop: 1.5,
        backgroundColor: "whitesmoke", 
        marginRight:1, 
        display:{
            xs: "none",
            md: "inline-flex"
        },
        alignItems:"center", 
        justifyContent:"center", 
        width: 110,
        position: "absolute",
        top: 1,
        right: 1
    },

    activate: {
        backgroundColor: "rgb(196 240 164)"
    },

    activationIndicatorFont:{
        //color: enabled ? "success.main" : "#afafaf", 
        fontWeight: 700, 
        fontSize: "0.9rem", 
        marginLeft: 1,
        
    },


    
}

export default classes
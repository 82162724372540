import { Add, Check, Delete, VerifiedUser } from "@mui/icons-material";
import { Box, Icon, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Food from "interfaces/Food";
import classes from "./classes";
import MoreButton, { MenuElem } from "components/atoms/Buttons/MoreButton";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { MOBILE } from "utils/constants";

interface Props{
    item: Food,
    onClick? : (food:Food) => void,
    hideButton?: boolean,
    showBackground?: boolean,
    canDelete?: boolean,
    openDialogDelete?: (food:Food)=> void,
    onSelectFoodToSimplify?: (food:Food) => void,
    selected?:boolean
}

export default function FoodRow({item,onClick, hideButton, showBackground, canDelete, openDialogDelete, onSelectFoodToSimplify, selected}:Props){


    const user = useSelector((state:RootState) => state.user.data)
    const mobile = useMediaQuery(MOBILE)

    const handleClick = (food:Food) => {
        onClick(food)
    }


    const onOpenDialogDelete = (food:Food) => ()=> {
        openDialogDelete(food)
    }


    const openSimplify = (food:Food) => ()=> {
        onSelectFoodToSimplify(food)
    }
    

    {/************** 
        MENU MORE 
    ***************/}

    const MENU:MenuElem[] = useMemo(()=>{
        var menu:MenuElem[] = []

        if(onOpenDialogDelete && item.source !== "Ciqual 2020, ANSES"){
                menu.push({
                    label: "Supprimer",
                    icon: <Delete />,
                    callback: onOpenDialogDelete(item)
                })
        } else if(item.source === "Ciqual 2020, ANSES" && user.role === "admin"){
            menu.push({
                label: "Admin - Simplifier",
                icon: <AdminPanelSettingsIcon />,
                callback: openSimplify(item)
            })
        }
        
        return menu

       
    },[])

    return(
        <Box
            sx={[
                classes.foodItem,
                showBackground && classes.withBackground
            ]}
        >
            <Box
                sx={classes.itemLabels}
            >
                <Box sx={classes.nameContainer}>
                    {(item.source === "Ciqual 2020, ANSES" && mobile )&& (
                        <Tooltip
                            title="Aliment vérifié"
                        >
                            <VerifiedUser 
                                sx={{fontSize: 20, marginRight: 2, color: "#68dc68"}}
                            />
                        </Tooltip>
                    )}
                    <Typography
                        sx={classes.foodItemName}
                    >
                        {item.name.fr}
                    </Typography>
                </Box>

                <Box
                    sx={classes.macros}
                >
                    <Box
                        sx={classes.macroItem}
                    >
                        {item.source === "Ciqual 2020, ANSES" && (
                            <Tooltip
                                title="Aliment vérifié"
                            >
                                <VerifiedUser 
                                    sx={{fontSize: 20, marginRight: 2, color: "#68dc68"}}
                                />
                            </Tooltip>
                        )}

                        <Typography
                            sx={classes.macroItemTitle}
                        >
                            {mobile? "Pro" : "Protéines"}: {item.macros.proteins} g
                        </Typography>
                        {/*<LinearProgress 
                            variant="determinate" 
                            value={prots}
                            sx={{color:"rgb(2, 178, 175)", marginTop: 1, height:10, backgroundColor: "white"}}
                            color="inherit"
                        />*/}
                    </Box>
                    <Box
                        sx={classes.macroItem}
                    >
                        <Typography
                            sx={classes.macroItemTitle}
                        >
                            {mobile? "Glu" : "Glucides"}: {item.macros.carbs} g
                        </Typography>
                    
                        {/*<LinearProgress 
                                variant="determinate" 
                                value={carbs}
                                sx={{color:"rgb(184, 0, 216)", marginTop: 1, height:10}}
                                color="inherit"
                    />*/}
                    </Box>
                    <Box
                        sx={classes.macroItem}
                    >
                        <Typography
                            sx={classes.macroItemTitle}
                        >
                            {mobile? "Lip" : "Lipides"}: {item.macros.lipids} g
                        </Typography>
                    
                        {/*<LinearProgress 
                                variant="determinate" 
                                value={lipids}
                                sx={{color:"rgb(46, 150, 255)", marginTop: 1, height:10}}
                                color="inherit"
                />*/}
                    </Box>
                </Box>
            </Box>
            {selected && (
                <Box
                    sx={{marginRight: 2}}
                >
                <Icon>
                    <Check 
                        sx={{color : "green"}}
                    />
                </Icon>
            </Box>
            )}

            {!hideButton && (
                <Box>
                    <IconButton
                        onClick={()=> handleClick(item)}
                    >
                        <Add />
                    </IconButton>
                </Box>
            )}

            {(canDelete || user.role === "admin") && (
                <MoreButton 
                    menuList={MENU}
                />
            )}
        </Box>
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { deleteInvitation } from "api/clients";
import { AppDispatch, RootState } from "app/store";
import ClientInvitation from "interfaces/ClientInvitation";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


/******** REDUX SELECTORS ******* */


interface Props{
    open: boolean,
    onClose: ()=>void,
    invitation: ClientInvitation
}

export default function DialogDeleteInvitation({open, onClose, invitation}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState) => state.clients.requests.deleteInvitation === "pending")

    
   /******** SUPRESSION DE L'INVITATION ******** */
    const onDeleteInvitation = async () => {
        dispatch(deleteInvitation(invitation._id)).unwrap().then(()=>{
            onClose()
        })
    }


    return(
        <Dialog open={open} disableScrollLock={true}>
            <DialogTitle>
                Supprimer une invitation
            </DialogTitle>
            <DialogContent>
            <Typography variant="body1">
                    Voulez vous supprimer l'invitation envoyée à <strong>{invitation?.to.firstname} {invitation?.to.lastname}</strong> ?
                </Typography>
            </DialogContent>
            <DialogActions>
                    <Button onClick={onClose}>
                        Annuler
                    </Button>
                    <LoadingButton disabled={pending} loading={pending} onClick={onDeleteInvitation}>
                        {pending? "Suppression ..." : "Confirmer"}
                    </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { BORDER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


export const classes = {

    customWorkoutHeader : {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        marginBottom: 3
    },

    pageHeader:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        marginBottom: 2.5,
        width: "100%",
    },


    tags : {
        display:"flex",
        flexDirection: "row",
        overflowX: {
            xs: "scroll",
            md: "hidden"
        },
        paddingLeft: {xs: 2, sm: 0},
        paddingRight: {xs: 2, sm: 0}
    },

    tagInfo: {
        backgroundColor: "white",
        padding: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontSize: "0.9rem",
        marginBottom: 2,
        display:"inline-flex",
        border: `solid 1px ${BORDER_COLOR}`,
        flexWrap: "no-wrap",
        marginRight: 1,
        flexDirection: "row",
        alignItems:"center",
        height: 38,
        borderRadius: 3,
    },

    enabled:{
        backgroundColor: "#c7eac2"
    },

    disabled:{
        backgroundColor: "whitesmoke"
    },

    tagInfoLabel :{
        fontSize: "0.9rem",
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    tagInfoClickable:{
        //backgroundColor: LIGHT_BUTTON_COLOR,
        cursor:"pointer",
        "&:hover":{
            backgroundColor: LIGHT_BUTTON_COLOR
        }
    },

    tagInfoEnabled: {
        backgroundColor: "#d5f1d7",
        border: "solid 1px #c8e1ca",

        "&:hover" : {
            backgroundColor: "#bbe4bd"
        }
    },

    tagInfoIcon : {
        marginRight: 0.5,
        color: "primary.main"
    },

    disabledIcon: {
        color: "gray"
    },

    tagInfoIconEnabled:{
        marginRight: 0.5,
        color: "#24c02b"
    },

    tagInfoValue: {
        fontWeight: 600,
        marginLeft: 1,
        fontSize: "0.9rem",
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    activated : {
        backgroundColor : "#b6e2b6",
        color: "white",
        marginLeft: 2
    },

    pageHeaderWithMargin:{
        marginTop: 4
    },

    buttonsContainer :{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "flex-end", 
        marginBottom: 2,
        marginTop: 2
    },

    chips:{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center" 
    },

    chipContained:{
        marginRight: 1, 
        fontWeight: 700, 
        backgroundColor: "warning.main", 
        color: "white" 
    },

    btActions:{
        marginLeft: 3, 
        display: "flex", 
        flexDirection: "row"
    },

    addBlockButtonContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

   

   

}

export default classes
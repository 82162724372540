import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import TitleTextField from "components/molecules/TitleTextField";
import classes from "./styles";
import React, { useState } from "react";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import DialogFoodList from "../DialogFoodList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import {CopyAll, Delete, LocalFireDepartment, MoreHoriz } from "@mui/icons-material";
import DialogDeleteMeal from "../DialogDeleteMeal";
import MealFoodItem from "../MealFoodItem";
import { toast } from "react-toastify";
import MacrosList from "../MacrosList";
import DialogRecipesList from "../DialogRecipesList";
import MealRecipeItem from "../MealRecipeItem";
import { onChangeMealName, onCopyMeal } from "features/mealPlanSlice";
import calcMacros from "function/calcMacros";


interface Props{
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
    mealIndex: number
}

export default function Meal({day, mealIndex}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const meal = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex])
    const mealName = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex].name)
    const [openDialogFood, setOpenDialogFood] = useState<boolean>(false)
    const [openDialogRecipe, setOpenDialogRecipe] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeleteDialogFood, setOpenDeleteDialogFood] = useState<boolean>(false)

    
    const onAddMealFood = () => {
        setOpenDialogFood(true)
    }


    const onAddRecipe = () => {
        setOpenDialogRecipe(true)
    }


    const changeMealName = (event: React.ChangeEvent<HTMLInputElement>)  => {
        dispatch(onChangeMealName({day,mealIndex, name: event.target.value}))
    }

    const deleteMeal = () => {
        setOpenDeleteDialogFood(true)
        setAnchorEl(null)
    }

    const copyMeal = () => {
        setAnchorEl(null)

        dispatch(onCopyMeal({meal}))
        toast.success('Repas copié')
    }


    return(
        <>
            <Box
                sx={classes.content}
            >
                <IconButton 
                    sx={classes.buttonClose} 
                    onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                >
                    <MoreHoriz />
                </IconButton>

                <Menu disableScrollLock={true} 
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                >
                    <MenuItem 
                        onClick={copyMeal}
                    >
                        <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                        <ListItemText>
                            Copier ce repas
                        </ListItemText>
                    </MenuItem>  
                    <MenuItem 
                        onClick={deleteMeal}
                    >
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Supprimer ce repas
                        </ListItemText>
                    </MenuItem>  
                </Menu>

         

                {/****************
                 * Repas titre
                ****************/}
                <Box
                    sx={classes.header}>
                        {/**** TIME ****
                        <TitleTextField 
                            variant="standard"
                            onChange={changeMealTime}
                            value={meal.time}
                            sx={{
                                width: 80
                            }} 
                        />
                         */}

                        {/**** NAME **** */}
                        <TitleTextField 
                            variant="standard"
                            value={mealName}
                            onChange={changeMealName} 
                            select
                        >
                            <MenuItem 
                                value={"Petit déjeuner"}
                            >
                                Petit déjeuner
                            </MenuItem>
                            <MenuItem 
                                value={"En-cas"}
                            >
                                En-cas
                            </MenuItem>
                            <MenuItem 
                                value={"Déjeuner"}
                            >
                                Déjeuner
                            </MenuItem>
                            <MenuItem 
                                value={"Dîner"}
                            >
                                Dîner
                            </MenuItem>

                        </TitleTextField>
                        <Box
                            sx={classes.kcalContainer}
                        >
                            <LocalFireDepartment 
                                sx={{ marginLeft: {xs: 0,sm:3}, marginRight: 1, color: "#e75023", fontSize: 18}}
                            />
                            <Typography
                                sx={classes.kcal}
                            >
                                {calcMacros([{...meal}])?.kcal} kcal
                            </Typography>
                        </Box>
                </Box>

                {/************* 
                 * MEAL MACROS 
                 * ********* */}
                 
                <MacrosList
                    meal={meal}
                />

                {/********************************
                 * Liste des aliments / Recettes
                **********************************/}

                <Box 
                    sx={classes.mealContent}>

                        {meal.content.map((elem, index)=> {
                            if(elem.type === "food"){
                                return(
                                    <MealFoodItem
                                        key={index}
                                        ingredientIndex={index}
                                        day={day}
                                        mealIndex={mealIndex}
                                    />
                                )
                            } else if (elem.type === "recipe"){
                                return(
                                    <MealRecipeItem
                                        key={index}
                                        recipeIndex={index}
                                        day={day}
                                        mealIndex={mealIndex}
                                    />

                                )
                            }
                        })}

                       
                        {meal.content.length === 0 && (
                            <Typography>
                                Aucun aliment ou recette pour le moment
                            </Typography>
                        )}

                        {/**************
                         * Boutons
                         ***************/}

                        <Box
                            sx={classes.mealButtons}
                        >

                                <SecondaryButton 
                                    onClick={()=> onAddMealFood()} 
                                    sx={{textTransform: "none", marginRight: {xs:0, sm:1}, marginBottom: {xs: 2, sm:0}}} 
                                    disableElevation={true}
                                >
                                        Ajouter un aliment
                                </SecondaryButton>

                                <SecondaryButton 
                                    onClick={()=> onAddRecipe()} 
                                    sx={{textTransform: "none"}} 
                                    disableElevation={true}
                                >
                                        Ajouter une recette
                                </SecondaryButton>
                        </Box>
                </Box>
            </Box>


            {/*********************
             * Suppression d'un repas
             **********************/}

            <DialogDeleteMeal
                open={openDeleteDialogFood}
                onClose={()=> setOpenDeleteDialogFood(false)}
                mealIndex={mealIndex}
                day={day}
            />


            {/*********************
             * Liste des aliments
             **********************/}

            <DialogFoodList
                open={openDialogFood}
                onClose={()=> setOpenDialogFood(false)}
                mealIndex={mealIndex}
                day={day}
            />


            {/*********************
             * Liste des recettes
             **********************/}

            <DialogRecipesList
                open={openDialogRecipe}
                onClose={()=> setOpenDialogRecipe(false)}
                mealIndex={mealIndex}
                day={day}
            />
        </>
    )
}
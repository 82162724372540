import { theme } from "utils/theme"

const classes = {

    layout:{
        minHeight:"100vh",
        width: "100%",
        maxWidth: "100vw",
        display:"flex", 
        alignItems: {
            xs : "center", 
            sm: "center",
        }, 
        justifyContent:{
            xs: "flex-start",
            sm:"center", 
        },
        //backgroundColor: "#ebebeb", 
        flexDirection: "column",
        backgroundColor: {
            xs: "white", 
            sm: "rgb(247 250 252)"
        },
        paddingLeft:{
            xs: 2,
            sm: 0
        },
        paddingRight:{
            xs: 2,
            sm: 0
        },
        paddingTop: {
            xs: 3,
            sm: 6,
        },
        paddingBottom: {
            xs: 1,
            sm: 6,
        },
        
    },

    card : {
        paddingLeft: {
            xs: 2,
            sm: 5
        },
        paddingRight: {
            xs: 2,
            sm: 5
        },
        paddingTop: {
            xs: 4,
            sm: 5
        },
        paddingBottom: {
            xs: 4,
            sm: 5
        },
        width: {
            xs: "100%",
            sm: 720,
            md: 720,
            lg: 720,
        },
        borderRadius:{
            xs:  0,
            sm: "5px"
        }

    },

    logoContainer:{
        width: "100%",
        marginBottom: 6,
        alignItems:"center",
        justifyContent:"center",
        marginTop: 1,
        display: {
            xs: "none",
            sm: "flex"
        }
    },

    textfieldLabel : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(15),
        marginBottom: 0.5, 
        color: "rgba(0,0,0,0.85)"
    },


    coach: {
        fontSize: 20, 
        fontWeight: 500,
        marginBottom: 1,
        color: "#363636",
        textAlign:'center',
        textTransform: "uppercase"
    },

    title: {
        fontWeight: 800,
        fontSize: {
            xs: theme.typography.pxToRem(24),
            md: theme.typography.pxToRem(30),
        },
        maxWidth: 550,
        textAlign: "center",
        marginBottom: 0.2,
        marginTop: {
            xs: 5,
            md:0
        }
    },

    subtitle:{
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(18),
        marginBottom: {
            xs: 1,
            md: 4,
        },
        color: "rgba(0,0,0,0.80)",
        textAlign: "center"

    },

    passwordHelper: {
        display:"flex", 
        flexDirection: "row"
    },

    passwordHelperElem:{
        marginRight: 2,
        display:"flex",
        alignItems:"center"
    },

    passwordHelperIcon:{
        fontSize:theme.typography.pxToRem(14),
        marginRight: 0.5,
        color: "rgb(203 203 203 / 60%)"
    },

    buttonContainer:{
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        width: "100%", 
        mt: 2
    },

    cguContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginTop: 2
    },

    cgu: {
        fontSize: theme.typography.pxToRem(14)
    },

    backToLogin : {
        fontWeight: 700, 
        fontSize: theme.typography.pxToRem(16), 
        color: "primary.main", 
        cursor: "pointer",
        marginBottom: {
            xs: 3,
            md:0
        }
    }
}


export default classes
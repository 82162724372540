import React from "react";
import { useState } from "react";
import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";


export interface MenuElem{
    icon: JSX.Element,
    label: string,
    callback: (e?:any)=> void
}


interface Props{
    menuList: MenuElem[],
    disabled?: boolean
}


export default function MoreButton({menuList,disabled}: Props){

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const openMenu = (e:any) =>{
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(e.currentTarget)
    }

    const closeMenu = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorEl(null)
    }

    const onClick = (callback:Function) => (e:any)=>{
        e.stopPropagation()
        e.preventDefault()
        callback()
        setAnchorEl(null)
    }

    
    return(
        <React.Fragment>
            <IconButton onClick={openMenu} disabled={disabled} sx={{borderRadius:"100px !important"}}>
                <MoreHoriz sx={{color: "rgba(0,0,0,0.75)"}} />
            </IconButton>
        
            <Menu disableScrollLock={true}  anchorEl={anchorEl} open={open} onClose={closeMenu}>
                {menuList.map((item:MenuElem, index: number)=>(
                    <MenuItem key={index} onClick={onClick(item.callback)}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>
                            {item.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}
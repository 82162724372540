import {  useState } from "react";
// MUI
import {  TextField, Dialog, DialogTitle, DialogActions, Button, DialogContent, Stack, useMediaQuery, IconButton,} from "@mui/material";

// REDUX
import { useDispatch, useSelector } from "react-redux";
// TOAST
import { toast } from "react-toastify";
// INterfaces
import { AppDispatch, RootState } from "app/store";

// Shared components
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { MOBILE, TABLET } from "utils/constants";
import { useHistory } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { createRecipe } from "api/recipes";

//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
}


//***************** COMPONENT ********* */

export default function DialogCreateRecipe({ open, onClose }: Props) {

    const navigate = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.planning.user)
    const [name, setName] = useState<string>('')
    const pending = useSelector((state:RootState) => state.recipes.requests.create === "pending")
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)


    /********** CREATiON DE LA RECETTE ************* */

    const onSubmit = () => {
        if(name === ""){
            toast.error("Cette recette n'a pas de nom")
            return
        }

        dispatch(createRecipe({name:{fr:name}})).unwrap().then((res:any)=> {
            if (res?.recipe) {
                navigate.push(`/recipes/${res.recipe._id}`)
                toast.success('Recette créée')
            }
            
        })
    }


    /********** JSX ************* */

    return (

        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={'xs'}
            fullScreen={mobile || tablet}
            disableScrollLock={true}
        >

            <DialogTitle>
                {mobile && (
                    <IconButton sx={{marginRight: 1.5}} onClick={onClose}>
                        <Close sx={{color: "rgba(0,0,0,0.85)"}}/>
                    </IconButton>
                )}
                Nouvelle recette
            </DialogTitle>
            <DialogContent>

                {/**** TEXTFIELDs **** */}
                <Stack>
                        <TextField
                            value={name}
                            label={`Nom de votre recette`}
                            sx={{ mb: 1, mt:1 }}
                            fullWidth={true}
                            onChange={(e)=> setName(e.target.value)}
                        />

                        {mobile && (
                            <LoadingButton 
                                onClick={onSubmit} 
                                disabled={pending} 
                                loading={pending} 
                                sx={{marginTop: 2}}
                            >
                                {pending ? "Création ..." : "Créer la recette"}
                            </LoadingButton>
                        )}
                    
                        
                </Stack>
                    
            </DialogContent>

            {(!mobile && !tablet) && (
                <DialogActions>
                    <Button onClick={onClose} disabled={pending}>
                        Annuler
                    </Button>

                    {/********* CREATION ****** */}
                    <LoadingButton 
                        onClick={onSubmit} 
                        disabled={pending} 
                        loading={pending}
                    >
                        {pending ? "Création ..." : "Créer la recette"}
                    </LoadingButton>
                    
                </DialogActions>
            )}
        </Dialog>
    )
}
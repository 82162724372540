import { Card, Popper, Typography } from "@mui/material";
import { useEffect } from "react";


interface Props{
    open: boolean,
    anchorEl: any,
    onClose: ()=> void,
    text: string
}

export default function SuccessPopper({open,anchorEl,onClose, text}:Props){

    /******** Fermeture du popover après un laps de temps ******* */
    useEffect(()=>{
        if(open){
            setTimeout(()=>{
                onClose()
            },1000)
        }
    },[open])


    return(
        <Popper 
                open={open}
                anchorEl={anchorEl}
            >
                <Card sx={{backgroundColor: "success.main"}}>
                    <Typography sx={{ p: 1, fontSize: 13, color: "white" }}>
                        {text}
                    </Typography>
                </Card>
            </Popper>
    )
}
import { Typography } from "@mui/material";

interface Props {
    children: React.ReactNode
}

export default function RowCardTitle({children}:Props){

    return(
        <Typography sx={{fontWeight: 600, fontSize: {xs: 15, sm:16}}}>
            {children}
        </Typography>
    )
}
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material";
import Recipe from "interfaces/Recipe";
import { MOBILE, RECIPE_TAGS } from "utils/constants";
import classes from "./styles";
import calcBoxHeight from "function/calcBoxHeight";
import Youtube from 'react-player/youtube'
import { useMemo, useState } from "react";
import { FoodPortion } from "interfaces/Food";
import { DEFAULT_PORTIONS } from "utils/constants";
import { Check } from "@mui/icons-material";
import MacroPieChart from "../MacroPieChart";
import calcMacros from "function/calcMacros";
import Meal from "interfaces/Meal";


interface Props{
    recipe: Recipe,
    open: boolean,
    onClose: ()=> void
}





export default function DialogRecipe({recipe, open, onClose}:Props){
    const mobile = useMediaQuery(MOBILE)
    const [playingVideo, setPlayIngVideo] = useState(false)
    const [loading, setLoading] = useState(false)

    const playerIsReady = () => {
        setTimeout(()=>{
            setLoading(false)
        },200)
    }


    const CHART_DATA = useMemo(()=>{
    
        const meals:Meal[] = [{
            name:"recipe",
            time:"00:00",
            content:[]
        }]

        recipe.ingredients.forEach((elem)=> {
            meals[0].content.push({
                type: "food",
                food: elem.ingredient.food,
                portion: elem.ingredient.portion,
                label: elem.ingredient.label,
                grammage: elem.ingredient.grammage
            })
        })


        const total = calcMacros(meals)
  
        
        const labels = [
          { value: Math.round(total.proteins), label: `Protéines - ${Math.round(total.proteins)}g`},
          { value: Math.round(total.lipids), label: `Lipides - ${Math.round(total.lipids)}g`},
          { value: Math.round(total.carbs), label: `Glucides - ${Math.round(total.carbs)}g`},
          //{value: Math.round(total.kcal), label: "Kcal"}
      ]
  
        return labels
  
    },[recipe.ingredients])

    return(
        <Dialog
            open={open}
            fullScreen={mobile}
            disableScrollLock={true}

        >
            <DialogTitle>
                {recipe.name.fr}
            </DialogTitle>
            <DialogContent
                sx={{padding:0, width: mobile ? "100%" : 640}}
            >
                {/**** Vidéo ***** */}

                <Box sx={[classes.video, {
                    height: calcBoxHeight(1080,1920,640)
                }]}>

                    {((!recipe.video || recipe.video?.url === "") &&  (recipe?.cover?.url && recipe?.cover?.url !== "")) && (
                        <Box 
                            component={'img'}
                            sx={classes.cover}
                            src={recipe.cover.url}
                        />
                    )}

                    {((!recipe.video || recipe.video?.url === "") && (!recipe.cover?.url || recipe.cover?.url === "")) && (
                        <Box 
                            component={'img'}
                            sx={classes.cover}
                            src={"https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/a5158a4a-2ee9-40d6-fe0b-84b8b5fb3d00/workoutCover"}
                        />
                    )}


                    {(recipe?.video && recipe.video.url !== "") && (
                        <Youtube
                            url={recipe?.video.url}
                            width={'100%'}
                            playing={playingVideo}
                            controls={true}
                            onReady={playerIsReady}
                            style={{
                                opacity: loading ? 0 : 1
                            }}
                        />
                    )}

                    {(recipe?.video && loading) && (
                        <Box 
                            sx={[
                                classes.loading,
                                {height: calcBoxHeight(1080,1920,640)}
                            ]}
                        >
                            <CircularProgress size={50} />
                        </Box>
                        
                    )}
                </Box>


                {/************
                 * TAGS
                 *************/}

                <Box
                    sx={classes.section}
                >
                    <Box sx={classes.tags}>
                        {recipe.tags.map((tag, tagIndex)=>{
                            return(
                                <Box 
                                    key={tagIndex}
                                    sx={[
                                        classes.tag,
                                        {backgroundColor: RECIPE_TAGS.find((elem)=> elem.id === tag).color}
                                    ]}>
                                        {RECIPE_TAGS.find((elem)=> elem.id === tag).label.fr}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                {/************
                 * MACROS
                 *************/}

                 <Box>
                    <MacroPieChart
                        data={CHART_DATA}
                        smaller={true}
                    />

                 </Box>

                {/************
                 * INGREDIENTS
                 *************/}

                <Box
                    sx={classes.section}
                >
                    <Typography
                        sx={classes.sectionTitle}
                    >
                        Ingrédients
                    </Typography>
                    <Box>
                        {recipe.ingredients.map((elem,index)=> {

                            const portions = elem.ingredient.food.portions
                            const defaultPortions:FoodPortion[] =  [...DEFAULT_PORTIONS]
                            const allPortions = defaultPortions.concat(portions)
                            const ingredientPortion = allPortions.find((portion)=> portion.label.fr === elem.ingredient.label).label.fr

                            return(
                                <Box
                                    sx={classes.ingredientContainer}
                                    key={index}
                                >
                                    <Check 
                                        sx={{fontSize: 20}}
                                    />
                                    <Typography
                                        sx={classes.ingredient}
                                    >
                                        {elem.ingredient.portion} {ingredientPortion} {elem.ingredient.food.name.fr}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>


                {/************
                 * ETAPES
                 *************/}

                <Box
                    sx={classes.section}
                >
                    <Typography
                        sx={classes.sectionTitle}
                    >
                        Etapes (pour {recipe.portions} portion{recipe.portions > 1 ? "s" : ""})
                    </Typography>
                    
                    <Box>

                        {recipe.steps.length === 0 && (
                            <Typography
                                sx={{marginTop: 1}}
                            >
                                Aucune description
                            </Typography>
                        )}
                        {recipe.steps.map((step, stepIndex)=>{
                            return(
                                <Box
                                    sx={classes.step}
                                    key={stepIndex}
                                >
                                    <Typography
                                         sx={classes.stepIndex}
                                    >
                                        {stepIndex + 1} - 
                                    </Typography>
                                    <Typography>
                                        {step}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>

                </Box>


            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const classes = {
    listHeader : {
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"space-between",
        mb:3
    },


    tabs: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },


}

export default classes
import React from "react"
import {Box} from "@mui/material"
import { Thumbnail as ThumbnailInterface } from "interfaces/Table"
import classes from "./styles"


export default function Thumbnail({image, onClick}:ThumbnailInterface){


    return(
        <React.Fragment>
            <Box 
                sx={classes.container} 
                onClick={onClick}
            >
                {image && (
                    <Box 
                        component={"img"} 
                        src={image?.url} 
                        sx={classes.image(Boolean(onClick))}
                    />
                )}
            </Box>
        </React.Fragment>
    )
}
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material"
import { Box, Chip as MuiChip, lighten} from "@mui/material"
import React from "react"
import { theme, primaryColor } from "utils/theme"
import classes from "./styles"


/******************* CHIPS ******************* */

interface Props {
    label: string,
    selected: boolean,
    onClick: ()=>void,
    disabled?: boolean
}

export function ChipSelector({label, selected, onClick, disabled}:Props){

    

    return(
        <Box sx={{marginBottom: 1, marginTop: 1}}>
            <MuiChip 
                label={label} 
                sx={[classes.chip, selected && classes.selected]} 
                clickable={!disabled}
                onClick={!disabled ? onClick : ()=> null}
                icon={selected ? <CheckCircle sx={{color: `${primaryColor} !important`,fontSize: 20}}/> : <RadioButtonUnchecked sx={{color: "#e3e3e3 !important", fontSize: 20}}/>}
            />
        </Box>
    )
}

function areEqual(prev:Props,next:Props){
    return prev.selected === next.selected
}

export default React.memo(ChipSelector, areEqual)


import { theme } from "utils/theme"


const classes = {
    name: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700
    },

    sectionTitle : {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,

    },


    sectionSubTitle : {
        fontSize: theme.typography.pxToRem(14),
    },

    rowHeader : {
        display:"flex",
        backgroundColor: "whitesmoke",
        height: 40,
        flexDirection: "row",
        marginTop: 2
    },

    rowValues : {
        display:"flex",
        backgroundColor: "whitesmoke",
        height: 40,
        flexDirection: "row",
    },

    rowValue: {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flex: 1,
        textAlign: "center"
    },

    rowValueBold : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flex: 1,
        textAlign: "center",
        fontWeight: 600
    },

    noPerf : {
        fontSize: theme.typography.pxToRem(14)
    }
}

export default classes
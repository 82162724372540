
import React, { useState, useMemo, useEffect } from "react"

// MUI
import {Box, Button, Chip, Grid, Typography, useMediaQuery} from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// REDUX
import { shallowEqual, useDispatch, useSelector } from "react-redux"

// DATE FNS
import { fr } from "date-fns/locale";
import {format} from "date-fns"

// LOCAL COMPONENTS
import DialogName from "components/organisms/DialogHandleUserName"
import DialogGender from "components/organisms/DialogHandleUserGender"
import DialogBirthday from "components/organisms/DialogHandleUserBirthday"
import DialogEmail from "components/organisms/DialogHandleUserEmail"
import DialogPassword from "components/organisms/DialogHandleUserPassword"

// constants
import { MOBILE, TABLET, TRAD } from "utils/constants"

// INTERFACES
import Subscription from "interfaces/Subscription"
import { AppDispatch, RootState } from "app/store"


// SHARED COMPONENTS
import { selectDrawerItem } from "features/appSlice";
import classes from "./styles"
import { useHistory } from "react-router-dom";
import {getNextInvoice, postSessionPortal } from "api/subscriptions";
import PremiumChip from "components/atoms/Chips/Premium";
import ProfileHeader from "components/organisms/ProfileHeader";
import SectionTitle from "components/atoms/Typography/SectionTitle";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import DialogDeleteAccount from "components/molecules/DialogDeleteAccount";
import Toolbar from "components/molecules/Toolbar";


{/********** MAIN COMPONENT ************* */}

export default function Account(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data, shallowEqual)
    const [openDialogName, setOpenDialogName] = useState<boolean>(false)
    const [openDialogBirthday, setOpenDialogBirthday] = useState<boolean>(false)
    const [openDialogGender, setOpenDialogGender] = useState<boolean>(false)
    const [openDialogEmail, setOpenDialogEmail] = useState<boolean>(false)
    
    const [openDialogPassword, setOpenDialogPassword] = useState<boolean>(false)
    const [billingPortalUrl, setBillingPortalUrl] = useState<string>(null)
    const [cancelSubscriptionUrl, setCancelSubscriptionUrl] = useState<string>(null)
    const [nextInvoice, setNextInvoice] = useState(null)
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)

    useEffect(()=>{
        dispatch(selectDrawerItem(7))
        if(user.customerId){
            dispatch(postSessionPortal({})).unwrap().then((res)=> {
                setBillingPortalUrl(res.url)
            })
        }
    },[user])


    useEffect(()=>{
        if(!user.subscription?.current?.cancel_at_period_end && user.subscription?.current?.status === "active"){
            dispatch(postSessionPortal({flow:"subscription_cancel"})).unwrap().then((res)=> {
                setCancelSubscriptionUrl(res.url)
            })
            dispatch(getNextInvoice()).unwrap().then((res)=>{
                setNextInvoice(res.invoice)
            })
        }   
    },[user.subscription?.current?.cancel_at_period_end])


    /*********** ABONNEMENT DE l'UTILISATEUR ******** */

    const subscription:Subscription = useMemo(()=>{

        if(Boolean(user.subscription?.current?.subscriptionId)){
            return user.subscription.current
        } else {
            return null
        }
    },[user])


    const onRedirectToBillingPortal = () => {
        window.location.replace(billingPortalUrl)
    }


    const onRedirectToSubscriptions = () => {
        history.push('subscriptions')
    }

    const onRedirectToCancelSubscription = () => {
        window.location.replace(cancelSubscriptionUrl)
    }


    const onDeleteAccount = () => {
        setOpenDeleteAccount(true)
    }

    return(

        <React.Fragment>
            <Header expanded={true}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={9}>
                        <Toolbar expanded={true}>
                            <ProfileHeader
                                data={user}
                                hideTabs={true}
                                userProfile={true}
                                showDisconnect={mobile ? false : true}
                            />
                        </Toolbar>
                    </Grid>
                </Grid>
            </Header>
            <MainContent header="withTabs">
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={9}>

                        {/****** INFORMATIONS PERSONELLES *********/}

                        <Box sx={classes.section}>
                            <SectionTitle>
                                Données personnelles
                            </SectionTitle>
                            
                            {/****** NOM ****** */}

                            <Box sx={[classes.sectionRow, classes.clickable]} onClick={()=> setOpenDialogName(true)}>
                                <Typography sx={classes.rowLabel}>
                                    Nom
                                </Typography>
                                <Typography sx={classes.rowValue}>
                                    {`${user.firstname} ${user.lastname}`}
                                </Typography>
                                {(!mobile && !tablet)&& (<KeyboardArrowRightIcon />)}
                            </Box>

                            {/****** GENDER ****** */}

                            <Box sx={[classes.sectionRow, classes.clickable]} onClick={()=> setOpenDialogGender(true)}>
                                <Typography sx={classes.rowLabel}>
                                    Sexe
                                </Typography>
                                <Typography sx={classes.rowValue}>
                                    {`${TRAD[user.gender].fr}`}
                                </Typography>
                                {(!mobile && !tablet)&& (<KeyboardArrowRightIcon />)}
                            </Box>


                            {/****** ANNIVERSAIRE ****** */}

                            <Box sx={[classes.sectionRow, classes.clickable]} onClick={()=> setOpenDialogBirthday(true)}>
                                <Typography sx={classes.rowLabel}>
                                    Date de naissance
                                </Typography>
                                <Typography sx={classes.rowValue}>
                                    {`${format(new Date(user.birthday), "dd MMMM yyyy", {locale: fr})}`}
                                </Typography>
                                {(!mobile && !tablet)&& (<KeyboardArrowRightIcon />)}
                            </Box>
                        </Box>


                        {/****** CONNEXION ************/}

                        <Box sx={classes.section}>
                            <SectionTitle>
                                Identifiants
                            </SectionTitle>

                            {/****** EMAIL *******/}
                            <Box sx={[classes.sectionRow, classes.clickable]} onClick={()=> setOpenDialogEmail(true)}>
                                <Typography sx={classes.rowLabel}>
                                    Email
                                </Typography>
                                <Typography sx={classes.rowValue}>
                                    {`${user.email}`}
                                </Typography>
                                {(!mobile && !tablet)&& (<KeyboardArrowRightIcon />)}
                            </Box>

                            {/****** PASSWORD *******/}
                            <Box sx={[classes.sectionRow, classes.clickable]} onClick={()=> setOpenDialogPassword(true)}>
                                <Typography sx={classes.rowLabel}>
                                    Mot de passe
                                </Typography>
                                <Typography sx={classes.rowValue}>
                                    ********
                                </Typography>
                                {(!mobile && !tablet)&& (<KeyboardArrowRightIcon />)}
                            </Box>
                            
                        </Box>

                        {/****** ABONNEMENT ************/}

                        <Box sx={classes.section}>
                            <SectionTitle>
                                Abonnements
                            </SectionTitle>

                            {!subscription && (
                                <Box sx={[classes.sectionRow]}>
                                    <Typography sx={classes.rowLabel}>
                                        Plan
                                    </Typography>
                                    <Typography sx={classes.rowValue}>
                                        {`${subscription ? subscription?.product.name + " à " + subscription?.price.amount/100 + "€/mois" : "Aucun plan en cours"}`} 
                                        {subscription?.subscriptionId && <PremiumChip />}
                                    </Typography>
                                </Box>
                            )}

                            {!subscription && (
                                <Box sx={{marginLeft: 1.5, marginBottom: 2,marginTop: 1}}>
                                    <Button 
                                        sx={classes.button} 
                                        onClick={onRedirectToSubscriptions}>
                                            Choisir un abonnement
                                    </Button>
                                </Box>
                            )}

                            {subscription && (
                                <Box>
                                    <Typography sx={classes.subscriptionName}>
                                        {subscription.product.name} 
                                        {(subscription.cancel_at_period_end && subscription.status !== "trialing") &&  <Chip label={"Annulé"} sx={{ml:1}} size="small"/>}
                                        {subscription.status === "trialing" &&  <Chip label={"Essai"} sx={{ml:2}} size="small"/>}
                                    </Typography>
                                    
                                    {subscription.status !== "trialing" && (
                                        <Typography sx={classes.subscriptionPrice}>
                                            {subscription.price.amount/100}.00€ / mois
                                        </Typography>
                                    )}
                                    {!subscription.cancel_at_period_end && (
                                        <>
                                            <Typography sx={classes.subscriptionRenew}>
                                                Ton abonnement sera renouvelé le {format(new Date(subscription?.end), "dd MMMM yyyy", {locale:fr})}
                                            </Typography>
                                            <Typography sx={classes.subscriptionRenew}>
                                                Estimation du montant de ton prochain paiement : <strong>{`${nextInvoice?.amount_due/100}`}€</strong>
                                            </Typography>
                                        </>
                                    )}

                                    {subscription.cancel_at_period_end && (
                                        <Typography sx={classes.subscriptionRenew}>
                                            {subscription.status === "trialing" ? "Ta période d'essai" : "Ton abonnement"} prendra fin le {format(new Date(subscription.end), "dd MMMM yyyy", {locale:fr})}
                                        </Typography>
                                    )}

                            <Box sx={[classes.buttonsContainer]}>
                                <Button 
                                    sx={classes.button} 
                                    disabled={!billingPortalUrl}
                                    onClick={onRedirectToBillingPortal}>
                                        Facturation
                                </Button>
                                {!subscription.cancel_at_period_end && (
                                    <Button 
                                        sx={classes.button} 
                                        disabled={!cancelSubscriptionUrl}
                                        onClick={onRedirectToCancelSubscription}>
                                            Annuler
                                    </Button>
                                )}
                                <Button 
                                    sx={classes.button} 
                                    onClick={onRedirectToSubscriptions}>
                                        Modifier
                                </Button>
                            </Box>
                                    
                                </Box>
                            )}
                        </Box>

                        <Box sx={classes.section}>
                            <SectionTitle>
                                Gestion des données
                            </SectionTitle>
                            <Box sx={{marginLeft: 1.5, marginBottom: 2,marginTop: 1}}>
                                <Button 
                                    sx={[classes.button, classes.buttonDelete]} 
                                    onClick={onDeleteAccount}>
                                        Supprimer mon compte
                                </Button>
                            </Box>
                        </Box>
                        
                    </Grid>
                </Grid>
                        
                        
            </MainContent>

        {/********* DIALOGS ********** */}


        {/********* NAME ********** */}

        <DialogName
            open={openDialogName}
            onClose={()=> setOpenDialogName(false)}
        />

        {/********* Sexe ********** */}

        <DialogGender
            open={openDialogGender}
            onClose={()=> setOpenDialogGender(false)}
        />

        {/********* Date de naissance ********** */}

        <DialogBirthday
            open={openDialogBirthday}
            onClose={()=> setOpenDialogBirthday(false)}
        />


        {/********* Email ********** */}

        <DialogEmail
            open={openDialogEmail}
            onClose={()=> setOpenDialogEmail(false)}
        />


         {/********* Password ********** */}

         <DialogPassword
            open={openDialogPassword}
            onClose={()=> setOpenDialogPassword(false)}
        />




        <DialogDeleteAccount
            open={openDeleteAccount}
            onClose={()=> setOpenDeleteAccount(false)}
        />

        </React.Fragment>
    )
}
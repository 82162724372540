import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from "@mui/material";
import { updateProgramDuration } from "api/programs";
import { AppDispatch, RootState } from "app/store";
import { addWeeks, differenceInCalendarWeeks } from "date-fns";
import Planning from "interfaces/Planning";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


interface Props{
    open: boolean,
    onClose: ()=>void
}

export default function DialogHandleProgramWeeks({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [duration, setDuration] = useState<number>(1)
    const [endDate, setEndDate] = useState<Date>(new Date())
    const program = useSelector((state:RootState)=> state.planning.builder)
    const pending = useSelector((state:RootState) => state.programs.requests.update === "pending")

    useEffect(()=>{
        setEndDate(addWeeks(new Date(program.startDate), duration))
    },[duration])


    useEffect(()=>{
        if(open){
            setDuration(differenceInCalendarWeeks(new Date(program.endDate), new Date(program.startDate)))
        }   
    },[open])


    const onChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value
        if(value === "0"){
            return
        }
        setDuration(parseInt(value))
    }


    const onSubmit = () =>{
        const newProgram:Planning = {
            ...program,
            endDate: endDate.toString()
        }

        dispatch(updateProgramDuration({program: newProgram})).unwrap().then(()=>{
            onClose()
        })
    }


    return(
        <Dialog open={open}>
            <DialogTitle>
                Durée du programme
            </DialogTitle>

            <DialogContent>
                <TextField 
                    value={duration}
                    onChange={onChangeDuration}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">semaine(s)</InputAdornment>,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton
                    disabled={pending}
                    loading={pending}
                    onClick={onSubmit}
                >
                    Enregistrer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { Badge, Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import PageTitle from "components/atoms/Typography/PageTitle";
import Header from "components/molecules/Header";
import SearchTextfield from "components/molecules/Search";
import Toolbar from "components/molecules/Toolbar";
import { DESKTOP, MOBILE, SMALL_DESKTOP, TABLET } from "utils/constants";
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import MoreButton from "components/atoms/Buttons/MoreButton";
import { Add, FilterList } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import MainContent from "components/molecules/MainContent";
import { useEffect, useMemo, useRef, useState } from "react";
import DialogCreateRecipe from "components/organisms/DialogCreateNewRecipe";
import classes from "./styles";
import RecipeCard from "components/organisms/RecipeCard";
import Tabs from "components/molecules/Tabs";
import { selectDrawerItem } from "features/appSlice";
import EmptySearch from "components/molecules/EmptySearch";
import { resetSearchRecipes, searchRecipe } from "features/recipesSlice";
import RecipesFilters from "components/organisms/RecipesFilters";
import Recipe from "interfaces/Recipe";
import { ViewportList } from "react-viewport-list";

export default function Recipes(){

    const dispatch = useDispatch<AppDispatch>()
    const IS_TABLET = useMediaQuery(TABLET)
    const IS_MOBILE = useMediaQuery(MOBILE)
    const IS_SMALL_DESKTOP = useMediaQuery(SMALL_DESKTOP)
    const IS_DESKTOP = useMediaQuery(DESKTOP)
    const recipes = useSelector((state:RootState) => state.recipes)
    const [openDialogRecipe, setOpenDialogRecipe] = useState<boolean>(false)
    const [tabs, setTabs] = useState(0);
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);
    const [search, setSearch] = useState<string>("");
    const tagsFilter = useSelector(
        (state: RootState) => state.recipes.tagsFilter
    );
    const listRef = useRef(null);




    /*******************  
     * RESET DE L'ECRAN 
     ***************** */

    useEffect(() => {
        dispatch(resetSearchRecipes());
        dispatch(selectDrawerItem(4));
    }, [dispatch]);


     /******************* 
      * GESTION DES TABS 
      * ******************/

     const tabsLabels = useMemo(() => {
        
        return [
            `Mes recettes (${recipes.filtered.custom.length})`, 
            `Brouillons (${recipes.filtered.draft.length})`
        ];
    }, [recipes]);

     const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    /************************* 
     *      RECHERCHE 
     * *********************** */

    const onSearch = (e:any) => {
        setSearch(e.currentTarget.value);
        
        dispatch(searchRecipe({search:e.currentTarget.value}))
    }

    useEffect(() => {
        dispatch(searchRecipe({ search, tagsFilter }));
    }, [tagsFilter]);


   
    /************************* 
     *  LISTE DES RECETTES 
     * *********************** */

    const RECIPES = useMemo(()=>{
       if(tabs === 0){
            return recipes.filtered.custom
       }else{
            return recipes.filtered.draft
       }
        
    },[tabs,recipes])


    const DISPLAY:{content:Recipe[]}[] = useMemo(()=>{
        var display:{content:Recipe[]}[] = []
        var row = 0
        var limit = 3

        console.log(IS_MOBILE, IS_TABLET, IS_DESKTOP)

        if(IS_MOBILE) limit = 1
        if(IS_TABLET) limit = 2
        if(IS_SMALL_DESKTOP && IS_TABLET) limit = 2
        if(IS_SMALL_DESKTOP && !IS_TABLET) limit = 3
        if(IS_DESKTOP) limit = 3

        RECIPES.forEach((recipe)=> {
            if(row === limit){
                row = 0
            }
            if(row === 0){
                display.push({content:[]})
            }
            display[display.length - 1].content.push(recipe)
            row++
        })

        console.log('RECIPES', display)


        return display
    },[RECIPES, IS_DESKTOP, IS_MOBILE, IS_TABLET])


   


    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };



    return(
        <>
                {/********************
                 *      HEADER 
                 ********************/}
                <Header
                    expanded={true}
                >
                    <Grid 
                        container 
                        justifyContent="center"
                    >
                        <Grid 
                            item 
                            xs={12} 
                            sm={12} 
                            md={12} 
                            lg={10} 
                            xl={8}
                        >
                            <Toolbar
                                expanded={true}
                            >
                                <PageTitle
                                    expanded={true}
                                >
                                    Recettes
                                </PageTitle>

                                <Box 
                                    sx={classes.tabs}>
                                    <Tabs 
                                        tabs={tabsLabels} 
                                        value={tabs} 
                                        onChange={onChangeTabs} 
                                    />

                                {/************************
                                 * RECHERCHE + BOUTON ADD
                                 *************************/}

                                {!IS_MOBILE && (
                                    <Box sx={{display:"flex", flexDirection: "row"}}>

                                        <IconButton
                                            sx={{ marginRight: 1 }}
                                            onClick={onOpenFilterList}
                                        >
                                            <Badge
                                                badgeContent={tagsFilter.length} 
                                                color="primary"
                                            >
                                            <FilterList />
                                            </Badge>
                                        </IconButton>

                                        <SearchTextfield
                                            onChange={onSearch}
                                            placeholder={"Rechercher une recette"}
                                        />

                                        {!IS_TABLET && (
                                            <ContainedPrimaryButton 
                                                variant="contained" 
                                                onClick={()=>setOpenDialogRecipe(true)}
                                            >
                                                Ajouter une recette
                                            </ContainedPrimaryButton>
                                        )}
                                    </Box>
                                )}

                                {IS_MOBILE && (
                                    
                                    <MoreButton
                                        menuList={[{
                                            icon: <Add/>,
                                            label: "Ajouter une recette",
                                            callback:()=>setOpenDialogRecipe(true)
                                        }]}
                                    />
                                
                                )}
                                </Box>
                                
                                
                            </Toolbar>
                        </Grid>
                    </Grid>
                </Header>

                {/********************
                 *      CONTENT 
                 ********************/}

                <MainContent
                    header={"simple"}
                >

                {DISPLAY.length > 0 && (
                    <Grid 
                            container 
                            justifyContent="center" 
                            sx={{paddingBottom: 10,}}
                        >
                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >
                                <Box 
                                    sx={classes.listHeader}
                                >

                                    {/**** BOUTON ADD SUR TABLETTE ***** */}
                                    {(IS_TABLET && !IS_MOBILE) && (
                                        <Box
                                            sx={{marginTop: 3}}
                                        >
                                            <ContainedPrimaryButton 
                                                variant="contained" 
                                                onClick={()=>setOpenDialogRecipe(true)}
                                            >
                                                Ajouter une recette
                                            </ContainedPrimaryButton>

                                            <Box>
                                              
                                            </Box>
                                        </Box>
                                    )}

                                </Box>

                                    <ViewportList
                                        ref={listRef}
                                        items={DISPLAY}
                                        initialPrerender={12}
                                        //itemSize={95}
                                    >
                                        {(item, index) => (
                                            <Grid 
                                                container 
                                                spacing={3}
                                                key={index}
                                                sx={{marginBottom: 3}}
                                            >
                                                {item.content.map((recipe, recipeIndex) => {
                                                    return(
                                                        <RecipeCard
                                                            key={recipeIndex}
                                                            recipe={recipe}
                                                        />
                                                    )
                                                })}
                                            </Grid>
                                        )}
                                    </ViewportList>
                                
                                
                                {/*DISPLAY.map((row,index)=> {
                                    console.log('row', row)
                                    
                                    return(

                                        <Grid 
                                            container 
                                            spacing={3}
                                            key={index}
                                            sx={{marginBottom: 3}}
                                        >
                                            {row.content.map((recipe, recipeIndex) => {
                                                console.log('recipe')
                                                return(
                                                    <RecipeCard
                                                        key={recipeIndex}
                                                        recipe={recipe}
                                                    />
                                                )
                                            })}
                                            
                                        </Grid>
                                    )
                            
                                })*/}
                        </Grid>
                    </Grid>
                )}
            



                     {/************** 
                      * EMPTY STATE 
                      * ****** */}

                     {RECIPES.length === 0 && (
                        <Box sx={{marginTop: 10}}>
                            <EmptySearch
                                title={"Aucune recette"}
                                description={`Tu n'as enregistré aucune recette pour le moment`}
                                
                            />
                        </Box>
                    )}


                    <DialogCreateRecipe
                        open={openDialogRecipe}
                        onClose={()=>setOpenDialogRecipe(false)}
                    />

                <RecipesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />


                </MainContent>
        </>
    )
}

import Workout from "interfaces/Workout";

export default function removePerformedSets(workout:Workout){
    const copy:Workout = JSON.parse(JSON.stringify(workout))

    copy.blocks.forEach((block,blockIndex) => {
        block.content.forEach((group, groupIndex)=>{
            group.exercices.forEach((exercice,exerciceIndex)=> {
                copy.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].replaced = undefined
                exercice.sets.forEach((set,setIndex)=> {
                    copy.blocks[blockIndex].content[groupIndex].exercices[exerciceIndex].sets[setIndex].performances = null
                })
            })
        })
    })

    return copy
}
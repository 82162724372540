import { Button, styled } from '@mui/material';
import { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from 'utils/constants';



const StyledButton = styled(Button)(({ theme }) => ({
    //borderRadius: '15px !important',
    fontWeight: 600,
    //marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    height: 40,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    //textTransform: "uppercase",
    //textTransform: "lowercase",
    //color: "rgba(0,0,0,085)",
    backgroundColor: LIGHT_BUTTON_COLOR,
    textTransform: "none",
    //backgroundColor: "#f5f5f5",
    //border: `solid 1px ${theme.palette.primary.main}`,
    "&:hover": {
        backgroundColor: HOVER_COLOR
    }
 }))




export default function SecondaryButton(buttonProps:MuiButtonProps){

        return(
            <StyledButton {...buttonProps} disableElevation>
                {buttonProps.children}
            </StyledButton>
        )
    
}
const classes=  {

    input:{
        width: "100%"
    },

    label:{
        marginBottom: 1
    },

    templateItem: {
        display: "flex",
        justifyContent:"space-between"
    },

    invalid: {
        color: "red"
    }
}


export default classes
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from "@mui/material";
import { deleteCheckup } from "api/checkups";
import { AppDispatch, RootState } from "app/store";
import { format, isValid } from "date-fns";
import { fr } from "date-fns/locale";
import Checkup from "interfaces/Checkup";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


interface Props{
    open: boolean,
    checkup: Checkup,
    onClose: ()=> void
}

export default function DialogDeleteCheckup({checkup,open, onClose}:Props){


    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.checkups.requests.delete === "pending")
    const [data, setData] = useState({
        name: checkup?.name,
        template: checkup?.template,
        //schedule: checkup?.schedule
    })


    useEffect(()=>{
        if(open){
            setData({
                name: checkup?.name,
                template: checkup?.template,
                //schedule: checkup?.schedule
            })
        }
    },[open])


    const template = useMemo(()=>{
        if(data.template){
            return false
        }else {
            return true
        }
    },[data])


    /********** SUPPRESSION DU BILAN ******** */

    const onSubmit = () => {
        dispatch(deleteCheckup({checkup})).unwrap().then((res:any)=>{
            onClose()
        })
    }



    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer un modèle de questionnaire
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer le modèle de questionnaire <strong>{data.name}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton onClick={onSubmit} variant="contained" disabled={pending} loading={pending}>
                    {pending ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import Fuse from "fuse.js"
import diacritics from 'diacritics'

const fuseoptions = {
    getFn: (obj:any, path: string | string[]) => {
        const value = Fuse.config.getFn(obj, path)
  
        if (Boolean(value) && value.length > 0) {
            return diacritics.remove(JSON.stringify(value))
        }
  
    },
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.5,
    location: 100,
    shouldSort: true,
    includeMatches: true,
    matchAllTokens: true,
    
    
}


export default function fuseSearch(collection:any, search:string, keys:string[], threshold?: number, ignoreLocation?: "ignore", location?: "start"){
    const options = {
        ...fuseoptions,
        threshold: threshold? threshold : fuseoptions.threshold,
        ignoreLocation: ignoreLocation === "ignore" ? false : fuseoptions.ignoreLocation,
        location: location === "start"? 0 : fuseoptions.location,
        keys: keys
    }

    // App exos
    const fuse = new Fuse(collection,options)
    //const result = fuse.search(diacritics.remove(search));
    const result = fuse.search(diacritics.remove(search));
    const items = result.map((elem:any) => {
        return elem.item
    })
    return items
}
import { BORDER_COLOR, MACROS_COLORS } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    content: {
        padding: 3,
        //border: "solid 2px #dfdfdf",
        borderRadius: 1,
        backgroundColor: "white",
        border: `solid 1px ${BORDER_COLOR}`,
        marginBottom: 3,
        position: "relative",
        overflow: "hidden"

    },

    header:{
        display:"flex",
        flexDirection: {xs: "column", sm:"row"},
        marginBottom: 3,
        width: "100%",
        alignItems:{xs: "flex-start", sm: "center"}
    },

    mealContent: {
        flexDirection: "column"
    },

    mealButtons:{
        display:"flex", 
        flexDirection: {xs: "column" , sm: "row"},
        marginTop: 3
    },

    buttonClose : {
        position: "absolute",
        top: theme.spacing(3),
        right: theme.spacing(3),
        borderRadius: 100
    },

 

    imageContainer : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: 200
    },


    kcal : {
        fontWeight: 600,
        fontSize: 18,

    },

    kcalContainer : {
        display:"flex",
        alignItems:"center",
        marginTop: {xs: 1, sm: 0},
    }
    



    
}


export default classes
import { useState } from "react";
//MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux"
// Router
import { useHistory } from "react-router-dom";
// Apis
import { createCheckup } from "api/checkups";
// Interfaces
import { AppDispatch, RootState } from "app/store"
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import Checkup from "interfaces/Checkup";


////// INTERFACES //////

interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogCreteCheckup({open,onClose}:Props){

    const navigate = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState) => state.checkups.requests.create === "pending")
    const [name,setName] = useState<string>('')


    /************* CREATION D'UNE SEANCE ************** */

    const onCreateCheckup = () => {

        const checkup:Checkup = {
            name, 
            template: true,
            status:"disabled",
            questions :[]
        }
        dispatch(createCheckup({checkup})).unwrap().then((res:any) => {
            if (res.checkup) {
                navigate.push(`/checkup/${res.checkup._id}`)
            }
        })
    }


    return(

        <Dialog open={open} fullWidth maxWidth={'xs'}>
            <DialogTitle>
                Créer un modèle de questionnaire
            </DialogTitle>
            <DialogContent>
                <TextField 
                    fullWidth={true}
                    value={name}
                    onChange={(e)=>setName(e.currentTarget.value)}
                    label='Nom du modèle'
                    placeholder="Indique le nom de ton modèle"
                    sx={{marginTop: 1}}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>Annuler</Button>
                <LoadingButton onClick={onCreateCheckup} disabled={pending} loading={pending}>
                    {pending ? "Création ..." : "Valider"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
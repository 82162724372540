import { BODY_BGCOLOR, HOVER_COLOR } from "utils/constants"

const classes = {

    content: {
        padding: 0, 
        minHeight: "70vh", 
        maxHeight: {
            xs: "auto",
            md:"70vh",
        },
        backgroundColor: "white"
    },

    list: {
        borderRight: "solid 1px #e1e1e1",
        backgroundColor: "white",
        height: "100%",
        minHeight: "70vh", 
        maxHeight: "70vh",
        padding: 3
    },

    block: {
        //backgroundColor: "whitesmoke",
        marginBottom: 2,
        //padding: 1.5
    },
    row:{
        position :"relative",
        paddingTop: 1,
        paddingBottom: 1,
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        borderBottom: "solid 1px #e1e1e1",

        "&:hover" : {
            backgroundColor: HOVER_COLOR,
            cursor: "pointer"
        }
    },

    rowSelected: {
        backgroundColor: "white",
    },

    workoutContent : {
        padding: 3,
        paddingLeft: 6,
        paddingRight: 6
    }
}   

export default classes
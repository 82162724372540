import { ExerciceSet, WorkoutExercice } from "interfaces/Workout"
import { v4 as uuid } from "uuid"

export default function addSetsToExercice(loop:number, exercice:WorkoutExercice){

    let exerciceToReturn = {...exercice}

    for (var j = 0; j < loop; j++) {
      let lastIndex:number = exerciceToReturn.sets.length - 1
      let setToInsert:ExerciceSet = {
        ...exerciceToReturn.sets[lastIndex], // Valeurs du précédents
        exerciceSetId: uuid()
      }
      exerciceToReturn.sets.push(setToInsert)
    }
    return exerciceToReturn
}
import React, { useState } from "react";
import { Box, Card,Grow,ListItemIcon,ListItemText,Menu,MenuItem,Typography } from "@mui/material";
import Checkup from "interfaces/Checkup";
import classes from "./styles" 
import { Check, PlaylistAddCheck } from "@mui/icons-material";
import DialogQuestionnaireResume from "../DialogQuestionnaireResume";

interface Props{
    questionnaire: Checkup
}





export function PlanningQuestionnaireCompleted({questionnaire}:Props){

    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState<boolean>(false)


    /******** GESTION DU MENU ******* */
    const onOpenMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }


    const onOpenDialogQuestionnaire = () => {
        setOpen(true)
        setAnchorEl(null)
    }

    
    
    return(
        <>

            <Grow in={true}>
                <Card 
                    sx={[classes.checkup]} 
                    elevation={0}
                    onClick={onOpenMenu}
                >
                    {/****** INFOS ******* */}

                
                        <Check 
                                sx={{marginRight:1}}
                            />

                        {/*** NOM *** */}
                        {/*** NOM *** */}
                        <Typography 
                            sx={classes.checkupName}
                        >
                            {questionnaire?.name}
                        </Typography>
                    
                </Card>
            </Grow>
          
            <Menu 
                disableScrollLock={true} 
                id="day-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={()=>setAnchorEl(null)}
            >
                <MenuItem 
                    onClick={onOpenDialogQuestionnaire} 
                >
                    <ListItemIcon>
                        <PlaylistAddCheck />
                    </ListItemIcon>
                    <ListItemText>
                        Consulter le questionnaire
                    </ListItemText>
                </MenuItem>
            </Menu>


     
            <DialogQuestionnaireResume
                open={open}
                onClose={()=>setOpen(false)}
                questions={questionnaire.questions}
                name={questionnaire.name}
                date={new Date(questionnaire.createdAt)}
            />
            

            
        </>

    )
}

function areEqual(prev:Props, next:Props){
    return prev.questionnaire === next.questionnaire
}

export default React.memo(PlanningQuestionnaireCompleted, areEqual)
import { lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {
    header : {
        display:"flex", 
        flexDirection: "column", 
        justifyContent:"center", 
        alignItems:"center"
    },

    selectedRange:{
        backgroundColor: lighten(theme.palette.primary.main,0.90)
    },

    rangeButtons:{
        display:"inline-flex", 
        alignItems:"center", 
        justifyContent:"space-between", 
        flexDirection: "row", 
        marginBottom: 3,
        width: 340
    },

    periodIndicator:{
        display:"flex", 
        width: "100%", 
        alignItems:"center", 
        justifyContent:"center"
    }
}


export default classes
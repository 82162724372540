import { Box, Button, Grid, Typography } from "@mui/material";
import { theme } from "utils/theme";
import classes from "./styles";

interface Props{
    onClick?: (e:any)=> void,
    buttonLabel?: string,
    title: string,
    description: string,
    image?: string,
    height?: string,
    titleSize?:number
}

export default function EmptyState({onClick, title, description, buttonLabel, image, height, titleSize}:Props){

    return(
        <Box sx={{display:"flex", width: "100%", alignItems:"center", justifyContent:"center", height: height? height: 300}}>
                <Box sx={classes.container}>

                    {/**** IMAGE ***** */}
                    {image && (
                    <Box 
                        component={"img"} 
                        src={`/assets/${image}`} 
                        sx={{width: "100%"}}
                    />
                    )}

                    {/**** TITRE/ DESCRIPTION ***** */}
                    <Typography sx={[classes.title, titleSize && {fontSize: theme.typography.pxToRem(titleSize)}]}>
                        {title}
                    </Typography>
                    <Typography sx={{textAlign:"center"}}>
                        {description}
                    </Typography>

                    {/**** BUTTON ***** */}

                    {onClick && (
                    <Button 
                        sx={{height: 40, width: 280, marginTop: 3, borderRadius:"20px !important", textTransform: "none"}} 
                        disableElevation 
                        variant="contained"
                        onClick={onClick}
                    >
                        {buttonLabel}
                    </Button>
                    )}
                </Box>
         
        </Box>
    )
}
import { useState } from "react";
// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux"
// Interfaces
import { AppDispatch, RootState } from "app/store"
// ROUTER
import { useHistory } from "react-router-dom";
import { createWorkout } from "api/workouts";
// REDUCERS
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import Workout from "interfaces/Workout";
import { WORKOUT_COVERS } from "utils/constants";


////// INTERFACES //////

interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogCreateWorkout({open,onClose}:Props){

    const navigate = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.workouts.requests.create === "pending")
    const [name,setName] = useState<string>('')


    /************* CREATION D'UNE SEANCE ************** */

    const onCreateWorkout = () => {

        const workout:Workout = {
            name,
            status: "disabled",
            template: true,
            cover: {url: WORKOUT_COVERS[0]}
        }

        dispatch(createWorkout({workout})).unwrap().then((res:any) => {
            navigate.push(`/workout/${res.workout._id}`) 
        })
    }


    return(

        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={'xs'}
        >
            <DialogTitle>
                Créer un modèle de séance
            </DialogTitle>
            <DialogContent>
                <TextField 
                    fullWidth={true}
                    value={name}
                    onChange={(e)=>setName(e.currentTarget.value)}
                    label=''
                    placeholder="Exemple : Push, Pectoraux ..."
                    sx={{marginTop: 1}}
                    disabled={pending}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                    <LoadingButton 
                        variant="contained" 
                        onClick={onCreateWorkout} 
                        loading={pending} 
                        disabled={pending}
                    >
                        {pending ? "Création" : "Valider"}
                    </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
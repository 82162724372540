
import { useEffect, useRef, useState } from "react";
// MUI
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, lighten, useMediaQuery } from "@mui/material"
// REDUX
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit";
// APP
import { AppDispatch, RootState } from "app/store"
import {theme } from 'utils/theme';
//import initFacebookSdk from "utils/helpers";
import Routes from "app/routes";
// PROVIDERS
import { ToastContainer, Slide } from "react-toastify";
//import { Scrollbars } from 'react-custom-scrollbars';
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// APIs
import { pingServer } from "features/appSlice";
import { getTemplates } from "api/templates";
import { getClients, getInvitations } from 'api/clients';
import { getAccessToken } from "api/user";
import { getExercices } from "api/exercices";
// REDUCERS
import { userIsReady } from "features/userSlice";
import { getMetrics } from "api/exerciceMetrics";
import cacheImages from "function/cacheImages";
import ForumIcon from '@mui/icons-material/Forum';
import { MOBILE } from "utils/constants";
import { getFoodList } from "api/food";
import { getRecipes } from "api/recipes";


declare global {
  interface Window { 
    'BrevoConversations': any; 
    'BrevoConversationsID': string,
    'BrevoConversationsSetup': any,
  }

  interface Document{
      'BrevoConversations' : any
  }
}




/************ REDUX SELECTORS *********** */

const userIsReadySelector = createSelector(
  (state: RootState) => state.user,
  (user) => user.isReady
)

const userIsAuthSelector = createSelector(
  (state: RootState) => state.user,
  (user) => user.isAuth
)


/************ APP *********** */

function App() {

  const dispatch = useDispatch<AppDispatch>()
  const isReady = useSelector(userIsReadySelector)
  const isAuth = useSelector(userIsAuthSelector)
  const user = useSelector((state:RootState)=> state.user)
  const brevoScriptRef= useRef<any>(null)
  const [showChat, setShowChat] = useState<boolean>(false)
  const mobile = useMediaQuery(MOBILE)

  

  useEffect(()=>{
    cacheImages([
      'assets/empty_clients.png',
      'assets/empty_exercices.png',
      'assets/no_results.png',
    ])
  },[dispatch])


  /*********** CHARGEMENT DU SDK FACEBOOK ************ 

  const loadFacebookSdk = async () => {
      await initFacebookSdk()
  }

  */


  useEffect(() => {
    dispatch(pingServer())
  }, [dispatch])

  

  /*************** AUTHETIFICATION SILENCIEUSE ***************/

  useEffect(() => {
    if (!isReady) {
      dispatch(getAccessToken())
    }
  }, [isReady, dispatch])



  /***************** LORSQUE L'UTILISATEUR EST AUTHENTIFIE **************** */
  useEffect(() => {
    if (isAuth && userIsReady) {
        dispatch(getClients()) // récupération des clients
        dispatch(getInvitations()) // récupération des invitations
        dispatch(getExercices()) // récupération des exercices
        dispatch(getTemplates()) // Récupération des templates
        dispatch(getMetrics()) // Récupération des templates
        dispatch(getFoodList()) // Récupération des aliments
        dispatch(getRecipes()) // Récupération des recettes
        //dispatch(getResultMetrics()) // Récupération des résults metrics
    }
  }, [isAuth, dispatch])

 
  /*************** APP ***************/


  useEffect(()=>{

    if(user.isAuth){
      const w = window
      const d= document
      const c = 'BrevoConversations'

      w[c] = {
       
        buttonPosition: window.innerWidth < 1024 ?
        'br' : 'bl'
      }

      w['BrevoConversationsSetup'] = {
        visitorId: user.data._id,
        customWidgetButton: ".brevo-bouton"
      }

      w.BrevoConversationsID = '621bcfb41f5a8a3dca52c255';

      w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
      };

      const scriptTag = document.createElement('script');
      scriptTag.async = true
      scriptTag.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      
      if (d.head) d.head.appendChild(scriptTag);
      scriptTag.addEventListener('load', () => {
          brevoScriptRef.current = window['BrevoConversations']
          brevoScriptRef.current.updateIntegrationData({
              email: user.data.email,
              firstName: user.data.firstname,
              lastName: user.data.lastname,
              notes: `Abonnement : ${user.data.subscription?.current?.status} - ${user.data.subscription?.current?.product.name}`
          })
          setShowChat(true)
      })

      
    }
  },[user])


  const openWidget = () => {
    if(brevoScriptRef.current){
      brevoScriptRef.current.openChat()
    }
  }

  return (

    <div className="App">
          <CookiesProvider  >
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Routes />
                </LocalizationProvider>
                <CssBaseline enableColorScheme />
                <ToastContainer 
                  autoClose={2000} 
                  position="bottom-center" 
                  //hideProgressBar 
                  theme="light" 
                  transition={Slide}
                />


                {/********************
                 * BREVO WIDGET
                 ********************/}

                {(showChat && !mobile) && (
                  <Box 
                      onClick={openWidget} 
                      sx={{
                        position: "fixed", 
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        zIndex: 1000, 
                        borderRadius: 100,
                        bottom: {
                          xs: 80,
                          sm: 100,
                          lg: 20, 
                        },
                        right: 20, 
                        width: 60, 
                        height: 60, 
                        backgroundColor: theme.palette.primary.main,
                        cursor: "pointer",

                        "&:hover" : {
                          backgroundColor: lighten(theme.palette.primary.main, 0.1)
                        }
                      

                    }}
                  >
                    <ForumIcon 
                      sx={{color: "white", fontSize:"1.8rem"}}
                      
                    />
                  </Box>
                )}

            
              </ThemeProvider>
          </CookiesProvider>
   
    </div>


  );
}

export default App;

import { BODY_BGCOLOR, BORDER_COLOR } from "utils/constants";
import { theme } from "utils/theme";


const classes = {
    dialogContent:{
        padding: 0, 
        position: "relative", 
        overflow: "hidden", 
        boxSizing: "border-box",
        maxWidth: "100%",
        minHeight: {
            xs: "auto",
            md:"calc(100vh - 64px - 120px - 55px)"
        },
        backgroundColor: BODY_BGCOLOR
    },

    searchContainer : {
        display:"flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        justifyContent:"space-between",
        alignItems:"center"
    },

    scrollContainer:{
        paddingRight: {xs: 1.5, sm: 3},
        paddingTop: 0, 
        paddingLeft: {xs: 1.5, sm: 3},
        overflowY: "scroll", 
        maxHeight: {
            xs: `calc(100vh - 32px - 48px)`,
            sm: `calc(100vh - 121px - 64px - 53px)`
        },
        position: "relative",
        display:"flex",
        flexDirection :"column",
        flex: 1
    },


    header: {
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-around",
        height: 60,
        alignItems:"center",
        paddingLeft: 3,
        paddingRight: 3,
        backgroundColor: "white"
    },

    headerTitle : {
        display:"flex",
        flex: 2,
        textAlign:"left",
    },

    headerMacros:{
        display:"flex",
        flex: 1,
        flexDirection: "row",
        justifyContent:"flex-end"
    },

    headerMacroLabel : {
        width: 80
    },

    foodItem : {
        minHeight: 120,
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        width: "100%",
        borderBottom: `solid 1px ${BORDER_COLOR}`

        
    },

    itemLabels : {
        display:"flex",
        justifyContent:"flex-start",
        flexDirection: "column",
        flex: 1
    },

    macroItem:{
        marginRight: 3,
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
    },

    macroItemTitle:{

    },

    foodItemName : {
        textAlign: "left",
        flex: 2,
        paddingBottom: 0.5,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600
    },

    macros: {
        display:"flex",
        flex: 1,
        alignItems:"flex-end",
        justifyContent:"flex-start",
        flexDirection: "row",
    }
}


export default classes
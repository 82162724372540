import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {

    title: {
        fontWeight: 700,
        
    },

    content : {
        backgroundColor: "#f6f6f6", 
        overflowY: {
            xs: "auto",
            lg: "hidden", 
        },
        padding: 0,
    },

    scrollBar: {
        height:"calc(100vh - 90px - 64px - 53px)", 
        paddingRight: 3
    },


    mealContent : {
        borderBottom: `solid 1px ${BORDER_COLOR}`,
        paddingBottom: 1.5,
        paddingTop: 1.5,
        display: "flex",
        flexDirection: "row"
        
    },

    
    foodInfo :{
        display:"flex",
        flexDirection: "column"
    },

    mealContentTitle: {
        fontSize: 16,
        fontWeight: 600
    },



    header: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: "#f9f9f9",
        paddingLeft: {
            xs: 0,
            sm: 2,
        },
        paddingRight:2
    },




    brands : {
        fontSize: 14,
        fontStyle: "italic",
        marginTop: 8
    },

    kcal : {
        fontWeight: 600,
        fontSize: 18,

    },

    kcalContainer : {
        display:"flex",
        alignItems:"center",
        marginTop: {xs: 1, sm: 0},
        marginBottom: 2
    }



}


export default classes
import axios from "axios"

// test
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 30000,
});

instance.defaults.withCredentials = true
//instance.defaults.credentials = "include"

instance.interceptors.response.use((response) => { return response }, async (error) => {
    const originalRequest = error.config

    // Si l'URL est différente d'une demande de TOKEN, 
    //que le problème est 401 (problème de droit)
    // et qu'on a pas retenté d'avoir un token
    if (error.config.url !== "auth/accesstoken" && error.response.status === 401 && !originalRequest._retry) {
        // On définit le retry sur true pour éviter de refaire une demande ensuite
        originalRequest._retry = true

        try {
            const result = await instance.get(`auth/accesstoken`)
            const accessToken = result.data.accessToken
            instance.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
            
            // On retente la request d'origine avec le nouvel access token
            originalRequest.headers['authorization'] = `Bearer ${accessToken}`
            return instance(originalRequest)

        } catch (err) {
            return;
        }
    } else {
        return Promise.reject(error);
    }
});


export default instance
import { BORDER_COLOR } from "utils/constants";

export const classes = {

    container : {
        
    },


    listHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      mb: {
        xs: 3,
        lg: 0
      }
    },
  
    tabs: {
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  };
  
  export default classes;
  
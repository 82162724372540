import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import Recipe from "interfaces/Recipe";
import { deleteRecipe } from "api/recipes";
import { toast } from "react-toastify";

interface Props{
    open: boolean,
    onClose: ()=>void,
    recipe: Recipe
}

export default function DialogDeleteRecipe({open, onClose, recipe}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.recipes.requests.delete === "pending")

    
    /*************** SUPPRRESSION D'UN CLIENT ************ */
    const onDeleteRecipe = () => {
            dispatch(deleteRecipe({recipe:recipe})).unwrap().then(()=>{
            onClose()
            toast.success('Recette supprimée')
        })
    }


    return(
        <Dialog 
            open={open}
            disableScrollLock={true} 
        >
            <DialogTitle>
                Supprimer une recette
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Veux-tu supprimer la recette {recipe.name.fr} ? Cette dernière restera disponible dans les plans alimentaires où tu l'as incluse.
                </Typography>
            </DialogContent>
            <DialogActions>
                    <Button onClick={onClose}>
                        Annuler
                    </Button>
                    <LoadingButton 
                        disabled={pending} 
                        loading={pending} 
                        onClick={onDeleteRecipe}
                    >
                        {pending? "Suppression ..." : "Confirmer"}
                    </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
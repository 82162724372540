import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios"



export const getMetrics = createAsyncThunk('metrics/fetchMetrics', async () => {
    try {
        const response = await axios.get(`metrics`)
        const metrics = response.data.metrics
        return {metrics}
    }
    catch(err:any){
        return {
            error: err.response.data?.message[0]
        }
    }
})
import { lighten } from "@mui/material"
import { BORDER_COLOR } from "utils/constants"
import {theme} from "utils/theme"


const classes = {

   section : {
    flexDirection: "column"
   },

    buttonContainer: {
        display:"flex",
        alignItems:"center",
        jusstifyContent:"flex-end"

    },
    
}





export default classes
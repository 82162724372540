import { Typography } from "@mui/material"
import classes from "./styles"


interface Props{
    children: React.ReactNode,
    noMargin?:boolean
}

export default function SectionTitle({children, noMargin}:Props){


    return(
        <Typography sx={[classes.sectionTitle, {marginBottom: noMargin ? 0 : 2}]}>
            {children}
        </Typography>
    )
}
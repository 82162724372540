import { BORDER_COLOR, HOVER_COLOR } from "utils/constants"



const classes = {
    paper: {
        backgroundColor:"white", 
        border:`solid 1px ${BORDER_COLOR}`, 
        marginBottom: 2, 
        padding: {xs: 1.5, sm: 3}, 
        paddingTop: 2, 
        paddingBottom: 2, 
        display:"flex", 
        flex: 1,
        flexDirection: "row", 
        alignItems:"center",
        justifyContent: "space-between",

        
    },


    clickable : {
        "&:hover" : {
            backgroundColor: HOVER_COLOR
        },
        cursor: "pointer"
    },
}


export default classes
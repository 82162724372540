import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import Loader from "components/molecules/Loader";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { EventCard } from "components/molecules/EventCard";
import { HistoryEventDetails } from "interfaces/User";
import { addDays } from "date-fns";
import { getClientHistory } from "api/clients";
import EmptySearch from "components/molecules/EmptySearch";


export default function ClientHistory(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.clients.profile)
    const pending = useSelector((state:RootState)=> state.clients.requests.getClientHistory === "pending")
    const history:HistoryEventDetails[] = useSelector((state:RootState) => state.clients.clientHistory)
    const [filter, setFilter] = useState<string>('3')
    const [range, setRange] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})


    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setFilter(event.target.value as string)
    }


    /********************************
     * Récupération de l'historique
     ********************************/

    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(filter)+1))
        if(user?._id){
            dispatch(getClientHistory({
                user, 
                start: startDate, 
                end: new Date()
            }))
        }
    },[filter, user])


    /********************** 
     * FILTRAGE DES EVENTS 
     **********************/

    const filteredEvents:HistoryEventDetails[] = useMemo(() => {
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })
        

        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })

        meals= history.filter((elem)=> {
            return elem.action === "meal"
        })
        

        const events = workouts?.concat(checkups).concat(measures).concat(meals)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        return sortedEvents


    },[history])



    return(
        <>
            <Grid 
                container 
                justifyContent="center" 
                spacing={0}
            >
                <Grid 
                    item xs={12} 
                    sm={12} 
                    md={12} 
                    lg={10} 
                    xl={8}
                >
                    {/********************* 
                    *        HEADER 
                    ***********************/}
                    
                    <Box 
                        sx={classes.feedHeader}
                    >

                        <Typography 
                            sx={classes.sectionTitle}
                        >
                            Dernières activitées
                        </Typography>

                        <Select 
                            value={filter}
                            size="small"
                            onChange={handleChangeFilter}
                            MenuProps={{
                                disableScrollLock: true
                            }}
                        >
                            <MenuItem 
                                value={3}>
                                    3 derniers jours
                            </MenuItem>
                            <MenuItem 
                                value={7}>
                                    7 derniers jours
                            </MenuItem>
                            <MenuItem 
                                value={15}>
                                    15 derniers jours
                            </MenuItem>
                        </Select>

                    </Box>

                    {/********************* 
                    *        LOADER 
                    ***********************/}

                    {pending && (
                        <Loader />
                    )}


                    {/***********************
                     * LISTE DES EVENNEMENTS 
                     ***********************/}

                    {!pending && filteredEvents?.map((event, historyIndex:number) => {
                        return(
                            <Box 
                                key={historyIndex} 
                                sx={classes.eventContainer}
                            >
                                <Box 
                                    sx={classes.eventsTimeline}
                                />
                                <EventCard 
                                    event={event}
                                />
                            </Box>
                        )
                    })}


                    {/***** AUCUN EVENEMENT ***** */}
                    {!pending && filteredEvents?.length ===0 && (
                        <EmptySearch
                            description={`Il semblerait que ton client n'ait aucune activité sur les ${filter} derniers jours`}
                        />
                                    
                    )}

                </Grid>
            </Grid>

        </>
    )
}
import { Dialog, Box, TextField, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";
import { createCustomExercice } from "api/exercices";
import { AppDispatch, RootState } from "app/store";
import Exercice from "interfaces/Exercice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import {Editor, EditorState, convertFromRaw, convertToRaw} from 'draft-js';


/********* INTERFACES ******** */

interface Props{
    open: boolean,
    onClose: ()=> void
}


/********* MAIN COMPONENT ******** */

export  default function DialogCreateExercice({open,onClose}:Props){

    let navigate = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const pending:boolean = useSelector((state:RootState) => state.exercices.requests.create === "pending")
    const [name, setName] = useState<string>("")


    /********** Création d'un nouvel exercice ********** */
    const onCreateNewExercice = async () => {

        const newDescription = { editorState: EditorState.createEmpty() }

        const newExercice:Exercice = {
            name:{
                fr:name,
                en: ""
            },
            description: {
                fr: JSON.stringify(convertToRaw(newDescription.editorState.getCurrentContent())),
                en: JSON.stringify(convertToRaw(newDescription.editorState.getCurrentContent())),
            },
            groups: [],
            type: {
                _id: "63e2583d4634b611780b1f9a",
                name:{
                    fr: "Musculation",
                    en: "Weight lifting"
                }
            },
            draft: true,
            appExercice: false,
            image: null,
            video:null
            
        }

        await dispatch(createCustomExercice({exercice:newExercice})).unwrap().then((res: any) => {
            if (res?.exercice) {
                navigate.push(`/exercices/${res.exercice._id}`)
            }
        })
    }

    /*********** JSX ********* */

    return(

    <Dialog 
        open={open} 
        fullWidth maxWidth={"xs"} 
        disableScrollLock={true}
    >
        <DialogTitle>
            Créer un nouvel exercice
        </DialogTitle>
        <DialogContent>
            <TextField 
                label='Nom de cet exercice'
                onChange={(e)=> setName(e.target.value)}
                value={name}
                fullWidth
                sx={{marginTop: 1}}
                disabled={pending}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={pending}>
                Annuler
            </Button>
            <LoadingButton onClick={onCreateNewExercice} loading={pending} disabled={pending}>
                Créer
            </LoadingButton>
        </DialogActions>

    </Dialog>
    )
}
import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import classes from "./styles";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { MOBILE, TABLET, DESKTOP, MACROS_COLORS} from "utils/constants";


const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 16,
    fontWeight: 600
  }));


function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }


interface Props{
    data:{
        value: number,
        label: string,
    }[],
    smaller?: boolean
}


const calcKcal = (data:{value:number, label: string}[]) => {
  var total = 0
  data.forEach((elem,index) => {
    if(index===0){ // Proteins
      total = total + elem.value * 4
    } else if(index===1){ // Lipids
      total = total + elem.value * 9
    } else if(index===2){ 
      total = total + elem.value * 4
    }
  })

  return Math.round(total)
}


export function MacroPieChart({data, smaller}:Props){

  const statsRef = useRef(null)
  const mobile = useMediaQuery(MOBILE)
  const desktop = useMediaQuery(DESKTOP)
  const tablet = useMediaQuery(TABLET)
  const [size, setSize] = useState<any>([0, 0])
  
 

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const WIDTH = useMemo(()=>{
    return statsRef.current?.getBoundingClientRect().width
  },[size])


  /*************
   * CALCUL KCAL
   **************/

  const KCAL = useMemo(()=>{
    if(data){
      var total = calcKcal(data)

      return Math.round(total)
    }else {
      return 0
    }
  },[JSON.stringify(data)])


  /*************
   * CHART DATA
   **************/

  const DATA = useMemo(()=>{

    var kcal = calcKcal(data)

    const chartData = [...data].splice(0,3)
    const proteins = chartData[0].value ? (chartData[0].value *4) / kcal * 100 : 0
    const lipids = chartData[1].value? (chartData[1].value * 9) / kcal * 100 : 0
    const carbs = chartData[2].value? (chartData[2].value * 4)/ kcal * 100 : 0
    

    const formatedData = [
      { value: parseFloat(proteins.toFixed(1)), label: `Protéines - ${data[0].value}g`, formattedValue: "test"},
      { value: parseFloat(lipids.toFixed(1)), label: `Lipides - ${data[1].value}g`, formattedValue: "test"},
      { value: parseFloat(carbs.toFixed(1)), label: `Glucides - ${data[2].value}g`, formattedValue: "test"},
    ]
    
    return formatedData


  },[data])

  const FIBERS = useMemo(()=>{
    if(data){
      const fibers =  [...data].splice(3,4)
      return fibers
    } else {
      return 0
    }
  },[data])



    return(

      <Box
        sx={classes.stats}
        ref={statsRef}
      >
        <Typography
            sx={classes.statsTitle}
        >
          Macronutriments
        </Typography>
        <Box
          sx={classes.chartContainer}>
              <PieChart
                margin={{
                  right: 0
                }}
                slotProps={{
                  legend: { 
                    hidden: true,
                    padding: 0,
                    markGap: 0,labelStyle: {
                      display: "none"
                    },
                    "position": {
                      "vertical" : "bottom",
                      "horizontal" : "middle"
                      
                    },
                    
                  },
                  
                }}
                series={[
                {
                    arcLabel: (item) => item.value > 0 ? `${data[item.index].value}g` : "",
                    paddingAngle: 5,
                    innerRadius: mobile? 40:65,
                    data: DATA,
                },
                ]}
                
                height={smaller? 250 : 300}
                width={WIDTH - 48}
                  
    
        >
            <PieCenterLabel>
              {KCAL} kcal
            </PieCenterLabel>
              </PieChart>
              <Box sx={classes.macros}>
                <Box 
                  sx={classes.macroLine}
                >
                    <Box
                      sx={classes.macro}
                    >
                      <Box 
                        sx={[classes.macroColor, {backgroundColor: MACROS_COLORS.proteins}]}/>
                      <Typography
                            sx={classes.macroValue}
                          >
                          Protéines : {data && data[0]?.value}g
                      </Typography>
                    </Box>
                    <Box
                      sx={[classes.macro, {marginRight: 0}]}>
                      <Box 
                        sx={[
                          classes.macroColor, 
                          {backgroundColor: MACROS_COLORS.lipids}
                        ]}
                      />
                        <Typography
                              sx={classes.macroValue}
                            >
                            Lipides : {data && data[1]?.value}g 
                        </Typography>
                    </Box>
                </Box>

                {/**********************
                 * GLUCIDES + FIBRES
                 ***********************/}

                <Box 
                  sx={classes.macroLine}
                >
                    <Box
                      sx={[classes.macro, {marginRight: 0}]}>
                        <Box 
                          sx={[
                            classes.macroColor, 
                            {backgroundColor: MACROS_COLORS.carbs}
                          ]}/>
                          <Typography
                            sx={classes.macroValue}
                          >
                              Glucides : {data && data[2]?.value}g
                          </Typography>
                        </Box>
                    {/*
                    <Box
                      sx={classes.macro}
                    >
                      <Box 
                        sx={[
                          classes.macroColor, 
                          {backgroundColor: MACROS_COLORS.fibers}
                        ]}/>
                          <Typography
                            sx={classes.macroValue}
                          >
                            Fibres : {FIBERS && FIBERS[0]?.value}g
                          </Typography>
                    </Box>
                    */}
                </Box>
              </Box>

        
      </Box>
  </Box>


        
    )
}

function areEqual(prev:Props,next:Props){
  return JSON.stringify(prev.data) === JSON.stringify(next.data)
}

export default React.memo(MacroPieChart, areEqual)
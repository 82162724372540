import { Box, Avatar, Typography, IconButton, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import User from "interfaces/User"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "components/molecules/Tabs"
import classes from "./styles"
import { differenceInCalendarYears } from "date-fns"
import DialogAvatar from "../DialogAvatar"
import DialogDeleteAvatar from "components/molecules/DialogDeleteAvatar/intdex"
import { Delete, Edit } from "@mui/icons-material"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import { toast } from "react-toastify"
import { logout } from "api/user"
import LogoutIcon from '@mui/icons-material/Logout';
import { MOBILE } from "utils/constants"

interface Props{
    data: User,
    tabs?: number,
    defineTabs?: (index:number)=> void,
    hideTabs?:boolean,
    userProfile?: boolean,
    showDisconnect?: boolean
}


const tabsLabel = ["Historique","Statistiques",  "Plannification", "Plan alimentaire"]

const tradGender = (value:"male"|"female") => {
    switch(value) {
        case "male" : return "Homme";
        case "female" : return "Femme";
    }
}


export default function ProfileHeader({data, tabs, defineTabs, hideTabs, userProfile, showDisconnect = false}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const currentUser = useSelector((state:RootState) => state.user.data)
    const [openAvatar, setOpenAvatar] = useState<boolean>(false)
    const [openDeleteAvatar, setOpenDeleteAvatar] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    /****** LOGOUT ******* */

    const onLogout = () => {
        dispatch(logout())
        toast.success('Vous êtes déconnecté')
    }
    

    return(

        <React.Fragment>
            <Box sx={classes.header}>
                <Box 
                    sx={[classes.userData, !hideTabs && {paddingBottom: 0.5, paddingTop: 2}]}
                >
                    <Box sx={{flexDirection: "row",  display:"flex", flexGrow: 1}}>

                        {/******* AVATAR ******* */}
                        <Box sx={classes.avatarContainer}>
                            <Avatar 
                                src={data?.avatar?.url ? data.avatar.url : "SC"} 
                                sx={[classes.avatar, (userProfile && !currentUser.avatar?.url ) && classes.clickableAvatar]} 
                            />
                            {(userProfile && data.avatar?.cloudFlareId) && (
                                <IconButton sx={classes.deleteAvatarButton} onClick={()=>setOpenDeleteAvatar(true)}>
                                    <Delete/>
                                </IconButton>
                            )}

                            {(userProfile && !data.avatar?.cloudFlareId) && (
                                <IconButton sx={classes.deleteAvatarButton} onClick={()=>setOpenAvatar(true)}>
                                    <Edit/>
                                </IconButton>
                            )}

                        </Box>
                            

                        {/******* Infos utilisateur ******* */}
                        <Box sx={classes.infos}>
                            <Typography sx={classes.userName}>
                                {data?.firstname} {data?.lastname}
                            </Typography>

                            <Box sx={{ display: "flex", flexDirection: "row" }}>

                                {(!userProfile && !mobile) && (
                                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                                        {tradGender(data?.gender)} - 
                                    </Typography>
                                )}

                                {(data?.birthday && !userProfile && !mobile) && (
                                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                                        {differenceInCalendarYears(new Date(), new Date(data.birthday))} ans - 
                                    </Typography>
                                )}

                                {(data.size && !userProfile && !mobile) && (
                                <Typography variant="body2" sx={{ marginRight: 1 }}> 
                                    {data?.size} {(data && data?.size) && "cm"} -
                                </Typography>
                                )}

                                <Typography variant="body2" style={classes.email}>
                                    {data?.role === "demo" ? "demo@traener.com" : data?.email}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    
                    {showDisconnect && (
                        <SecondaryButton onClick={onLogout} endIcon={<LogoutIcon />}>
                            Déconnexion
                        </SecondaryButton>
                    )}
                </Box>

                



                {/******** TABS *********** */}
                
                {!hideTabs && (
                    <Tabs
                        value={tabs}
                        onChange={(e,value)=>defineTabs(value)}
                        tabs={tabsLabel}
                    />
                )}
            
        
            </Box>


            <DialogAvatar
                open={openAvatar}
                onClose={()=> setOpenAvatar(false)}
            />


            <DialogDeleteAvatar
                open={openDeleteAvatar}
                onClose={()=> setOpenDeleteAvatar(false)}
            />
        </React.Fragment>
    )
}
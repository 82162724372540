import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import TitleTextField from "components/molecules/TitleTextField";
import { useEffect, useState } from "react";
import classes from "./styles";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { toast } from "react-toastify";
import Food, { FoodPortion } from "interfaces/Food";
import { createCustomFood } from "api/food";
import { Delete } from "@mui/icons-material";
import { MOBILE } from "utils/constants";

interface Props{
    open: boolean,
    onClose: ()=>void,
    originalFood: Food
}




export default function DialogSimplifiedFood({open, onClose, originalFood}:Props){

    const [name, setName] = useState<string>('')
    const [customPortions, setCustomPortions] = useState<FoodPortion[]>([])
    const pending = useSelector((state:RootState) => state.food.requests.create === "pending")
    const dispatch = useDispatch<AppDispatch>()
    const [label, setLabel] = useState<string>("")
    const [equivalent, setEquivalent] = useState<number>(0)
    const mobile = useMediaQuery(MOBILE)

    const onDeleteMeasure = (index:number) => ()=> {
        const portions = [...customPortions]
        portions.slice(index,1)
        setCustomPortions(portions)
    }

    useEffect(()=>{
        if(!open && name !== ""){
            setTimeout(()=>{
                setName("")
                setCustomPortions([])
            },200)
            
        }
    },[open])


    useEffect(()=>{
        setLabel("")
        setEquivalent(0)
    },[customPortions.length])


    const onSubmit = () => {
        if(name === ""){
            toast.error('Tu dois nommer ton aliment')
            return
        }


        const newFood:Food = {
            ...originalFood,
            _id: undefined,
            name: {
                fr: name,
                en: ""
            },
            createdByApp: true,

        }

        if(customPortions){
            newFood.portions = customPortions
            dispatch(createCustomFood(newFood)).unwrap().then((res)=>{
                if(res.food){
                    toast.success('Aliment créé')
                    onClose()
                }
                
            })
        }

        
    }


    const onAddMeasure = () => {
        if(label === ""){
            toast.error("Tu n'as pas complété le nom de ton label")
            return
        }

        if(equivalent === 0){
            toast.error("L'équivalence en grammes ne peut pas être égal à zéro")
            return
        }

        var newMeasures = [...customPortions]
        newMeasures.push({
            label:{
                fr:label,
                en:""
            },
            equivalent: {
                portion: 1,
                value: equivalent
            }
        })
        setCustomPortions(newMeasures)
    }

    return(
        <Dialog
            fullWidth
            maxWidth={"sm"}
            open={open}
            disableScrollLock={true}
            fullScreen={mobile}
        >
            <DialogTitle>
                Simplifier un aliment
            </DialogTitle>
            <DialogContent>

                <Typography
                    style={classes.variantName}
                >
                    {originalFood.name.fr}
                </Typography>
                <TitleTextField
                    value={name}
                    placeholder="Nom simplifié"
                    variant="standard"
                    sx={{width: "100%"}}
                    onChange={(e)=> setName(e.target.value)}
                />


                {/*********************
                 * MESURES CUSTOM
                 *********************/}

                 <Typography
                    variant="h6"
                    sx={{marginTop: 3}}
                 >
                    Portions personnalisées
                 </Typography>

                <Typography
                    sx={{marginTop: 1}}
                >
                    Tu peux ajouter des portions personnalisées pour cet aliment. Indique le label de ta portion et son équivalence en grammes. <strong>Attention : une fois ton aliment créé, tu ne pourras plus le modifier. </strong>
                </Typography>

                <Box
                    sx={classes.measures}
                >
                   <Box
                        sx={classes.labelForm}
                   >
                        <TextField
                            value={label}
                            label="Label"
                            placeholder="Ex: Gros, Tartine, Tranche ..."
                            sx={{maxWidth: 260, minWidth: 260}}
                            variant="standard"
                            onChange={(e)=> setLabel(e.target.value)}

                        />
                        <Typography
                            sx={classes.equivalent}
                        >
                            =
                        </Typography>
                        <TextField
                            value={equivalent}
                            label="Grammes"
                            placeholder=""
                            sx={{maxWidth: 130}}
                            type="number"
                            variant="standard"
                            onChange={(e)=> setEquivalent(parseInt(e.target.value))}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                            }}

                        />
                    </Box>
                    <Box>
                        <Button
                            onClick={onAddMeasure}
                        >
                            Ajouter
                        </Button>
                    </Box>
                </Box>

                <Box
                    sx={classes.measuresList}
                >
                    {customPortions.map((elem,index)=>{
                        return(
                            <Box
                                key={index}
                                sx={classes.measureItem}
                            >
                                <Box
                                    sx={classes.labelForm}
                                >
                                    <TextField
                                        value={elem.label.fr}
                                        disabled={true}
                                        variant="standard"
                                        sx={{maxWidth: 260, minWidth: 260}}
                                    />
                                    <Typography
                                        sx={classes.equivalent}
                                    >
                                        =
                                    </Typography>
                                    <TextField
                                        value={elem.equivalent.value}
                                        disabled={true}
                                        variant="standard"
                                        sx={{maxWidth: 130}}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={onDeleteMeasure(index)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Box>

                                
                                   

                            </Box>
                        )
                    })}

                </Box>

            </DialogContent>
            <DialogActions>
                <Button
                    disabled={pending}
                    onClick={onClose}
                >
                    Fermer
                </Button>
                <LoadingButton
                    loading={pending}
                    disabled={pending}
                    onClick={onSubmit}
                >
                    Simplifier un aliment
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
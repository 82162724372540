import { Block, Check, Delete, Edit } from "@mui/icons-material"
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography, darken, useMediaQuery } from "@mui/material"
import Program from "interfaces/Program"
import React, {useMemo, useState } from "react"
import classes from "./styles"
import DialogDeleteProgram from "components/molecules/DialogDeleteProgram"
import DialogActivateWorkouts from "components/molecules/DialogActivateWorkouts"
import DialogUpdateProgram from "../DialogUpdateProgram"
import DialogDeleteProgramWorkouts from "../DialogDeleteProgramWorkouts"
import { MOBILE, TABLET } from "utils/constants"


interface Props{
    program: Program,
    left: string,
    width: string,
    borderRadius: string,
}

export default function PlanningProgram({
    program, 
    left, 
    width, 
    borderRadius,
}:Props){
    console.log('LEFT', left)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openProgramSettings, setOpenProgramSettings] = useState<boolean>(false)
    const [openDeleteProgram, setOpenDeleteProgram] = useState<boolean>(false)
    const [openActivateWorkouts, setOpenActivateWorkouts] = useState<boolean>(false)
    const [action,setAction] = useState<"enable" | "disable">("enable")
    const [openDeleteWorkouts, setOpenDeleteWorkouts] = useState<boolean>()    
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)

     /******** GESTION DU MENU ******* */
     const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }


    const onOpenProgramSettings = () => {
        handleCloseMenu()
        setOpenProgramSettings(true)
    }


    const onOpenDeleteProgramDIalog = () => {
        setOpenDeleteProgram(true)
        handleCloseMenu()
    }

    const onOpenHandleeWorkoutsStatus = (toDo:"enable"|"disable") => ()=>{
        setAction(toDo)
        setOpenActivateWorkouts(true)
        handleCloseMenu()
    }

    const dateRange = useMemo(()=>{
        return(
            {
                start: program.startDate,
                end: program.endDate
            }
        )
    },[program])

    const onDeleteWorkouts = () => {
        setOpenDeleteWorkouts(true)
        handleCloseMenu()
    }


    return(
        <React.Fragment>
       
            <Box sx={[
                classes.program, 
                {
                    backgroundColor: program.color, 
                    marginLeft: left, 
                    width, 
                    borderRadius,
                }
            ]} 
                onClick={openMenu}>
                    <Typography
                        sx={[
                            classes.programName,
                            {
                                color: darken(program.color, 0.7)
                            }
                        ]}
                    >
                        {program.name}

                    </Typography>
                    
            </Box>
    

            <Menu disableScrollLock={true} 
                id="program-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >

                <Typography sx={{ml: 3, mt:2, mb:1, color: "#83b4eb", fontSize: "1rem", fontWeight: 700}}>
                    Programme
                </Typography>
                <MenuItem onClick={onOpenProgramSettings}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>
                        Modifier les infos
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onOpenDeleteProgramDIalog}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer le programme
                    </ListItemText>
                </MenuItem>
                <Typography sx={{ml: 3, mt:2, mb:1, color: "#83b4eb", fontSize: "1rem", fontWeight: 700}}>
                    Séances du programme
                </Typography>
                <MenuItem onClick={onOpenHandleeWorkoutsStatus("enable")}>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText>
                        Activer les séances
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onOpenHandleeWorkoutsStatus('disable')}>
                    <ListItemIcon>
                        <Block />
                    </ListItemIcon>
                    <ListItemText>
                        Désactiver les séances
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={onDeleteWorkouts}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>
                        Supprimer les séances
                    </ListItemText>
                </MenuItem>
            </Menu>

            <DialogDeleteProgram
                open={openDeleteProgram}
                onClose={()=>setOpenDeleteProgram(false)}
                program={program}
            />

            <DialogActivateWorkouts
                open={openActivateWorkouts}
                onClose={()=> setOpenActivateWorkouts(false)}
                dateRange={dateRange}
                action={action}
            />

            <DialogUpdateProgram
                open={openProgramSettings}
                onClose={()=> setOpenProgramSettings(false)}
                program={program}
            />

            <DialogDeleteProgramWorkouts
                open={openDeleteWorkouts}
                onClose={()=>setOpenDeleteWorkouts(false)}
                program={program}
            />
        </React.Fragment>
    )
}




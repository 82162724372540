import { theme } from "utils/theme"

const classes = {

    container : {
        padding: 6, 
        height: "100%", 
        width: "100%", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        flexDirection: "column", 
        paddingBottom: 10, 
        position: "relative",
        maxWidth: 720
    },

    title: {
        fontSize: theme.typography.pxToRem(30), 
        fontWeight: 600, 
        marginBottom: 0.5
    }

}


export default classes
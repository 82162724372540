import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import { useState } from "react";
import { addDays, differenceInDays, format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";

import Program from "interfaces/Program";
import { checkProgramPlanningDate, createNewProgram, importProgram } from "api/programs";
import { toast } from "react-toastify";
import Workout from "interfaces/Workout";
import { getWorkouts, pasteMultiplesWorkouts } from "api/workouts";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { createSelector } from "@reduxjs/toolkit";
import removePerformedSets from "function/removePerformedSets";
import TemplatePrograms from "../TemplatesPrograms";
import { BORDER_COLOR, MOBILE, TABLET } from "utils/constants";
import { Close } from "@mui/icons-material";


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    date: Date,

}


const pendingSelector = createSelector(
    (state:RootState)=> state.planning.requests,
    (requests) => {
        return requests.importProgram === "pending" || requests.pasteMultipleWorkouts === "pending"
    }
)


export default function DialogImportProgram({ open, onClose, date}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const user:string = useSelector((state:RootState) => state.planning.user?._id) // Planning user le cas échéant
    const programs = useSelector((state:RootState)=> state.programs.templates.filtered)
    const [programSelected, setProgramSelected] = useState<Program>(programs[0])
    const pending = useSelector(pendingSelector)
    const pendingWorkouts = useSelector((state:RootState)=> state.workouts.requests.deleteMultiple === "pending")
    const mobile = useMediaQuery(MOBILE)
    const tablet = useMediaQuery(TABLET)
    const desktop = !mobile && !tablet

    const onSelectProgram = (program:Program)=>{
        setProgramSelected(program)
    }

    // LORS DE L'IMPORT DU PROGRAMME

    const onImport = () => {

        const duration:number = differenceInDays(new Date(programSelected.endDate), new Date(programSelected.startDate)) + 1
        const startDate:Date = date
        const endDate:Date = addDays(startDate, duration - 1)

        dispatch(checkProgramPlanningDate({start: startDate, end: endDate, createdFor: user })).unwrap().then((res:any)=>{
            if(res.programs?.length>0){
                toast.error('Un autre programme est compris dans cette plage de date')
                return 
            } else {
                // Récupération des séances du planning sur la plage de date

                dispatch(getWorkouts({
                    start: addDays(startDate,-1).toString(),  // Anomaly
                    end: endDate.toString(), 
                    createdFor: user, 
                })).unwrap().then((res)=>{
                    
                    const planningWorkouts = res.workouts

                    /************ RECUPERATION DES DONNEES DU PROGRAMME ********* */

                    dispatch(importProgram(programSelected._id)).unwrap().then((res:any)=>{

                        const {workouts} = res

                        /******* PROGRAMME ***** */
                        const newProgram:Program = {
                            _id: undefined,
                            name: programSelected.name,
                            startDate: startDate.toDateString(),
                            endDate: endDate.toDateString(),
                            template: false,
                            createdFor: user,
                            color: programSelected.color,
                            description: programSelected.description
                        }

                        /******* WORKOUTS ***** */ 

                        // ON parcourt tous les workouts pour remplacer les dates avec une nouvelle liste
                        const newWorkouts:Workout[] = []
                        workouts.map((workout:Workout)=>{

                            
                            // On vérifie s'il existe déja une séance, si non, on ajoute la séance
                            const differenceWithTemplateStart = differenceInDays(new Date(workout.schedule), new Date(programSelected.startDate))
                            const schedule = addDays(startDate, differenceWithTemplateStart).toDateString()
                            const thereIsAWorkoutInPlanning = planningWorkouts.find((elem)=> isSameDay(new Date(elem.schedule), new Date(schedule)))
                        
                            if(!Boolean(thereIsAWorkoutInPlanning)){
                        

                                newWorkouts.push({
                                    ...removePerformedSets(workout),
                                    _id: undefined,
                                    schedule,
                                    status: "disabled",
                                    template: false,
                                    programTemplate: undefined,
                                    createdFor: user,
                                    performed: null
                                })
                            }
                        })


                        // Enregistrement en BDD
                        dispatch(pasteMultiplesWorkouts({workouts: newWorkouts}))
                        dispatch(createNewProgram({program:newProgram}))
                        toast.success(`Programme importé du ${format(new Date(newProgram.startDate), "dd MMM",{locale: fr})} au ${format(new Date(newProgram.endDate), "dd MMM", {locale: fr})}`)
                        onClose()
                            
                        
                    })
                })
            }
        })

        
    }

    return(
    <Dialog 
        open={open} 
        fullWidth 
        maxWidth={"md"}
        fullScreen={mobile || tablet}
    >
        <DialogTitle>
            {mobile && (
                <IconButton sx={{marginRight: 1.5}} onClick={onClose}>
                    <Close sx={{color: "rgba(0,0,0,0.85)"}}/>
                </IconButton>
            )}
            {!mobile && (
                <span>Importer un programme au {format(new Date(date), "dd MMM yyyy", {locale: fr})}</span>
            )}

            {mobile && (
                <span>Importer un programme</span>
            )}
        </DialogTitle>

        <DialogContent sx={classes.content}>
            {/********** LISTE DES TEMPLATES DE PROGRAMME ******* */}
            <Box sx={classes.list}>
                <TemplatePrograms 
                    fromImport={true}
                    onSelectProgram={onSelectProgram}
                    programSelectedForImport={programSelected}
                />
            </Box>
        </DialogContent>




            <DialogActions sx={{borderTop: desktop ? "solid 1px #e1e1e1" : "none"}}>
                {desktop && (
                    <Button onClick={onClose} disabled={pending || pendingWorkouts}>
                        Fermer
                    </Button>
                )}
                <LoadingButton 
                    variant="contained" 
                    onClick={onImport}
                    disabled={pending || pendingWorkouts}
                    loading={pending || pendingWorkouts}
                >
                    Importer le programme
                </LoadingButton>
            </DialogActions>
        
    </Dialog>
    )
}
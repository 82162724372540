import { createSlice, current } from '@reduxjs/toolkit'
import Exercice, { ExerciceType } from "interfaces/Exercice"
import { toast } from 'react-toastify'
import fuseSearch from 'function/fuseSearch'
// API
import {
  getExercices, 
  deleteExercice, 
  getExercice,
  createCustomExercice,
  uploadExerciceImage,
  deleteExerciceImage,
  autoSaveExercice,
  manualSaveExercice,
} from "api/exercices"




const initBuilder:Exercice ={
  _id: undefined,
  name: {
    fr: null,
    en: null
  },
  description:{
    fr: "",
    en:"",
  },
  draft: true,
  appExercice: false,
  groups: [],
  type: {
    name : {
      fr: "Musculation",
      en: "Weight lifting"
    },
    _id: "63e2583d4634b611780b1f9a", // Musculation
  },
  //equipments: [],
  image: null,
  video: null,
}

interface ExercicesFilters{
  app: Exercice[],
  custom: Exercice[],
}


/************** INIT ****************/

interface Exercices {
  list: ExercicesFilters,
  filtered: ExercicesFilters,
  builder: Exercice,
  types: ExerciceType[]
  requests: {
    create: "pending" | "idle",
    update: "pending" | "idle",
    get: "pending" | "idle",
    delete: "pending" | "idle",
    duplicate: "pending" | "idle",
    manualSave: "pending" | "idle",
    uploadImage:"pending" | "idle",
    deleteImage:"pending" | "idle",
    autoSave: "pending" | "idle"
  },
  groupsFilter:string[],
  typesFilter: string[],
  search: string,
  hasUnsavedChanges: boolean,
  orderOptions: string[],
  order: "alphabetical" | "reverseAlphabetical"
}


const initialState:Exercices = {
  list: {
    app: [],
    custom: [],
  },
  filtered: {
    app: [], 
    custom: [],
  },
  builder: null,
  groupsFilter:[],
  typesFilter: [],
  types: [],
  requests: {
    create: "idle",
    update: "idle",
    get: "idle",
    delete: "idle",
    duplicate: "idle",
    manualSave: "idle",
    uploadImage: "idle",
    deleteImage:"idle",
    autoSave: "idle"
  },
  search: "",
  hasUnsavedChanges: false,
  orderOptions: ["alphabetical","reverseAlphabetical"],
  order: "alphabetical"


 
}




/************** Slice ****************/

export const exercicesSlice = createSlice({
  name: 'exercices',
  initialState,
  reducers: {

   

    // Modification d'un exercice custom
    updateCustomExercice:(state,action)=>{
      const exercice: Exercice = action.payload.exercice
      const index = state.list.custom.findIndex((elem: Exercice) => elem._id === exercice._id)
      state.list.custom[index] = exercice
    },


    /***** BUILDER ***** */

    resetBuilder: (state) => {
      state.builder = initBuilder
    },

    handleExerciceNameFr: (state, action:{payload:{name:string}}) => {
      const name:string = action.payload.name
      state.builder.name.fr = name
    },

    handleExerciceType: (state,action:{payload:{type:ExerciceType}}) => {
      const type:ExerciceType = action.payload.type
      state.builder.type = type
    },

    handleExerciceGroup: (state,action:{payload:{group:string}}) => {
      
      const group = action.payload.group
      const currentGroups:string[] = state.builder.groups
      const foundGroupIndex:number = currentGroups.findIndex((elem)=> elem === group)

      if(foundGroupIndex !== -1){
        state.builder.groups.splice(foundGroupIndex,1)
      }else{
        state.builder.groups.push(group)
      }
    },


    addVideoToExercice : (state,action:{payload:{url:string,platform:string}}) => {
      const url:string = action.payload.url
      const platform:string = action.payload.platform
      state.builder.video = {url,platform}
    },

    removeExerciceVideo: (state) => {
      state.builder.video = null
    },


    addImageToExercice : (state,action:{payload:{image:{url:string}}}) => {
      const image:{url:string} = action.payload.image
      state.builder.image = image ? image : null
    },

    removeExerciceImage : (state) => {
      state.builder.image = null
    },


    isSavingExercice : (state)=>{
      state.requests.autoSave = "pending"
    },

    exerciceHasUnsavedChanges: (state) =>{
      state.hasUnsavedChanges = true
    },



    /***** SEARCH ***** */
    searchExercices: (state, action) => {
      const search:string = action.payload.search
      const groupsFilters = state.groupsFilter
      const typesFilter = state.typesFilter

      const keys:string[]= [
        "name.fr",
        "name.en",
        "primaryGroups.name",
      ]

      const filteredLists = {
        app: current(state.list.app),
        custom: current(state.list.custom),
      }


      // Groupe et types

      if(groupsFilters.length>0 && typesFilter.length>0){
        filteredLists.app = [...state.list.app].filter((elem)=> {
          return elem.groups.some((group)=> state.groupsFilter.includes(group)) && state.typesFilter.includes(elem.type._id)
        })

        filteredLists.custom = [...state.list.custom].filter((elem)=> {
          return elem.groups.some((group)=> state.groupsFilter.includes(group)) && state.typesFilter.includes(elem.type._id)
        })
      }

      // Groupe uniquement
      else if(groupsFilters.length>0 && typesFilter.length===0){
        filteredLists.app = [...state.list.app].filter((elem)=> elem.groups.some((group)=> state.groupsFilter.includes(group) ))
        filteredLists.custom = [...state.list.custom].filter((elem)=> elem.groups.some((group)=> state.groupsFilter.includes(group)))
      }

      // Type uniquement
      else if(groupsFilters.length === 0 && typesFilter.length>0){
        filteredLists.app = [...state.list.app].filter((elem)=> {
          return state.typesFilter.includes(elem.type._id)
        })

        filteredLists.custom = [...state.list.custom].filter((elem)=> {
          return state.typesFilter.includes(elem.type._id)
        })
      }
    
      // App exos
      state.filtered = {
        app: search !== "" ? fuseSearch(filteredLists.app, search, keys) : filteredLists.app,
        custom: search !== "" ? fuseSearch(filteredLists.custom, search, keys) : filteredLists.custom,
      }
  
    },

    addFilterGroup : (state,action) => {
      const group = action.payload.group
      state.groupsFilter.push(group)
    },

    removeFilterGroup : (state,action) => {
      const group = action.payload.group
      const index = state.groupsFilter.findIndex((elem)=> group===elem)
      state.groupsFilter.splice(index,1)
    },


    addFilterType : (state,action) => {
      const type = action.payload.type
      state.typesFilter.push(type)
    },

    removeFilterType : (state,action) => {
      const type = action.payload.type
      const index = state.typesFilter.findIndex((elem)=> type===elem)
      state.typesFilter.splice(index,1)
    },

    clearExerciceFilter:  (state)=> {
      state.groupsFilter = []
      state.typesFilter=[]
    },

    // Reset de la recherche
    resetSearchExercices: (state) => {
        state.filtered = {
          app:state.list.app,
          custom: state.list.custom,
        }
    },

    onSelectExerciceOrder : (state, action:{payload:{order: "alphabetical" | "reverseAlphabetical"}}) => {
      state.order = action.payload.order
    },
  },



  extraReducers(builder) {
    builder

      // RECUPERATION DES EXERCICES et des types
      .addCase(getExercices.fulfilled, (state, action) => {
        const exercices:ExercicesFilters = action.payload.exercices
        const types:ExerciceType[] = action.payload.types
        if (exercices && types) {
          state.list = exercices
          state.filtered = exercices
          state.types = [...types].sort((a,b) => a.createdAt > b.createdAt ? 1 : a.createdAt === b.createdAt ? 0 : -1)
        } else{
          toast.error(action.payload.error)
        }
      })

      

      // RECUPERATION D'UN EXERCICE
      .addCase(getExercice.pending, (state) => {
        state.requests.get = "pending"
      })

      .addCase(getExercice.fulfilled, (state, action) => {
        state.requests.get = "idle"
        const exercice:Exercice = action.payload.exercice
        if (exercice) {
          state.builder = exercice
          state.hasUnsavedChanges = false
        } else{
          toast.error(action.payload.error)
        }
      })

      .addCase(getExercice.rejected, (state) => {
        state.requests.get = "idle"
        toast.error("Impossible de récupérer les donénes de cet exercice")
      })


      // CREATION D'UN EXERCICE CUSTOM
      .addCase(createCustomExercice.pending, (state) => {
        state.requests.create = "pending"
      })
      .addCase(createCustomExercice.fulfilled, (state,action) => {
        state.requests.create = "idle"
        const exercice:Exercice = action.payload.exercice
        if(exercice){
          state.builder = exercice
          const customList = [...current(state.list.custom)]
          customList.push(exercice)
          // Trie
          state.list.custom = customList.sort((a,b)=> {
            if(a.name.fr > b.name.fr){
              return 1
            } else if(a.name.fr < b.name.fr){
              return -1
            }else return 0
          })
            
        }
        else{
          toast.error(action.payload.error)
        }
      })

      .addCase(createCustomExercice.rejected, () => {
        toast.error("Erreur interne - Impossible de créer un nouvel exercice")
      })


      // SUPPRESSION D'UN EXERCICE
      .addCase(deleteExercice.pending, (state) => {
        state.requests.delete = "pending"
      })
      .addCase(deleteExercice.fulfilled, (state, action) => {
        state.requests.delete = "idle"
        const exercice:Exercice = action.payload.exercice
        if (exercice) {
          // Suppression d'un exercice custom
          const index = state.list.custom.findIndex((elem:Exercice) => elem._id === exercice._id)
          state.list.custom.splice(index, 1)
          toast.success(`Exercice supprimé`)
        } else {
          toast.error(action.payload.error)

        }
      })

      .addCase(deleteExercice.rejected, (state) => {
          state.requests.delete = "pending"
          toast.error("Erreur interne - Impossible de supprimer cet exercice")
      })


      // TELECHARGER UNE IMAGE
      .addCase(uploadExerciceImage.pending,(state)=>{
          state.requests.uploadImage = "pending"
      })

      .addCase(uploadExerciceImage.fulfilled,(state, action)=>{
        state.requests.uploadImage = "idle"

        if(action.payload.image){
          state.builder.image = action.payload.image
        } else {
          toast.error(action.payload.error)
        }

      })

      .addCase(uploadExerciceImage.rejected,(state)=>{
        state.requests.uploadImage = "idle"
        toast.error('Impossible de télécharger cette image')
      })


       // SUPPRIMER UNE IMAGE
      .addCase(deleteExerciceImage.pending,(state)=>{
        state.requests.deleteImage = "pending"
      })

      .addCase(deleteExerciceImage.fulfilled,(state, action)=>{
        state.requests.deleteImage = "idle"

        if(action.payload.image){
          state.builder.image = null
        } else {
          toast.error(action.payload.error)
        }

      })

      .addCase(deleteExerciceImage.rejected,(state)=>{
        state.requests.deleteImage = "idle"
        toast.error('Impossible de supprimer cette image')
      })


      // AUTOSAVE
      .addCase(autoSaveExercice.pending,(state)=>{
        state.requests.autoSave = "pending"
      })

      .addCase(autoSaveExercice.fulfilled,(state, action)=>{
        state.requests.autoSave = "idle"
        if(action.payload.exercice){
          const exerciceIndexFiltered:number = state.filtered.custom.findIndex((elem)=> elem._id === action.payload.exercice._id)
          state.filtered.custom[exerciceIndexFiltered] = action.payload.exercice
        }
      })

      .addCase(autoSaveExercice.rejected,(state)=>{
        state.requests.deleteImage = "idle"
        toast.error('La sauvegarde automatique a échouée')
      })


      // MANUAL SAVE
      .addCase(manualSaveExercice.pending,(state)=>{
        state.requests.manualSave = "pending"
      })

      .addCase(manualSaveExercice.fulfilled,(state, action)=>{
        state.requests.manualSave = "idle"
        if(action.payload.exercice){
          // ON vérifie si l'exercice est un brouillon
          //const exerciceIndexList:number = state.list.drafts.findIndex((elem)=> elem._id === action.payload.exercice._id)

          /*if(exerciceIndexList > -1){
            const exerciceIndexFiltered:number = state.filtered.drafts.findIndex((elem)=> elem._id === action.payload.exercice._id)
            // Suppression des brouillons
            state.list.drafts.splice(exerciceIndexList,1)
            state.filtered.drafts.splice(exerciceIndexFiltered,1)
            // Ajout dans les customs
            state.list.custom.push(action.payload.exercice)
            state.filtered.custom.push(action.payload.exercice)
            toast.success('Exercice ajouté à "Mes exercices')
            
          } else{*/
          const customFilteredIndex:number = state.filtered.custom.findIndex((elem)=> elem._id === action.payload.exercice._id)
          const customListIndex:number = state.list.custom.findIndex((elem)=> elem._id === action.payload.exercice._id)
          state.list.custom[customFilteredIndex] = action.payload.exercice
          state.filtered.custom[customListIndex] = action.payload.exercice
          toast.success('Modifications enregistrées')
          state.hasUnsavedChanges = false
          
        }
      })

      .addCase(manualSaveExercice.rejected,(state)=>{
        state.requests.manualSave = "idle"
        toast.error('La sauvegarde automatique a échouée')
      })
      



  }

})

export const { 
  resetBuilder,
  handleExerciceNameFr,
  handleExerciceType,
  handleExerciceGroup,
  addVideoToExercice,
  removeExerciceVideo,
  addImageToExercice,
  removeExerciceImage,
  searchExercices, 
  resetSearchExercices, 
  updateCustomExercice, 
  isSavingExercice,
  exerciceHasUnsavedChanges,
  onSelectExerciceOrder,
  addFilterGroup,
  removeFilterGroup,
  clearExerciceFilter,
  addFilterType,
  removeFilterType
} = exercicesSlice.actions

export default exercicesSlice.reducer
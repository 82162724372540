
import { Avatar, Box, Card, Typography, useMediaQuery } from "@mui/material";
import { format, isBefore, isSameDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { BODY_MEASURES, MOBILE } from "utils/constants";
import classes from "./styles"
import DialogChartMeasure from "components/organisms/DialogChartMeasure";
import DialogQuestionnaireResume from "components/organisms/DialogQuestionnaireResume";
import { HistoryEventDetails } from "interfaces/User";
import { fr } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { handleSubscriptionPresentation } from "features/appSlice";
import DialogWorkoutResults from "components/organisms/DialogWorkoutResults";
import calcTonnage from "function/calcTonnage";
import { theme } from "utils/theme";
import Icon from "components/atoms/Icon";
import calcMacros from "function/calcMacros";
import DialogMealConsumed from "components/organisms/DialogMealConsumed";


const EVENTS_COLOR:any = {
    "workout": theme.palette.primary.main,
    "measure": "#ffb57c",
    "questionnaire": "#3b1dd2",
    "meal" : "#0eafa0"
}


const ICON:any = {
    "workout": "dumbbell-filled",
    "measure": "balance",
    "questionnaire": "survey",
    "meal" : "meal"
}


//****** INTERFACES ******* */


interface Props{
    event: HistoryEventDetails,
}

//****** MAIN COMPONENT ******* */

export function EventCard({event}:Props) {

    const dispatch = useDispatch<AppDispatch>()
    const coach = useSelector((state:RootState) => state.user.data)
    const {user,action, date, type, questions, name} = event
    const [newEvent, setNewEvent] = useState<boolean>(false)


   
    //let navigate = useHistory();
    const [openStatsChart, setOpenStatsChart] = useState<boolean>(false)
    const [openQuestionnaire, setOpenQuestionnaire] = useState<boolean>(false)
    const [openWorkout, setOpenWorkout] = useState<boolean>(false)
    const [openMeal, setOpenMeal] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)

    const generateEventDescription = (event: HistoryEventDetails) => {

        switch(event.action){

            /*** WORKOUT ***** */
            case "workout" : {
                return(
                    <>
                        <Typography sx={{ml:0.5}}>
                            a éffectué la séance <strong>{event.name}</strong>
                        </Typography>
                        <Box
                            sx={{display:"flex", flexDirection:"row"}}
                        >
                            <Box sx={
                                [classes.measureContainer, {marginRight: 2}]
                            }>
                                Difficulté: &nbsp;<strong>{event.workout.performed.rpe}</strong>
                            </Box>
                            <Box 
                                sx={classes.measureContainer}
                            >
                                Tonnage: &nbsp;<strong>{calcTonnage(event.workout)} kg</strong>
                            </Box>
                        </Box>
                        {event.workout.performed.note !== "" && (
                            <Typography
                                sx={classes.userNote}
                            >
                                "{event.workout.performed.note}"
                            </Typography>
                        )}
                    </>
                        
                    
                )
            }

            /*** MEASURE ***** */
            case "measure" : {
                if(event.value){
                    const measure = BODY_MEASURES.find((elem)=> elem.label === event.type)
                    return(
                        <>
                        <Typography>
                            a ajouté une nouvelle donnée corporelle
                        </Typography>
                        <Box sx={classes.measureContainer}>
                            <Typography sx={classes.measure}>
                                {measure.fr} :&nbsp; 
                            </Typography>
                            <Typography 
                                sx={[classes.measure,{fontWeight: 700}]}
                            >
                                {event.value}{measure.units}
                            </Typography>
                        </Box>
                        
                        </>
                    )
                }
                break;
            }

            /*** MEASURE ***** */
            case "questionnaire" : {
                return(
                    <Typography>
                        a répondu à un questionnaire : <strong>{event.name}</strong>
                    </Typography>
                )
            }

            case "meal" : {
                return(
                    <>
                        <Typography sx={{ml:0.5}}>
                            a ajouté le repas <strong>{event.name}</strong> à son journal
                        </Typography>
                        <Box
                            sx={{display:"flex", flexDirection:"row"}}
                        >
                            
                            <Box 
                                sx={classes.measureContainer}
                            >
                                Total calorique: &nbsp;<strong>{calcMacros([{...event.meal}]).kcal} kcal</strong>
                            </Box>
                        </Box>
                        
                    </>
                        
                    
                )
            }
        }
    }


    const onClickCard = (action:"questionnaire"|"workout"|"measure"|'meal') => ()=> {
        setNewEvent(false)
        // Aucun abonnement en cours
        if(!coach.subscription || !coach.subscription?.current){
            dispatch(handleSubscriptionPresentation({open:true}))
            return
        }


        if(action === "measure"){
            setOpenStatsChart(true)
        } 
        else if(action === "workout"){
            setOpenWorkout(true)
        }
        else if(action === "questionnaire"){
            setOpenQuestionnaire(true)
        }
        else if(action === "meal"){
            setOpenMeal(true)
        }
    }


    const TODAY = useMemo(()=>{
        return isSameDay(new Date(event.date), new Date())
    },[event.date])


    /******* JSX ******** */
    
    return(
        <Box sx={classes.container}>

            <Box 
                sx={classes.eventIndicatorContainer}>
                <Box 
                    sx={[
                        classes.eventIndicator,
                        {backgroundColor: EVENTS_COLOR[event.action]}
                    ]} 
                >
                    <Icon
                        icon={ICON[event.action]}
                        size={18}
                        color="white"
                    />

                </Box>
            </Box>

            <Card 
                sx={classes.card} 
                elevation={0} 
                onClick={onClickCard(action)}
            >

                <Box 
                    sx={classes.cardInfos}>

                    {/******** CARD HEADER ******** */}

                    <Box sx={classes.cardHeader}>
                        <Avatar 
                            src={user?.avatar?.url} 
                            sx={{marginRight: 1.5, width: 50, height: 50}}

                        />
                    
                        <Box sx={{display:"flex", flexDirection:"column"}}>
                            <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                <Typography sx={classes.name}>
                                    {user?.firstname} {user?.lastname} - {TODAY ? `Aujourd'hui à  ${format(new Date(event.date), "HH:mm")}`: format(new Date(event.date), mobile ? "dd/MM/yyyy" : "dd MMM yyyy à HH:mm", {locale: fr})}
                                </Typography>
                                <Typography variant={mobile ? "body2" : "body1"}>
                                    {generateEventDescription(event)}
                                 </Typography>
                            
                            </Box>
                            
                        </Box>
                    </Box>

                   

                </Box>
            </Card>
            

            {/********** CHART DIALOG ********** */}

            {(action === "measure") && (
                <DialogChartMeasure
                    open={openStatsChart}
                    onClose={()=>setOpenStatsChart(false)}
                    type={type}
                    user={user}
                />
            )}

            {(action === "questionnaire") && (
                <DialogQuestionnaireResume
                    open={openQuestionnaire}
                    onClose={()=>setOpenQuestionnaire(false)}
                    questions={questions}
                    name={name}
                    date={date}
                />
            )}


            {(action === "workout") && (
                <DialogWorkoutResults
                    open={openWorkout}
                    onClose={()=>setOpenWorkout(false)}
                    workout={event.workout}
                />
            )}


            {(action === "meal") && (
                <DialogMealConsumed
                    open={openMeal}
                    onClose={()=> setOpenMeal(false)}
                    meal={event.meal}
                />
            )}


        </Box>
    )
}

export default React.memo(EventCard)
    
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "utils/axios"
import Program from 'interfaces/Program'
import sleep from 'function/sleep'
import Planning from 'interfaces/Planning'

//const today:Date = new Date(new Date().setHours(0,0,0,0))


/*****************************
            API
 *****************************/


/******* RECUPERATION DES PROGRAMMES ****** */

export const fetchAll = createAsyncThunk('programs', async () => {
    try {
        const response = await axios.get(`training-programs`)
        const programs = response.data.programs
        return { programs }

    } catch (err: any) {

        return {
            error: err.response.data?.message[0]
        }
    }
})

/******* CREATION D'UN NOUVEAU PROGRAMME ****** */

export const createNewProgram = createAsyncThunk('programs/create', async (data:{program:Program}) => {

    const newProgram = data.program

    try {
        const response = await axios.post(`programs`, newProgram)
        const program = response.data.program
        return { program }

    } catch (err: any) {
        await sleep(1000)

        return {
            error: err.response.data?.message[0]
        }
    }
})



export const updateProgram = createAsyncThunk('programs/update', async (data:{program:Planning}) => {
    try {

        const response = await axios.put(`programs/${data.program._id}`, data.program)
        const program = response.data.program
        return { program }

    } catch (err: any) {
        await sleep(1000)

        return {
            error: err.response.data?.message[0]
        }
    }
})



export const updateProgramDuration = createAsyncThunk('programs/update/duration', async (data:{program:Planning}) => {
    try {

        const response = await axios.put(`programs/${data.program._id}/duration`, data.program)
        const program = response.data.program
        return { program }

    } catch (err: any) {
        await sleep(1000)

        return {
            error: err.response.data?.message[0]
        }
    }
})


/******* DUPLIQUER UN PROGRAMME ****** */

export const duplicateProgram = createAsyncThunk('programs/duplicate', async (data: {program:Program}) => {

  
})



/******* SUPPRIMER UN PROGRAMME ****** */

export const deleteProgram = createAsyncThunk('programs/delete', async (data:{program:Program, deleteContent?: boolean}) => {

    const {program} = data

    try {
        await axios.delete(`programs/${program._id}`,{ data: {deleteContent: data.deleteContent}})
        return { program }

    } catch (err: any) {
        return {
            error: err.response.data?.message[0]
        }
    }
})


  /******* RECUPERER LES DONNEES D'UN PROGRAMME ****** */

export const getProgram = createAsyncThunk('program/get', async (id: string) => {

    try {
        const response = await axios.get(`programs/${id}`)
        const {program, workouts, checkups } = response.data
        return { program, workouts, checkups }

    } catch (err: any) {
        return {
            error: err.response.data?.message[0]
        }
    }
})


export const importProgram = createAsyncThunk('program/import', async(id:string) => {
    try{
        const response = await axios.get(`programs/${id}`)
        const {program, workouts, checkups } = response.data
        return { program, workouts, checkups }
    } catch (err: any) {
        return {
            error: err.response.data?.message[0]
        }
    }
})


export const checkProgramPlanningDate = createAsyncThunk('program/import', async(payload:{start:Date, end: Date, createdFor: string}) => {
    try{
        const response = await axios.get(`programs/check/planning?start=${payload.start}&end=${payload.end}&createdFor=${payload.createdFor}`)
        const {programs} = response.data
        return { programs }
    }
    catch(err:any){
        return {
            error: err.response.data?.message[0]
        }
    }
})


/******* SAUVEGARDER LES MODIFICATIONS D'UN PROGRAMME (Uniquement pour les programmes personnalisés) ****** */

export const saveProgram = createAsyncThunk('program/save', async (payload:{program:Program}) => {

    /*
    const noti: any = toast.loading("sauvegarde en cours ...")


    const filteredWorkouts:ScheduledWorkout[] = [] // Liste des séances filtrés (suppression des séances en trop)
    const filteredCheckups:ScheduledCheckup[] = [] // Liste des checkups filtrés (suppression des checkups en trop)
    

    try {

        // Données du formulaire
        const form:Program = payload.program
        const template:boolean = form.template
        const currentWorkouts:ScheduledWorkout[] = form.schedule.filter((elem:ScheduleElement) => elem.hasOwnProperty('workout'))
        const currentCheckups:ScheduledCheckup[] = form.schedule.filter((elem:ScheduleElement) => elem.hasOwnProperty('checkup'))
        const programStart:string = form.startDate
        const programEnd:string= form.endDate
        const programName:string = form.name

        let workoutsError= null
        let checkupsError= null


        // Filtrage des séances en trop
        currentWorkouts.forEach((elem:ScheduledWorkout)=>{
            if(isWithinInterval(new Date(elem.date), {start: new Date(programStart), end: new Date(programEnd)})){
                filteredWorkouts.push(elem)
            }
        })

        // Filtrage des checkups en trop
        currentCheckups.forEach((elem:ScheduledCheckup)=>{
            if(isWithinInterval(new Date(elem.date), {start: new Date(programStart), end: new Date(programEnd)})){
                filteredCheckups.push(elem)
            }
        })

        // Création du formulaire finale
        const data:Program = { ...form, schedule: filteredWorkouts.concat(filteredCheckups) }

        /*********** GESTION DES ERREURS *********** 

         // Pas de nom de programme
         if(programName === ""){
            let error = "Veuillez nommer votre programme"
            toast.update(noti, { render: "Veuillez nommer votre programme", type: "error", isLoading: false, autoClose: 1500 });
            return {error}
        }


        // date de fin antérieur à la date actuelle (pas pour les templates)
        if(!template && isBefore(new Date(programEnd), today)){
            let error = "La date de fin du programme ne peut pas être antérieur à la date du jour"
            toast.update(noti, { render: error, type: "error", isLoading: false, autoClose: 1500 });
            return {error}
        }

    
        // Vérification des workouts
        workoutsError = checkProgramWorkouts(data)

        // Vérification des checkups
        checkupsError = checkProgramCheckups(data)


        // S'il y'a une erreur dans une séance ou un checkup, on affiche un toast
        if(Boolean(workoutsError) || Boolean(checkupsError)){
            toast.update(noti, { render: workoutsError? workoutsError : checkupsError, type: "error", isLoading: false, autoClose: 1500 });
            return {error: workoutsError || checkupsError}
        }

        /*************** ENVOIE DES DONNEES **************** 

        const response = await axios.put(`training-programs/${data._id}`, data)
        const program:Program = response.data.program

        await sleep(1000)
        toast.update(noti, { render: "Modifications enregistrées", type: "success", isLoading: false, autoClose: 1500 });
        //thunkApi.dispatch(changeStatusToSaved()) // Changement du status du CHIP
        return { program }

    } catch (err: any) {
        toast.update(noti, { render: "Impossible de sauvegarder", type: "error", isLoading: false, autoClose: 1500 });
        return {
            error: err.response.data?.message[0]
        }
    }
    */

})


/******* CRER UN NOUVEAU TEMPLATE DE PROGRAMME ****** */

export const saveAsProgramTemplate = createAsyncThunk('program/create/template', async (payload: {form:Program}) => {

    /*
    const { form } = payload
    const filteredWorkouts:ScheduledWorkout[] = []
    const filteredCheckups:ScheduledCheckup[] = []

    try {
        const currentWorkouts:ScheduledWorkout[] = form.schedule.filter((elem:ScheduleElement) => elem.hasOwnProperty('workout'))
        const currentCheckups:ScheduledCheckup[] = form.schedule.filter((elem:ScheduleElement) => elem.hasOwnProperty('checkup'))
        const programStart:string = form.startDate
        const programEnd:string = form.endDate

        // Filtrage des séances en trop
        currentWorkouts.forEach((elem:ScheduledWorkout)=>{
            if(isWithinInterval(new Date(elem.date), {start: new Date(programStart), end: new Date(programEnd)})){
                filteredWorkouts.push(elem)
            }
        })

        // Filtrage des checkups en trop
        currentCheckups.forEach((elem:ScheduledCheckup)=>{
            if(isWithinInterval(new Date(elem.date), {start: new Date(programStart), end: new Date(programEnd)})){
                filteredCheckups.push(elem)
            }
        })

        if(filteredWorkouts.length ===0){
            return {error: "Le programme ne contient aucune séance"}
        }


        const startDate:Date = new Date(new Date().setHours(0,0,0,0))
        const endDate:Date = new Date(new Date(addWeeks(Date.now(), form.duration)).setHours(0,0,0,0))

        // Création des données du templates à envoyer
        const data:Program = {
            ...form,
            schedule: filteredWorkouts.concat(filteredCheckups),
            template: true,
            draft: false,
            _id: undefined,
            recipient: undefined,
            startDate: startDate.toString(),
            endDate: endDate.toString()
        }

        ///// ENVOIE DES DONNEES //////
        const response = await axios.post(`training-programs`, { data })
        const newProgram:Program = response.data.program
        const program: Program = {
            ...data,
            _id: response.data.program._id,
            createdBy: newProgram.createdBy,
            createdAt: newProgram.createdAt,
        }

        await sleep(800)
        return { program }

    } catch (err: any) {
        return {
            error: err.response.data?.message[0] || "Erreur inconnue - Impossible d'enregistrer le modèle"
        }
    }
    */

})


/********************     AUTOSAVE PROGRAM    *************************** */

export const autoSaveProgram =  createAsyncThunk('program/autosave', async (data:{program:Program}) => {

    try {
        const response = await axios.put(`training-programs/${data.program._id}`, data.program)
        const program: Program = response.data.program
        await sleep(500)
        return {program}


    } catch (err: any) {
        return {
            error: err.response.data?.message[0]
        }
    }
})



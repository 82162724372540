import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import { handleTrack } from "features/workoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "utils/theme";
import classes from "./styles";
import { Check, Rule } from "@mui/icons-material";

interface Props{
    open: boolean,
    onClose: ()=> void,
    blockIndex: number,
    blockId: string,
    currentTrack: boolean
}

export default function DialogBlockTracking({open,blockIndex, blockId, onClose, currentTrack}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const block = useSelector((state:RootState)=> state.workouts?.builder?.blocks[blockIndex])

    const onHandleTrack = (track:boolean) => {
        dispatch(handleTrack({track, blockId}))
        //onClose()
    }


    return(
        <Dialog open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                {block?.name}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Box>
                            <Box sx={classes.imageContainer}>
                                <Box 
                                    component={"img"}
                                    src="/assets/avec_performances.jpg"
                                    sx={{width:{xs: "100%", sm: "500px"}}}
                                />
                            </Box>
                    
                                <Typography sx={{fontWeight: 600, fontSize: theme.typography.pxToRem(22), marginBottom: 1}}>
                                    Suivi des performances
                                </Typography>
                            
                                    <Box 
                                        sx={[
                                            classes.trackingButton, 
                                            !currentTrack && classes.trackingButtonOff
                                        ]} 
                                    >
                                
                                    <Check 
                                        sx={{fontSize: 18, marginRight: 1, color: currentTrack ? "white" : "#bcbcbc"}} 
                                    />
                                    
                                    <Typography 
                                        sx={[classes.trackingButtonLabel, !currentTrack && classes.trackingButtonLabelOff]}>
                                            {currentTrack ? "Suivi activé" : "Suivi désactivé"}
                                    </Typography>
                                </Box>
                           
                           
                            {block.track && (
                                <Typography sx={{fontWeight: 400, fontSize: theme.typography.pxToRem(16), color:"rgba(0,0,0,0.75)", lineHeight: 1.5 }}>
                                    Ton client devra indiquer les performances réalisées (poids, répétitions ...) sur les exercices de ce bloc.
                                </Typography>
                            )}
                            {!block.track && (
                                <Typography sx={{fontWeight: 400, fontSize: theme.typography.pxToRem(16), color:"rgba(0,0,0,0.75)", lineHeight: 1.5 }}>
                                    Ton client devra simplement suivre les prescriptions des exercices de ce bloc sans enter ses performances.
                                </Typography>
                            )}
                            <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", marginTop: 2, flexDirection: "row"}}>
                                {!currentTrack && 
                                    <SecondaryButton onClick={()=>onHandleTrack(true)}>
                                        Activer le suivi
                                    </SecondaryButton>
                                }
                                {currentTrack && (
                                    <SecondaryButton onClick={()=>onHandleTrack(false)}>
                                        Désactiver le suivi
                                    </SecondaryButton>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                  
                </Grid>

                <DialogActions>
                    <Button
                        onClick={onClose}>
                        Fermer
                    </Button>
                </DialogActions>
                
                
            </DialogContent>
            
        </Dialog>
    )
}
import { BUTTON_SECONDARY_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {


   
    questionnaireStatus: {
        height: 25,
        paddingLeft: 1, 
        paddingRight: 1,
        lineHeight: "25px",
        borderRadius: 1,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        marginBottom: {
            xs: 2,
            sm: 0
        },
        marginRight: {
            xs: 0,
            sm: 2
        }
    },

    questionnaireStatusValid:{
        color: "primary.main",
        backgroundColor: LIGHT_BUTTON_COLOR,
        
    },

    questionnaireStatusIsNotValid:{
        backgroundColor: "#fff8d9",
        color: "#de9c0d"
        
    }
}

export default classes
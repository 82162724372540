import { createSlice } from '@reduxjs/toolkit'
import { getMetrics } from 'api/exerciceMetrics'
import { ExerciceMetrics } from 'interfaces/Exercice'


/************** INTERFACE ****************/


interface Metrics{
    list: ExerciceMetrics[],
    request: {
        status: "idle" | "pending"
    }
}


/************** INIT ****************/

const initialState:Metrics = {
  list : [],
    request: {
        status: "idle"
    }
}


export const exerciceMetricsSlice = createSlice({
    name: 'exerciceMetrics',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
          .addCase(getMetrics.pending, (state) => {
            state.request.status = "pending"
          })
          .addCase(getMetrics.fulfilled, (state, action) => {
            const metrics:ExerciceMetrics[] = action.payload?.metrics
            if (metrics) {
              state.request.status = "idle"
              state.list = metrics
            }
          })
    }
})


export default exerciceMetricsSlice.reducer
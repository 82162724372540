import { darken } from "@mui/material"
import { LIGHT_BUTTON_COLOR } from "utils/constants"



const styles = {
    imageContainer:{
        width: "auto", 
        backgroundColor:"#FCFCFC", 
        marginBottom: 2,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    trackingButton: {
        display:"inline-flex", 
        flexDirection: "row", 
        marginBottom: 2,
        backgroundColor: "#67c83c", 
        paddingLeft: {
            xs: 0,
            sm: 1
        }, 
        paddingRight: {
            xs: 0,
            sm: 1
        }, 
        paddingTop: {
            xs: 0,
            sm: 0.5
        }, 
        paddingBottom: {
            xs: 0,
            sm: 0.5
        }, 
        borderRadius: 2,

       
    },

  

    trackingButtonOff: {
        backgroundColor: "whitesmoke"
    },

    trackingButtonLabel : {
        fontSize: 13, 
        fontWeight: 600,
        color: "white"
    },

    trackingButtonLabelOff : {
        color: "#bcbcbc"
    },
}   


export default styles
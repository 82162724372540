import { Box, Dialog, DialogContent, IconButton, Typography, useMediaQuery } from "@mui/material";
import classes from "./styles";
import { Close } from "@mui/icons-material";
import { MOBILE } from "utils/constants";

interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogDemoUserAdded({open, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)

    return(
        <Dialog 
            open={open} 
            fullScreen={mobile} 
            disableScrollLock={true}
        >
            <DialogContent>
                <Box sx={{padding: mobile? 3 : 5, paddingTop: 5}}>
                    <Typography variant='h5' sx={{fontWeight: 700, marginBottom: 3, textAlign:"center"}}>
                        Ta période d'essai a débuté
                    </Typography>
                    <Typography
                        sx={{textAlign: "center"}}
                    >
                        Nous avons ajouté un profil de démonstration <strong>"Arthur Demo"</strong> à ta base de clients afin que tu découvres l'application Traener. Tu peux consulter son planning, ses données corporelles ou les séances qu'il a effectué depuis la page "clients".
                    </Typography>
                    <Box sx={classes.avatarRow}>
                        <Box sx={classes.avatarContainer}>
                            <Box 
                                component={"img"} 
                                src={"https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/5d889c29-815b-4572-714a-b3936fa0e000/public"} 
                            />
                        </Box>
                        <Typography style={classes.name}>
                            Arthur Demo
                        </Typography>
                    </Box>
                    
                    {/****** BOUTON CLOSE ****** */}
                    <Box sx={{position: "absolute", right: 10, top: 10}}>
                        <IconButton 
                            sx={{backgroundColor: "#eaeaea", borderRadius: "100% !important"}} 
                            onClick={onClose} 
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from "@mui/material"
import { DEFAULT_PORTIONS, MOBILE } from "utils/constants"
import classes from "./styles"
import Meal from "interfaces/Meal"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import MacrosList from "../MacrosList"
import calcMacros from "function/calcMacros"
import { LocalFireDepartment } from "@mui/icons-material"


interface Props{
    open: boolean,
    meal:Meal,
    onClose: ()=> void
}

export default function DialogMealConsumed({meal, open, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)


    return(
        <Dialog 
            open={open} 
            fullWidth 
            maxWidth={"sm"} 
            fullScreen={mobile? true:false}
            disableScrollLock={true}
        >
            <DialogTitle>
                <Typography 
                    variant="h6" 
                    style={classes.title}
                >
                    {meal.name}
                </Typography>
                <Typography>
                    Enregistré à {format(new Date(meal.date), "HH:mm", {locale:fr})}
                </Typography>
                
              
            </DialogTitle>
            <DialogContent>

                <Box
                    sx={classes.kcalContainer}
                >
                    <LocalFireDepartment 
                        sx={{ marginRight: 1, color: "#e75023", fontSize: 18}}
                    />
                    <Typography
                        sx={classes.kcal}
                    >
                        {calcMacros([{...meal}])?.kcal} kcal
                    </Typography>
                </Box>
          
                <MacrosList
                    meal={meal}
                />


                {meal.content.map((elem,index)=> {
                    if(elem.type === "food"){

                        const foodPortions = [...DEFAULT_PORTIONS].concat(elem.food.portions)

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.food.name.fr}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} {foodPortions.find((portion) => portion.label.fr === elem.label).label.fr}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    } else if(elem.type === "scanned"){
 

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >

                             
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.foodScanned.name.fr}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} {elem.label}
                                    </Typography>
                                    <Typography
                                        style={classes.brands}
                                    >
                                        Marque : {elem.foodScanned.brands}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    } else if(elem.type === "recipe"){

                        return(
                            <Box
                                key={index}
                                sx={classes.mealContent}
                            >
                                <Box sx={classes.foodInfo}>
                                    <Typography
                                        style={classes.mealContentTitle}
                                    >
                                        {elem.recipe.name.fr}
                                    </Typography>
                                    <Typography>
                                        {elem.portion} portion{elem.portion > 1 ?  "s" :""}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    }
                })}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
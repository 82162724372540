

const classes = {
    tab:{
        minWidth: 150, 
        width: "auto", 
        color: "#cecece", 
        textTransform: "none",
        
    }
}

export default classes
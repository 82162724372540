import { ArrowBack, AspectRatio, CheckCircle, Close, Delete, Download, ImageNotSupported, Save, YouTube} from "@mui/icons-material"
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import AutosaveIndicator from "components/molecules/AutosaveIndicator"
import Header from "components/molecules/Header"
import TitleTextField from "components/molecules/TitleTextField"
import Toolbar from "components/molecules/Toolbar"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory, useParams } from "react-router-dom"
import classes from "./styles"
import debounce from "lodash.debounce"
import { AUTOSAVE_TIMER, INPUT_DEBOUNCE_TIMER, MOBILE, RECIPE_TAG, RECIPE_TAGS } from "utils/constants"
import MainContent from "components/molecules/MainContent";
import styled from "@emotion/styled"
import { theme } from "utils/theme"
import RecipeItem from "components/organisms/RecipeItem"
import { getRecipe, manualSaveRecipe, updateRecipe, uploadRecipeImage } from "api/recipes"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import MacroPieChart from "components/organisms/MacroPieChart"
import { onAddStep, onChangeRecipeBuilderPortions, onChangeRecipeDescription, onChangeRecipeName, onChangeStep, onDeleteStep, onHandleRecipeTag, recipeHasUnsavedChanges, recipeIsSaving } from "features/recipesSlice"
import DialogFoodList from "components/organisms/DialogFoodList"
import Meal from "interfaces/Meal"
import calcMacros from "function/calcMacros"
import { ChipSelector } from "components/molecules/ChipSelector"
import Recipe from "interfaces/Recipe"
import Loader from "components/molecules/Loader"
import YoutubeVideoPlayer from 'react-player'
import calcBoxHeight from "function/calcBoxHeight"
import Image from "interfaces/Image"
import { toast } from "react-toastify"
import DialogLoader from "components/molecules/DialogLoader"
import Video from "interfaces/Video"
import UnsavedIndicator from "components/molecules/UnsavedIndicator"
import { definePageTitle, selectDrawerItem } from "features/appSlice"
import DialogSaveRecipe from "components/molecules/DialogSaveRecipe"
import Lottie from "lottie-react"
import foodAlim from "../../lottie/food.json";
import DialogDeleteRecipeImage from "components/molecules/DialogDeleteRecipeImage"
import { ViewportList } from "react-viewport-list";




const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))



export default function RecipeBuilder(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const params:{recipeid:string} = useParams()
    const createdBy = useSelector((state:RootState) => state.user.data._id)
    const pending = useSelector((state:RootState)=> state.recipes.requests.update === "pending")
    const pendingManualSave = useSelector((state:RootState)=> state.recipes.requests.manualSave === "pending")

    const [openWaitinUpdate, setOpenWaitinUpdate] = useState<boolean>(false)
    const [localDescription, setLocalDescription] = useState<string>('')
    const [localName, setLocalName] = useState<string>('')
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const recipeBuilderName = useSelector((state:RootState) => state.recipes.builder.name.fr)
    const recipeBuilderDescription = useSelector((state:RootState) => state.recipes.builder.description.fr)
    const recipeBuilderTags = useSelector((state:RootState) => state.recipes.builder.tags)
    const recipeBuilderIngredients = useSelector((state:RootState) => state.recipes.builder.ingredients)
    const recipeBuilderSteps = useSelector((state:RootState) => state.recipes.builder.steps)
    const recipeBuilderPortions = useSelector((state:RootState) => state.recipes.builder.portions)
    const [openDialogFood, setOpenDialogFood] = useState<boolean>(false)
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [videoIsCorrect, setVideoIsCorrect] = useState<boolean>(false)
    const [mediaHeight, setMediaHeight] = useState<number>(0)
    const [imageSelected, setImageSelected] = useState<{url:string, cloudFlareId?:string}>({url:null, cloudFlareId: null})
    const uploadPending:boolean = useSelector((state:RootState) => state.recipes.requests.uploadImage === "pending")
    const image:Image = useSelector((state:RootState) => state.recipes.builder?.cover) // Image du formulaire
    const [openDeleteImage, setOpenDeleteImage] = useState<boolean>(false)
    const hasUnsavedChanges = useSelector((state:RootState) => state.recipes.hasUnsavedChanges)
    const mobile = useMediaQuery(MOBILE)
    const isDraft: boolean = useSelector((state:RootState) => state.recipes.builder?.draft, shallowEqual)
    const [openSaveRecipe, setOpenSaveRecipe] = useState<boolean>(false)




   /******************************* 
    * CHANGEMENT DE LA DESCRIPTION 
    * *************************** */

    const debounceOnChangeDescription = useRef(debounce((data)=> {
        dispatch(onChangeRecipeDescription({description: data.description}))
    },500)).current


    const onChangeLocalDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription:string = event.target.value
        setLocalDescription(newDescription)
        debounceOnChangeDescription({description: newDescription})
    }



    /***********************
        ETAPES
    ***********************/

    // Ajout
    const addStep = () => {
        dispatch(onAddStep())
    }

    // SUPPRESSION
    const deleteStep = (index:number) => ()=> {
        dispatch(onDeleteStep({stepIndex: index}))
    }

    // Changement
    const changeStep =  (index:number) => (event: React.ChangeEvent<HTMLInputElement>)=> {
        dispatch(onChangeStep({
            stepIndex: index, 
            step: event.target.value
        }))
    }

    // Portions
    const changeRecipeBuilderPortions = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeRecipeBuilderPortions({portions:event.target.value}))
    }


    /****************************************** 
     * FERMETURE, LA RECETTE N'EST PLUS READY 
     * ************************************** */

    useEffect(()=>{
        return function cleanup() {
            setFormIsReady(false)
        };
    },[dispatch])



    /***************************************** 
     * MISE EN FORME DES DONNES DU FORMULAIRE 
     * ***************************************/

    const recipeForm = useMemo(()=>{
        const recipe:Recipe={
            _id: params?.recipeid,
            name:{
                fr: localName,
                en:""
            },
            description:{
                fr: localDescription,
                en: ""
            },
            tags: recipeBuilderTags,
            ingredients: recipeBuilderIngredients,
            steps:recipeBuilderSteps,
            portions: recipeBuilderPortions,
            publishForClient: false,
            createdBy,
            cover:imageSelected,
            video: {
                url: videoUrl
            },
            draft: isDraft
        }
        return recipe
    },[recipeBuilderName,recipeBuilderDescription, recipeBuilderTags, recipeBuilderIngredients, recipeBuilderSteps, recipeBuilderPortions, imageSelected, videoUrl])


    /******************** 
     *      AUTOSAVE 
     * ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((recipe)=> {
        dispatch(updateRecipe(recipe))
    }, AUTOSAVE_TIMER)).current


    // Appel du debounce à chaque changement
    useEffect(() => {
        if(formIsReady && isDraft){
            dispatch(recipeIsSaving())
            debounceAutoSave(recipeForm)
        }else if(formIsReady && !isDraft){
            dispatch(recipeHasUnsavedChanges())
        }
        // eslint-disable-next-line
    }, [recipeForm,isDraft])


    /******************** 
     * CHANGEMENT DU NOM 
     * **************** */

    const debounceOnChangeName = useRef(debounce((data)=> {
        const {newName} = data
        dispatch(onChangeRecipeName({ name : newName}))
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>)=> {
        const newName:string = event.target.value
        setLocalName(newName)
        debounceOnChangeName({newName: newName})
    }


    /****************************** 
     * RETOUR A l'ECRAN PRECEDENT 
     * ***************************/

    const backToPreviousScreen = () =>{
        if(pending){
            setOpenWaitinUpdate(true)
            return
        }
        else{
            history.push(`/recipes`)
        }
        
    }

    const addFood = () => {
        setOpenDialogFood(true)
    }




    /************* RECUPERATION DES DONNEES LE CAS ECHEANT **************** */

    useEffect(()=>{
        dispatch(selectDrawerItem(4))
        //dispatch(definePageTitle({pageTitle: isDraft? "Création d'une recette (brouillon)" : "Modifier une recette"}))
        dispatch(getRecipe(params.recipeid)).unwrap().then((res:any)=>{
            
            setLocalName(res.recipe.name.fr)
            setLocalDescription(res.recipe.description.fr)
            if(res.recipe.cover){
                setImageSelected(res.recipe.cover)
            }
            if(res.recipe.video){
                setVideoUrl(res.recipe.video.url)
            }
            setTimeout(()=>{
                setFormIsReady(true)
            },500)
        })
        
    },[params.recipeid])


    const CHART_DATA = useMemo(()=>{
    
        const meals:Meal[] = [{
            name:"recipe",
            time:"00:00",
            content:[]
        }]

        recipeBuilderIngredients.forEach((elem)=> {
            meals[0].content.push({
                type: "food",
                food: elem.ingredient.food,
                portion: elem.ingredient.portion,
                label: elem.ingredient.label,
                grammage: elem.ingredient.grammage
            })
        })


        const total = calcMacros(meals)
  
        
        const labels = [
          { value: Math.round(total.proteins), label: `Protéines - ${Math.round(total.proteins)}g`},
          { value: Math.round(total.lipids), label: `Lipides - ${Math.round(total.lipids)}g`},
          { value: Math.round(total.carbs), label: `Glucides - ${Math.round(total.carbs)}g`},
          //{value: Math.round(total.kcal), label: "Kcal"}
      ]
  
        return labels
  
    },[recipeBuilderIngredients])



    const selectTag = (elem:RECIPE_TAG) => ()=> {
        dispatch(onHandleRecipeTag({tag:elem.id}))
    }


    const onChangeLink = (e:React.ChangeEvent<HTMLInputElement>) => {
        setVideoUrl(e.target.value)
    }




    /******************************************
     * CALCUL DE LA HAUTEUR DU CONTAINER VIDEO
     ******************************************/


    useEffect(() => {
        if (videoRef.current && formIsReady) {
            const boundingRect = videoRef.current.getBoundingClientRect()
            const videoWidth = boundingRect.width;
            setMediaHeight(calcBoxHeight(1080, 1920, videoWidth))
        }
    }, [videoRef.current, formIsReady]);


     /******************************************
     * LISTE DES MINIATURES YOUTUBE
     ******************************************/

     const thumbnailsList:{url:string}[] = useMemo(()=>{
        if(videoIsCorrect){
            // Récupération de l'ID Vidéo
            var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/;
            var match = videoUrl.match(regExp);

            const videoId = match && match[1].length == 11 ? match[1] : false;
            

            var thumbnailsList:Image[] = [{url: "https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"}]

            // ON Parcourt les miniatures (il y'a 4 miniatures sur youtube par vidéo)
            for (var i = 1; i < 4; i++) {
                thumbnailsList.push({
                    url: "https://img.youtube.com/vi/" + videoId + "/maxres" + i + ".jpg"
                })
            }
            if(!imageSelected.url){
                setImageSelected({url:"https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"})
            }

            return thumbnailsList
        } else {
            return []
        }
    },[videoIsCorrect])


    /******************************************
     * Sélection d'une miniature
     ******************************************/

    const onSelectThumbnail = (thumbnail:{url:string, cloudFlareId?:string}) => ()=>{
        if(imageSelected.cloudFlareId){
            toast.error('Supprime ton image téléchargée avant de sélectionner une autre miniature')
            return
        }
        setImageSelected(thumbnail)
    }




    /*********************************************************** 
     * SELECTION D'UN FICHIER LOCAL - Téléchargement de l'image 
     * **********************************************************/

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFile:Blob = e.target.files[0]
        if (newFile.size > 10 * 1024 * 1024) {
            toast.error('Le fichier est trop volumineux (maximum 10mo)')
        }else if (newFile.type !== "image/jpeg" && newFile.type !== "image/png") {
            toast.error('Format non pris en charge (PNG/JPEG exclusivement)')
        }else{
            dispatch(uploadRecipeImage({
                recipeId: params.recipeid, 
                file: newFile
            })).unwrap().then((res:any)=>{
                if(res.image){
                    setImageSelected(res.image)
                    if(isDraft){
                        dispatch(manualSaveRecipe({...recipeForm, cover: res.image}))
                    }
                }
                
            })
        }
    }

    const checkVideoLink = (url:string) => {
        var regExp = new RegExp(/^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/);
        var match = url.match(regExp);
        if(match) {
            setVideoIsCorrect(true)
        }
        else if(videoIsCorrect) {
            setVideoIsCorrect(false)
            if(!imageSelected?.cloudFlareId){
                setImageSelected({url:null, cloudFlareId: null})
            }
        }
    }



    useEffect(()=>{
        checkVideoLink(videoUrl)
    },[videoUrl])


     /********* VERIFICATION DU STATUS DE SAUVEGARDE DU WORKOUT */

     useEffect(()=>{
        // Si la recipe a terminé de sauvegarder et que le dialog d'attente est affiché, on le ferme
        if(!pending && openWaitinUpdate){
            setOpenWaitinUpdate(false)

            
            history.push(
                {
                    pathname: `/recipes`,
                   
                }
            )
           
            
        }
    },[openWaitinUpdate, pending])



    const onSave = () =>{
        debounceAutoSave.cancel()
        dispatch(manualSaveRecipe(recipeForm))
    }


    if (formIsReady) {
    return(
        <React.Fragment>
            <Header>
                <Grid 
                    container 
                    justifyContent="center"
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={12} 
                        md={12} 
                        lg={11} 
                        xl={8}
                    >

                        
                        {/********* TITRE DE LA PAGE******** */}
                        <Toolbar>
                            <Tooltip 
                                title={"Retour à la liste des recettes"}
                            >
                                <IconButton 
                                    sx={{marginRight: 2}} 
                                    onClick={backToPreviousScreen}
                                >
                                    <ArrowBack sx={{color: "black"}}/>
                                </IconButton>
                            </Tooltip>

                            <TitleTextField 
                                fullWidth 
                                label={""} 
                                value={localName} 
                                onChange={onChangeName}
                                variant="standard" 
                            />
                                        
                            <Box 
                                sx={classes.infosContainer}
                            >

                                {/********************* 
                                 * AUTO SAVE INDICATOR 
                                 *********************/}

                                 {/********** INDICATEURS ************** */}
                                 {!mobile && (
                                    <Box sx={classes.indicators}>
                                        {isDraft && (<AutosaveIndicator showDraft={true} />)}

                                        {!isDraft && (<UnsavedIndicator saved={!hasUnsavedChanges}/>)}
                                    </Box>
                                )}
                                
                            </Box>
                        </Toolbar>
                    </Grid>
                </Grid>
            </Header>

            <MainContent 
                padding={mobile? false : true}
            >

                {/**************************
                 * Recette HEADER
                 ***************************/}
                <Grid 
                    container 
                    justifyContent="center"
                >

                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={8} 
                        xl={8}
                    > 

                        <Box 
                            sx={{display:"flex", justifyContent:"flex-end", marginBottom: 3
                            }}
                        >
                            {isDraft && (
                                <SecondaryButton 
                                    onClick={()=> setOpenSaveRecipe(true)} 
                                    startIcon={<Save />}
                                >
                                    Publier la recette
                                </SecondaryButton> 
                            )}


                            {!isDraft && (
                                <Button 
                                    variant="outlined"
                                    sx={{marginLeft: 3}} 
                                    onClick={onSave}
                                >
                                    Sauvegarder les modifications
                                </Button>
                            )}

                        </Box>
                    </Grid>
                </Grid>


                {/**************************
                 * Recette CONTAINER
                 ***************************/}

                <Grid 
                    container 
                    justifyContent="center" 
                    sx={{paddingBottom: 10,}}
                    spacing={3}
                >

                    <Grid
                        item
                        xs={12}
                        md={8}
                    >

                        {/*************
                         * PRESENTATION
                         **************/}

                        <Box 
                            sx={classes.container}
                        >
                            <Typography 
                                variant="body1" 
                                sx={classes.sectionLabel}
                            >
                                Présentation de la recette
                            </Typography>
                            <DescriptionTextField
                                multiline={true}
                                fullWidth
                                value={localDescription}
                                onChange={onChangeLocalDescription}
                                placeholder={`(Optionnel) Présentation de la recette`}
                                variant="standard" 
                            />
                            
                        </Box>


                        
                                


                        {/*************
                         * TAGS
                         **************/}

                        <Box 
                            sx={classes.container}
                        >
                            <Typography 
                                variant="body1" 
                                sx={classes.sectionLabel}
                            >
                                Tags
                            </Typography>

                            <Box
                                sx={classes.chips}
                            >

                                {RECIPE_TAGS.map((elem,index)=> {
                                    return(
                                        <ChipSelector
                                            label={elem.label.fr} 
                                            key={index} 
                                            selected={Boolean(recipeBuilderTags.find((tag)=> tag === elem.id))} 
                                            onClick={selectTag(elem)} 
                                        />
                                    )
                                })}

                            </Box>
                            
                            
                        </Box>
                            

                        


                        {/*************
                         * INGREDIENTS
                         **************/}

                        <Grid 
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                lg={7}
                            >
                                <Box 
                                    sx={[
                                        classes.container,
                                        classes.fullHeight
                                    ]}
                                >
                                    <Typography 
                                        variant="body1" 
                                        sx={classes.sectionLabel}
                                    >
                                        Ingrédients
                                    </Typography>
                                    
                                    
                                    {/************************
                                     * LISTE DES INGREDIENTS
                                     ************************/}

                                    {recipeBuilderIngredients.map((_,index)=> {
                                        return(
                                            <RecipeItem
                                                key={index}
                                                ingredientIndex={index}
                                            />
                                        )
                                    })}

                                    {recipeBuilderIngredients.length === 0 && (
                                        <Box>
                                            <Typography>
                                                Aucun ingrédient ajouté à la recette
                                            </Typography>
                                        </Box>
                                    )}

                                    {recipeBuilderIngredients.length === 0 && (
                                        <Box sx={classes.imageFood}>
                                            <Box
                                                sx={classes.imageContainer}
                                            >
                                            <Lottie 
                                                    animationData={foodAlim} 
                                                    loop={true} 
                                                />
                                            </Box>
                                        </Box>
                                    )}


                                    {/***************************
                                     * Boutons AJOUT D'INGREDIENT
                                     ***************************/}

                                    <Box
                                        sx={classes.mealButtons}
                                    >

                                        <SecondaryButton 
                                            onClick={()=> addFood()} 
                                            sx={{textTransform: "none", marginRight: 1}} 
                                            disableElevation={true}
                                        >
                                                Ajouter un ingrédient
                                        </SecondaryButton>

                                        
                                    </Box>
                                    
                                </Box>
                            </Grid>


                            {/**************
                             * MACROS CHART
                             **************/}

                            <Grid
                                item
                                xs={12}
                                lg={5}
                            >
                                <MacroPieChart
                                    data={CHART_DATA}
                                    smaller={true}
                                />
                            </Grid>
                        </Grid>


                    




                        {/********************** 
                         * ETAPES DE LA RECETTE 
                         * ******************* */}
                            

                        <Box
                            sx={classes.container}
                        >
                            <Typography 
                                variant="body1" 
                                sx={classes.sectionLabel}
                            >
                                Etapes de la recette
                            </Typography>

                            <Box
                                sx={classes.portions}
                            >
                                <Typography>
                                    Recette pour 
                                </Typography>
                                <TextField
                                    value={recipeBuilderPortions}
                                    onChange={changeRecipeBuilderPortions}
                                    variant="standard"
                                    size="small"
                                    sx={{maxWidth: 40, marginLeft: 2, marginRight: 2, textAlign:"center"}}
                                    type="number"
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                                <Typography>
                                    portion(s)
                                </Typography>
                            </Box>
                            
                            {recipeBuilderSteps.map((elem, index)=>{
                                return(
                                    <Box
                                        sx={classes.step}
                                        key={index}
                                    >
                                        <Typography
                                            sx={classes.stepIndex}
                                        >
                                            {index+1} - 
                                        </Typography>
                                        <DescriptionTextField
                                            value={elem}
                                            multiline={true}
                                            fullWidth
                                            variant="standard"
                                            placeholder={`Description de l'étape`}
                                            onChange={changeStep(index)}
                                        />
                                        <IconButton
                                            onClick={deleteStep(index)}
                                            sx={{marginLeft: 1}}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </Box>
                                )
                            })}


                            {/*****************************
                             * Ajouter une étape (BOUTON)
                             ******************************/}

                            <Box
                                sx={{marginTop: 3}}
                            >
                                <SecondaryButton 
                                    onClick={()=> addStep()} 
                                    sx={{textTransform: "none", marginRight: 1}} 
                                    disableElevation={true}
                                >
                                        Ajouter une étape
                                </SecondaryButton>
                            </Box>


                        </Box>


                        <Grid 
                            container 
                            spacing={3}
                        >

                            {/********************* 
                             * VIDEO DE L'EXERCICE 
                             * ******************/}
                                    
                            <Grid 
                                item
                                xs={12}
                                lg={6}
                            >
                                <Box 
                                    sx={classes.container}
                                >
                                    <Typography 
                                        variant="body1" 
                                        sx={classes.sectionLabel}
                                    >
                                        Vidéo de la recette
                                    </Typography>
                                    <Typography>
                                        Ajoute le lien Youtube de ta recette (optionnel)
                                    </Typography>

                                    <TextField 
                                        fullWidth
                                        value={videoUrl}
                                        label={"Lien Youtube"}
                                        onChange={onChangeLink}
                                        sx={{mt:4}}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><YouTube sx={{color: "#ff0000"}} /></InputAdornment>,
                                            endAdornment: <InputAdornment position="end"> {videoIsCorrect ? <CheckCircle sx={{color:"green"}}/> : videoUrl !== "" ? <Close sx={{color: "red"}}/> : ""}</InputAdornment>
                                        }}
                                    />

                                    
                                    <Box 
                                        ref={videoRef} 
                                        sx={[
                                            classes.videoContainer, 
                                            {
                                                height: mediaHeight
                                            }]}
                                    >
                                        {videoIsCorrect && (
                                                <YoutubeVideoPlayer 
                                                    url={videoUrl} 
                                                    height={mediaHeight} 
                                                    width={'100%'} 
                                                    playing={false}
                                                    config={{
                                                        youtube: {
                                                            playerVars:{
                                                                modestbranding: 0,
                                                                rel: 0,
                                                                showinfo: 0,
                                                                controls: 2
                                                            }
                                                        }
                                                    }}
                                                    
                                                />
                                        
                                        )}

                                        {!videoIsCorrect && (
                                            <Box 
                                                sx={{width:"100%", height: "100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                                <Typography 
                                                    sx={{fontWeight:700, color: "#a8a8a8"}}
                                                >
                                                    Aucune vidéo pour cette recette
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    
                                    
                                    
                                </Box>
                            </Grid>

                            {/************ 
                             * MINIATURES 
                             * ******* */}

                            <Grid 
                                item
                                xs={12}
                                lg={6}
                            >
                                <Box 
                                    sx={classes.section}
                                >
                                    <Typography 
                                        variant="body1" 
                                        sx={classes.sectionLabel}
                                    >
                                        Image de la recette
                                    </Typography>
                                    <Typography>
                                        Indique le lien de ta video Youtube pour afficher les miniatures disponibles, ou télécharge ta propre image.
                                    </Typography>
                                        
                                    <Grid 
                                        container 
                                        spacing={3} 
                                        flexDirection={"row"} 
                                        sx={{mt:0.5}}
                                    >

                                        {/******************************** 
                                         * LISTE DES MINIATURES YOUTUBES 
                                         * **** *************************/}

                                        {videoIsCorrect && (
                                            thumbnailsList.map((thumbnail,index)=>{

                                                const selected = thumbnail.url === imageSelected.url

                                                return(
                                                    <Grid 
                                                        item 
                                                        xs={12} 
                                                        sm={6}
                                                    >
                                                        <Button 
                                                            onClick={onSelectThumbnail(thumbnail)} 
                                                            sx={{padding:0}} 
                                                            disabled={uploadPending}
                                                        >
                                                            <Box
                                                                component={"img"} 
                                                                src={thumbnail.url} 
                                                                key={index} 
                                                                sx={[
                                                                    classes.thumbnail,
                                                                    selected && classes.thumbnailSelected
                                                                ]}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                )
                                            })
                                        )}

                                        {/***************************************** 
                                         * LISTE DES MINIATURES YOUTUBES (VIDES)
                                         * **** **********************************/}

                                        {!videoIsCorrect && Array.from(Array(4)).map((_,index)=>{
                                            return(
                                                <Grid 
                                                    item 
                                                    xs={12} 
                                                    sm={6} 
                                                    key={index} 
                                                    sx={[{height:{xs: mediaHeight, sm: mediaHeight/2 - 6}}]}
                                                >
                                                    <Box sx={[
                                                        classes.thumbnail, 
                                                        {
                                                            height:{xs: mediaHeight, sm: mediaHeight/2 - 6}, cursor: "default"
                                                        }
                                                    ]}>
                                                        <ImageNotSupported />
                                                    </Box>
                                                </Grid>
                                            )
                                        })}


                                        {/*********************** 
                                         * BOUTON UPLOAD D'IMAGE 
                                         * ******************* */}

                                        {!image?.cloudFlareId &&  (
                                            <Grid 
                                                item 
                                                xs={12} 
                                                sm={6}  
                                                sx={[{height:{xs: mediaHeight, sm: mediaHeight/2 - 6, marginTop: 3, marginBottom: 16}}]}
                                            >
                                                <Button sx={[
                                                    classes.uploadField, 
                                                    {cursor: "default", height:{xs: mediaHeight, sm: mediaHeight/2 - 6}}
                                                ]}>
                                                    <input 
                                                        type="file" 
                                                        id="upload-image" 
                                                        style={{display:"none", height: "100%", width: "100%"}} 
                                                        accept="image/png, image/jpeg" 
                                                        onChange={onFileChange}
                                                        disabled={uploadPending}
                                                    />
                                                        <Box 
                                                            sx={{flexDirection:"column", cursor: "pointer"}} 
                                                            component="label" 
                                                            htmlFor='upload-image'
                                                        >
                                                            <Typography>
                                                                Télécharger une image
                                                            </Typography>
                                                            <Typography>
                                                                Fichier jpg/png
                                                            </Typography>
                                                            <Typography>
                                                                Format 16/9 recommandé
                                                            </Typography>
                                                            <Download 
                                                                sx={{marginTop:1}}
                                                            />
                                                        </Box>
                                                </Button>
                                            </Grid>
                                        )}

                                        {/***************** 
                                         * IMAGE UPLOADEE 
                                         * ******** ******/}
                                        
                                        {image?.cloudFlareId &&  (
                                            <Grid 
                                                item xs={6}  
                                                sx={[{position: "relative"}]}
                                            >
                                                <Box
                                                    component={"img"} 
                                                    src={image.url} 
                                                    sx={[
                                                        classes.thumbnail,
                                                        classes.thumbnailSelected
                                                    ]}
                                                />

                                                <Box 
                                                    sx={classes.deleteImageButton}
                                                >
                                                    <IconButton 
                                                        onClick={()=>setOpenDeleteImage(true)}
                                                    >
                                                        <Delete 
                                                            sx={{color:'white'}}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        )}

                                    </Grid>
                                </Box>
                            </Grid>

                        </Grid>
                        
                    </Grid>


                    {/***********
                     * DROITE
                     ***********

                   
                    */}

                </Grid>

                {/*********************
                * Liste des aliments
                **********************/}

                <DialogFoodList
                    open={openDialogFood}
                    onClose={()=> setOpenDialogFood(false)}
                    fromRecipe={true}
                />

            </MainContent>


            <DialogLoader
                open={openWaitinUpdate || (pendingManualSave && !openSaveRecipe)}
                text={"Sauvegarde en cours ..."}
            />


            {/**** LOADER ENREGISTREMENT ***** */}
            <DialogLoader
                open={uploadPending}
                text={"Téléchargement l'image ..."}
            />


            <DialogSaveRecipe 
                open={openSaveRecipe}
                onClose={()=> setOpenSaveRecipe(false)}
                recipe={recipeForm}
                debounce={debounceAutoSave}
            />


            <DialogDeleteRecipeImage
                open={openDeleteImage}
                onClose={()=> setOpenDeleteImage(false)}
            />


            <Prompt
                when={hasUnsavedChanges}
                message="Tu n'as pas sauvegardé tes changements, veux-tu quitter la page ?"
            />


        </React.Fragment>
    )
    }else{
        return(
            <Loader />
        )
    }
}



const classes = {
    oops: {
        fontWeight: 700,
        fontSize: {
            xs: "2rem",
            sm: "2.6rem"
        },
        textAlign: "center"
    },

    description: {
        textAlign: {
            xs: "center",
            sm: "auto"
        }
    }
}


export default classes
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "utils/axios"
import Ingredient from "interfaces/Food"
import generateCiqualTable from "function/generateCiqualTable"
import { toast } from "react-toastify"
import Food from "interfaces/Food"


/////////// EXCLUSIVEMENT EN MODE DEVELOPPEMENT ////////////

export const fetchLocalCiqual = createAsyncThunk('food/local', async () => {

    try{
        const list:Ingredient[] = await generateCiqualTable()
        return {tableCiqual : list}
        
    }catch(err: any){
        return {error: "Impossible de récupérer les données"}
    }
})


export const getFoodList = createAsyncThunk('food/ciqual', async () => {

    try {
        const response = await axios.get(`food`)
        const foodList:Ingredient[] = response.data.foodList

        return { food: foodList}

      } catch (err: any) {
        toast.error('Impossible de récupérer la liste des aliments')
        return {
          error: err.response.data?.message[0]
        }
      }
})


export const createCustomFood = createAsyncThunk('food/create', async (data:Food) => {
  try {
    const response = await axios.post(`food`, data)
    const food:Food = response.data.food

    return { food}

  } catch (err: any) {
    toast.error('Impossible de créer un nouvel aliment')
    return {
      error: err.response.data?.message[0]
    }
  }
})


{/***********************
  Supprimer un aliment
*************************/}

export const deleteCustomFood = createAsyncThunk('food/delete', async (foodid:string) => {
  try {
    const response = await axios.delete(`food/${foodid}`)
    const food:Food = response.data.food

    return {food}

  } catch (err: any) {
    toast.error('Impossible de supprimer cet aliment')
    return {
      error: err.response.data?.message[0]
    }
  }
})
import { lighten } from "@mui/material"
import { BORDER_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes = {
    measureCard: {
        display: "flex", 
        alignItems: "center", 
        flexDirection: "column", 
        backgroundColor: "white", 
        paddingTop: 3, 
        paddingBottom: 3,
        position: "relative",
        border: `solid 1px ${BORDER_COLOR}`,

        "&:hover" : {
            backgroundColor: lighten(theme.palette.primary.main, 0.95),
            cursor : "pointer"
        }
    },

    expandIcon: {
        position: "absolute",
        top: 8,
        right: 8
    }
}

export default classes
import { Box, Typography } from "@mui/material";

interface Props{
    saved: boolean
}

export default function UnsavedIndicator({saved}:Props) {
    return (
        <Box sx={{display:"inline-flex", alignItems:"center", justifyContent:"center"}}> 
            {!saved && (
                <>
                    <Box component="span" sx={{backgroundColor: "#e74b34", borderRadius: 100, width: 12, height: 12, marginRight:1}} />
                    <Typography sx={{color: "#adadad", fontWeight: 500, fontSize: "0.9rem"}}>
                        Non enregistré
                    </Typography>
                </>
            )}

            {saved && (
                <>
                    <Box component="span" sx={{backgroundColor: "#24c02b", borderRadius: 100, width: 12, height: 12, marginRight:1}} />
                    <Typography sx={{color: "#adadad", fontWeight: 500, fontSize: "0.9rem"}}>
                       Enregistré
                    </Typography>
                </>
            )}
        </Box>
    )
}
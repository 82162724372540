import { createSlice } from '@reduxjs/toolkit'
import { getClientsHistory } from 'api/clients'
import { isAfter } from 'date-fns'
import { HistoryEventDetails } from 'interfaces/User'

/**
 * INIT
 */

interface Dashboard{
    history: HistoryEventDetails[],
    requests:{
        get: "idle" | "pending"
    }
}

const initialState = {
    history: [],
    requests:{
        get:"idle"
    }
} as Dashboard


export const dashboardSlice = createSlice({
    name:"dashboard",
    initialState,
    reducers:{
       
    },
    extraReducers(builder){
        builder
        .addCase(getClientsHistory.pending, (state)=>{
            state.requests.get = "pending"
        })
        .addCase(getClientsHistory.fulfilled, (state, {payload})=>{
            var currentHistory:HistoryEventDetails[] = payload.history
            // Trie par date
            currentHistory = currentHistory.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())

            state.history = currentHistory
            state.requests.get = "idle"
        })
    }
})


export const { 

  } = dashboardSlice.actions

export default dashboardSlice.reducer




import { BORDER_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


export const classes = {
   


    row : {
        display:"flex",
        flexShrink: 1,
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"center"
    },

    type:{
        paddingLeft: 1,
        paddingRight: 1,
        backgroundColor: "whitesmoke",
        borderRadius: 2,
        fontSize: theme.typography.pxToRem(13),
        marginRight: 1,
        color: "white",
        fontWeight: 500,
        marginTop: 0.5
    },


    muscle : {
        paddingLeft: 1,
        paddingRight: 1,
        backgroundColor: "#d9ecf1",
        borderRadius: 2,
        fontSize: theme.typography.pxToRem(13),
        marginRight: 1,
        fontWeight: 500,
        marginTop: 0.5
    },


    addedCheck: {
        position: "absolute", 
        index: 2, 
        left:0, 
        top:0, 
        background: "rgba(0,0,0,0.35)", 
        width: "100%", 
        height: "100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },  

    imageContainer:{
        marginRight: 2, 
        minWidth : {xs : 50, sm: 100}, 
        maxWidth : {xs : 50, sm: 100}, 
        minHeight: {xs : 50, sm: 60},
        maxHeight: {xs : 50, sm: 60},
        overflow: "hidden", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        borderRadius: {xs: "100px !important", sm : "10px !important"},
        backgroundColor: "#ebebeb",
        position: "relative"
    },

    image : {
        minWidth : {sm: 100}, 
        maxWidth : {sm: 100}, 
        minHeight: {xs : 50, sm: 60},
        maxHeight: {xs : 50, sm: 60},
       
        
    },

    exerciceInfos:{
        display:"flex", 
        flexDirection: {
            xs: "column",
            sm: "row"
        }, 
        flexShrink: 1, 
        position: "relative"
    },

    infos: {
        display:"flex", 
        flexDirection: "row",
        maxWidth: "100%"
    },


    draftIndicator : {
        backgroundColor: LIGHT_BUTTON_COLOR,
        color: "primary.main",
        height: 25,
        paddingLeft: 1, 
        paddingRight: 1,
        lineHeight: "25px",
        borderRadius: 1,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        marginTop: {
            xs: 1,
            sm: 0
        },
        marginLeft: 1
    },

    actions: {
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
    },

    button: {
        borderRadius: "100px !important"
    },

    draftBoxContainer : {
        display:"flex",
        flexDirection: "column",
        justifyContent:"center",
        alignItems:"center"
    },

    draftBox: {
        backgroundColor: "#adbabe",
    },

    draftBoxLabel : {
        color: "white",
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 600
    }
}

export default classes


// ROUTER
import {useHistory } from "react-router-dom"
// STORE
import { AppDispatch } from "app/store"
// MUI
import {Box, TextField, Typography, Paper, styled, Stack, InputAdornment, IconButton} from "@mui/material"
// REDUX
import { useDispatch } from "react-redux"
// FEATURES
import classes from "./styles"
import { theme } from "utils/theme";
import { forgotPassword, resetPassword, submitDigit } from "api/user"
import { useEffect, useMemo, useRef, useState } from "react"
import LoadingButton from "components/atoms/Buttons/LoadingButton"
import { toast } from "react-toastify";
import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import AuthLayout from "components/templates/AuthLayout"


const DigitInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#f1f6ff",
        border: '1px solid #ced4da75',
        fontSize: theme.typography.pxToRem(40),
        height: 60,
        textAlign:"center",
        width: 70,
        padding: '10px 12px',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
}))


/*********** MAIN COMPONENT ************** */

export default function ForgotPassword(){

    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const [digit, setDigit] = useState<string[]>(["","","",""]);
    const [success, setSuccess] = useState<boolean>(false)
    const [submiting, setSubmiting] = useState<boolean>(false)
    const [digitIsCorrect, setDigitIsCorrect] = useState<boolean>(false)
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [email, setEmail] = useState<string>('')
    const [token, setToken] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    // Reference des digits
    const digit1 = useRef(null)
    const digit2 = useRef(null)
    const digit3 = useRef(null)
    const digit4 = useRef(null)

    /******* SOUMISSION DU FORMULAIRE  - PART 1 -  EMAIL ********* */

    const onSubmit = async () => {
        if(email === ""){
            toast.error('Entre ton adresse e-mail')
            return
        }
        setSubmiting(true)
        dispatch(forgotPassword({email})).unwrap().then((res:any)=> {
            if(res.success){
                setSuccess(true)
                setSubmiting(false)
            } else {
                toast.error(res.message)
                setSubmiting(false)
            }
        }).catch((err)=>{
            toast.error('Aucun utilisateur trouvé')
            setSubmiting(false)

        })
    }


   
    /********* ***************
     * 
     * GESTION DES DIGITS 
     * 
     * ******** ****************/

   

    const onChangeDigit = (digitIndex:number) => (event:React.ChangeEvent<HTMLInputElement>) => {
        const digitsList:string[] = [...digit]
        const newValue = event.target.value
        const regex = /^[0-9]{0,1}$/
        if(regex.test(newValue)){
            digitsList[digitIndex] = newValue
            setDigit(digitsList)
           
        }
    }

    /******* On colle un DIGIT ********* */

    const pasteDigit = (e:any) => {
        const value = e.clipboardData.getData("text")
        const regex = /^[0-9]{4}$/
        if(regex.test(value)){
            var numbers = value.split('')
            setDigit(numbers)
        }
    }

    useEffect(() => {
        window.addEventListener("paste", pasteDigit);
    
        return () => {
          window.removeEventListener("paste", pasteDigit)
        };
    }, []);


    /******* SOUMISSION DU FORMULAIRE (DIGIT) ********* */

    const onSubmitDigit = () =>{
        setSubmiting(true)
        const newDigit:string = digit[0] +digit[1] + digit[2] + digit[3] 
        dispatch(submitDigit({digit: parseInt(newDigit), email: email})).unwrap().then((res:any)=> {
            if(res.digitIsCorrect && res.token){
                setDigitIsCorrect(true)
                setToken(res.token)
                setSubmiting(false)
            } else {
                toast.error('Le code est incorrect')
                setSubmiting(false)
            }
        }).catch((err)=>{
            toast.error(err.response.message)
            setSubmiting(false)
        })
    }


    const goToSignin = () => {
        history.push('/login')
    }


    /******* MODIFICATION DU MOT DE PASSE ********* */
    const onSubmitNewPassword = () =>{
       
        const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

        if(!regex.test(newPassword)){
            toast.error('Le format du mot de passe est incorrect')
         
            return
        }

        if(newPassword !== confirmPassword){
            toast.error('Les mots de passe ne correspondent pas')
          
            return
        }

        setSubmiting(true)
        dispatch(resetPassword({email,token,password:newPassword})).unwrap().then((res:any)=>{
            if(res.success){
                toast.success('Ton mot de passe a été modifié')
                history.push('/login')
                setSubmiting(false)
            } else {
                toast.error(res.message)
                setSubmiting(false)
            }
        }).catch((err)=>{
            toast.error(err.response.message)
            setSubmiting(false)
        })
    }


    const showPopper =  (e: React.SyntheticEvent) => {
        setAnchorEl(e.currentTarget)
    }

    const hidePopper =  (e: React.SyntheticEvent) => {
        setAnchorEl(null)

    }


    const validatePasswordFormat = useMemo(()=>{
        var characters = false
        var number = false
        var capital= false

        const charactersRegex = /.{8}/
        if(charactersRegex.test(newPassword)){
            characters = true
        }

        const regexNumber = /([0-9]){1}/
        if(regexNumber.test(newPassword)){
            number = true
        }

        const regexCapital = /([A-Z]){1}/
        if(regexCapital.test(newPassword)){
            capital = true
        }

        return {
            characters,number,capital
        }

    },[newPassword])


    return(
        <AuthLayout>
            
            {/*********** 1 - DEMANDE D'EMAIL ********** */}

            {!success && (
                <>
                    <Typography sx={classes.title}>
                        Mot de passe oublié
                    </Typography>

                    <Typography sx={classes.subtitle}>
                        Saisissez l'adresse email associée àvtre compte Traener. Un lien contenant un code de réinitialisation vous sera envoyé.
                    </Typography>

                    {/****** FORMULAIRE ******* */}
                    <form style={{width: "100%"}}>
        
                        <Typography sx={classes.textfieldLabel}>Email</Typography>
                            
                        <TextField 
                            value={email}
                            onChange={(e)=> setEmail(e.target.value)}
                            fullWidth={true} 
                            sx={{marginBottom: 3,}}
                            autoComplete="email"
                        />
                                    
                            
                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width: "100%", mt: 3}}>
                            <LoadingButton 
                                fullWidth={true} 
                                variant="contained" 
                                sx={{height: 48, minWidth: "100%"}}
                                disableElevation={true}
                                disabled={submiting}
                                loading={submiting}
                                onClick={onSubmit}
                            >
                                {submiting ? "Vérification ... " :"Valider"}
                            </LoadingButton>
                        </Box>
                    </form>
                </>
            )}


            {/*********** 2 - DEMANDE DE DIGIT ********** */}

            {(success && !digitIsCorrect) &&(
                <>
                    <Typography sx={classes.title}>
                        Mot de passe oublié
                    </Typography>
                    <Typography sx={classes.subtitle}>
                        Saisis le code reçu dans ta boite mail :
                    </Typography>
                    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", width: "100%", justifyContent:"space-between"}}>
                        <DigitInput autoFocus value={digit[0]} onChange={onChangeDigit(0)} ref={digit1}/>
                        <DigitInput value={digit[1]} onChange={onChangeDigit(1)} ref={digit2}/>
                        <DigitInput value={digit[2]} onChange={onChangeDigit(2)} ref={digit3}/>
                        <DigitInput value={digit[3]} onChange={onChangeDigit(3)} ref={digit4}/>
                    </Box>
                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width: "100%", mt: 4}}>
                        <LoadingButton 
                            fullWidth={true} 
                            variant="contained" 
                            sx={{height: 48, minWidth: "100%"}}
                            disableElevation={true}
                            disabled={submiting}
                            loading={submiting}
                            onClick={onSubmitDigit}
                        >
                            {submiting ? "Vérification ... " :"Valider"}
                        </LoadingButton>
                    </Box>
                </>
            )}


            {/********* 3 - Reinitialisation du MDP **************** */}

            {(digitIsCorrect && token) && (
                <>
                    <Typography sx={classes.title}>
                        Mot de passe oublié
                    </Typography>
                    <Typography sx={classes.subtitle}>
                        Le code est correct ! Saisis ton nouveau mot de passe :
                    </Typography>
                    <Stack sx={{width: "100%"}}>
                        <form>
                            <Typography sx={classes.textfieldLabel}>
                                Nouveau mot de passe
                            </Typography>
                            
                            <TextField 
                                fullWidth
                                name="password"
                                autoComplete="new-password"
                                value={newPassword}
                                onChange={(e)=> setNewPassword(e.target.value)}
                                type={"password"}
                                
                                onFocus={showPopper}
                                onBlur={hidePopper}
                                helperText={
                                    <Box sx={classes.passwordHelper}>
                                        <Box sx={classes.passwordHelperElem}>
                                            <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.characters && {color: "#57d657",}]} />
                                            8 caractères
                                        </Box>
                                        <Box sx={classes.passwordHelperElem}>
                                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.capital && {color: "#57d657",}]} />
                                            1 majuscule
                                        </Box>
                                        <Box sx={classes.passwordHelperElem}>
                                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.number && {color: "#57d657",}]} />
                                            1 chiffre
                                        </Box>
                                    </Box>
                                }
                            />

                        
                        
                            <Typography sx={[classes.textfieldLabel, {marginTop: 3}]}>
                                Confirmer le mot de passe
                            </Typography>
                            <TextField 
                                fullWidth
                                name="confirm-password"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e)=> setConfirmPassword(e.target.value)}
                                type={showPassword ? "text":"password"}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </form>

                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width: "100%", mt: 4}}>
                            <LoadingButton 
                                fullWidth={true} 
                                variant="contained" 
                                sx={{height: 48, minWidth: "100%"}}
                                disableElevation={true}
                                disabled={submiting}
                                loading={submiting}
                                onClick={onSubmitNewPassword}
                            >
                                {submiting ? "Vérification ... " :"Valider"}
                            </LoadingButton>
                        </Box>
                    </Stack>
                </>
         
            )}

            <Box sx={{display:"flex", flexDirection: "column", marginTop: 2, alignItems:"center"}}>
                <Typography 
                    sx={{fontWeight: 700, fontSize: theme.typography.pxToRem(16), color: "primary.main", cursor: "pointer"}} 
                    onClick={goToSignin}
                >
                    Retourner à l'écran de connexion
                </Typography>
            </Box>
        </AuthLayout>
    )
}
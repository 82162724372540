import { createSlice, current } from '@reduxjs/toolkit'
import { getUserData, updateClientMealPlan } from 'api/clients'
import { createMealPlanTemplate, deleteMealPlan, getMealPlanTemplate, getMealPlanTemplates, updateMealPlan } from 'api/mealPlans'
import { getTemplates } from 'api/templates'
import fuseSearch from 'function/fuseSearch'
import Food, { FoodPortion } from 'interfaces/Food'
import Meal from 'interfaces/Meal'
import MealPlan from 'interfaces/MealPlan'
import Recipe from 'interfaces/Recipe'
import { toast } from 'react-toastify'

interface MealPlanSlice{
    mealPlan?: MealPlan,
    requests:{
        create: "pending" | "idle",
        delete: "pending" | "idle",
        update: "pending" | "idle",
        getAll: "pending" | "idle",
        import : "pending" | "idle",
        get: "pending" | "idle"
    },
    templates:{
        list: MealPlan[],
        filtered: MealPlan[]
      },
      builderIsReady: boolean,
      mealCopied: Meal,
      mealPlanCopied: MealPlan,
      dayCopied: Meal[]
}

const initialState = {
    mealPlan: null,
    requests:{
        create: "idle",
        update: "idle",
        delete: 'idle',
        getAll: "idle",
        get: "idle"
    },
    templates:{
        list: [],
        filtered: []
    },
    builderIsReady: false,
    mealCopied: null,
    mealPlanCopied: null,
    dayCopied: null
} as MealPlanSlice


export const mealPlanSlice = createSlice({
    name: 'mealPlan',
    initialState,
    reducers:{
        // FUSE SEARCH
        searchMealPlansTemplates: (state, action) => {
            const search:string = action.payload.search
    
            const keys:string[] = [
            "name",
            ]
    
            state.templates.filtered = search !== "" ? fuseSearch(current(state.templates.list), search, keys) : state.templates.list
        },
        resetSearchMealPlans: (state) => {
            state.templates.filtered = state.templates.list
        },

        mealPlanIsSaving: (state) => {
          state.requests.update = "pending"
        },
    


        resetMealPlanBuilder : (state) => {
            state.builderIsReady = false
            state.mealPlan = null
        },

        onChangeMealPlanName : (state, {payload}) => {
          state.mealPlan.name = payload.name
        },

        onAddMeal : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
      
            var newMeal:Meal = {
              name: "Petit déjeuner",
              time: "08:00",
              content: []
            }
      
            const mealsLenght:any = state.mealPlan ? state.mealPlan[day]?.length : 0

            var lastMeal = state.mealPlan? state.mealPlan[day][mealsLenght - 1] : null
      
            if(lastMeal?.name === null){

              newMeal = {
                name: "Petit déjeuner",
                time: "08:00",
                content: []
              }
            }else if(lastMeal?.name === "Petit déjeuner"){
              newMeal = {
                name: "En-cas",
                time: "10:00",
                content: []
              }
            }else if(lastMeal?.name === "En-cas" && lastMeal.time === "10:00"){
              newMeal = {
                name: "Déjeuner",
                time: "12:00",
                content: []
              }
            }else if(lastMeal?.name ==="Déjeuner"){
              newMeal = {
                name: "En-cas",
                time: "16:00",
                content: []
              }
            }else if(lastMeal?.name === "En-cas" && lastMeal.time === "16:00"){
              newMeal = {
                name: "Dîner",
                time: "19:00",
                content: []
              }
            } else {
              newMeal = {
                name: "Petit déjeuner",
                time: "08:00",
                content: []
              }
            }
      
            if(!state.mealPlan || !state.mealPlan[day]){

              const newMealPlan:MealPlan = {
                status: "disabled",
                monday : [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: []
              }

              newMealPlan[day].push(newMeal)
              state.mealPlan = {...newMealPlan}

              
            }else{
              state.mealPlan[day].push(newMeal)
            }
      
            
          },
      
          onDeleteMeal: (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const index = payload.index
            state.mealPlan[day].splice(index,1)
            toast.success('Repas supprimé')
          },
      
          onAddIngredient : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const index = payload.index
            const food:Food = payload.food
    
      
            state.mealPlan[day][index].content.push({
              type: "food",
              food,
              portion: food.portions?.length > 0 ? food.portions[0].equivalent.portion : 100,
              label: food.portions?.length > 0 ? food.portions[0].label.fr : "grammes",
              grammage: food.portions?.length > 0 ? food.portions[0].equivalent.portion * (food.portions[0].equivalent.value / food.portions[0].equivalent.portion) : 100
            })
          },
      
          onDeleteIngredient : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const mealIndex = payload.mealIndex
            const ingredientIndex = payload.ingredientIndex
      
            state.mealPlan[day][mealIndex].content.splice(ingredientIndex,1)
      
          },
      
          onAddRecipeToMeal:(state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const index = payload.index
            const recipe:Recipe = payload.recipe

            console.log('la recipe', recipe)
            
            state.mealPlan[day][index].content.push({
              type: "recipe",
              recipe,
              portion: recipe.portions,
            })
          },
      
          onChangeIngredientPortion : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const foodIndex = payload.foodIndex
            const mealIndex = payload.mealIndex
            const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)
            const currentLabel = state.mealPlan[day][mealIndex].content[foodIndex].label
            state.mealPlan[day][mealIndex].content[foodIndex].portion = payload.portion
      
            // Calcul du grammage
            // Grammes ou mililitres
            if(currentLabel === "grammes" || currentLabel === "millilitres"){
              state.mealPlan[day][mealIndex].content[foodIndex].grammage = payload.portion
            }
      
            // Portions personnalisées
            else{
              // Calcul du grammage
              const label:string = state.mealPlan[day][mealIndex].content[foodIndex].label
              const foodPortion = foodPortions.find((elem)=> elem.label.fr === label)
              const grammage = payload.portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
      
              state.mealPlan[day][mealIndex].content[foodIndex].grammage = grammage
      
            }
            
      
          },
      
          onChangePortionLabel : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const foodIndex = payload.foodIndex
            const mealIndex = payload.mealIndex
            const newLabel = payload.newLabel
            const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)
      
            state.mealPlan[day][mealIndex].content[foodIndex].label = newLabel
            const portion = state.mealPlan[day][mealIndex].content[foodIndex].portion
      
            if(newLabel === "grammes" || newLabel === "millilitres"){
              state.mealPlan[day][mealIndex].content[foodIndex].grammage = portion
            } else {
              const foodPortion = foodPortions.find((elem)=> elem.label.fr === newLabel)
              const grammage = portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
              state.mealPlan[day][mealIndex].content[foodIndex].grammage = grammage
            }
          },
    


          onChangeMealRecipePortion : (state, {payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const mealIndex = payload.mealIndex
            const recipeIndex = payload.recipeIndex
            const portion = payload.portion
      
            state.mealPlan[day][mealIndex].content[recipeIndex].portion = portion
          },

                
          onChangeMealRecipeIngredientPortion: (state,{payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const mealIndex = payload.mealIndex
            const recipeIndex = payload.recipeIndex
            const ingredientIndex = payload.ingredientIndex
            const portion = payload.portion
            const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)

            const currentLabel = state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.label
          
            state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.portion = portion
            
            if(currentLabel === "grammes" || currentLabel === "millilitres"){
              state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.grammage = payload.portion
            }
      
            // Portions personnalisées
            else{
              // Calcul du grammage
              const label:string = state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.label
              const foodPortion = foodPortions.find((elem)=> elem.label.fr === label)
              const grammage = payload.portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
      
              state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.grammage = grammage
      
            }
          },


          onChangeMealRecipeIngredientLabel: (state,{payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const mealIndex = payload.mealIndex
            const recipeIndex = payload.recipeIndex
            const ingredientIndex = payload.ingredientIndex
            const newLabel = payload.newLabel
            const foodPortions:FoodPortion[] = payload.foodPortions // Portions personnalisées de l'ingrédient (ex: gros, moyen ...)

            state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.label = newLabel
            const portion = state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.portion
          
            
            if(newLabel === "grammes" || newLabel === "millilitres"){
              state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.grammage = portion
            }
      
            // Portions personnalisées
            else{
              // Calcul du grammage
              const foodPortion = foodPortions.find((elem)=> elem.label.fr === newLabel)
              const grammage = portion * (foodPortion.equivalent.value / foodPortion.equivalent.portion)
              state.mealPlan[day][mealIndex].content[recipeIndex].recipe.ingredients[ingredientIndex].ingredient.grammage = grammage
      
            }
          },
      
          onDeleteMealRecipe: (state,{payload}) => {
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const mealIndex = payload.mealIndex
            const recipeIndex = payload.recipeIndex
      
            state.mealPlan[day][mealIndex].content.splice(recipeIndex,1)
          },
      
          onChangeMealName : (state, {payload}) =>{
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const name = payload.name
            const mealIndex = payload.mealIndex
            state.mealPlan[day][mealIndex].name = name
          },
      
          onChangeMealTime : (state, {payload}) =>{
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
            const time = payload.time
            const mealIndex = payload.mealIndex
            state.mealPlan[day][mealIndex].time = time
          },
      
          onCopyMeal : (state, {payload}) => {
            const meal = payload.meal
            state.mealCopied = meal
          },
      
          onPastMeal : (state,{payload}) => {
            const meal = state.mealCopied
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
      
      
            state.mealPlan[day].push(meal)
          },

          onCopyDayMeals : (state,{payload}) => {
            const meals = payload
            console.log('MEALS COPIES', meals)
            state.dayCopied = [...meals]
          },

          onPasteDayMeals : (state,{payload}) => {
            const meals = state.dayCopied
            const day:'monday' |"tuesday"| "wednesday"| "thursday"| "friday"| "saturday"| "sunday" = payload.day
      
      
            state.mealPlan[day] = [... state.mealPlan[day]].concat(meals)
          },
      
          onCopyMealPlan : (state) => {
            state.mealPlanCopied = {...state.mealPlan, _id: undefined}
          },
      
          onPasteMealPlan : (state) => {
            state.mealPlan = state.mealPlanCopied
          },
      
      
          onImportMealPlan : (state,{payload}) => {
            state.mealPlan = payload.mealPlan
          }
      
    },
    extraReducers(builder){

        // Create Meal Plan
        builder.addCase(createMealPlanTemplate.pending, (state) => {
            state.requests.create = "pending"
        })
        builder.addCase(createMealPlanTemplate.fulfilled, (state,{payload}) => {
            state.requests.create = "idle"
            const {mealPlan} = payload

            if(mealPlan?.template){ // template
                const customList = [...current(state.templates.list)]
                customList.push(mealPlan)
                const sortedList = customList.sort((a,b)=> {
                  if(a.name > b.name){
                    return 1
                  } else if(a.name < b.name){
                    return -1
                  }else return 0
                })
      
                state.templates.list = sortedList
                state.templates.filtered = sortedList
            }
        })


        builder.addCase(getTemplates.fulfilled, (state, {payload}) => {
            if(payload.templates.mealPlans){
                state.templates.list = payload.templates.mealPlans
                state.templates.filtered =payload.templates.mealPlans
            }


        })


         /******** DELETE MEALPLAN ******* */
        .addCase(deleteMealPlan.pending, (state) => {
            state.requests.delete = "pending"
        })
  
        // template
        .addCase(deleteMealPlan.fulfilled, (state,{payload}) => {
            state.requests.delete = "idle"
            const {mealPlan} = payload
            if(mealPlan?.template){ 
                const listIndex = state.templates.list.findIndex((elem:MealPlan)=> elem._id === mealPlan._id)
                state.templates.list.splice(listIndex,1)
                const filteredIndex = state.templates.filtered.findIndex((elem:MealPlan)=> elem._id === mealPlan._id)
                state.templates.filtered.splice(filteredIndex,1)
            }
        })
      
        .addCase(deleteMealPlan.rejected, (state) => {
            state.requests.delete = "idle"
            toast.error('Impossible de supprimer ce plan alimentaire')
        })

        /************************** 
         * GET MEALPLAN (TEMPLATE)
         * ************************/

        .addCase(getMealPlanTemplate.pending, (state) => {
            state.requests.get = "pending"

        })

        .addCase(getMealPlanTemplate.fulfilled, (state, {payload}) => {
            state.requests.get = "idle"
            state.mealPlan = payload.mealPlan
            state.builderIsReady = true
            
        })


        /***************************
         *  GET MEALPLAN (CLIENT)
         *************************/

        .addCase(getUserData.fulfilled, (state,{payload})=> {
              if(payload.client?.mealPlan){
                console.log('meal plan récup', payload.client.mealPlan)
                state.mealPlan = payload.client.mealPlan
              }
        })


        /***************************
         *  UPDATE MEALPLAN (TEMPLATE)
         *************************/

        .addCase(updateMealPlan.pending, (state,{payload}) => {
          state.requests.update = "pending"
        })

        .addCase(updateMealPlan.fulfilled, (state,{payload}) => {
          
          state.requests.update = "idle"

          if(payload.mealPlan?.template){
              const mealPlan = payload.mealPlan
              const listIndex = state.templates.list.findIndex((elem:MealPlan)=> elem._id === mealPlan._id)
              const currentName = state.templates.list[listIndex].name
              if(currentName !== mealPlan.name){
                state.templates.list[listIndex].name = mealPlan.name
                const filteredIndex = state.templates.filtered.findIndex((elem:MealPlan)=> elem._id === mealPlan._id)
                state.templates.filtered[filteredIndex].name = mealPlan.name
              }
          }
        })

          /***************************
         *  UPDATE MEALPLAN (CLIENT)
         *************************/


        .addCase(updateClientMealPlan.pending, (state) => {
            state.requests.update = "pending"
        })
        .addCase(updateClientMealPlan.fulfilled, (state, {payload}) => {
          state.requests.update = "idle"
          const currentStatus = state.mealPlan?.status
          const newStatus = payload.client.mealPlan?.status
          if(currentStatus !== newStatus && state.mealPlan){
            state.mealPlan.status = payload.client.mealPlan.status
          }
        })


    },
    
})


export const {
    searchMealPlansTemplates,
    resetSearchMealPlans,
    resetMealPlanBuilder,
    onAddMeal,
    onDeleteMeal,
    onAddIngredient,
    onDeleteIngredient,
    onChangeIngredientPortion,
    onChangePortionLabel,
    onChangeMealTime,
    onChangeMealName,
    onPastMeal,
    onCopyMeal,
    onAddRecipeToMeal,
    onChangeMealRecipePortion,
    onDeleteMealRecipe,
    onCopyMealPlan,
    onPasteMealPlan,
    onImportMealPlan,
    onChangeMealPlanName,
    mealPlanIsSaving,
    onCopyDayMeals,
    onPasteDayMeals,
    onChangeMealRecipeIngredientPortion,
    onChangeMealRecipeIngredientLabel
} = mealPlanSlice.actions


export default  mealPlanSlice.reducer

import IcoMoon, { IconProps } from "react-icomoon";
const iconSet = require('./icons.json')



export default function Icon(props: IconProps) {

    return (
        <IcoMoon iconSet={iconSet} {...props} />
    )
}
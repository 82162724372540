
import calcBoxHeight from "function/calcBoxHeight"
import { BORDER_COLOR, DRAWER_WIDTH_MIN, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {



    header:{
        display:"flex",
        flexDirection: "row",
        justifyContent:"space-between",
        alignItems:"center"
    },

    tagInfo: {
        backgroundColor: "#ffd6df",
        padding: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        fontSize: "0.9rem",
        marginBottom: 2,
        display:"inline-flex",
        border: `solid 1px ${BORDER_COLOR}`,
        flexWrap: "no-wrap",
        marginRight: 1,
        flexDirection: "row",
        alignItems:"center",
        height: 38,
        borderRadius: 1,
    },

    tagInfoIconEnabled:{
        marginRight: 0.5,
        color: "#24c02b"
    },


    tagInfoClickable:{
        //backgroundColor: LIGHT_BUTTON_COLOR,
        cursor:"pointer",
        fontSize: 20,
        "&:hover":{
            backgroundColor: LIGHT_BUTTON_COLOR
        }
    },

    tagInfoEnabled: {
        backgroundColor: "#d5f1d7",
        border: "solid 1px #c8e1ca",

        "&:hover" : {
            backgroundColor: "#bbe4bd"
        }
    },

    tagInfoIcon : {
        marginRight: 0.5,
        color: "primary.main",
        fontSize: 20
    },

    tagInfoValue: {
        fontWeight: 600,
        marginLeft: 1,
        fontSize: "0.9rem",
        flexWrap: "nonwrap",
        whiteSpace: "nowrap"
    },

    disabledIcon: {
        color: "gray"
    },

    nutrition: {
        width: "100%",
        position:"relative",
    },


    
    content:{
        width: "100%",
        paddingTop: 3,
        minHeight: 300,
        paddingBottom: "100px"
    },

    button: {
        display:"flex",
        alignItems:"flex-start",
        justifyContent:"flex-start",
        flexDirection: {
            xs: "column",
            sm: "row"
        }
    },

    stats: {
        backgroundColor: "white",
        width: "100%",
        border: `solid 1px ${BORDER_COLOR}`,
        padding: 3,
        marginBottom: 3
    },

    statsTitle: {
        fontWeight: 700
    },

    chartContainer: {
        display:"flex",
        justifyContent:"center",
        alignContent: "center",
        marginBottom: 3
    },

    noMealContainer:{
        padding: 3,
        //border: "solid 2px #dfdfdf",
        borderRadius: 1,
        //backgroundColor: "white",
        border: `dashed 8px ${BORDER_COLOR}`,
        marginBottom: 3,
        minHeight: 300,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },


    noMealContainerLabel : {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        color: "#b7b7b7"
    },

    buttonMore : {
        borderRadius: "100px !important",
        marginLeft: 3
    },
    

}

export default classes
import { Close, Delete } from "@mui/icons-material"
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import { FoodPortion } from "interfaces/Food"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import { onChangeRecipeIngredientPortion, onChangeRecipeIngredientPortionLabel, onDeleteRecipeIngredient } from "features/recipesSlice"


interface Props{
    ingredientIndex: number,
}


export default function RecipeItem({ingredientIndex}:Props){

    const portion = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.portion)
    const foodPortions = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.food.portions)
    const name = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.food.name.fr)
    const label = useSelector((state:RootState)=> state.recipes.builder.ingredients[ingredientIndex].ingredient.label)
    const dispatch = useDispatch<AppDispatch>()


    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeRecipeIngredientPortion({
            index: ingredientIndex,
            portion: event.target.value,
            foodPortions: foodPortions
        }))
    }

    const changePortionLabel = (event: SelectChangeEvent<string>) => {
        dispatch(onChangeRecipeIngredientPortionLabel({
            index: ingredientIndex,
            newLabel: event.target.value,
            foodPortions: foodPortions
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteRecipeIngredient({index:ingredientIndex}))
    }


    const PORTIONS:FoodPortion[] = useMemo(()=>{
        const allPortions:FoodPortion[] = [
            {
                label: {
                    fr: "grammes",
                    en: "grams"
                },
                equivalent: {
                    portion: 100,
                    value: 100
                } 
            },
            {
                label: {
                    fr: "millilitres",
                    en: "milliliters"
                },
                equivalent: {
                    portion: 100,
                    value: 100
                } 
            }
        ]

        
        if(foodPortions){
            return allPortions.concat(foodPortions)
        }else{
            return allPortions
        }
    },[foodPortions])

    return(
        <Box
            sx={classes.item}
        >   
            <Box
                sx={classes.itemInfos}
            >

                <TextField
                    value={portion}
                    sx={{width: 100, marginRight: 1}}
                    size="small"
                    onFocus={event => {
                        event.target.select();
                    }}
                    onChange={onChangePortion}
                />

                <Select
                    size="small"
                    value={label}
                    onChange={changePortionLabel}
                    sx={{width: 170, minWidth: 170, maxHeight: 40}}
                >
                    {PORTIONS.map((elem, index)=> {
                        return(
                            <MenuItem 
                                key={index}
                                value={elem.label.fr}
                            >
                                {elem.label.fr}
                            </MenuItem>
                        )
                    })}
                    
                    
                        
                </Select>

                <Typography
                    sx={classes.itemName}
                >
                    {name}
                </Typography>
            </Box>
            <Box>
                <IconButton
                    onClick={onDelete}
                >
                    <Delete/>
                </IconButton>
            </Box>
        </Box>
    )


}
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "utils/axios"
import sleep from "function/sleep"
import Recipe from "interfaces/Recipe"



// Création d'un nouveau brouillon
export const createRecipe = createAsyncThunk('recipes/createRecipe', async (data:{name:{fr:string}}) => {

    const form:Recipe = {
        name: {
          fr: data.name.fr,
          en: ""
        },
        description:{
          fr: "",
          en: ""
        },
        ingredients: [],
        portions: 1,
        steps:[],
        publishForClient: false,
        cover:null,
        tags:[],
        video: null,
        draft: true
    }
  
    try {
      const response = await axios.post(`recipes`, form)
      const recipe:Recipe = response.data.recipe
      await sleep(1000)
      return { recipe }
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
})


// Création d'un nouveau brouillon
export const getRecipes = createAsyncThunk('recipes/get', async () => {

  try {
    const response = await axios.get(`recipes`)
    const recipes = response.data.recipes
    await sleep(1000)
    return { recipes }
  } catch (err: any) {
    return {
      error: err.response.data?.message[0]
    }
  }
})


// SUPPRESSION D'UNE RECETTE
export const deleteRecipe = createAsyncThunk('recipes/delete', async(data:{recipe: Recipe})=>{
  try {
      const response = await axios.delete(`recipes/${data.recipe._id}`)
      const recipe = response.data.recipe
      await sleep(1000)
      return { recipe }
  } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
  }
})


export const getRecipe = createAsyncThunk('recipe/get', async (id: string) => {

  try {
    const response = await axios.get(`recipes/${id}`)
    const recipe:Recipe = response.data.recipe
    return { recipe }

  } catch (err: any) {

    return {
      error: err.response.data?.message[0]
    }
  }
})


export const updateRecipe = createAsyncThunk('recipe/update', async (recipe:Recipe) => {

  try {
      const response = await axios.put(`recipes/${recipe._id}`, recipe)
      const updatedRecipe: Recipe = response.data.recipe
      return { recipe:{
        ...updatedRecipe,
        ingredients: recipe.ingredients
      }}

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }
})


  /****************** ENREGISTREMENT DE L'image de la recette **************** */

  export const uploadRecipeImage = createAsyncThunk('recipes/upload/image', async (data:{recipeId:string, file:Blob}) => {

    var formData = new FormData();
    formData.append('file', data.file)

    try {
      
     // On poste l'image pour téléchargement sur cloudflare
     const result = await axios.post(`recipes/recipe/${data.recipeId}/image`, formData)
     const image:{url:string,cloudFlareId:string} = result.data.image
     return {image}

  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })

  /****************** SUPPRESSION DE L'image de la recette **************** */


  export const deleteRecipeImage = createAsyncThunk('recipes/deleteImage', async (data:{recipeId:string})=>{

    try{
      const result = await axios.delete(`recipes/recipe/${data.recipeId}/image`) // Suppression du fichier sur cloudflare ET dans la BDD
      const cover = result.data.cover
      return {cover}
    }
    catch(err:any){
      return ({error: err.response.data?.message[0]})
    }
    
  })


  export const manualSaveRecipe = createAsyncThunk('recipes/manualSave', async(recipe:Recipe)=>{
    const formatedData = {
      ...recipe,
      draft: false
    }


    try {
      const response = await axios.put(`recipes/${formatedData._id}`, formatedData)
      const updatedRecipe: Recipe = response.data.recipe
      await sleep(1500)
      return { recipe:{
        ...updatedRecipe,
        ingredients: recipe.ingredients
      }}
      
   
      

  } catch (err: any) {
      return {
          error: err.response.data?.message[0]
      }
  }
  })
import { theme } from "utils/theme"

const classes = {
    video:{
        width: "100%",  
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
         position: "relative"
    },

    header:{
        paddingTop: 1,
        paddingBottom: 2, 
        borderBottom: "solid 1px whitesmoke", 
        display:"flex", 
        alignItems:"flex-start",
        flexDirection: "column",
        marginTop: 3,
    },

    name:{
        fontWeight: 700,
        fontSize: {
            xs: theme.typography.pxToRem(18),
            sm: theme.typography.pxToRem(22)
        },
        marginBottom: 1
    },

    type:{
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14)
    },

    loading:{
        display:"flex", 
        position: "absolute", 
        width: "100%", 
        alignItems:"center", 
        justifyContent:"center" 
    },

    section:{
        height: 70, 
        display:"flex", 
        flexDirection: "row", 
        borderBottom: "solid 1px whitesmoke", 
        alignItems:"center", 
        justifyContent:"space-between"
    },

    sectionTitle : {
        fontWeight: 700
    },

    description:{
        display:"flex", 
        flexDirection: "column",  
        alignItems:"flex-start", 
        justifyContent:"space-between"
    }
}

export default classes


const classes = {

    listHeader : {
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"space-between",
        mb:3
    }
}


export default classes
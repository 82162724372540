import {useEffect, useMemo, useState} from "react"
// MUI
import {TextField, IconButton, InputAdornment, DialogTitle, Dialog, DialogContent, DialogActions, Button, Box, useMediaQuery} from "@mui/material"
import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";
//INterface
import { AppDispatch, RootState} from "app/store";
// REDUX
import { useSelector, useDispatch } from "react-redux";
// Apis
import { updateUserData } from "api/user";
// Constants
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
// Toastify
import { toast } from "react-toastify";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import classes from "./styles"

/*********** REDUX SELECTORS *************** */ 


interface DialogPropsTypes {
    open: boolean,
    onClose: ()=> void,
}


/*********** MAIN COMPONENT *************** */ 

export default function DialogPassword({open,onClose}:DialogPropsTypes){

    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const mobile = useMediaQuery(MOBILE)

    useEffect(()=>{
        if(open){
            setNewPassword("")
            setConfirmPassword("")
            setCurrentPassword("")
        }
    },[open])

    const dispatch = useDispatch<AppDispatch>()

    const submit = () => {
        const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

        if(!regex.test(newPassword)){
            toast.error('Le format du mot de passe est incorrect')
            return
        }

        if((newPassword !== confirmPassword)){
            toast.error('Les mots de passe ne correspondent pas')
            return 
        }

        dispatch(updateUserData({newPassword,currentPassword})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
    }


    const validatePasswordFormat = useMemo(()=>{
        var characters = false
        var number = false
        var capital= false

        const charactersRegex = /.{8}/
        if(charactersRegex.test(newPassword)){
            characters = true
        }

        const regexNumber = /([0-9]){1}/
        if(regexNumber.test(newPassword)){
            number = true
        }

        const regexCapital = /([A-Z]){1}/
        if(regexCapital.test(newPassword)){
            capital = true
        }

        return {
            characters,number,capital
        }

    },[newPassword])


    return(

        <Dialog 
            open={open} 
        >
            <DialogTitle>
                Modifier ton mot de passe
            </DialogTitle>
            <DialogContent sx={{width:mobile ? "100%" : DIALOG_WIDTH}}>
            <TextField
                fullWidth
                name={"password"}
                label="Mot de passe actuel"
                onChange={(e)=> setCurrentPassword(e.target.value)}
                value={currentPassword}
                type={showCurrentPassword ? 'text' : 'password'}
                autoComplete="off"
                disabled={pending}
                sx={{marginBottom: 2, marginTop: 1}}
                InputProps={{
                    endAdornment:(
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=> setShowCurrentPassword(!showCurrentPassword)}
                        >
                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <TextField
                fullWidth
                name={"newPassword"}
                label="Nouveau mot de passe"
                onChange={(e)=> setNewPassword(e.target.value)}
                value={newPassword}
                autoComplete="off"
                disabled={pending}
                sx={{marginBottom: 2}}
                type={'password'}
                helperText={
                    <Box sx={classes.passwordHelper}>
                        <Box sx={classes.passwordHelperElem}>
                            <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.characters && {color: "#57d657",}]} />
                            8 caractères
                        </Box>
                        <Box sx={classes.passwordHelperElem}>
                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.capital && {color: "#57d657",}]} />
                            1 majuscule
                        </Box>
                        <Box sx={classes.passwordHelperElem}>
                        <CheckCircle sx={[classes.passwordHelperIcon, validatePasswordFormat.number && {color: "#57d657",}]} />
                            1 chiffre
                        </Box>
                    </Box>
                }
            />


            <TextField
                fullWidth
                name={"confirmPassword"}
                label="Confirme ton nouveau mot de passe"
                onChange={(e)=> setConfirmPassword(e.target.value)}
                value={confirmPassword}
                type={'password'}
                disabled={pending}
                autoComplete="off"
                
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                    {pending? "Modification ..." : "Valider"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles";
import { Box, Collapse, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import {Delete, ExpandMore, KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import DialogRecipe from "../DialogRecipe";
import { onChangeMealRecipeIngredientPortion, onChangeMealRecipePortion, onDeleteMealRecipe } from "features/mealPlanSlice";
import { MOBILE } from "utils/constants";
import MealFoodItem from "../MealFoodItem";
import RecipeItem from "../RecipeItem";
import MealRecipeIngredient from "../MealRecipeIngredient";
import styled from "@emotion/styled";
import { theme } from "utils/theme";

interface Props{
    recipeIndex: number,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
           
        }
    },
}))



function Buttons ({expandRecipe, expanded, onDelete}:{expandRecipe: ()=>void, expanded: boolean, onDelete: ()=>void}){
    return(
        <Box
            sx={{display:"flex", alignSelf: "flex-start", alignItems:"center"}}
        >
            <IconButton 
                onClick={expandRecipe}
                sx={[
                    classes.dropDownButton(expanded), 
                    classes.expandButton, 
                    {marginRight: 0}
                ]}
                className="collapse-exercice"
            >
                <KeyboardArrowDown />
            </IconButton>
            <IconButton
                onClick={onDelete}
            >
                <Delete/>
            </IconButton>
            </Box>
    )
}


export default function MealRecipeItem({recipeIndex, mealIndex, day}:Props){

    
    const portion = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].portion)
    const recipe = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe)
    const thumbnail = useSelector((state:RootState) => state.mealPlans.mealPlan[day][mealIndex]?.content[recipeIndex].recipe.cover.url)
    const dispatch = useDispatch<AppDispatch>()
    const [openRecipeDialog, setOpenRecipeDialog] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)
    const [expanded, setExpanded] = useState<boolean>(false)


    const expandRecipe = () => {
        setExpanded(!expanded)
    }

    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeMealRecipePortion({
            day,
            recipeIndex,
            mealIndex,
            portion: event.target.value,
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteMealRecipe({
            day,
            recipeIndex,
            mealIndex
        }))
    }






    return(
        <Box
            sx={classes.item}
        >

            {/****************
             * HEADER ITEM
             *****************/}

            

            <Box 
                sx={classes.itemHeader}
            >
                <Box
                    sx={classes.itemInfos}
                >
                
                 
                    <Box
                        sx={classes.thumbnail}>
                            <Box 
                                component="img"
                                src={thumbnail ? thumbnail : "https://imagedelivery.net/qsQDCGgCbnSFthoQCKOq5w/a5158a4a-2ee9-40d6-fe0b-84b8b5fb3d00/public"}
                                sx={classes.cover}
                                onClick={()=> setOpenRecipeDialog(true)}
                            />
                    </Box>
                    
                    {/**************
                     * RECIPE INFOS
                     *************/}
                    <Box
                        sx={classes.recipeInfos}>
                        
                            <Typography
                                sx={classes.itemName}
                            >
                                Recette - {recipe.name.fr}
                            </Typography>

                            <Box
                                sx={{
                                    display:"flex",
                                    order: 2,
                                    position: "relative", top: -10,
                                    flexDirection:"row",
                                    justifyContent:{xs: "space-between", sm: "flex-start"},
                                    alignItems:"center"
                                }}
                            >
                                <TextField
                                    value={portion}
                                    sx={{width: 130, marginRight: 1, marginTop: 1, minWidth: 130}}
                                    size="small"
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={onChangePortion}
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                portion(s)
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {mobile && (
                                    <Buttons
                                        expandRecipe={expandRecipe}
                                        expanded={expanded}
                                        onDelete={onDelete}
                                    />
                                )}
                            </Box>
                    </Box>

                
                </Box>

                {!mobile && (
                    <Buttons
                        expandRecipe={expandRecipe}
                        expanded={expanded}
                        onDelete={onDelete}
                    />
                )}
            </Box>

       


            <Box sx={classes.ingredients}>
                
                <Collapse 
                    in={expanded} 
                    mountOnEnter 
                    unmountOnExit
                >

                    {recipe.ingredients.map((_,index)=> {
                        return(
                            <MealRecipeIngredient 
                                key={index}
                                mealIndex={mealIndex}
                                recipeIndex={recipeIndex}
                                ingredientIndex={index}
                                day={day}
                            />
                        )
                    })}

                {expanded && (
                    <Box
                        sx={classes.lineIndicator}
                    />
                )}
                </Collapse>
            </Box>

            <DialogRecipe
                open={openRecipeDialog}
                onClose={()=>setOpenRecipeDialog(false)}
                recipe={recipe}
            />

        </Box>
    )
}
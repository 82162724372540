import { Box, useMediaQuery } from "@mui/material"
import React from "react"
import { HEADER_MIN_HEIGHT, HEADER_MIN_HEIGHT_CLIENTPROFILE, HEADER_MIN_HEIGHT_EXPANDED, MOBILE } from "utils/constants"
import classes from "./styles"

interface Props{
    children: React.ReactNode,
    headerRef?: React.Ref<React.ReactNode>,
    expanded?: boolean,
    clientProfile?: boolean
}

export default function Header({children, expanded = false, clientProfile= false}:Props){


    return(
        <Box 
            sx={[
                classes.header,
                {
                    height: clientProfile ? HEADER_MIN_HEIGHT_CLIENTPROFILE :  expanded ? HEADER_MIN_HEIGHT_EXPANDED : HEADER_MIN_HEIGHT

                }, 
                
            ]}
            >
                {children}
        </Box>
    )
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { useState } from "react";
import classes from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { toast } from "react-toastify";
import { deleteAccount, logout } from "api/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useHistory } from "react-router-dom";
import axios from "utils/axios"
import { MOBILE } from "utils/constants";


interface Props{
    open: boolean,
    onClose: () =>void
}

export default function DialogDeleteAccount({open, onClose}:Props){
    
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState)=> state.user.data)
    const [password, setPassword] = useState<string>("")
    const [supp, setSupp] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const pending = useSelector((state:RootState)=> state.user.requests.delete === "pending")
    const mobile = useMediaQuery(MOBILE)

    const onDelete = () => {
        if(supp !== "SUPPRIMER"){
            toast.error('Tapez SUPPRIMER en lettre majuscule')
        }

        dispatch(deleteAccount({email:user.email, password, userId: user._id})).unwrap().then((res)=> {
            if(res.success){
                toast.success('Votre compte a été supprimée')
                axios.defaults.headers.common['authorization'] = null
            }else {
                toast.error(res.error)
            }
        })
    }

    return(
        <Dialog open={open} fullScreen={mobile}>
            <DialogTitle>
                Supprimer mon compte
            </DialogTitle>
            <DialogContent>
                En supprimant définitivement votre compte Traener, l'ensemble des données ci-dessous sera supprimée :
                <ul>
                    <li>Vos données personnelles</li>
                    <li>Vos données corporelles</li>
                    <li>Vos séances effectuées</li>
                    <li>Vos questionnaires complétés</li>
                    <li>Vos modèles de séances, programmes et questionnaires</li>
                    <li>Vos données de facturation ainsi que l'historique de vos paiements</li>
                </ul>
                
                <strong>Cette action est instantanée et irréversible, aucune des données citées ci-dessus ne pourra être récupérée</strong>.
                <br /><br />
                Entrez votre mot de passe et tapez <strong style={{color: "red"}}>"SUPPRIMER"</strong> en lettre majuscule dans le champ ci-dessous et validez. <strong style={{color: "red"}}></strong>

                <Box sx={{flexDirection: "column", mt:3}}>
                    <TextField
                        value={password}
                        label="Votre mot de passe"
                        onChange={(e)=> setPassword(e.target.value)}
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        sx={{mb:3}}
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=> setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        value={supp}
                        onChange={(e)=> setSupp(e.target.value)}
                        label={'Tapez "SUPPRIMER"'}
                        fullWidth
                    />
                </Box>

                <Box sx={classes.buttonsContainer}>
                    <LoadingButton 
                        variant="contained" 
                        sx={classes.button} 
                        onClick={onDelete}
                        loading={pending}
                        disabled={pending}
                    >
                        Supprimer mon compte
                    </LoadingButton>
                </Box>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}
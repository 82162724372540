import { createSlice, current } from '@reduxjs/toolkit'
import { createCustomFood, deleteCustomFood, getFoodList } from 'api/food'
import fuseSearch from 'function/fuseSearch'
import Food from 'interfaces/Food'
import Meal from 'interfaces/Meal'
import { toast } from 'react-toastify'


interface Foods {
    list: {
        ciqual: Food[],
        app: Food[],
        custom: Food[],
    },
    filtered:{
        ciqual: Food[],
        app: Food[],
        custom: Food[],
    },

    requests: {
      create: "pending" | "idle",
      update: "pending" | "idle",
      get: "pending" | "idle",
      delete: "pending" | "idle",
      duplicate: "pending" | "idle",
      getAllFood: "pending" | "idle"
    },
    search: string,
  }


  const initialState:Foods = {
    list: {
      ciqual: [],
      app: [],
      custom: [],
    },
    filtered: {
      app: [],
      ciqual: [], 
      custom: [],
    },
    requests: {
      create: "idle",
      update: "idle",
      get: "idle",
      delete: "idle",
      duplicate: "idle",
      getAllFood: "idle"
    },
    search: "",
  
  
   
  }


  export const foodSlice = createSlice({
    name: 'food',
    initialState,
    reducers: {


      /***** SEARCH ***** */
      searchFood: (state, action) => {
        const search:string = action.payload.search

        const keys:string[]= [
          "name.fr",
        ]

        const filteredLists = {
          app: current(state.list.app),
          ciqual: current(state.list.ciqual),
          custom: current(state.list.custom),
        }


        // App exos
        state.filtered = {
          app: search !== "" ? fuseSearch(filteredLists.app, search, keys, 0.2, "ignore", "start") : filteredLists.app,
          ciqual: search !== "" ? fuseSearch(filteredLists.ciqual, search, keys, 0.2, "ignore", "start") : filteredLists.ciqual,
          custom: search !== "" ? fuseSearch(filteredLists.custom, search, keys, 0.2, "ignore", "start") : filteredLists.custom,
        }
      },

      
    },

    extraReducers : (builder) => 
    builder
    .addCase(getFoodList.pending, (state)=>{
      state.requests.getAllFood = "pending"
    })

    .addCase(getFoodList.fulfilled, (state, {payload}) => {
      state.requests.getAllFood = "idle"
      const ciqual = payload.food.filter((elem) => elem.source === "Ciqual 2020, ANSES" && !elem.createdByApp && !elem.archived)
      const app = payload.food.filter((elem) => elem.source === "Ciqual 2020, ANSES" && Boolean(elem.createdByApp) && !elem.archived)
      const custom = payload.food.filter((elem) => elem.source === "custom" && Boolean(elem.createdBy) && !elem.archived)


      state.list.ciqual = ciqual
      state.filtered.ciqual=ciqual
      state.list.app=app
      state.filtered.app=app
      state.list.custom=custom
      state.filtered.custom=custom


    })


    .addCase(createCustomFood.fulfilled, (state, {payload}) => {
      if(payload.food){
        const {food} = payload
        if(food.source === "custom"){ // template
          const customList = [...current(state.list.custom)]
          customList.push(food)
          const sortedList = customList.sort((a,b)=> {
            if(a.name > b.name){
              return 1
            } else if(a.name < b.name){
              return -1
            }else return 0
          })

          state.list.custom = sortedList
          state.filtered.custom = sortedList
        
      }else if(food.source === "Ciqual 2020, ANSES"){
          const simplifiedList = [...current(state.list.app)]
          simplifiedList.push(food)
          const sortedList = simplifiedList.sort((a,b)=> {
            if(a.name > b.name){
              return 1
            } else if(a.name < b.name){
              return -1
            }else return 0
          })

          state.list.app = sortedList
          state.filtered.app = sortedList
        
      }else{
        toast.error(payload.error)
      }
      }
    })

    .addCase(deleteCustomFood.pending, (state) => {
      state.requests.delete = "pending"
    })

    .addCase(deleteCustomFood.fulfilled, (state,{payload}) => {
      state.requests.delete = "idle"
      if(payload.food){
        // Suppression d'un aliment custom
        const indexList = state.list.custom.findIndex((elem:Food) => elem._id === payload.food._id)
        state.list.custom.splice(indexList, 1)
        const indexFiltered = state.filtered.custom.findIndex((elem:Food) => elem._id === payload.food._id)
        state.filtered.custom.splice(indexFiltered, 1)
        toast.success(`Aliment supprimé`)
      }

    })
    
  })

  export const { 
    searchFood,
  } = foodSlice.actions


  export default foodSlice.reducer
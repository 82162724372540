import React, {useRef, useEffect, useState, useMemo} from "react"
// MUI
import { Grid, Typography, Box, TextField, InputAdornment, Button, IconButton, Tooltip, useMediaQuery } from "@mui/material"
// REDUX
import { useDispatch,useSelector, shallowEqual } from "react-redux"
// INTERFACES
import { AppDispatch, RootState } from "app/store"
// CONSTANTS
import { AUTOSAVE_TIMER, EXERCICE_GROUPS, EXERCICE_GROUPS_TRAD, MOBILE } from "utils/constants"
import Exercice, {ExerciceType } from "interfaces/Exercice"
// REDUCERS
import { 
    handleExerciceNameFr, 
    handleExerciceType, 
    handleExerciceGroup,
    isSavingExercice,
    exerciceHasUnsavedChanges,
} from "features/exerciceSlice"

// API
import { 
    autoSaveExercice, 
    getExercice, 
    manualSaveExercice, 
    uploadExerciceImage,
} from "api/exercices"

// SHARED COMPONENTS
import TitleTextField from "components/molecules/TitleTextField"
import AutosaveIndicator from "components/molecules/AutosaveIndicator";
// Function
import calcBoxHeight from "function/calcBoxHeight"
import ChipSelector from "components/molecules/ChipSelector"
// STYLE
import classes from "./styles"
import debounce from "lodash.debounce";
import Image from "interfaces/Image"
import { toast } from "react-toastify"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { definePageTitle, selectDrawerItem } from "features/appSlice"
import Loader from "components/molecules/Loader"
import DialogLoader from "components/molecules/DialogLoader"
import { ArrowBack, CheckCircle, Close, Delete, Download, Save, YouTube } from "@mui/icons-material"
import YoutubeVideoPlayer from 'react-player'
import {Editor, EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import DialogDeleteExerciceImage from "components/molecules/DialogDeleteExerciceImage"
import DialogSaveExercice from "components/molecules/DialogSaveExercice"
import UnsavedIndicator from "components/molecules/UnsavedIndicator"
import Header from "components/molecules/Header"
import MainContent from "components/molecules/MainContent"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import Toolbar from "components/molecules/Toolbar"


const weightLifting = "63e2583d4634b611780b1f9a"
const cardio = "63e25db34634b611780b1fab"

const GROUPS_SORTED = (values:string[]):{value:string, trad:string}[] => {
    const list:{value:string, trad:string}[] = []
    values.forEach((group)=>{
        list.push({trad: EXERCICE_GROUPS_TRAD[group].fr, value: group})
    })
    return list.sort((a,b) => {
        if( a.trad < b.trad ){
            return -1
        }
        if ( a.trad > b.trad ){
            return 1;
        }
      return 0
    })
}



/******************* EXERCICE BUILDER ******************* */

export function ExerciceBuilder(){

    const history = useHistory()
    const pendingManualSave:boolean = useSelector((state: RootState) => state.exercices.requests.manualSave === "pending")
    const hasUnsavedChanges = useSelector((state:RootState) => state.exercices.hasUnsavedChanges)
    const dispatch = useDispatch<AppDispatch>()
    const params:any = useParams()
    const exerciceId = params.exerciceid
    const name:string = useSelector((state:RootState) => state.exercices.builder?.name.fr, shallowEqual)
    const groups:string[] = useSelector((state:RootState) => state.exercices.builder?.groups)
    const type:ExerciceType = useSelector((state:RootState) => state.exercices.builder?.type)
    const image:Image = useSelector((state:RootState) => state.exercices.builder?.image) // Image du formulaire
    const isDraft: boolean = useSelector((state:RootState) => state.exercices.builder?.draft, shallowEqual)
    const typesList:ExerciceType[] = useSelector((state: RootState) => state.exercices.types) // liste des types d'exercices
    const uploadPending:boolean = useSelector((state:RootState) => state.exercices.requests.uploadImage === "pending")
    const [openDeleteImage, setOpenDeleteImage] = useState<boolean>(false)

    // Local
    const [formIsReady, setFormIsReady] = useState<boolean>(false)
    const videoRef = useRef(null);
    const [mediaHeight, setMediaHeight] = useState<number>(0)
    const [openSaveExercice, setOpenSaveExercice] = useState<boolean>(false)
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [imageSelected, setImageSelected] = useState<{url:string, cloudFlareId?:string}>({url:null, cloudFlareId: null})
    const [videoIsCorrect, setVideoIsCorrect] = useState<boolean>(false)
    const [descriptionFr, setDescriptionFr] = useState({ editorState: EditorState.createEmpty() })
    const [descriptionEn, setDescriptionEn] = useState({ editorState: EditorState.createEmpty() })
    const mobile = useMediaQuery(MOBILE)

    /************ RECUPERATION DES DONNEES DE L'EXERCICE *********** */

    useEffect(() => {
        // Si on modifie un modèle de bilan/brouillon
        if (Boolean(exerciceId) && !formIsReady) {
            dispatch(selectDrawerItem(2))
            dispatch(definePageTitle({pageTitle: isDraft? "Création d'un exercice (brouillon)" : "Modifier un exercice personnalisé"}))
            dispatch(getExercice(exerciceId)).unwrap().then((res) => {
                if(res.exercice){
                    setTimeout(()=>{
                        setFormIsReady(true)
                    },500)
                    
                    if(res.exercice.image){
                        setImageSelected(res.exercice.image)
                    }
                    if(res.exercice.video){
                        setVideoUrl(res.exercice.video.url)
                    }
                    setDescriptionFr({editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(res.exercice.description.fr)))})
                }else{
                    toast.error("Impossible de récupérer les données de l'exercice")

                }
            })
        } 
    }, [dispatch, exerciceId, formIsReady])



    /************* DONNEES CONDENSEES POUR L'AUTOSAVE **************** */

    const formData:Exercice = useMemo(()=>{
        return {
            _id: exerciceId,
            name:{fr:name}, 
            groups: groups, 
            description: {
                fr: JSON.stringify(convertToRaw(descriptionFr.editorState.getCurrentContent())),
                en: JSON.stringify(convertToRaw(descriptionEn.editorState.getCurrentContent()))
            },
            //equipments,
            type: type, 
            video:{
                platform: "youtube",
                url: videoUrl
            },
            draft: true,
            image: imageSelected,
            appExercice: false
        }
    },[isDraft, name, groups, videoUrl, type, exerciceId, imageSelected, descriptionFr, descriptionEn])


    /********** CHANGEMENT DU NOM D'EXERCICE ******** */
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(handleExerciceNameFr({ name: event.currentTarget.value }))
    }

    /********** CHANGEMENT DU TYPE D'EXERCICE ******** */
    const onHandleExerciceType = (newType:ExerciceType) => ()=> {
        if(!isDraft && (type._id === cardio || type._id === weightLifting)){
            toast.error("Impossible de modifier ce type d'exercice une fois publié")
            return 
        }

        else if(!isDraft && (newType._id === weightLifting || type._id === cardio)){
            toast.error("Impossible de modifier ce type d'exercice une fois publié")
            return 
        }

        else{

            dispatch(handleExerciceType({type: newType}))
        }
    }

    /********** CHANGEMENT DES GROUPES MUSCULAIRE D'EXERCICE ******** */
    const onHandleExerciceGroup = (group:string) => ()=> {
        dispatch(handleExerciceGroup({group}))
    }


    /******************** CALCUL DE LA HAUTEUR DES CADRES IMAGE ET VIDEO ***************** */

    useEffect(() => {
        if (videoRef.current && formIsReady) {
            const boundingRect = videoRef.current.getBoundingClientRect()
            const videoWidth = boundingRect.width;
            setMediaHeight(calcBoxHeight(1080, 1920, videoWidth))
        }
    }, [videoRef.current, formIsReady]);






    /**** LISTE DES MINIATURES YOUTUBE ******* */

    const thumbnailsList:{url:string}[] = useMemo(()=>{
        if(videoIsCorrect){
            // Récupération de l'ID Vidéo
            var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/;
            var match = videoUrl.match(regExp);

            const videoId = match && match[1].length == 11 ? match[1] : false;
            

            var thumbnailsList:Image[] = [{url: "https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"}]

            // ON Parcourt les miniatures (il y'a 4 miniatures sur youtube par vidéo)
            for (var i = 1; i < 4; i++) {
                thumbnailsList.push({
                    url: "https://img.youtube.com/vi/" + videoId + "/maxres" + i + ".jpg"
                })
            }
            if(!imageSelected.url){
                setImageSelected({url:"https://img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"})
            }

            return thumbnailsList
        } else {
            return []
        }
    },[videoIsCorrect])
    

     /**** VERIFICATION DU LIEN VIDEO ******* */

    const checkVideoLink = (url:string) => {
        var regExp = new RegExp(/^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/);
        var match = url.match(regExp);
        if(match) {
            setVideoIsCorrect(true)
        }
        else if(videoIsCorrect) {
            setVideoIsCorrect(false)
            if(!imageSelected?.cloudFlareId){
                setImageSelected({url:null, cloudFlareId: null})
            }
        }
    }

    const onChangeLink = (e:React.ChangeEvent<HTMLInputElement>) => {
        setVideoUrl(e.target.value)
    }

     useEffect(()=>{
        checkVideoLink(videoUrl)
    },[videoUrl])

    

    const onSelectThumbnail = (thumbnail:{url:string, cloudFlareId?:string}) => ()=>{
        if(imageSelected.cloudFlareId){
            toast.error('Supprime ton image téléchargée avant de sélectionner une miniature')
            return
        }
        setImageSelected(thumbnail)
    }


    const onChangeDescription = (e:EditorState) => {
        setDescriptionFr({editorState: e})
    }


    /********** SELECTION D'UN FICHIER LOCAL - Téléchargement de l'image ********** */
    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFile:Blob = e.target.files[0]
        if (newFile.size > 10 * 1024 * 1024) {
            toast.error('Le fichier est trop volumineux (maximum 10mo)')
        }else if (newFile.type !== "image/jpeg" && newFile.type !== "image/png") {
            toast.error('Format non pris en charge (PNG/JPEG exclusivement)')
        }else{
            dispatch(uploadExerciceImage({exerciceId:exerciceId, file: newFile})).unwrap().then((res)=>{
                if(res.image){
                    setImageSelected(res.image)
                }
                
            })
        }
    }


    useEffect(()=>{
        if(!image?.url && videoIsCorrect){
            setImageSelected({url:thumbnailsList[0].url})
        }
    },[image])

     /*********** AUTOSAVE  ************** */

    // DEBOUNCE
    const debounceAutoSave = useRef(debounce((formData:Exercice)=> {
        dispatch(autoSaveExercice({exercice: formData}))
    }, AUTOSAVE_TIMER)).current


     useEffect(() => {
        if (formIsReady && isDraft) {
            dispatch(isSavingExercice())
            debounceAutoSave(formData)
        }else if(formIsReady && !isDraft){
            dispatch(exerciceHasUnsavedChanges())
        }
    }, [formData]) 



    const onSave = () =>{
        debounceAutoSave.cancel()
        dispatch(manualSaveExercice({exercice:formData}))
    }


    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        const handler = (event: BeforeUnloadEvent) => {
          event.preventDefault();
          event.returnValue = "";
        };
    
        // if the form is NOT unchanged, then set the onbeforeunload
        if (hasUnsavedChanges) {
          window.addEventListener("beforeunload", handler);
          // clean it up, if the dirty state changes
          return () => {
            window.removeEventListener("beforeunload", handler);
          };
        }
        // since this is not dirty, don't do anything
        return () => {};
      }, [hasUnsavedChanges]);


    /********* MAIN COMPONENT ********* */

    if (formIsReady) {

        return(
            
            <React.Fragment >

                {/****** HEADER ***** */}
                <Header>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={7}>
                            <Toolbar>
                                {/********* TITRE DE LA PAGE******** */}
                                <Tooltip title={"Liste des exercices"}>
                                    <IconButton 
                                        sx={{marginRight: 2}} 
                                        onClick={()=>history.push({pathname: '/exercices', state: {
                                            tabs: 1, 
                                          },})}
                                    >
                                        <ArrowBack 
                                            sx={{color: "black"}}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <TitleTextField 
                                    fullWidth 
                                    label={''} 
                                    value={name} 
                                    onChange={onChangeName} 
                                    variant="standard"
                                    placeholder="Exemple : Développé couché"
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    
                                />
                            
                            
                           
                                {/********** INDICATEURS ************** */}
                                {!mobile && (
                                    <Box sx={classes.indicators}>
                                        {isDraft && (<AutosaveIndicator showDraft={true} />)}

                                        {!isDraft && (<UnsavedIndicator saved={!hasUnsavedChanges}/>)}
                                    </Box>
                                )}
                           
                            </Toolbar>
                        </Grid>
                    </Grid>
                </Header>

                {/****** MAIN CONTENT ***** */}

                <MainContent>   
                    <Grid 
                        container 
                        justifyContent="center"
                    >
                        <Grid 
                            item xs={12} 
                            sm={12} 
                            md={12} 
                            lg={8} 
                            xl={7}
                        > 

                            <Box 
                                style={{display:"flex", justifyContent:"flex-end"}}
                            >
                                {isDraft && (
                                    <SecondaryButton onClick={()=> setOpenSaveExercice(true)} startIcon={<Save />}>
                                        Publier l'exercice
                                    </SecondaryButton> 
                                )}


                                {!isDraft && (
                                    <Button variant="outlined" sx={{marginLeft: 3}} onClick={onSave}>
                                        Sauvegarder les modifications
                                    </Button>
                                )}

                            </Box>

                            {/****** TYPE D'EXERCICE ******* */}

                            <Box sx={[{...classes.section},{marginTop: 3}]}>
                                <Typography variant="body1" sx={classes.sectionLabel}>
                                    Type d'exercice
                                </Typography>
                                <Box sx={classes.chips}>

                                    {typesList.map((elem:ExerciceType, index:number)=> {
                                        const selected = type._id === elem._id
                                        //const canChange = isDraft || (!isDraft && (type._id ===  isometric || type._id === flexibility || type._id === mobility))

                                        return (
                                            <ChipSelector 
                                                label={elem.name.fr} 
                                                key={index} 
                                                selected={selected} 
                                                onClick={onHandleExerciceType(elem)}
                                                //disabled={!canChange}
                                            />
                                        )
                                    })}
                                </Box>
                                {/*!isDraft && (
                                    <Typography sx={{color :"#9f9f9f", fontSize: 14}}>
                                        Tu ne peux plus modifier le type d'un exercice après l'avoir publié
                                    </Typography>
                                )*/}
                            </Box>
                            

                            {/****** GROUPES MUSCULAIRE ******* */}

                            <Box sx={classes.section}>
                                <Typography variant="body1" sx={classes.sectionLabel}>
                                    Groupes musculaires ciblés
                                </Typography>
                                <Box sx={classes.chips}>
                                    {GROUPS_SORTED(EXERCICE_GROUPS).map((label, index:number)=> {

                                        const selected = groups.find((group:string)=> group === label.value)

                                        return (
                                            <ChipSelector 
                                                label={label.trad} 
                                                key={index} 
                                                selected={Boolean(selected)} 
                                                onClick={onHandleExerciceGroup(label.value)}

                                            />
                                        )
                                    })}
                                </Box>
                            </Box>


                            {/****** DESCRIPTION ******* */}

                            <Box sx={classes.section}>
                                <Typography variant="body1" sx={classes.sectionLabel}>
                                    Description de l'exercice
                                </Typography>
                                <Box sx={classes.draftJsEditor}>
                                    <Editor 
                                        editorState={descriptionFr.editorState} 
                                        onChange={onChangeDescription}
                                        placeholder="(Optionnel) Entrez une description, des conseils d'éxécution etc ..."
                                        stripPastedStyles={true}
                                    />
                                </Box>
                            </Box>

                            
                            <Grid container spacing={3}>

                                {/****** VIDEO DE L'EXERCICE ******* */}
                                
                                <Grid item xs={12} lg={6}>
                                    <Box sx={[classes.section, classes.noMargin]}>
                                        <Typography variant="body1" sx={classes.sectionLabel}>
                                            Vidéo de l'exercice
                                        </Typography>
                                        <Typography>
                                            Ajoute une vidéo de démonstration a cet exercice pour optimiser la qualité des mouvements de vos clients
                                        </Typography>

                                        <TextField 
                                            fullWidth
                                            value={videoUrl}
                                            label={"Lien Youtube"}
                                            onChange={onChangeLink}
                                            sx={{mt:4}}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><YouTube sx={{color: "#ff0000"}} /></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"> {videoIsCorrect ? <CheckCircle sx={{color:"green"}}/> : videoUrl !== "" ? <Close sx={{color: "red"}}/> : ""}</InputAdornment>
                                            }}
                                        />

                                        
                                        <Box ref={videoRef} sx={[classes.videoContainer, {height: mediaHeight}]}>
                                            {videoIsCorrect && (
                                                    <YoutubeVideoPlayer 
                                                        url={videoUrl} 
                                                        height={mediaHeight} 
                                                        width={'100%'} 
                                                        playing={false}
                                                        config={{
                                                            youtube: {
                                                                playerVars:{
                                                                    modestbranding: 0,
                                                                    rel: 0,
                                                                    showinfo: 0,
                                                                    controls: 2
                                                                }
                                                            }
                                                        }}
                                                        
                                                    />
                                            
                                            )}

                                            {!videoIsCorrect && (
                                                <Box sx={{width:"100%", height: "100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                                    <Typography sx={{fontWeight:700, color: "#a8a8a8"}}>
                                                        Aucune vidéo pour cet exercice
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        
                                        
                                    </Box>
                                </Grid>

                                {/****** MINIATURES ******* */}

                                <Grid item xs={12} lg={6}>
                                    <Box sx={classes.section}>
                                        <Typography variant="body1" sx={classes.sectionLabel}>
                                            Image de la recette
                                        </Typography>
                                        <Typography>
                                            Indique le lien de ta video Youtube pour afficher les miniatures disponibles, ou télécharge ta propre image.
                                        </Typography>
                                            
                                        <Grid container spacing={3} flexDirection={"row"} sx={{mt:0.5}}>

                                            {/****** LISTE DES MINIATURES YOUTUBES **** */}
                                            {videoIsCorrect && (
                                                thumbnailsList.map((thumbnail,index)=>{

                                                    const selected = thumbnail.url === imageSelected.url

                                                    return(
                                                        <Grid item xs={12} sm={6}>
                                                            <Button onClick={onSelectThumbnail(thumbnail)} sx={{padding:0}} disabled={uploadPending}>
                                                                <Box
                                                                    component={"img"} 
                                                                    src={thumbnail.url} 
                                                                    key={index} 
                                                                    sx={[classes.thumbnail,selected && classes.thumbnailSelected]}
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                            )}

                                            {/****** LISTE DES MINIATURES YOUTUBES **** */}
                                            {!videoIsCorrect && Array.from(Array(4)).map((_,index)=>{
                                                return(
                                                    <Grid 
                                                        item 
                                                        xs={12} 
                                                        sm={6} 
                                                        key={index} 
                                                        sx={[{height:{xs: mediaHeight, sm: mediaHeight/2 - 6}}]}
                                                    >
                                                        <Box sx={[classes.thumbnail, {height:{xs: mediaHeight, sm: mediaHeight/2 - 6}, cursor: "default"}]}>
                                                            <ImageNotSupportedIcon />
                                                        </Box>
                                                    </Grid>
                                                )
                                            })}


                                            {/********* BOUTON UPLOAD D'IMAGE ******** */}

                                            {!image?.cloudFlareId &&  (
                                                <Grid 
                                                    item 
                                                    xs={12} 
                                                    sm={6}  
                                                    sx={[{height:{xs: mediaHeight, sm: mediaHeight/2 - 6, marginTop: 3, marginBottom: 16}}]}
                                                >
                                                    <Button sx={[classes.uploadField, {cursor: "default", height:{xs: mediaHeight, sm: mediaHeight/2 - 6}}]}>
                                                        <input 
                                                            type="file" 
                                                            id="upload-image" 
                                                            style={{display:"none", height: "100%", width: "100%"}} 
                                                            accept="image/png, image/jpeg" 
                                                            onChange={onFileChange}
                                                            disabled={uploadPending}
                                                        />
                                                            <Box 
                                                                sx={{flexDirection:"column", cursor: "pointer"}} 
                                                                component="label" 
                                                                htmlFor='upload-image'
                                                            >
                                                                <Typography>
                                                                    Télécharger une image
                                                                </Typography>
                                                                <Typography>
                                                                    Fichier jpg/png
                                                                </Typography>
                                                                <Typography>
                                                                    Format 16/9 recommandé
                                                                </Typography>
                                                                <Download 
                                                                    sx={{marginTop:1}}
                                                                />
                                                            </Box>
                                                    </Button>
                                                </Grid>
                                            )}

                                            {/********* IMAGE UPLOADEE ******** */}
                                            
                                            {image?.cloudFlareId &&  (
                                                <Grid item xs={6}  sx={[{height : mediaHeight/2, position: "relative"}]}>
                                                    <Box sx={classes.deleteImageButton}>
                                                        <IconButton onClick={()=>setOpenDeleteImage(true)}>
                                                            <Delete sx={{color:'white'}}/>
                                                        </IconButton>
                                                    </Box>
                                                    <Box
                                                        component={"img"} 
                                                        src={image.url} 
                                                        sx={[classes.thumbnail,classes.thumbnailSelected]}
                                                    />
                                                </Grid>
                                            )}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>

                            
                        </Grid>
                    </Grid>
                </MainContent>

                            
                    
                


                {/****************************** 
                 * LOADER ENREGISTREMENT AUTO 
                 * ***** **********************/}

                <DialogLoader
                    open={pendingManualSave && !isDraft}
                    text={"Enregistrement des modifications ..."}
                />

                {/****************************** 
                 * LOADER TELECHARGEMENT IMAGE 
                 * ***** **********************/}

                <DialogLoader
                    open={uploadPending}
                    text={"Téléchargement l'image ..."}
                />

                {/****************************** 
                 * DIALOG SUPPRESSION IMAGE
                 * ***** **********************/}

                <DialogDeleteExerciceImage
                    open={openDeleteImage}
                    onClose={()=> setOpenDeleteImage(false)}
                />

                {/****************************** 
                 * DIALOG SAUVEGARDE EXERCICE
                 * ***** **********************/}

                <DialogSaveExercice 
                    open={openSaveExercice}
                    onClose={()=> setOpenSaveExercice(false)}
                    exercice={formData}
                    debounce={debounceAutoSave}
                />


                <Prompt
                    when={hasUnsavedChanges}
                    message="Tu n'as pas sauvegardé tes changements, veux-tu quitter la page ?"
                />


                
            </React.Fragment>

            
        )

    }else {
        return (
            <Loader />
        )
    }

}



export default ExerciceBuilder
import { createAsyncThunk } from '@reduxjs/toolkit'
import Checkup from 'interfaces/Checkup'
import MealPlan from 'interfaces/MealPlan'
import Program from 'interfaces/Program'
import Workout from 'interfaces/Workout'
import axios from "utils/axios"


/*****************************
            API
 *****************************/


/******* RECUPERATION DES TEMPLATES ****** */

export const getTemplates = createAsyncThunk('templates', async () => {

    try {
        const response = await axios.get(`templates`)
        const templates:{
            workouts:Workout[], 
            programs:Program[], 
            checkups:Checkup[],
            mealPlans: MealPlan[]
        } = response.data.templates
        return { templates }

    } catch (err: any) {

        return {
            error: err.response.data?.message[0]
        }
    }
})








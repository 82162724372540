import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AppDispatch } from "app/store";
import { removeExerciceFromWorkout } from "features/workoutSlice";
import React from "react";
import { useDispatch } from "react-redux";


interface Props{
    open: boolean,
    onClose: ()=> void,
    exerciceId: string,
    blockId: string,
    groupId: string
}

export function DialogExerciceDelete({open,onClose, groupId, exerciceId, blockId}:Props){

    const dispatch = useDispatch<AppDispatch>()

    const onSubmit = () => {
        dispatch(removeExerciceFromWorkout({exerciceId, blockId, groupId}))
        onClose()
    }
    
    return(
        <Dialog 
            open={open} 
            disableScrollLock={true}
        >
            <DialogTitle>
                Supprimer un exercice
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer cet exercice de la séance ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button variant="contained" onClick={onSubmit}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    )
}

function areEqual(prev:Props,next:Props){
    return prev.exerciceId === next.exerciceId && prev.open === next.open
}

export default React.memo(DialogExerciceDelete, areEqual)
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { MOBILE } from "utils/constants";
import classes from "./styles";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useMemo } from "react";

interface Props{
    open: boolean,
    exerciceName: string,
    exerciceId: string,
    onClose: ()=> void,
    workoutExerciceId?: string,
    groupId?: string,
    blockId?:string
}


export default function DialogClientPerformances({open, exerciceName, exerciceId, blockId, groupId, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const mobile = useMediaQuery(MOBILE)
    const clientProfile = useSelector((state:RootState) => state.clients.profile)
    const coach = useSelector((state:RootState) => state.user.data)
    const createdFor = useSelector((state:RootState) => state.workouts.builder.createdFor)
    const workout = useSelector((state:RootState) => state.workouts.builder)

    const performances = useMemo(()=>{
        var whereToSearch = createdFor === clientProfile?._id ? {...clientProfile} : {...coach}
        const perf = whereToSearch?.performances?.find((elem)=> elem.exercice._id === exerciceId)
        return perf
    },[exerciceId, open])



    

    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth={"xs"}
            fullScreen={mobile? true: false}
        >
           
            <DialogContent>
                <Typography
                    sx={classes.name}
                >
                    {exerciceName}
                </Typography>

                <Box
                    sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", marginTop: 3}}
                >
                    <Typography
                        sx={classes.sectionTitle}
                    >
                        Dernière séance
                    </Typography>

                    {/*<Tooltip
                        title="Copier les séries dans la séance"
                    >
                        <IconButton
                            onClick={addSets}
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
    */}
                </Box>
                <Typography
                    sx={classes.sectionSubTitle}
                >
                    {performances ?  `Séance du ${format(new Date(performances.date), "dd MMMM yyyy", {locale: fr   })}` : ""}
                </Typography>

                {performances && (
                    <Box
                        sx={classes.rowHeader}
                    >
                        <Typography
                            sx={classes.rowValue}
                        >
                            #
                        </Typography>
                        <Typography
                            sx={classes.rowValue}
                        >
                            Reps
                        </Typography>
                        <Typography
                            sx={classes.rowValue}
                        >
                            Poids
                        </Typography>
                    </Box>
                )}


                {!performances && (
                    <Typography
                        sx={classes.noPerf}
                    >
                        Aucune performance enregistrée
                    </Typography>
                )}
                {performances?.sets.map((set, index)=> {
                    return(
                        <Box
                            sx={classes.rowValues}
                            key={index}
                        >
                        <Typography
                            sx={classes.rowValueBold}
                        >
                            {index+1}
                        </Typography>
                        <Typography
                            sx={classes.rowValueBold}
                        >
                            {set.reps}
                        </Typography>
                        <Typography
                            sx={classes.rowValueBold}
                        >
                            {set.weight} kg
                        </Typography>
                </Box>
                    )
                })}
                
                <Box
                    sx={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between", marginTop: 3}}
                >
                <Typography
                    sx={classes.sectionTitle}
                >
                    Meilleure performance
                </Typography>
                </Box>

                {performances?.record && (
                <Typography
                    sx={classes.sectionSubTitle}
                >
                    Toute séance
                </Typography>
                )}

                {(performances && performances.record) &&  (
                    <Box
                        sx={classes.rowHeader}
                    >
                        <Typography
                            sx={classes.rowValue}
                        >
                            Reps
                        </Typography>
                        <Typography
                            sx={classes.rowValue}
                        >
                            Poids
                        </Typography>
                    </Box>
                )}

                {performances?.record && (
                
                    <Box
                        sx={classes.rowValues}
                    >
                        <Typography
                            sx={classes.rowValueBold}
                        >
                            {performances?.record.reps} *
                        </Typography>
                        <Typography
                            sx={classes.rowValueBold}
                        >
                            {performances?.record.weight} kg
                        </Typography>
                    </Box>
                    
                )}

                {!performances && (
                    <Typography
                        sx={classes.noPerf}
                    >
                        Aucune performance enregistrée
                    </Typography>
                )}

            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>

        </Dialog>
    )
}
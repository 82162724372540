import React, { useEffect, useState } from "react"
// Redux
import { useDispatch, useSelector } from "react-redux"
// Mui
import { Grid, Typography, Box } from "@mui/material"
// Interfaces
import { AppDispatch, RootState } from "app/store";
// Local components
import SubscriptionCard from "components/organisms/SubscriptionCard"
import { definePageTitle } from "features/appSlice"
import { getProducts } from "api/subscriptions";
import classes from "./styles"
import SubscriptionProduct from "interfaces/SubscriptionProduct";
const colors = ['rgb(210 222 239)', "#facf90", "#fad86b"]

// SUBSCRIPTIONS //

export default function Subscriptions(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const clients = useSelector((state:RootState) => state.clients.list.clients.length)
    const invitations = useSelector((state:RootState) => state.clients.list.invitations.length)
    const products:SubscriptionProduct[] = useSelector((state:RootState) => state.subscriptions.products)
    const [withNutrition, setWithNutrition] = useState<boolean>(false)


    useEffect(()=>{
        dispatch(getProducts())
    },[dispatch])


    useEffect(()=>{
        dispatch(definePageTitle({pageTitle: 'Abonnements'}))
    },[clients])


        
    /*************** RENDER *********** */

    return(
        <React.Fragment>
            <Grid container sx={{justifyContent:"center", padding: 3}}>
                <Grid item xs={12} xl={10}>
                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection: "column", mb:3}}>
                        <Typography sx={classes.title}>
                            Des tarifs clairs, abordables et sans engagement
                        </Typography>
                        {user.subscription?.current?.subscriptionId && (
                            <Typography sx={{fontSize: 16, mt:2}}>
                                Tu es actuellement abonné à l'offre <strong>{user.subscription.current?.product?.name}</strong>
                            </Typography>
                        )}
                        <Typography sx={{fontSize: 16, mt:2}}>
                            Tu as actuellement <strong>{clients} client(s)</strong> et <strong>{invitations} invitation(s)</strong> en cours
                        </Typography>
                    </Box>

                    
                    <Grid 
                        container 
                        spacing={6} 
                        justifyContent='center'
                        sx={{maxWidth: 1480, marginTop: 3}}
                    >

                        {/***** LISTE DES ABONNEMENTS *****/}
                        {[...products].sort((a,b) => a.default_price.unit_amount > b.default_price.unit_amount ? -1 : 1).map((product:SubscriptionProduct, index)=> {

                            const currentSubscription:boolean = user.subscription?.current?.product.id === product.id
                            const hasTooManyClients = (clients + invitations) > parseInt(product.metadata.clients)

                            return(
                                <SubscriptionCard 
                                    product={product} 
                                    current={currentSubscription} 
                                    hasTooManyClients={hasTooManyClients}
                                    key={product.id}
                                    withNutrition={withNutrition}
                                    color={colors[index]}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
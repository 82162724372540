import { useState, useRef, useEffect } from "react";
// MUI
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material"
import { Delete, Upload } from "@mui/icons-material";
import { toast } from "react-toastify";
// REDUX
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// INTERFACES
import { AppDispatch, RootState } from "app/store";
import Avatar from "components/molecules/ProfileAvatar";
// Apis
import { uploadAvatar } from "api/user";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


/********* REDUX SELECTORS ******* */

interface Props {
    open: boolean,
    onClose: ()=> void,
}


/********* MAIN COMPONENT ******* */

export default function DialogAvatar({open,onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const [file, setFile] = useState<Blob>(null)
    const [preview, setPreview] = useState<string>(null)
    const inputRef = useRef(null)


    /**************** SELECTION D'UNE IMAGE **************** */
    const onFileChange = (e:React.ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        const file:Blob = target.files[0]

        setFile(file)
        if (file.size > 5 * 1024 * 1024) {
            toast.error('Le fichier est trop volumineux. Limite : 5mo')
            setFile(null)
            return;
        } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
            toast.error('Format non pris en charge')
            setFile(null)
            return;
        }
    }


    useEffect(()=>{
        if(Boolean(file)) {
            setPreview(URL.createObjectURL(file))
        }
        else {
            setPreview(null)
        }
    },[file])


    /**************** Ouverture du browser OS **************** */
    const openBrowser = () => {
        inputRef.current.click()
    }


    /**************** Fermeture du dialog **************** */
    const onCloseDialog = () => {
        onClose()
        setTimeout(()=>{
            setPreview(null)
            setFile(null)
        },200)
    }

    /**************** Ouverture du dialog **************** */
    useEffect(()=>{
        if(open && user.avatar?.url){
            setPreview(user.avatar?.url)
        }
    },[open])


    const removeAvatar = () =>{
        setFile(null)
        setPreview(null)
    }


    const onUploadAvatar = async () => {
        if(Boolean(file)){
            dispatch(uploadAvatar(file)).unwrap().then((res)=>{
                if(res.avatar){
                    onClose()
                }
            })
        }
    }



    return(
       <Dialog open={open}>
            <DialogTitle>
                Modifier mon avatar
            </DialogTitle>
            <DialogContent>
                <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {preview && (
                        <Avatar 
                            url={preview}
                            icon={Delete}
                            width={150} 
                            height={150}
                            onClick={removeAvatar}
                            disableButton={pending}
                        />
                    )}

                    {/********** AFFICHAGE DU BOUTON D'UPLOAD *********** */}
                    {!preview && (
                        <Button 
                            sx={{
                                width: 150,
                                height: 150,
                                backgroundColor: "whitesmoke",
                                border: "5px dashed rgb(221, 221, 221)",
                                borderRadius: "100% !important",
                                textTransform: "none",
                                color: "inherit",
                                flexDirection: "column"
                            }}
                            onClick={openBrowser}
                            >
                                <input 
                                    type="file" 
                                    id="upload-image" 
                                    style={{display:"none"}} 
                                    accept="image/png, image/jpeg"
                                    ref={inputRef}
                                    onChange={onFileChange}
                                />

                                <Upload sx={{color:"rgb(221, 221, 221)", fontSize: 50}} />
                                <Typography sx={{color: "rgb(221, 221, 221)", fontWeight: 700,}}>
                                    PNG/JPG
                                </Typography>
                        </Button>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton disabled={pending} loading={pending} onClick={onUploadAvatar}>
                    {pending? "Téléchargement" : "Valider"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
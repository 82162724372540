import { theme } from "utils/theme"


const classes = {

    title:{
        fontSize: {
            xs : theme.typography.pxToRem(20),
            sm: theme.typography.pxToRem(22)
        },
        fontWeight: {
            xs: 700,
            sm: 500
        }
       
    }
}


export default classes

import { Box, Paper, Typography } from "@mui/material"
import classes from "./styles"
import React from "react"

interface Props{
    children: React.ReactNode,
    signup?:boolean
}

export default function AuthLayout({children, signup = false}:Props){

    return(
        <Box sx={[classes.signinLayout]} className="layout">

            <Typography sx={classes.title}>
                Content de te revoir
            </Typography>
            <Typography sx={classes.subtitle}>
                Prêt à booster ton coaching !
            </Typography>
            
            {/********* LOGO (signup) ************* */}
            {signup && (
                <Box sx={classes.logoContainer}>
                    <Box 
                        component="img" 
                        src="/assets/logodark.svg" 
                        sx={{ width: "auto", height: "40px", paddingLeft: 1}} 
                    />
                </Box>
            )}

            <Paper sx={[classes.card,  signup && classes.signup]}>
                
                {/********* LOGO (signin) ************* */}
                {!signup && (
                    <Box sx={classes.logoContainer}>
                        <Box 
                            component="img" 
                            src="/assets/logodark.svg" 
                            sx={{ width: "auto", height: "40px", paddingLeft: 1}} 
                        />
                    </Box>
                )}

                {children}
            </Paper>

            

        </Box>
    )
}
import { createSlice } from '@reduxjs/toolkit'
import { getProducts, startTrial } from 'api/subscriptions'
import SubscriptionProduct from 'interfaces/SubscriptionProduct'

interface SubscriptionsSlice {
    products: SubscriptionProduct[],
    request: {
        getProducts: "idle" | "pending",
        activateTrial: "idle" | "pending"
    },
}


const initialState:SubscriptionsSlice = {
    products : [],
    request : {
        getProducts: "idle",
        activateTrial: "idle"
    }
}


export const SubscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers:{
        
    },
    extraReducers(builder){
        builder

        .addCase(getProducts.pending, (state) => {
            state.request.getProducts = "pending"
        })

         // Récupération des abonnements
         .addCase(getProducts.fulfilled, (state,action) => {
            state.products = action.payload.products
            state.request.getProducts = "idle"
        })

        
    }
})

export const {} = SubscriptionSlice.actions

export default SubscriptionSlice.reducer

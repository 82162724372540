import { theme } from "utils/theme"

const classes = {


    title: {
        fontWeight: 900,
        fontSize: theme.typography.pxToRem(34),
        marginBottom: 0.2
    },

    subtitle:{
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(20),
        marginBottom: 4,
        color: "rgba(0,0,0,0.80)"

    },

    signinLayout:{
        minHeight:"100vh",
        width: "100%",
        maxWidth: "100vw",
        display:"flex", 
        alignItems: {
            xs : "center", 
            sm: "center",
        }, 
        justifyContent:{
            xs: "flex-start",
            sm:"center", 
        },
        //backgroundColor: "#ebebeb", 
        flexDirection: "column",
        backgroundColor: {
            xs: "white", 
            sm: "rgb(247 250 252)"
        },
        paddingLeft:{
            xs: 2,
            sm: 0
        },
        paddingRight:{
            xs: 2,
            sm: 0
        },
        paddingTop: {
            xs: 3,
            sm: 6,
        },
        paddingBottom: {
            xs: 1,
            sm: 6,
        },
        
    },


    signupLayout: {
        justifyContent: "flex-start"
    },

    

    logoContainer:{
        display:"flex",
        width: "100%",
        marginBottom: 3,
        alignItems:"center",
        justifyContent:"center",
    },

    card : {
       position: "relative",
        paddingLeft: {
            xs: 2,
            sm: 5
        },
        paddingRight: {
            xs: 2,
            sm: 5
        },
        paddingTop: {
            xs: 4,
            sm: 5
        },
        paddingBottom: {
            xs: 4,
            sm: 5
        },
        width: {
            xs: "100%",
            sm: 500,
        },
        borderRadius:{
            xs:  0,
            sm: "15px"
        },
        
    },


    signup : {
        width: {
            xs: "100%",
            sm: 650,
        },
    },
}


export default classes
const classes = {
    rowInfos: {
        display:"flex", 
        flexDirection:"row", 
        alignItems:"center", 
        justifyContent:"center",
    }
}


export default classes
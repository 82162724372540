import { styled, TextField } from "@mui/material"
import { AppDispatch } from "app/store"
import { handleOptionLabel, pushOption } from "features/checkupSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER } from "utils/constants"


  /******** STYLED COMPONENTS ******* */

const Option = styled(TextField)(() => ({
    "& .MuiInput-root": {
        fontSize: '1rem',
        maxWidth: 400,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            

        }
    },
}))

/******** INTERFACES ******* */

interface Props{
    questionIndex:number,
    optionIndex: number,
    optionLabel: string

}


    /******** MAIN COMPONENT ******* */

export function CheckupOptionTextField({questionIndex, optionIndex, optionLabel}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [value, setValue] = useState<string>("")

    /************** VALEUR PAR DEFAULT ********* */

    useEffect(()=>{
        setValue(optionLabel)
    },[])


    /************** AJOUT d'UNE OPTION ********* */

    const onAddOption = () => {
        dispatch(pushOption({questionIndex }))
    }


    /************** CHANGEMENT DU LABEL DE REPONSE ********* */

    const debounceOnChange = useRef(debounce((data)=> {
        const {questionIndex,optionIndex, optionLabel} = data
        dispatch(handleOptionLabel({questionIndex, optionIndex, optionLabel}))
    },500)).current

    
    const onChangeOptionLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionLabel:string = event.target.value
        setValue(optionLabel)
        debounceOnChange({questionIndex, optionIndex, optionLabel})
    }


    /********* EN APPUYANT SUR ENTRER **********/

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            onAddOption()
        }
    }


    /********* JSX **********/

    return(
        <Option 
            onKeyDown={onKeyDown} 
            value={value} 
            onChange={onChangeOptionLabel} 
            fullWidth 
            variant="standard" 
            sx={{backgroundColor: "white", height: 34}} 
            placeholder={'Option de réponse'} 
        />
    )
}


function areEqual(prev:Props,next:Props){
    return prev.questionIndex === next.questionIndex && prev.optionIndex === next.optionIndex
}

export default React.memo(CheckupOptionTextField, areEqual)
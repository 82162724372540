import { BORDER_COLOR, DRAWER_WIDTH_MIN, HEADER_MIN_HEIGHT } from "utils/constants"

const classes= {
        header : {
            display:"flex",
            position: "fixed",
            minHeight: HEADER_MIN_HEIGHT,
            zIndex: 10,
            left: {
                xs: 0,
                lg: DRAWER_WIDTH_MIN
            },
            paddingBottom: 0, 
            backgroundColor: "white", 
            borderBottom: `solid 1px ${BORDER_COLOR}`,
            alignItems:"center",
            justifyContent:"center",
            paddingLeft: {
                xs: 2,
                sm: 3,
            },
            paddingRight: {
                xs: 2,
                sm: 3,
            },
            width: {
                xs: "100%",
                lg: `calc(100% - ${DRAWER_WIDTH_MIN}px)`,
            },
           

        }
}

export default classes
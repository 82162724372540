import { Paper } from "@mui/material"
import classes from "./styles"

interface Props{
    onClick?: ()=> void,
    children: React.ReactNode
}

export default function RowCard({onClick, children}:Props){
    
    return(
        <Paper 
            elevation={0}
            sx={[classes.paper, onClick && classes.clickable]}
            onClick={onClick}
        >
            {children}
        </Paper>
    )
}
import { LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    button: {
        height: 40, 
        borderRadius: "20px !important", 
        fontSize: 16, 
        fontWeight: 600,
        textTransform: "none", 
        pl: 4, 
        pr:4,
        backgroundColor: LIGHT_BUTTON_COLOR,
        flex: 1,
        width: "340px !important",
        minWidth: "340px !important",
        marginTop: 4
    },


    content: {
        padding: {

            xs: 3,
            sm:5,
        },
        height: "100%", 
        display:"flex", 
        justifyContent: "center", 
        alignItems:"center", 
        flexDirection: "column"
    },


    buttonStart : {
        backgroundColor: "primary.main",
        textTransform: "none"
    },

    buttonsContainer:{
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        mt:4, 
        flexDirection: "column"
    },

    description: {
        fontWeight: 400, 
        marginTop: 2, 
        fontSize: {
            xs : theme.typography.pxToRem(16),
            md: theme.typography.pxToRem(18)
        }, 
        textAlign: "center"
    }
}


export default classes
import { useEffect, useState } from "react";
// MUI
import {  TextField, Box, Dialog, DialogTitle, DialogActions, Button, DialogContent, Stack, Chip, styled, Typography } from "@mui/material";
// DATE PICKER
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// DATE FNS
import { addDays, addWeeks, differenceInCalendarDays, isBefore } from "date-fns";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// TOAST
import { toast } from "react-toastify";
// INterfaces
import Program from "interfaces/Program";
import { AppDispatch, RootState } from "app/store";

// Styles
import classes from "./styles"
// Shared components
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import User from "interfaces/User";
import { checkProgramPlanningDate, createNewProgram, updateProgram } from "api/programs";
import { PROGRAM_COLORS } from "utils/constants";
import { fr } from "date-fns/locale";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { theme } from "utils/theme";
import ProgramDescription from "components/molecules/ProgramDescription";



const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            //borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            borderBottom: 0
        }
    },
}))


//***************** INTERFACES ********* */

interface Props {
    open: boolean,
    onClose: () => void,
    program?: Program
}


//***************** COMPONENT ********* */

export default function DialogUpdateProgram({ open, onClose, program }: Props) {

    const dispatch = useDispatch<AppDispatch>()
    const user:User = useSelector((state:RootState) => state.clients.profile)
    const [name, setName] = useState<string>('')
    const [start, setStart] = useState<Date>(new Date())
    const [end, setEnd] = useState<Date>(new Date())
    const [color, setColor] = useState<string>(PROGRAM_COLORS[0])
    const [description, setDescription] = useState<string>(program.description? program.description : "")
    const pending = useSelector((state:RootState) => state.planning.requests.updateProgram === "pending")


    const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }


    const handleChangeStartDate = (value:Date) => {
        setStart(value)
    }

    const handleChangeEndDate = (value:Date) => {
        setEnd(value)
    }


    const onAddWeeks = (value:number) => () => {
        setEnd(new Date(addDays(new Date(addWeeks(start,value)),-1)))
    }


    useEffect(()=>{
        setName(program.name)
        setColor(program.color)
        setStart(new Date(program.startDate))
        setEnd(new Date(program.endDate))
        setDescription(program.description? program.description : "")
    },[open])


    /********** MODIFIER DU PROGRAMME ************* */

    const onSubmit = () => {
        
        if(name === ""){
            toast.error("Ce programme n'a pas de nom")
            return
        }

        if(isBefore(end, start)){
            toast.error('La date de fin ne peut pas être antérieur à la date de début')
            return
        }

        if(differenceInCalendarDays(end, start) < 6){
            toast.error("Le programme doit durer au moins 1 semaine")
            return
        }

         // Vérification de la plage de date
         dispatch(checkProgramPlanningDate({start, end, createdFor: program.createdFor })).unwrap().then((res:any)=>{
            
            if(res.programs?.length>0 && res.programs.filter((elem:Program)=> elem._id !== program._id).length > 0){
                toast.error('Un autre programme est compris dans cette plage de date')
                return 
            } 
            else{

                const newProgram:Program = {
                    _id: program._id,
                    name,
                    startDate: start.toDateString(),
                    endDate: end.toDateString(),
                    color: color,
                    template: false,
                    description
                }

                dispatch(updateProgram({program:newProgram})).unwrap().then(() => {
                    onClose()
                })
            }
         })
    }


    /********** JSX ************* */

    return (

        <Dialog open={open} fullWidth maxWidth={'sm'}>

            <DialogTitle>
                Modifier un programme
            </DialogTitle>
            <DialogContent>

                    <Typography 
                        variant="body1" 
                        sx={classes.sectionLabel}
                    >
                        Configuration
                    </Typography>


     
                    {/**** TEXTFIELDs **** */}
                    <Stack>
                        <TextField
                            value={name}
                            label={`Nom du programme`}
                            sx={{ mb: 1, mt:1 }}
                            fullWidth={true}
                            onChange={(e)=> setName(e.target.value)}
                        />

                        <Box sx={classes.colorChoice}>
                            {PROGRAM_COLORS.map((colorElem:string)=> {

                                const selected:boolean = color === colorElem
                                
                                return(
                                    <Box 
                                        key={colorElem}
                                        sx={classes.color(colorElem, selected)} 
                                        onClick={()=> setColor(colorElem)}
                                    />
                                )
                            })}
                            
                        </Box>

                        
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                            <DesktopDatePicker

                                label="Début du programme"
                                inputFormat="eeee dd MMMM yyyy"
                                value={start}
                                onChange={handleChangeStartDate}
                                renderInput={(params) => <TextField {...params} />}
                            
                            />
                        

                            <Box sx={{display:"flex", flexDirection: "row", mt: 2, mb: 2.5}}>
                                <Chip clickable label="1 semaine" sx={{mr:1}} onClick={onAddWeeks(1)}/>
                                <Chip clickable label="4 semaines" sx={{mr:1}} onClick={onAddWeeks(4)}/>
                                <Chip clickable label="8 semaines" sx={{mr:1}} onClick={onAddWeeks(8)}/>
                                <Chip clickable label="12 semaines" sx={{mr:1}} onClick={onAddWeeks(12)}/>
                            </Box>

                            <DesktopDatePicker
                                label="Fin du programme"
                                inputFormat="eeee dd MMMM yyyy"
                                value={end}
                                onChange={handleChangeEndDate}
                                renderInput={(params) => <TextField {...params} />}
                            />

                        </LocalizationProvider>
                    
                        
                    </Stack>

                   
                    <ProgramDescription
                        value={description}
                        onChange={onChangeDescription}
                    />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>

                {/********* Modification ****** */}
        
                <LoadingButton onClick={onSubmit} disabled={pending} loading={pending}>
                    {pending ? "Enregistrement ..." : "Enregistrer"}
                </LoadingButton>
                
            </DialogActions>
        </Dialog>
    )
}
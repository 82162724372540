import React, { useEffect, useMemo, useRef, useState } from "react";
// MUI
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { FilterList } from "@mui/icons-material";
// Redux
import { AppDispatch, RootState } from "app/store";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// SHARED COMPONENTS
import Tabs from "components/molecules/Tabs";
import DialogExercice from "components/organisms/DialogExercice";

// LOCAL COMPONENTS
import DialogDeleteExercice from "components/molecules/DialogDeleteExercice";
import DialogCreateExercice from "components/organisms/DialogCreateExercice";

// INTERFACES
import Exercice from "interfaces/Exercice";
// REDUCERS
import { searchExercices, resetSearchExercices } from "features/exerciceSlice";
// ROUTER
import { definePageTitle, selectDrawerItem } from "features/appSlice";
import { useHistory } from "react-router-dom";

import PageTitle from "components/atoms/Typography/PageTitle";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import ListItemExercice from "components/molecules/ListItemExercice";
import { ViewportList } from "react-viewport-list";
import EmptyState from "components/molecules/EmptyState";
import SearchTextfield from "components/molecules/Search";
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import ExercicesFilters from "components/organisms/ExercicesFilters";
import { MOBILE, TABLET } from "utils/constants";
import EmptySearch from "components/molecules/EmptySearch";
import classes from "./styles";
import Toolbar from "components/molecules/Toolbar";

const EXERCICES_LISTS: string[] = ["app", "custom", "drafts"];

/************ EXERCICESLIST ************ */

export default function ExercicesList() {
    const navigate = useHistory();
    const dispatch = useDispatch<AppDispatch>();
    const filteredExercices = useSelector(
        (state: RootState) => state.exercices.filtered
    );
    const allExercices = useSelector((state: RootState) => state.exercices.list);
    const groupFilters = useSelector(
        (state: RootState) => state.exercices.groupsFilter
    );
    const typesFilters = useSelector(
        (state: RootState) => state.exercices.typesFilter
    );
    const mobile = useMediaQuery(MOBILE);

    // REF
    const leftItemRef = useRef(null);
    const listRef = useRef(null);

    // Local
    const [tabs, setTabs] = useState(0);
    const [openDialogExercice, setOpenDialogExercice] = useState<boolean>(false);
    const [openDeleteDialogExercice, setOpenDeleteDialogExercice] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [exerciceSelected, setExerciceSelected] = useState<Exercice>(null);
    const [openCreateExercice, setOpenCreateExercice] = useState<boolean>(false);
    const [fixedMenu, setFixedMenu] = useState<boolean>(false);
    const [anchorFilterList, setAnchorFilterList] = useState<HTMLButtonElement>(null);

    const IS_TABLET = useMediaQuery(TABLET);

    useEffect(() => {
        // Lorsque la liste des exercices de bases change, on relance une recherche
        dispatch(searchExercices({ search }));
    }, [allExercices]);

    /********** A l'arrivée sur l'écran, on reset la recherche ********** */
    useEffect(() => {
        dispatch(resetSearchExercices());
        dispatch(selectDrawerItem(2));
        dispatch(definePageTitle({ pageTitle: "Liste des exercices" }));
    }, [dispatch]);

    /********** GESTION DES TABS ********** */
    const onChangeTabs = (_: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    /********** CLICK SUR UN EXERCICE ********** */
    const navigateTo = (exercice: Exercice) => {
        if (!exercice.appExercice) {
            navigate.push(`/exercices/${exercice._id}`);
        } else {
            setExerciceSelected(exercice);
            setOpenDialogExercice(true);
        }
    };

    /********** Gestion des dialogs ********** */

    // Fermeture de l'exercice details dialogue
    const onCloseDialogExercice = () => {
        setOpenDialogExercice(false);
        setExerciceSelected(null);
    };

    // Suppression
    const onOpenDeleteExerciceDialog = (exercice: Exercice) => async () => {
        setExerciceSelected(exercice);
        setOpenDeleteDialogExercice(true);
    };

    const onCloseDeleteExerciceDialog = () => {
        setOpenDeleteDialogExercice(false);
        setTimeout(() => {
            setExerciceSelected(null);
        }, 200);
    };

    /********** TABS LABELS ********** */

    const tabsLabels = useMemo(() => {
        return [`Application`, `Mes exercices`];
    }, [filteredExercices]);

    /********** EXERCICES A AFFICHER ********** */

    const exercicesToDisplay = useMemo(() => {
        let exercices: Exercice[] = filteredExercices.app;
        if (tabs === 0) exercices = filteredExercices.app;
        else if (tabs === 1) exercices = filteredExercices.custom;

        return exercices;
    }, [tabs, filteredExercices]);

    /********** RECHERCHE ********** */

    const onSearch = (e: any) => {
        setSearch(e.currentTarget.value);
        dispatch(searchExercices({ search: e.currentTarget.value }));
    };

    /************************* FILTRE *********************** */

    useEffect(() => {
        dispatch(searchExercices({ search }));
    }, [groupFilters, typesFilters]);

    /************************* POSITIONNEMENT DU MENU LATERAL *********************** */
    useEffect(() => {
        if (leftItemRef?.current) {
            const handleScroll = () => {
                const y = leftItemRef.current.getBoundingClientRect().y;
                if (y < -25 && !fixedMenu) {
                    setFixedMenu(true);
                } else if (y > -24 && fixedMenu) {
                    setFixedMenu(false);
                }
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [leftItemRef?.current, fixedMenu, tabs]);

    
    const CURRENT_LIST: "app" | "custom" = useMemo(() => {
        return EXERCICES_LISTS[tabs] as "app" | "custom";
    }, [tabs]);

    const onOpenFilterList = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterList(event.currentTarget);
    };

    /********** JSX ********** */

    return (
        <React.Fragment>
            <Header expanded={true}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                            <Toolbar expanded={true}>
                                <PageTitle expanded={true}>
                                    Exercices
                                </PageTitle>
                        
                                <Box sx={classes.tabs}>
                                    <Tabs 
                                        tabs={tabsLabels} 
                                        value={tabs} 
                                        onChange={onChangeTabs} 
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            flex: 2,
                                            flexDirection: "row",
                                            marginBottom: 1,
                                        }}
                                    >
                                        <IconButton
                                            sx={{ marginRight: 1 }}
                                            onClick={onOpenFilterList}
                                        >
                                            <FilterList />
                                        </IconButton>

                                        {!mobile && (
                                            <SearchTextfield
                                                onChange={onSearch}
                                                placeholder={"Rechercher un exercice"}
                                            />
                                        )}

                                        {!IS_TABLET && (
                                            <ContainedPrimaryButton
                                                variant="contained"
                                                onClick={() => setOpenCreateExercice(true)}
                                                disableElevation={true}
                                            >
                                                Ajouter un exercice
                                            </ContainedPrimaryButton>
                                        )}
                                    </Box>
                                </Box>
                            </Toolbar>
                       
                    </Grid>
                </Grid>
            </Header>

            <MainContent
                header="withTabs"
            >
                {/***************************************************************
                *          LISTE DES EXERCICES AVEC MENU DE FILTRAGE
                ***************************************************************/}

                {allExercices[CURRENT_LIST].length > 0 && (
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ paddingBottom: 10 }}
                    >
                        {/***** LISTE DES EXERCICES **** */}

                        <Box sx={classes.listHeader}>
                            {IS_TABLET && (
                                <ContainedPrimaryButton
                                    variant="contained"
                                    onClick={() => setOpenCreateExercice(true)}
                                    disableElevation={true}
                                >
                                    Ajouter un exercice
                                </ContainedPrimaryButton>
                            )}
                        </Box>

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                            {/**** RESULTATS **** */}

                            {exercicesToDisplay.length > 0 && (
                                <Box 
                                    ref={listRef}
                                >
                                    <ViewportList
                                        ref={listRef}
                                        items={exercicesToDisplay}
                                        initialPrerender={15}
                                        itemSize={95}
                                    >
                                        {(item) => (
                                            <ListItemExercice
                                                key={item._id}
                                                exercice={item}
                                                onOpenDeleteExerciceDialog={onOpenDeleteExerciceDialog}
                                                onClick={navigateTo}
                                            />
                                        )}
                                    </ViewportList>
                                </Box>
                            )}

                            {/**** AUCUN RESULTAT **** */}

                            {exercicesToDisplay.length === 0 && (
                                <EmptySearch 
                                    title={"Aucun exercice"}
                                    description={"Tu n'as créé aucun exercice personnalisé pour le moment."} 
                                />
                            )}
                        </Grid>
                    </Grid>
                )}

                {/**************************************
                 *          AUCUN EXERCICE CREE
                 ***************************************/}

                {allExercices[CURRENT_LIST].length === 0 && (
                    <EmptyState
                        title={"Aucun exercice"}
                        description={
                            "Tu n'as créé aucun exercice personnalisé pour le moment."
                        }
                        image={"empty_exercices.png"}
                        height={`calc(100vh - 48px - 115px)`}
                    />
                )}

                {/********** DIALOGS ********** */}
                <DialogExercice
                    exerciceId={exerciceSelected?._id}
                    open={openDialogExercice}
                    onClose={onCloseDialogExercice}
                />

                <DialogDeleteExercice
                    exercice={exerciceSelected}
                    open={openDeleteDialogExercice}
                    onClose={onCloseDeleteExerciceDialog}
                />

                <DialogCreateExercice
                    open={openCreateExercice}
                    onClose={() => setOpenCreateExercice(false)}
                />

                <ExercicesFilters
                    open={Boolean(anchorFilterList)}
                    anchorEl={anchorFilterList}
                    onClose={() => setAnchorFilterList(null)}
                />
            </MainContent>
        </React.Fragment>
    );
}

import { BORDER_COLOR } from "utils/constants";

const classes = {
    stats: {
        backgroundColor: "white",
        width: "100%",
        border: `solid 1px ${BORDER_COLOR}`,
        padding: 3,
        marginBottom: 0,
        
    },

    statsTitle: {
        fontWeight: 700
    },

    chartContainer: {
        marginBottom: 0,
        paddingTop: 3,
        paddingBottom: 3,
        overflow:"hidden",

    },

    macros : {
        display: "flex",
        flexDirection: "column",
        marginTop: 3,
        justifyContent:"center",
        alignItems:"center"
        
    },


    macroLine: {
        display:"flex",
        flexDirection: "row",
        marginBottom: 1.5,
        justifyContent: "space-between",
    },

    macro: {
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent: "center",
        marginRight: 3
    },

    macroColor: {
        width: "20px",
        height: "20px",
        backgroundColor: "black",
        marginRight: 1.5,
    },

    macroValue: {
        //width: 150
    }
}


export default classes
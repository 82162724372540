/**
 * Ajout des Key sur les exercices/groupes et des blocks de séances (Workouts créés avant la mise à jour V2)
 * 
 * @params {Checkup} La séance nécessitant des Keys uniques
 * @return {Checkup} La séance avec les clés 
 * 
 * */


import Checkup, { CheckupQuestion } from "interfaces/Checkup"
import { v4 as uuid } from "uuid"



export function addIdsToCheckup(checkup: Checkup) {

    const checkupWithIds:Checkup = { ...checkup }
    const questions:CheckupQuestion[] = []

    // ON PARCOURT L'ENSEMBLE DES QUESTIONS
    checkupWithIds.questions.forEach((question: CheckupQuestion, questionIndex:number ) => {
        questions.push({...question, questionId : uuid()})
    })

    checkupWithIds.questions = questions

    // ON renvoie le checkup avec les clés ajoutées
    return checkupWithIds

}

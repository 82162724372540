import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from "@mui/material";
import { MOBILE } from "utils/constants";
import classes from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { Close } from "@mui/icons-material";

interface Props{
    open: boolean,
    onClose: ()=> void
}

export default function DialogIsTrialing({open, onClose}:Props){

    const mobile = useMediaQuery(MOBILE)
    const user = useSelector((state:RootState)=> state.user.data)

    return(

        <Dialog 
            open={open} 
            fullScreen={mobile}
        >
            <DialogContent 
                sx={{width: mobile ? "100%" : 620, overflowY: "auto", padding : mobile? 1 : 3}}
            >
                
                <Box sx={classes.content}>
                    <Typography 
                        variant='h5' 
                        sx={{fontWeight: 700, marginBottom: 1, textAlign:"center"}}
                    >
                        Ta période d'essai n'est pas terminé !
                    </Typography>

                    {/*<Box component="img" src='/assets/subscription_needed.jpg' sx={{width: mobile ? "100%" : 500, marginBottom: 3}} />*/}

                    {/************ DESCRIPTION PERIODE D'ESSAI *********** */}

                   
                    <Typography 
                        sx={classes.description}
                    >
                        {user.firstname}, tu es toujours en période d'essai, profite de ce temps pour continuer à tester Traener gratuitement ! Tu ne perdras aucune donnée et tes clients ne seront pas impactés lorsque ta période d'essai sera terminée. <br/>No stress 😎 !
                    </Typography>

                    <Button 
                            sx={classes.button} 
                            onClick={onClose} >
                                J'ai compris
                        </Button>
                </Box>

                {/****** BOUTON CLOSE ****** */}
                <Box sx={{position: "absolute", right: 10, top: 10}}>
                    <IconButton 
                        sx={{backgroundColor: "#eaeaea", borderRadius: "100% !important"}} 
                        onClick={onClose} 
                        >
                        <Close />
                    </IconButton>
                </Box>
                   

                    
            </DialogContent>

            
        </Dialog>
            
    )
}
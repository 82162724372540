import { Typography } from "@mui/material";
import React from "react";
import classes from "./styles";

interface Props{
    children: React.ReactNode
}

export default function DialogTitleLabel({children}:Props){

    return(
        <Typography sx={classes.title}>
           {children}
        </Typography>
    )
}
import { Box, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import classes from "./styles";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { useMemo } from "react";
import { FoodPortion } from "interfaces/Food";
import { DEFAULT_PORTIONS } from "utils/constants";
import { onChangeIngredientPortion, onChangePortionLabel, onDeleteIngredient } from "features/mealPlanSlice";

interface Props{
    ingredientIndex: number,
    mealIndex: number,
    day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
}

export default function MealFoodItem({ingredientIndex, mealIndex, day}:Props){

    const portion = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].portion)
    const foodPortions = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].food.portions)
    const name = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].food.name.fr)
    const label = useSelector((state:RootState)=> state.mealPlans.mealPlan[day][mealIndex]?.content[ingredientIndex].label)
    const dispatch = useDispatch<AppDispatch>()


    const onChangePortion = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onChangeIngredientPortion({
            day,
            foodIndex: ingredientIndex,
            mealIndex,
            portion: event.target.value,
            foodPortions: foodPortions
        }))
    }

    const changePortionLabel = (event: SelectChangeEvent<string>) => {
        dispatch(onChangePortionLabel({
            day,
            foodIndex: ingredientIndex,
            mealIndex,
            newLabel: event.target.value,
            foodPortions: foodPortions
        }))
    }


    const onDelete = () => {
        dispatch(onDeleteIngredient({
            day,
            ingredientIndex,
            mealIndex
        }))
    }



    const PORTIONS:FoodPortion[] = useMemo(()=>{
        const allPortions:FoodPortion[] =  [...DEFAULT_PORTIONS]

        
        if(foodPortions){
            return allPortions.concat(foodPortions)
        }else{
            return allPortions
        }
    },[foodPortions])


    return(
        <Box
            sx={classes.item}
        >   
            <Box
                sx={classes.itemInfos}
            >

                <Box
                    sx={classes.infos}
                >

                    <TextField
                        value={portion}
                        sx={classes.portionInput}
                        size="small"
                        onFocus={event => {
                            event.target.select();
                        }}
                        onChange={onChangePortion}
                    />

                    <Select
                        size="small"
                        value={label}
                        onChange={changePortionLabel}
                        sx={classes.labelInput}
                    >
                        {PORTIONS.map((elem, index)=> {
                            return(
                                <MenuItem 
                                    key={index}
                                    value={elem.label.fr}
                                >
                                    {elem.label.fr}
                                </MenuItem>
                            )
                        })}
                        
                        
                            
                    </Select>

                </Box>

                <Typography
                    sx={classes.itemName}
                >
                    {name}
                </Typography>
            </Box>
            <Box
                sx={{marginLeft: 2}}
            >
                <IconButton
                    onClick={onDelete}
                >
                    <Delete/>
                </IconButton>
            </Box>
        </Box>
    )
}
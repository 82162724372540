import { createSlice, current } from '@reduxjs/toolkit'
// API
import { 
  checkProgramPlanningDate,
    createNewProgram, deleteProgram, updateProgram,
} from 'api/programs';
import { getTemplates } from 'api/templates';
import { addWeeks } from 'date-fns';
import fuseSearch from 'function/fuseSearch';
import Checkup from 'interfaces/Checkup';
import Program from 'interfaces/Program';
import Workout from 'interfaces/Workout';
import { toast } from 'react-toastify';


/************** INIT SLICE ****************/

interface ProgramSlice {
    requests:{
        get:"pending"|"idle",
        create:"pending"|"idle",
        update: "pending" | "idle",
        delete: "pending" | "idle",
        duplicate: "pending" | "idle",
        check: "pending" | "idle"
    },
    templates:{
        list: Program[],
        filtered: Program[]
    },
    builderIsReady: boolean,
}


/******** INIT STATE ********** */
const initialState:ProgramSlice = {
    requests:{
        get:"idle",
        create:"idle",
        update: "idle",
        delete:"idle",
        duplicate: "idle",
        check: "idle"
    },
    templates:{
        list: [],
        filtered: []
    },
    builderIsReady: false,
} 




/*********************************************** */


// Slice
export const programBuilder = createSlice({
  name: 'programs',
  initialState,

  /*********************** REDUCERS ******************** */

  reducers: {
       // FUSE SEARCH
    searchProgramsTemplates: (state, action) => {
        const search:string = action.payload.search
  
        const keys:string[] = [
          "name",
        ]
  
        state.templates.filtered = search !== "" ? fuseSearch(current(state.templates.list), search, keys) : state.templates.list
      },
      resetSearchPrograms: (state) => {
        state.templates.filtered = state.templates.list
      }
  },
  /*********************** EXTRA REDUCERS ******************** */

  extraReducers(builder) {
    builder


     /********* GET TEMPLATES ********* */
    .addCase(getTemplates.fulfilled, (state, {payload})=>{
        const templates = payload.templates
        if(templates.programs){
          state.templates.list = templates.programs
          state.templates.filtered = templates.programs
        }
    })


    /********* CREATE PROGRAM ********* */
    .addCase(createNewProgram.pending, (state)=>{
        state.requests.create = "pending"
    })
    .addCase(createNewProgram.fulfilled, (state, {payload})=>{
        state.requests.create = "idle"
        const {program} = payload
        if(program?.template){ // Séance personnalisée
            const customList = [...current(state.templates.list)]
            customList.push(program)
            const sortedList = customList.sort((a,b)=> {
              if(a.name > b.name){
                return 1
              } else if(a.name < b.name){
                return -1
              }else return 0
            })
            state.templates.list = sortedList
            state.templates.filtered = sortedList
        }
    })


    /********* DELETE PROGRAM  (TEMPLATE) ********* */
    .addCase(deleteProgram.pending, (state)=>{
        state.requests.delete = "pending"
    })
    .addCase(deleteProgram.fulfilled, (state, {payload})=>{
        state.requests.delete = "idle"
        const {program} = payload
        if(program.template){
            const index = state.templates.list.findIndex((elem) => elem._id === program._id)
            state.templates.list.splice(index, 1)
            const indexFiltered = state.templates.filtered.findIndex((elem) => elem._id === program._id)
            state.templates.filtered.splice(indexFiltered, 1)
            toast.success('Modèle supprimé')
        }
    })

    /******* CHECK PROGRAM DATE ******** */

    .addCase(checkProgramPlanningDate.pending, (state) => {
        state.requests.check = "idle"
    })
    .addCase(checkProgramPlanningDate.fulfilled, (state)=>{
      state.requests.check = "idle"
    })


    /******* UPDATE PROGRAM ******** */

    
    .addCase(updateProgram.fulfilled, (state,{payload})=>{
      state.requests.update = "idle"
      const {program} = payload
      if(program?.template){ // program custom
        const indexList = state.templates.list.findIndex((elem)=> elem._id === program._id)
        state.templates.list[indexList] = program
        const indexFiltered = state.templates.filtered.findIndex((elem)=> elem._id === program._id)
        state.templates.filtered[indexFiltered] = program

      }
    })

  }
})

export const {
    searchProgramsTemplates,
    resetSearchPrograms
} = programBuilder.actions


export default programBuilder.reducer
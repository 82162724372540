import { lighten } from "@mui/material"
import { HOVER_COLOR } from "utils/constants"
import { theme } from "utils/theme"

const BUTTON_SIZE = 38

const classes = {
    weekButtons : {
        zIndex:3,
        width:BUTTON_SIZE,
        height: BUTTON_SIZE,
        borderRadius: "100px !important",
        position: "absolute",
        top : 10,
        right: 5,
        minWidth: `${BUTTON_SIZE}px !important`,
        padding: 0,
        margin:0,
        backgroundColor: HOVER_COLOR,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        "&:hover":{
            backgroundColor: lighten(theme.palette.primary.main, 0.94),
        }
    },

    menuLabel:{
        ml: 3, 
        mt:2, 
        mb:1, 
        color: "#83b4eb", 
        fontSize: "1rem", 
        fontWeight: 700
    },
    

    hidden: {
        display: "none"
    }
}


export default classes
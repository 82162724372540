

const classes = {
    autoSave: {
        display: {
            xs: "none", 
            sm: "inline-flex",
        }, 
        alignItems:"center", 
        justifyContent:"center",
        flexWrap: "nowrap",
        textWrap: "nowrap"
    },

    indicator: {
        backgroundColor: "#24c02b", 
        borderRadius: 100, 
        width: 12, 
        height: 12, 
        marginRight:1,
        
    },

    typo: {
        color: "#adadad", 
        fontWeight: 500, 
        fontSize: "0.9rem"
    }
}


export default classes
import Workout from "interfaces/Workout";


export default function calcTonnage(workout:Workout):number{

    var tonnage:number = 0

    workout.blocks.filter((block) => block.track).forEach((block)=>{
        block.content.forEach((group)=> {
            group.exercices.forEach((exercice)=> {
                exercice.sets.forEach((set)=> {
                    if(
                        set.performances?.performed && 
                        set.performances.hasOwnProperty("weight") && 
                        set.performances.hasOwnProperty("reps") && 
                        set.performances.weight !== "" && 
                        set.performances.reps !== "" 
                    ){
                        tonnage = tonnage + parseFloat(set.performances.weight) * parseFloat(set.performances.reps)
                        if(set.dropset?.weight){
                            Array.from(Array(set.dropset.sets)).forEach((_,index)=> {
                                tonnage = tonnage + ((parseFloat(set.performances.dropset[index].weight) || 0) * (parseFloat(set.performances.dropset[index].reps) || 0))
                                
                              })
                        }
                    }
                })
            })
        })
    })


    return tonnage
}
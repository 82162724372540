import { Box, Button, Grid, Popover, Typography } from "@mui/material"
import classes from "./style"
import { EXERCICE_GROUPS, EXERCICE_GROUPS_TRAD } from "utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
import { addFilterGroup, addFilterType, clearExerciceFilter, removeFilterGroup, removeFilterType } from "features/exerciceSlice"
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { ExerciceType } from "interfaces/Exercice"

interface Props{
    open: boolean,
    onClose: ()=>void,
    anchorEl: HTMLButtonElement
}

/*const TYPES = [
    {
        _id:"63e2674d8a98895bec9b5f1c",
        name: {
            fr: "Isométrie",
            en: "Isometric"
        }
    },
    {
        _id:"63e2583d4634b611780b1f9a",
        name: {
            fr: "Musculation",
            en: "Weight lifting"
        }
    },
    {
        _id:"63e25db34634b611780b1fab",
        name: {
            fr: "Cardio",
            en: "Cardio"
        }
    },
    {
        _id:"65f17147cf4542f714e2d047",
        name: {
            fr: "Mobilité",
            en: "Mobility"
        }
    }
]*/

export default function ExercicesFilters({onClose, open, anchorEl}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const filtersGroups = useSelector((state:RootState)=> state.exercices.groupsFilter)
    const filtersTypes = useSelector((state:RootState)=> state.exercices.typesFilter)
    const typesList:ExerciceType[] = useSelector((state: RootState) => state.exercices.types) // liste des types d'exercices


    const onClearFilter = () => {
        dispatch(clearExerciceFilter())
    }


    /************************* CHECK FILTER *********************** */

    const handleFilterGroup = (group:string) => ()=>{

        const exist = filtersGroups.find((elem)=> elem === group)

        if(!exist){ 
            dispatch(addFilterGroup({group}))
        } else {
            dispatch(removeFilterGroup({group}))
        }
    }


    const handleFilterType = (type:string) => ()=>{

        const exist = filtersTypes.find((elem)=> elem === type)

        if(!exist){ 
            dispatch(addFilterType({type}))
        } else {
            dispatch(removeFilterType({type}))
        }
    }




    return(
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            disableScrollLock={true}
            sx={classes.popover}
        >
        <Box 
            sx={[classes.filterMenu]}
        >
            <Box 
                sx={{position: "relative"}}
            >

                {/**** FILTER HEADER **** */}
                <Box sx={classes.filterHeader}>
                    <Typography sx={{fontWeight: 600, fontSize: 15}}>
                        Filtrer les exercices
                    </Typography>
                    <Button 
                        sx={{textTransform: "none"}} 
                        onClick={onClearFilter}
                    >
                        Décocher
                    </Button>
                </Box>

                <Grid sx={{display:"flex", flexDirection: "row"}}>

                    <Grid 
                        item 
                        xs={6}
                        sx={{width: 200}}
                    >

                        {/**** LISTE DES GROUPES **** */}
                        {EXERCICE_GROUPS.map((group)=> {

                            const checked = filtersGroups.find((elem)=> elem === group)

                            return(
                                <Box 
                                    key={group} 
                                    sx={classes.filter} 
                                    onClick={handleFilterGroup(group)}
                                >

                                    {checked && <CheckBox sx={[classes.checkbox, classes.checboxChecked]}/>}
                                    {!checked && <CheckBoxOutlineBlank sx={classes.checkbox}/>}
                                    
                                    <Typography sx={classes.group}>
                                        {EXERCICE_GROUPS_TRAD[group].fr}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Grid>

                    <Grid 
                        item 
                        xs={6}
                        sx={{width: 200}}
                    >
                         {/**** LISTE DES <TYPES> **** */}
                         {typesList.map((type)=> {

                            const checked = filtersTypes.find((elem)=> elem === type._id)

                            return(
                                <Box 
                                    key={type._id} 
                                    sx={classes.filter} 
                                    onClick={handleFilterType(type._id)}
                                >
                                    {checked && <CheckBox sx={[classes.checkbox, classes.checboxChecked]}/>}
                                    {!checked && <CheckBoxOutlineBlank sx={classes.checkbox}/>}
                                   
                                    <Typography sx={classes.group}>
                                        {type.name.fr}
                                    </Typography>
                                </Box>
                            )
                            })}
                    </Grid>

                </Grid>
            

            </Box>
        </Box>
        </Popover>
    )
}
import { useState, useEffect } from "react";
//MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery } from "@mui/material";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
// INTERFACE
import { AppDispatch, RootState } from "app/store";
// Apis

// Constants
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateUserData } from "api/user";

/*********** REDUX SELECTORS *************** */ 


interface Props {
    open: boolean,
    onClose: ()=> void,
}

/*********** MAIN COMPONENT *************** */ 

export default function DialogName({open,onClose}:Props){
    

    const dispatch = useDispatch<AppDispatch>()
    const [firstname, setFirstname] = useState<string>("")
    const [lastname, setLastname] = useState<string>("")
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const mobile = useMediaQuery(MOBILE)

    useEffect(()=>{
        if(open){
            setFirstname(user.firstname)
            setLastname(user.lastname)
        }
    },[open])


    const submit = () => {
        dispatch(updateUserData({firstname,lastname})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
        
    }

    return(

        <Dialog 
            open={open}
        >
            <DialogTitle>
                Modifier ton nom
            </DialogTitle>
            <DialogContent sx={{width:mobile? "100%" : DIALOG_WIDTH}}>
                <TextField
                    fullWidth
                    label="Prénom"
                    onChange={(e)=> setFirstname(e.target.value)}
                    value={firstname}
                    sx={{marginTop: 1, marginBottom: 2}}
                />

                <TextField
                    fullWidth
                    label="Nom"
                    onChange={(e)=> setLastname(e.target.value)}
                    value={lastname}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                    {pending? "Modification ..." : "Valider"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Box, Button, Grid, IconButton, Popover, Typography } from "@mui/material"
import { AppDispatch, RootState } from "app/store"
import { addMonths, endOfMonth, endOfWeek, format, getMonth, getYear, startOfMonth, startOfWeek } from "date-fns"
import { fr } from "date-fns/locale"
import { defineNewPlanningRange } from "features/planningSlice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import User from "interfaces/User"
import { getPlanning } from "api/clients"




/**************** MAIN ****************** */

export default function PlanningDatePicker(){

    const dispatch = useDispatch<AppDispatch>()
    const planningStart = useSelector((state:RootState) => state.planning.builder.startDate,)
    const ready = useSelector((state:RootState) => state.planning.builder.isReady)
    const user = useSelector((state:RootState) => state.planning.user)
    
    const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null)
    const [pickerMonth, setPickerMonth] = useState<number>(getMonth(endOfWeek(new Date(planningStart), {locale: fr})))
    const [pickerYear, setPickerYear] = useState<number>(getYear(endOfWeek(new Date(planningStart), {locale: fr})))


    /*************************
     * OUVERTURE DU PICKER
     * **********************/

    useEffect(()=>{
        if(datePickerAnchorEl){
            setPickerMonth(getMonth(endOfWeek(new Date(planningStart), {locale: fr})))
            setPickerYear(getYear(endOfWeek(new Date(planningStart), {locale: fr})))
        }
    },[datePickerAnchorEl])


    /********* AFFICHER LE MOIS SUIVANT ******* */

    const nextMonth = async () => {
        var year:number = getYear(endOfWeek(new Date(planningStart), {locale:fr}), )
        const nextMonth:number = getMonth(addMonths(endOfWeek(new Date(planningStart), {locale:fr}),1)) // Ajout d'un mois au dernier jour du premier week end du planning
        if(nextMonth === 0){
            year = year + 1
        }
        const lastDayOfNexMonth: Date = endOfMonth(new Date(`${nextMonth + 1}/01/${year}`)) // Dernier joru du mois prochain
        
        const start = new Date(startOfWeek(startOfMonth(lastDayOfNexMonth), {locale: fr}).toDateString())
        const end = new Date(endOfWeek(lastDayOfNexMonth, {locale: fr}).toDateString())

       

        dispatch(defineNewPlanningRange({start: start.toDateString(),end: end.toDateString()}))
        dispatch(getPlanning({
            userId: user._id, 
            start: new Date(start), 
            end: new Date(end)
        }))
    }


    /********* AFFICHER LE MOIS PRECEDENT ******* */

    const prevMonth = async () => {
        var year:number = getYear(endOfWeek(new Date(planningStart), {locale:fr}))
        const prevMonth:number = getMonth(addMonths(endOfWeek(new Date(planningStart), {locale:fr}),-1)) // On retir un mois au dernier jour du premier week end du planning
        if(prevMonth === 11){
            year = year - 1
        }
        const lastDayOfNexMonth: Date = endOfMonth(new Date(`${prevMonth + 1}/01/${year}`)) // Dernier joru du mois prochain

        const start = new Date(startOfWeek(startOfMonth(lastDayOfNexMonth), {locale: fr}).toDateString())
        const end = new Date(endOfWeek(lastDayOfNexMonth, {locale: fr}).toDateString())

        dispatch(defineNewPlanningRange({start: start.toDateString(),end: end.toDateString()}))
        dispatch(getPlanning({
            userId: user._id, 
            start: new Date(start), 
            end: new Date(end)
        }))
    }


    /********* Choisir une nouvelle plage avec le date picker ******* */
    const onDefineNewRange = async () => {
        const lastDayOfNexMonth: Date = endOfMonth(new Date(`${pickerMonth + 1}/01/${pickerYear}`)) // Dernier joru du mois prochain
        const start = new Date(startOfWeek(startOfMonth(lastDayOfNexMonth), {locale: fr}).toDateString())
        const end = new Date(endOfWeek(lastDayOfNexMonth, {locale: fr}).toDateString())
        
        dispatch(defineNewPlanningRange({start: start.toDateString(),end: end.toDateString()}))
        dispatch(getPlanning({
            userId: user._id, 
            start: new Date(start), 
            end: new Date(end)
        }))
        setDatePickerAnchorEl(null)
    }

    return(
        <Box sx={classes.header}>


            {/**************************************************
             *           INDICATEUR DU MOIS EN COURS    
             ***************************************************/}

            <IconButton 
                sx={{marginRight: 2}} 
                onClick={prevMonth} 
                disabled={!ready}
            >
                <ChevronLeft />
            </IconButton>
            

            <Button 
                sx={classes.button}
                onClick={(e)=> setDatePickerAnchorEl(e.currentTarget)}
            >
                {format(endOfWeek(new Date(planningStart), {locale: fr}),"MMMM", {locale: fr})} {format(endOfWeek(new Date(planningStart), {locale: fr}),"yyyy", {locale: fr})}
            </Button>
        

            <IconButton 
                sx={{marginLeft: 2}} 
                onClick={nextMonth} 
                disabled={!ready}
            >
                <ChevronRight />
            </IconButton>


            {/**************************************
             *              DATE PICKER POPOVER
             **************************************/}

            <Popover
                open={Boolean(datePickerAnchorEl)}
                anchorEl={datePickerAnchorEl}
                onClose={()=>setDatePickerAnchorEl(null)}
                PaperProps={{
                    sx:{
                        width: 800,
                        maxHeight: 400,
                        overflowY: "hidden"
                    }
                }}
                
            >
                <Box sx={{padding: 3}}>
                    <Typography variant="h5">
                        {format(endOfWeek(new Date(`${pickerMonth + 1}/01/${pickerYear}`), {locale: fr}),"MMMM yyyy", {locale: fr})}
                    </Typography>
                </Box>



                <Grid container>
                    <Grid item xs={6} sx={{borderRight:"solid 2px whitesmoke"}}>
                        <Grid container>
                            {Array.from(Array(12)).map((_,index:number)=> {

                                const selected:boolean = pickerMonth === index

                                return(
                                    <Grid item xs={4} key={index}>
                                        <Button 
                                            sx={[classes.calendarItem, selected  && classes.calendarItemSelected]}
                                            onClick={()=>setPickerMonth(index)}
                                        >
                                            {format(new Date(`${index+1}/15/1970`), "MMMM", {locale: fr})}
                                        </Button>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    

                    <Grid item xs={6}>
                    
                            <Grid container sx={{overflowY: "scroll", maxHeight: 240}}>
                                {Array.from(Array(80)).map((_,index:number)=> {

                                    const selected:boolean = pickerYear === (2020 + index)

                                    return(
                                        <Grid item xs={4} key={index}>
                                            <Button 
                                                sx={[classes.calendarItem, selected && classes.calendarItemSelected]}
                                                onClick={()=>setPickerYear(2020 + index)}
                                            >
                                                {2020 + index}
                                            </Button>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        
                    </Grid>

                </Grid>

                <Box sx={{padding: 3, display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                    <Button onClick={()=>setDatePickerAnchorEl(null)} sx={{marginRight:2}}>
                        Fermer
                    </Button>
                    <Button variant="contained" onClick={onDefineNewRange}>
                        Valider
                    </Button>
                </Box>
            </Popover>
        </Box>
    )
}
import { useState, useEffect } from "react";
// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, useMediaQuery } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
// INTERFACES
import { AppDispatch, RootState } from "app/store";
// COnstants
import { DIALOG_WIDTH, MOBILE } from "utils/constants";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { updateUserData } from "api/user";


/*********** REDUX SELECTORS *************** */ 

interface Props {
    open: boolean,
    onClose: ()=> void,
}


/*********** MAIN COMPONENT *************** */ 

export default function DialogGender({open,onClose}:Props){
    
    const pending = useSelector((state:RootState) => state.user.requests.update === "pending")
    const user = useSelector((state:RootState) => state.user.data)
    const dispatch = useDispatch<AppDispatch>()
    const [gender, setGender] = useState<string>("")
    const mobile = useMediaQuery(MOBILE)

    // OUverture du dialog
    useEffect(()=>{
        if(open){
            setGender(user.gender)
        }
    },[open])


    // Changement de sexe
    const handleChange = (event: SelectChangeEvent) => {
        setGender(event.target.value as string);
    };


    // Envoie du formulaire 

    const submit = () => {
        dispatch(updateUserData({gender})).unwrap().then((res)=>{
            if(res.user) onClose()
        })
    
    }


    /*********** JSX *************** */ 

    return(

        <Dialog 
            open={open} 
        >
            <DialogTitle>
                Modifier ton sexe
            </DialogTitle>
            <DialogContent sx={{width:mobile ? "100%" : DIALOG_WIDTH}}>
                <Select
                    value={gender}
                    label="Sexe"
                    onChange={handleChange}
                    fullWidth
                    sx={{mt: 1, mb: 1}}
                > 
                    <MenuItem value={"male"}>Homme</MenuItem>
                    <MenuItem value={"female"}>Femme</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton disabled={pending} loading={pending} onClick={submit}>
                    {pending? "Modification ..." : "Valider"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
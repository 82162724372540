import { BUTTON_SECONDARY_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"
import { theme } from "utils/theme"


const classes= {


    pageHeader:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        marginBottom: 1.5
    },

    header: {
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", 
        marginBottom: 2
    },

    pageHeaderWithMargin:{
        marginTop: 4
    },

    header__buttons:{
        display: "flex", 
        flexDirection: "row",
        justifyContent:"flex-end",
        marginTop: 2,
        marginBottom: 2
    },

    emptyForm: {
        width: "100%", 
        height: 77.88, 
        border: "dashed 3px #f3f3f3", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", mb:3 
    },

    buttonContainer:{
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        width: "100%"
    },

    indicators:{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center"
    },


    chips:{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center" 
    },

    chipContained:{
        marginRight: 1, 
        fontWeight: 700, 
        backgroundColor: "primary.main", 
        color: "white" 
    },

    btActions:{
        marginLeft: 3, 
        display: "flex", 
        flexDirection: "row"
    },

    questionnaireStatus: {
        marginRight: 1,
        height: 25,
        paddingLeft: 1, 
        paddingRight: 1,
        lineHeight: "25px",
        borderRadius: 1,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
    },

    questionnaireStatusValid:{
        color: "primary.main",
        backgroundColor: LIGHT_BUTTON_COLOR,
        
    },

    questionnaireStatusIsNotValid:{
        backgroundColor: "#fff8d9",
        color: "#de9c0d"
        
    },

    mobileStatus : {
        display:"flex",
        alignItems:"center",
        justifyContent:{xs: "flex-end", sm: "flex-start"},
        paddingRight: 1,
        marginBottom: {xs: 1, sm: 3}
    }
}

export default classes
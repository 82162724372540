import { BORDER_COLOR } from "utils/constants";
import { theme } from "utils/theme";


export const classes = {
    container:{
            width: "100%",
            marginBottom: 2,
            backgroundColor: "white",
            padding: 3,
            border: `solid 1px ${BORDER_COLOR}`
        
    },

    sectionLabel:{
        width: "100%",
        marginBottom: 2,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18)
    },
}

export default classes
import calcBoxHeight from "function/calcBoxHeight"
import { BORDER_COLOR, DRAWER_WIDTH_MIN } from "utils/constants"
import { theme } from "utils/theme"

const classes = {

    planning: {
        width: "100%",
        position:"relative",
    },

    planningHeader: {
        minWidth: "100%"
    },

    

    planningContent:{
        minWidth: "100%",
        borderBottom: `solid 1px #e4e4e4`
    },

    planningContentScrolled: {
        marginTop: "45px"
    },


    planningStatus:{
        height: 60,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginBottom: 2
    },


    

    

    dayContent:{
        paddingbottom: 3, 
        display:"flex", 
        flex: 1,
        justifyContent:"flex-start",
        alignItems:"center",
        borderRight: "solid 1px whitesmoke",
        width: "100%",
        position: "relative",
        flexDirection: "column",
        borderBottom: "solid 1px #eaeaea",
        backgroundColor: "transparent",
        //backgroundColor: "white",
        //opacity: disabled ? 0.4 : 1,
        minHeight: 400,
    },

    dayContentStack: {
        paddingLeft: 1,
        paddingRight: 1
    },

    dateLabel:(isToday:boolean, isFirstDayOfMonth:boolean )=>({
        //marginBottom: 2, 
        width: "100%", 
        height: 40,
        //borderRadius: 50, 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        //backgroundColor: isToday ? lighten(theme.palette.primary.main,0.85) : "inherit", 
        //color: isToday ? "white" : "black",
        color: isToday ?"primary.main" :"#333333",
        //fontWeight: (isToday || isFirstDayOfMonth) ?"700" : "inherit",
        fontWeight: isToday || isFirstDayOfMonth ? 700 : 500,
        fontSize: "1.2rem"
    }),


    

    /************* TEMPLATE VIEW ************* */

    pageHeader:{
        display:"flex", 
        flexDirection:"row", 
        justifyContent:"space-between", 
        alignItems:"center",
        marginBottom: 2.5,
        width: "100%",
    },
    

}

export default classes
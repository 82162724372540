
import { Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, TextField, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { postInvitation } from "api/clients";
import React, { useEffect, useState } from "react";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { toast } from "react-toastify";
import { MOBILE } from "utils/constants";


{/********** Dialog d'envoi d'une invitation ********** */}

interface DialogInvitation {
    open: boolean,
    onClose: ()=> void
}

export default function DialogInvitation({open, onClose}:DialogInvitation){
    const dispatch= useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState) => state.clients.requests.postInvitation === "pending")
    const[email,setEmail] = useState<string>('')
    const[firstname,setFirstname] = useState<string>('')
    const[lastname,setLastname] = useState<string>('')
    const mobile = useMediaQuery(MOBILE)

    const onChangeFirstName = (e:React.ChangeEvent<HTMLInputElement>) => {
        setFirstname(e.target.value)

    }

    const onChangeLastName = (e:React.ChangeEvent<HTMLInputElement>) => {
        setLastname(e.target.value)

    }

    const onChangeEmail = (e:React.ChangeEvent<HTMLInputElement>) =>{
        setEmail(e.target.value)
        
    }

    const onPostInvitation = async () => {
        dispatch(postInvitation({email,firstname,lastname})).unwrap().then((res) => {
            if(res.invitation){
                onClose()
            } else if(res.error){
                toast.error(res.error)
            }
            
        })
    }


    return(

        <Dialog
            open={open}
            maxWidth={"xs"}
            fullScreen={mobile? true:false}
            disableScrollLock={true}
        >
            <DialogTitle>
                Envoyer une invitation
            </DialogTitle>

            <DialogContent>

                <Typography 
                    variant="body1">
                    Entre les informations de ton client. Une invitation à s'inscrire sur Traener lui sera envoyée par e-mail. <strong>Pense à bien respecter la casse (majuscules et minusules) de l'adresse email.</strong>
                </Typography>

                <Box sx={{ marginTop: 3 }}>
                    <TextField
                        name={"email"}
                        value={email}
                        label="Email"
                        type="email"
                        fullWidth={true}
                        sx={{ marginBottom: 3 }}
                        onChange={onChangeEmail}
                        autoComplete="off"
                        
                    />
        
                    <TextField
                        name={"firstname"}
                        value={firstname}
                        label="Prénom"
                        fullWidth={true}
                        sx={{ marginBottom: 3 }}
                        onChange={onChangeFirstName}
                        autoComplete="off"
                    />

                    <TextField
                        name={"lastname"}
                        value={lastname}
                        label="Nom"
                        fullWidth={true}
                        onChange={onChangeLastName}
                        autoComplete="off"
                    />
                </Box>

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} disabled={pending}>
                    Annuler
                </Button>
                <LoadingButton 
                    variant="contained" 
                    onClick={onPostInvitation} 
                    loading={pending} 
                    disabled={pending}
                >
                    Envoyer
                </LoadingButton>
            </DialogActions>

        </Dialog>

    )
}
import { Box, InputAdornment, TextField, useMediaQuery } from "@mui/material"
import { AppDispatch } from "app/store"
import { changeRestValue } from "features/workoutSlice"
import debounce from "lodash.debounce"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { INPUT_DEBOUNCE_TIMER, MOBILE } from "utils/constants"
import classes from "../SetTextField/styles"
import TimeMaskInput from "components/molecules/TimeInput"

/******** INTERFACES ******* */


interface Props{
    restTextField?: boolean,
    defaultValue: any,
    setIndex: number,
    blockId: string,
    groupId: string,
    exerciceId: string,
    disabled?:boolean,
    width?: string,
}





/******** MAIN COMPONENT ******* */

export function RestTextField({defaultValue, blockId, exerciceId, groupId, setIndex, disabled, width}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [value, setValue] = useState<number | string>("")
    const mobile = useMediaQuery(MOBILE)

    /************** VALEUR PAR DEFAULT ********* */

    useEffect(()=>{
        if(defaultValue !== value){
            setValue(defaultValue)
        }
    },[defaultValue])


    /************** CHANGEMENT DU TEMPS DE REPOS ********* */

    const debounceOnChange = useRef(debounce((data)=> {
        const {blockId, groupId, exerciceId, setIndex, columnIndex, value} = data
        dispatch(changeRestValue({
            blockId, 
            groupId, 
            exerciceId, 
            setIndex, 
            value
        }))
    },INPUT_DEBOUNCE_TIMER)).current


    const onChangeTimeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue:string = event.target.value
        setValue(newValue)
        debounceOnChange({value: newValue, blockId, groupId, exerciceId, setIndex})
    }


    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value
        const regex = /^[0-5]?\d:[0-5]\d$/
        if(!regex.test(value)){
            setValue("00:00")
            debounceOnChange({value: "00:00", blockId, groupId, exerciceId, setIndex})
        }
    }


    /********* JSX **********/

    return(
        <Box sx={[classes.prescriptionValue, width && {maxWidth:width}]}>
 
            <TextField
                type={"text"}
                variant="outlined"
                fullWidth={true}
                size="small"
                value={value}
                sx={[classes.prescriptionTextField, value === "00:00" && classes.noRest]}
                onChange={onChangeTimeValue}
                onBlur={onBlur}
                InputProps={{
                    startAdornment:
                    <InputAdornment position="start" sx={{color:"rgba(0,0,0,0.85)", fontWeight: 700}} disableTypography>
                        {mobile ? "Repos :" : ""}
                    </InputAdornment>,
                    endAdornment: 
                    <InputAdornment position="end" sx={{color:"primary.main", fontWeight: 700}} disableTypography>
                        {mobile ? "" : "min"}
                    </InputAdornment>,
                    inputComponent: TimeMaskInput as any,
                }}
                disabled={disabled?disabled: false}
            />
            
        </Box>
    )
    
}


function areEqual(prev:Props,next:Props){
    return prev.blockId === next.blockId && 
    prev.blockId === next.blockId &&
    prev.exerciceId === next.exerciceId &&
    prev.groupId === next.groupId &&
    prev.setIndex === next.setIndex &&
    prev.defaultValue === next.defaultValue
}

export default React.memo(RestTextField, areEqual)
import { theme } from "utils/theme"

const classes = {

passwordHelper: {
    display:"flex", 
    flexDirection: {xs: "column", sm:"row"}
},

passwordHelperElem:{
    marginRight: 2,
    display:"flex",
    alignItems:"center"
},

passwordHelperIcon:{
    fontSize:theme.typography.pxToRem(14),
    marginRight: 0.5,
    color: "rgb(203 203 203 / 60%)"
}

}

export default classes
import { Box, Grid, Typography } from "@mui/material";
import classes from "./styles"


interface Props{
    value: string |number,
    label: string
}

export default function WorkoutStats({value, label}:Props){
    return(
        <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Box sx={classes.card}>
                <Typography style={{ fontSize: "1rem" }}>
                    {label}
                </Typography>
                <Typography style={{ fontSize: "2.6rem", fontWeight: 700 }}>
                    {value}
                </Typography>
            </Box>
        </Grid>
    )
}
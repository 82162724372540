import { Box } from "@mui/material"
import React from "react"
import { theme } from "utils/theme"
import { HEADER_MIN_HEIGHT, HEADER_MIN_HEIGHT_EXPANDED, HEADER_MIN_HEIGHT_CLIENTPROFILE } from "utils/constants"

interface Props{
    children: React.ReactNode,
    header?: "simple" | "withTabs" | 'clientProfile',
    padding?: boolean
}

export default function MainContent({children, header = "simple", padding = true}:Props){


    return(
        <Box sx={{
            padding: {
                xs: padding ? 2 : 0, 
                sm: padding? 3 : 0
            }, 
            paddingTop: `calc(${header === "simple" ? `${HEADER_MIN_HEIGHT}px` : header === "clientProfile" ? `${HEADER_MIN_HEIGHT_CLIENTPROFILE}px` : `${HEADER_MIN_HEIGHT_EXPANDED}px`} + ${theme.spacing(3)}) !important`, 
        }}>
            {children}
        </Box>
    )
}
import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "utils/axios"
import sleep from "function/sleep"
import MealPlan from 'interfaces/MealPlan'


/****************************
 * RECUPERATION DES TEMPLATES
 ****************************/

export const getMealPlanTemplates = createAsyncThunk('mealPlans/getTemplates', async() => {

  try {
    const response = await axios.get('mealPlans')
    const mealPlans:MealPlan = response.data.mealPlans
    await sleep(500)
  
    return { mealPlans }

} catch (err: any) {
  return {
    error: err.response.data?.message[0]
  }
}
})


/**********************************
 * RECUPERATION D'UN SEUL TEMPLATE
 ***********************************/

export const getMealPlanTemplate = createAsyncThunk('mealPlans/getTemplate', async (id: string) => {
  console.log('on envoi', id)
  try {
    const response = await axios.get(`mealPlans/${id}`)
    const mealPlan:MealPlan = response.data.mealPlan
    await sleep(500)
  
    return { mealPlan }

} catch (err: any) {
  return {
    error: err.response.data?.message[0]
  }
}
})


/*************************
 * CREATION D'UN TEMPLATE
 *************************/

export const createMealPlanTemplate = createAsyncThunk('mealPlans/createTemplate', async (data:{mealPlan:MealPlan, name:string, createdBy:string}) => {

    const {mealPlan, name, createdBy} = data

    const newMealPlan:MealPlan = {
      ...mealPlan,
      _id: undefined,
      name,
      createdBy,
      template:true
    }


    try {
        const response = await axios.post('mealPlans', newMealPlan)
        const mealPlan:MealPlan = response.data.mealPlan
        await sleep(500)
      
        return { mealPlan }
  
    } catch (err: any) {
      return {
        error: err.response.data?.message[0]
      }
    }
  })


/*************************
 * SUPPRESSION D'UN TEMPLATE
 *************************/


export const deleteMealPlan = createAsyncThunk('mealPlan/delete', async (data:{mealPlan:MealPlan}) => {

  try {
    const result = await axios.delete(`mealPlans/${data.mealPlan._id}`)
    const deleted:MealPlan = result.data.mealPlan 
    await sleep(500)
    return { mealPlan: deleted }

  } catch (err: any) {

    return {
      error: err.response.data?.message[0]
    }
  }
})



/*************************
 * UPDATE D'UN TEMPLATE
 *************************/


export const updateMealPlan = createAsyncThunk('mealPlan/update', async (data:{mealPlan:MealPlan}) => {
  
  

  try {
    const result = await axios.put(`mealPlans/${data.mealPlan._id}`, {...data.mealPlan, template: true})
    const mealPlan:MealPlan = result.data.mealPlan 
    await sleep(500)
    return { mealPlan }

  } catch (err: any) {

    return {
      error: err.response.data?.message[0]
    }
  }
})


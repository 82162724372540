

const classes = {
    container:{ 
        //width: 110, 
        //height: 62, 
        height: 62,
        width: 62,
        borderRadius: 100,
        marginRight: 3, 
        backgroundColor: "rgb(243, 248, 252)", 
        position: "relative", 
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        //borderRadius: "5px",
        overflow: "hidden",

    },

    image: (onClick:Boolean)=>({
        objectFit:"contain", 
        backgroundColor: "rgb(243, 248, 252)", 
        height: 62, 
        border: "0px", 
        //borderRadius: "3px",
        transition: "all .1s ease-in-out",
        "&:hover":{
            transform: onClick ? "scale(1.1)" : "scale(1)"
        }
    })
}

export default classes
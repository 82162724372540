
import { Dialog, DialogTitle, DialogActions, DialogContent, Box, Button, Typography, IconButton } from "@mui/material";
import { RootState } from "app/store";
import ContainedButton from "components/atoms/Buttons/ContainedButton";
import { useSelector } from "react-redux";
import classes from "./styles";
import { useHistory } from "react-router-dom";
import { Close } from "@mui/icons-material";

interface Props {
    open: boolean,
    onClose: ()=> void
}


export default function DialogTruspilot({open,onClose}:Props){

    const history = useHistory()
    const user = useSelector((state:RootState)=> state.user.data)

    const goToTrustpilot = () => {
        window.open('https://fr.trustpilot.com/review/traener.com', "blank")
    }


    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
            disableScrollLock
        >
            
            <DialogContent>
                <Typography 
                        variant='h5' 
                        sx={{fontWeight: 700, marginBottom: 3, textAlign:"center", marginTop: 3}}
                    >
                        Nous soutenir
                </Typography>

                <Box>

                </Box>

                <Typography 
                    sx={classes.description}
                >
                    {user.firstname} nous avons besoin de toi ! Nous travaillons chaque jour avec les coachs sportifs comme toi pour améliorer Traener et en faire le logiciel de coaching ultime. <br/><br/> Si tu aimes l'application, laisses nous un avis sur <strong>Truspilot</strong>, cela ne prend que deux minutes et ça nous aide (vraiment) beaucoup !
                </Typography>
                <Box
                    sx={{display:"flex", alignItems:"center", justifyContent:"center"}}
                >
                    <Button 
                        sx={classes.button} 
                        onClick={goToTrustpilot}
                
                        >
                            Je laisse un avis
                    </Button>
                    
                </Box>

                <Box
                    sx={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom: 3}}
                >
                    <Typography>
                        On te remercie d'avance infiniement ! 🥰
                    </Typography>
                    
                </Box>
                {/****** BOUTON CLOSE ****** */}
                <Box 
                    sx={{position: "absolute", right: 10, top: 10}}
                >
                        <IconButton 
                            sx={{backgroundColor: "#eaeaea", borderRadius: "100% !important"}} 
                            onClick={onClose} 
                            >
                            <Close />
                        </IconButton>
                    </Box>
            </DialogContent>
            
        </Dialog>
    )
}
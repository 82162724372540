import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, SelectChangeEvent, Stack, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import classes from "./styles"
import Checkup, { ScheduledQuestionnaire } from "interfaces/Checkup";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import { createCheckup } from "api/checkups";
import { useHistory } from "react-router-dom";
import { postQuestionnaire } from "api/clients";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { fr } from "date-fns/locale";
import { MOBILE } from "utils/constants";
import { CheckCircle, Close, Save, Warning } from "@mui/icons-material";
import { theme } from "utils/theme";
import checkQuestionnaireFormIsValid from "function/checkQuestionnaireFormIsValid";

interface Props{
    open: boolean,
    onClose: ()=>void,
    punctual?:boolean,
    defaultDate?: Date
}





const weekDays = ['sunday','monday', 'tuesday', "wednesday", 'thursday', 'friday', 'saturday']


export default function DialogScheduleQuestionnaire({open,onClose, defaultDate, punctual}:Props){


    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state:RootState)=> state.clients.requests.postQuestionnaire === "pending")
    const [recurrence, setRecurrence] = useState<any>(format(defaultDate, "eeee").toLowerCase())
    const [template, setTemplate] = useState<string>("")
    const templatesList = useSelector((state:RootState)=> state.checkups.templates.list)
    const planningStart:string = useSelector((state:RootState) => state.planning.builder.startDate)
    const planningEnd:string = useSelector((state:RootState) => state.planning.builder.endDate)
    const clientProfileId:string = useSelector((state:RootState) => state.clients.profile?._id)
    const [start, setStart] = useState<Date>(defaultDate)
    const scheduledQuestionnaires:ScheduledQuestionnaire[] = useSelector((state:RootState) => state.clients.profile?.scheduledQuestionnaires)

    useEffect(()=>{
        if(open){
            setStart(defaultDate)
        }
    },[open])


    const HAS_SCHEDULE_QUESTIONNAIRE = useMemo(()=>{
        const day = format(start, "eeee").toLowerCase()
        const found = scheduledQuestionnaires?.find((elem) => format(new Date(elem.start), "eeee").toLowerCase() === day && elem.recurrence !== "none")
        if(found){
            return true
        }

    },[start])

    
    const mobile = useMediaQuery(MOBILE)
    

    const TEMPLATES = useMemo(()=>{
        return templatesList.filter((elem)=> {
            return checkQuestionnaireFormIsValid(elem)
        })
    },[templatesList])



    const onSubmit = () => {
        dispatch(postQuestionnaire({
            clientId:clientProfileId, 
            recurrence: punctual? "none" : recurrence, 
            templateId: template,
            start,
            punctual
        })).unwrap().then((res)=>{
            if(res.questionnaire)
            onClose()
        })
    }


    const onChangeStartDate = (newValue:Date) => {
        setStart(newValue)
        if(recurrence !== "month" && (format(newValue, "eeee").toLowerCase() !== "recurrence")){
            setRecurrence(format(newValue, "eeee").toLowerCase())
        }
    }


    const onCreateCheckup = () => {
        // Génération du nom
        var name = "Nouveau questionnaire"
        

        // Création du checkup template
        const checkup:Checkup = {
            name, 
            template: true,
            status:"disabled",
            questions :[],
           
        }

        dispatch(createCheckup({checkup})).unwrap().then((res:any) => {
            if (res.checkup) {
                history.push(
                    {
                        pathname: `/checkup/${res.checkup._id}`,
                        state: { 
                            clientProfileId,
                            openModal: {
                                type: punctual ? "punctual" : "recurrence",
                                date: start
                            },
                            planningRange:{
                                start: new Date(planningStart),
                                end: new Date(planningEnd),
                            }
                        },
                    }
                )
            }
        })
    }



    const SHOW_RECURRENCE = useMemo(()=>{
        if(punctual){
            return false
        }else{
            return true
        }
    },[punctual])



    const RECURRENCE_LABEL = useMemo(()=>{
        if(open){
            switch(format(start, "eeee").toLowerCase()) {
                case "sunday" : {
                    return "Tous les dimanches"
                }
                case "monday" : {
                    return "Tous les lundis"
                }
                case "tuesday" : {
                    return "Tous les mardis"
                }
                case "wednesday" : {
                    return "Tous les mercredis"
                }
                case "thursday" : {
                    return "Tous les jeudis"
                }
                case "friday" : {
                    return "Tous les vendredis"
                }
                case "saturday" : {
                    return "Tous les samedis"
                }
                case "month" : {
                    return "Tous les mois"
                }

            }
        }
    },[open, start])


    /*useEffect(()=>{
        if(RECURRENCE_LABEL !== "Tous les mois" && recurrence !== format(start, "eeee").toLowerCase()){
            console.log('changement de récurrence', format(start, "eeee").toLowerCase())
            setRecurrence(format(start, "eeee").toLowerCase())
        }
    },[RECURRENCE_LABEL])*/
    

    return(
        <Dialog 
            open={open} 
            maxWidth={"xs"} 
            fullWidth 
            fullScreen={mobile? true: false}
        >

            {mobile && (
                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={2}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                                sx={{color: "black"}}
                            >
                                <Close />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: "black" }} component="div">
                                {punctual ? "Questionnaire ponctuel": "Programmer"}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={onSubmit}
                                aria-label="close"
                                sx={{color: theme.palette.primary.main}}
                            >
                                <Save />
                            </IconButton>
                        </Toolbar>
                </AppBar>
   
            )}

            {!mobile && (
                <DialogTitle>
                    Programmer un questionnaire {punctual? "ponctuel" : "récurrent"}
                </DialogTitle>
            )}
            <DialogContent>
                <Stack spacing={2}>
                    
                    {punctual && (
                        <Typography
                            sx={{fontWeight: 700}}
                        >
                            Date :  {format(new Date(start), "eeee dd MMM yyyy", {locale:fr})}
                        </Typography>
                    )}

                    {HAS_SCHEDULE_QUESTIONNAIRE && (
                        <Typography>
                            Note : Ce questionnaire ponctuel remplacera le questionnaire programmé ce même jour
                        </Typography>
                    )}


                    {!punctual && (
                        <LocalizationProvider 
                            dateAdapter={AdapterDateFns} 
                            adapterLocale={fr}
                        >
                            <Box sx={classes.input}>
                                <Typography sx={classes.label}>
                                    {!punctual ?  'A partir du' : "Programmé le"}
                                </Typography>
                                <DatePicker
                                    label="Programmer un questionnaire"
                                    value={start}
                                    onChange={onChangeStartDate}
                                    renderInput={(params) => <TextField {...params} label='' fullWidth/>}
                                    views={['day']}
                                    disablePast
                                
                                />
                            </Box>
                        </LocalizationProvider>
                    )}

                  

                    {/***** RECURRENCE ***** */}
                    {SHOW_RECURRENCE && (
                    <Box sx={classes.input}>
                        <Typography sx={classes.label}>
                            Récurrence
                        </Typography>
                       
                        <Select
                            fullWidth
                            value={recurrence}
                            onChange={(event: SelectChangeEvent) => {
                                setRecurrence(event.target.value as "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "month" );
                            }}
                        >
                            <MenuItem 
                                value={format(start, "eeee").toLowerCase()}
                            >
                               {RECURRENCE_LABEL}
                            </MenuItem>
                            <MenuItem value="month">
                                Toutes les 4 semaines
                            </MenuItem>
                            
                        </Select>
                    </Box>
                    )}



                    
                    
                 
                    
                    
                    
                    {/***** MODELE ***** */}
                    
                        <Box sx={classes.input}>
                            <Typography sx={classes.label}>
                                Modèle de questionnaire
                            </Typography>
                            {templatesList?.length > 0 && (
                                <Select
                                    fullWidth
                                    value={template}
                                    placeholder="Sélectionnez un questionnaire"
                                    onChange={(event: SelectChangeEvent) => {
                                        setTemplate(event.target.value as string);
                                    }}
                                    sx={{mb:1.5}}
                                    >
                                    {templatesList.map((template)=>{

                                        return(
                                            <MenuItem 
                                                value={template._id} 
                                                key={template._id} 
                                                sx={[
                                                    classes.templateItem,
                                                    !checkQuestionnaireFormIsValid(template) && classes.invalid
                                                ]} >
                                                <Typography>
                                                    {template.name}
                                                </Typography>
                                                {/*checkQuestionnaireFormIsValid(template) ? 
                                                <Tooltip
                                                    title={"Ce formulaire est complet"}
                                                >
                                                    <CheckCircle 
                                                        sx={{color: "green"}}
                                                    /> 
                                                </Tooltip>: 
                                                    <Tooltip
                                                        title={"Ce formulaire est incomplet et contient des erreurs"}
                                                    >
                                                        <Warning
                                                            sx={{color: "#ed2912"}}
                                                        />
                                                    </Tooltip>
                                                */}
                                                
                                                
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            )}

                            {(Boolean(template) && !checkQuestionnaireFormIsValid(templatesList.find((elem)=> elem._id=== template))) && (
                                <Typography sx={{marginBottom: 2, color:"#ed2912" }}>
                                    Ce formulaire est incomplet et ne sera pas afficher dans le planning de ton client tant qu'il ne sera pas valide.
                                </Typography>
                            )}

                            {templatesList?.length ===0 && (
                                <Typography
                                    sx={{marginBottom: 2}}
                                >
                                    Vous n'avez aucun modèle de questionnaire
                                </Typography>
                            )}

                            {!mobile && (
                                <SecondaryButton 
                                    onClick={onCreateCheckup}
                                >
                                    Créer un modèle
                                </SecondaryButton>
                            )}
                    
                        </Box>
                  
                </Stack>
            </DialogContent>

            {!mobile && (
            <DialogActions>
                <Button disabled={pending} onClick={onClose}>
                    Annuler
                </Button>
                <LoadingButton loading={pending} disabled={pending || template === ""} onClick={onSubmit}>
                    Valider
                </LoadingButton>
            </DialogActions>
            )}
        </Dialog>
    )
}
import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Card, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { handleBlockInstructions, handleTrack } from "features/workoutSlice";
import { GroupExercice, WorkoutBlock as WorkoutBlockType, WorkoutExercice} from "interfaces/Workout";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import { createSelector } from "@reduxjs/toolkit";
import DialogExercicesList from "components/organisms/DialogExercicesList";
import Exercice from "../WorkoutExercice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { CheckCircle,Close, Rule } from "@mui/icons-material";
import DialogBlockDelete from "components/molecules/DialogBlockDelete";
import BlockTitle from "components/molecules/WorkoutBlockName";
import 'draft-js/dist/Draft.css';
import debounce from "lodash.debounce";
import 'react-quill/dist/quill.snow.css';
import "./editorStyle.css"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogBlockTracking from "../DialogBlockTracking";
import EmptyState from "components/molecules/EmptyState";
import { MOBILE } from "utils/constants";



const editorModules = {
    toolbar: [
        ['bold', 'italic', 'underline','strike', ],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['clean']
    ],
}


const editorFormats = [
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
  ]


////// REDUX SELECTORS ///////

const nameSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.name
    }
)


const contentSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.content
    }
)


const trackSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.track
    }
)


const typeSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.type
    }
)


const instructionsSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.instructions
    }
)


////// INTERFACES ///////
interface Props {
    blockId: string, // Id unique du block
    blockIndex: number
}


/*********** MAIN COMPONENT ********* */

export function WorkoutBlock({blockId, blockIndex}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const name:string = useSelector((state:RootState)=>nameSelector(state,blockId))
    const content:GroupExercice[] = useSelector((state:RootState)=>contentSelector(state,blockId))
    const track:boolean = useSelector((state:RootState)=>trackSelector(state,blockId))
    const type = useSelector((state:RootState)=> typeSelector(state,blockId))
    const currentInstructions = useSelector((state:RootState)=> instructionsSelector(state,blockId))
    const [blockIsReady, setBlockIsReady] = useState<boolean>(false)
    const [openList, setOpenList] = useState<boolean>(false)
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [instructions, setInstructions] = useState('')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openBlockTracking, setOpenBlockTracking] = useState<boolean>(false)
    const [exerciceToSwap, setExerciceToSwap] = useState<{groupId:string, exerciceId: string}>(null)
    const mobile = useMediaQuery(MOBILE)

    /****** INSTRUCTIONS EN MODE TEXTE ***** */

    const debounceInstructions = useRef(debounce((data:string)=> {
        dispatch(handleBlockInstructions({instructions: data, blockId}))
    }, 1000)).current


    useEffect(()=>{
        //const stringifyInstructions = JSON.stringify(convertToRaw(instructions.editorState.getCurrentContent()))
        debounceInstructions(instructions)
    },[instructions])


    useEffect(()=>{
        if(currentInstructions && !blockIsReady){
            setInstructions(currentInstructions)
            setBlockIsReady(true)
        }
    },[currentInstructions, blockIsReady])


    const handleBlockStatus = () => {
        dispatch(handleTrack({blockId, track: !track}))
    }


    const onOpenDeleteBlock = () => {
        setOpenDelete(true)
        setAnchorEl(null)
    }


    const onOpenSwap = (exerciceId:string, groupId:string) => {
        setOpenList(true)
        setExerciceToSwap({
            groupId,
            exerciceId,
        })
    }


    const onCloseExercicesList = () => {
        setOpenList(false)
        setTimeout(()=>{
            setExerciceToSwap(null)
        },200)
        
    }

 
    /************ JSX ************ */

    return(

        <Box sx={classes.block}>


            {/****************************** 
             * 
            *     HEADER DU BLOCK
            * 
            *  *************************** */}

            <Box sx={[
                classes.header, 
                content.length ===0 && classes.headerWithBorder
            ] }>

                {/********* TITRE DU BLOC ******** */}
                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center"}}>

                    {/***** BOUTON TRACKING *** */}

                    {/** DESKTOP *** */}
                    {!mobile && (
                        <Box 
                            sx={[
                                classes.trackingButton, 
                                !track && classes.trackingButtonOff
                            ]} 
                            onClick={()=>setOpenBlockTracking(true)}
                        >
                           
                            <Rule 
                                sx={{fontSize: 18, marginRight: 1, color: track ? "white" : "#bcbcbc"}} 
                            />
                            
                            <Typography 
                                sx={[
                                    classes.trackingButtonLabel, 
                                    !track && classes.trackingButtonLabelOff
                                ]}>
                                    {track ? "Avec suivi" : "Sans suivi"}
                            </Typography>
                        </Box>
                    )}

                    {/** Mobile *** */}
                    
                    {mobile && (
                        <Box 
                            sx={[classes.trackingButton, !track && classes.trackingButtonOff, classes.trackingButtonMobile]}
                            onClick={()=>setOpenBlockTracking(true)}
                        >
                            <Rule 
                                sx={{fontSize: 22, color: track ? "primary.main" : "#bcbcbc"}} 
                            />
                        </Box>
                    )}

                    {/** Blcok Title *** */}
                        <BlockTitle
                            blockId={blockId}
                            name={name}
                            className={"block-name"}
                            blockIndex={blockIndex}
                        />
                </Box>


                {/********* LISTE DES BOUTONS DU BLOCK ******** */}

                <Box sx={classes.blockButtons}>
                    

                    {/********* MORE ******** */}
                    <IconButton 
                        sx={{borderRadius: "100px !important", marginLeft: 2}} 
                        onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                    >
                        <MoreHorizIcon />
                    </IconButton>

                    <Menu disableScrollLock={true} 
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={()=>setAnchorEl(null)}
                    >
                        <MenuItem onClick={onOpenDeleteBlock}>
                            <ListItemIcon>
                                <Close fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Supprimer
                            </ListItemText>
                        </MenuItem>  
                    </Menu>
                </Box>
            </Box>


            {/****************************** 
             * 
            *     CONTENU DE LA SEANCE
            * 
            *  *************************** */}

            <Box>

                {/********* CONTENU DU BLOC ******** */}
                
                {type === "builder" && (
                    <Droppable droppableId={blockId}>
                        {(provided: any) => (
                            <Box 
                                ref={provided.innerRef} 
                                {...provided.droppableProps}
                                sx={[classes.dropZone, content.length ===0 && classes.emptyBlock]} 
                            >

                                {content.length ===0 && (
                                    <EmptyState
                                        //image="empty_bloc.png"
                                        title="Aucun exercice"
                                        description={`Le bloc ${name} ne comporte aucun exercice`}
                                        titleSize={22}
                                        buttonLabel="Ajouter des exercices"
                                        onClick={()=> setOpenList(true)}
                                    />
                                )}
                                

                                {/***** LISTE DES ELEMENTS DRAGGABLE (EXERCICE OU GROUPE) ****** */}
                                {content.map((group:GroupExercice, index:number)=> {

                                    const isLastBlockElement:boolean = index === content.length - 1

                                    return(
                                        <Draggable 
                                            draggableId={group.groupId} 
                                            index={index}
                                            key={group.groupId}
                                        >
                                            {(provided: any, snapshot: any) => (
                                                <Card
                                                    elevation={snapshot.isDragging? 1 : 0} 
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps}
                                                    sx={classes.draggableElement(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                        <Box sx={classes.group} key={index}>

                                                            {/***** SUPERSET INDICATOR **** */}
                                                            {group.exercices.length > 1 && (
                                                                <Box sx={{flexDirection: "row", display:"flex", alignItems:"center"}}>
                                                                    <Box sx={classes.supersetLabel}>
                                                                        Superset ({group.exercices.length})
                                                                    </Box>
                                                                </Box>
                                                                
                                                            )}

                                                            {/* Liste des exercices */}
                                                            
                                                            <Box sx={classes.groupContent}>
                                                                {group.exercices.map((workoutExercice:WorkoutExercice, exerciceIndex: number)=> {

                                                                    const isLastGroupExercice:boolean = group.exercices.length -1 === exerciceIndex

                                                                    return(
                                                                        <Exercice 
                                                                            key={exerciceIndex}
                                                                            _id={workoutExercice.exercice._id}
                                                                            exerciceId={workoutExercice.exerciceId}
                                                                            blockId={blockId}
                                                                            groupId={group.groupId}
                                                                            isLastBlockElement={isLastBlockElement}
                                                                            isLastGroupExercice={isLastGroupExercice}
                                                                            fromSuperset={group.exercices.length>1}
                                                                            track={track}
                                                                            groupIndex={index}
                                                                            exerciceIndex={exerciceIndex}
                                                                            onOpenSwap={onOpenSwap}
                                                                            type={workoutExercice.exercice.type._id}
                                                                        />
                                                                    )
                                                                })}

                                                            </Box>
                                                        </Box>
                                                </Card>
                                            )}

                                        </Draggable>
                                    )
                                })}

                                {provided.placeholder}

                            </Box>
                        )}


                    </Droppable>
                )}
                
                {content.length>0 && (
                <Box sx={classes.addExerciceButton}>
                    <Button 
                        onClick={()=> setOpenList(true)} 
                        sx={{textTransform: "none"}} 
                        variant="contained"
                        disableElevation={true}
                    >
                        Ajouter un exercice
                    </Button>
                </Box>
                )}

                    

                {/********* TEXT ******** 

                {type === "text" && (
                    <Box sx={classes.blockInstructions}>
                        <ReactQuill 
                            theme="snow"
                            value={instructions} 
                            onChange={setInstructions} 
                            modules={editorModules}
                            formats={editorFormats}
                            placeholder="Entrez les instructions relatives à ce bloc"
                            
                        />
                        

                        {/*<Editor 
                            editorState={instructions.editorState} 
                            onChange={onChangeInstructions}
                            placeholder={`Entrez les prescriptions pour le bloc ${name}`}
                        />
                        <Box sx={classes.editorToolbar}>
                            <IconButton onClick={onBoldClick} sx={classes.toolbarButton}>
                                <FormatBoldIcon />
                            </IconButton>
                            <IconButton onClick={onItalicClick} sx={classes.toolbarButton}>
                                <FormatItalic />
                            </IconButton>
                        </Box>
                        
                    </Box>
                )}
                */}
            </Box>


            {/********** LISTE DES EXOS ******* */}

            <DialogExercicesList 
                open={openList} 
                onClose={onCloseExercicesList} 
                blockId={blockId}
                exerciceId={exerciceToSwap?.exerciceId}
                groupId={exerciceToSwap?.groupId}
                swap={Boolean(exerciceToSwap)}
            />

            {/********** Dialog de suppression d'un bloc ******* */}

            <DialogBlockDelete
                open={openDelete}
                onClose={()=> setOpenDelete(false)}
                blockId={blockId}
                blockName={name}
            />


            <DialogBlockTracking
                open={openBlockTracking}
                onClose={()=> setOpenBlockTracking(false)}
                blockIndex={blockIndex}
                blockId={blockId}
                currentTrack={track}
            />

        </Box>
    )
}





export default WorkoutBlock


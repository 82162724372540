import { darken, lighten } from "@mui/material"
import { theme } from "utils/theme"

const classes = {

    // SUBSCRIPTION CARD

    currentIndicator:{
        position: "absolute", 
        top: -40, 
        right: -40, 
        backgroundColor: "primary.main", 
        width: 80, 
        height: 80, 
        transform: "rotate(45deg)"
    },

    header:{
        display: "flex", 
        flexDirection:"column", 
        alignItems:"flex-start"
    },

    title: {
        fontSize: theme.typography.pxToRem(36), 
        color:"black",
        fontWeight: 600
        
    },


    descriptionContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        height: 80
    },

    description:{
        marginTop: 3,
        fontSize: {
            xs: theme.typography.pxToRem(16),
            md: theme.typography.pxToRem(16),
        },
        fontStyle: "italic",
        fontWeight: 600
    },

    clients :{
        fontSize: {
            xs: theme.typography.pxToRem(18),
            md: theme.typography.pxToRem(24),
        },
        color:"black", 
        fontWeight: 700
    },

    price: {
        fontSize: {
            xs: theme.typography.pxToRem(30), 
            md: theme.typography.pxToRem(60), 
        },
        color:"black", 
        fontWeight: 900
    },

    recurence:{
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700
    },

    feature:{
        display:"flex", 
        flexDirection:"row", 
        height: 48, 
        alignItems:"center"
    },

    featureLabel:{
        fontWeight: 500, 
        fontSize: {
            xs: theme.typography.pxToRem(16),
            md: theme.typography.pxToRem(18),
        }
    },

    button: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        height: 80, 
        mt: 3
    },

    card : {
        padding: {
            xs: 3,
            md: 4,
        }, 
        position: "relative"
    }


    
}


export default classes
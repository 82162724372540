import { AppDispatch, RootState } from "app/store"
import { useDispatch, useSelector } from "react-redux"
// REDUCER
// API
import { deleteExercice } from "api/exercices";
import Exercice from "interfaces/Exercice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import LoadingButton from "components/atoms/Buttons/LoadingButton";


/******* INTERFACES ****** */

interface Props{
    open: boolean,
    onClose: () => void,
    exercice: Exercice
}


/******* MAIN COMPONENT ****** */

export default function DialogDeleteExercice({open,onClose, exercice}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const pending = useSelector((state: RootState) => state.exercices.requests.delete === "pending")


    /********** Suppression d' un exercice ********** */
    const onDeleteExercice = async () => {
        dispatch(deleteExercice({exercice})).unwrap().then(()=>{
            onClose()
        })
    }


    return(

        <Dialog open={open}>
            <DialogTitle>Supprimer un exercice</DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer l'exercice <strong>{exercice?.name?.fr || "sans nom"}</strong> de {exercice?.draft ? 'tes brouillons ?' : "ta liste d'exercices personnalisés ?"} {!exercice?.draft ?"Ce dernier restera disponible dans les séances précédemment créées mais ne pourra plus être ajouté à de nouvelles séances.":""}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pending}>Annuler</Button>
                <LoadingButton loading={pending} disabled={pending} onClick={onDeleteExercice}>Supprimer</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
import { DRAWER_WIDTH_MIN} from "utils/constants"

const classes = {

    container:{
        display:"flex", 
        flexDirection:"column", 
        width:"100%",
        maxWidth:"100%",
        marginLeft: {
            xs: 0,
            lg: `${DRAWER_WIDTH_MIN}px`
        },
        paddingBottom: {
            xs: 10,
            md: 0
        }
    },

 
}

export default classes
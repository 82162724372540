import { useEffect, useMemo, useState } from "react";
// REDUX
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// MUI
import { Box, Typography, Grid, Select, MenuItem, SelectChangeEvent, useMediaQuery} from "@mui/material";
// APP
import { AppDispatch, RootState } from "app/store";
// APIS
import { getClientsHistory} from "api/clients";
// SHARED COMPONENTS
import Loader from "components/molecules/Loader";
// STYLES
import classes from "./styles";
// LOcal component
import { handleSubscriptionPresentation, selectDrawerItem } from "features/appSlice";
import EventCard from "components/molecules/EventCard";
import { HistoryEventDetails } from "interfaces/User";
import Header from "components/molecules/Header";
import PageTitle from "components/atoms/Typography/PageTitle";
import { addDays } from "date-fns";
import EmptySearch from "components/molecules/EmptySearch";
import MainContent from "components/molecules/MainContent";
import Toolbar from "components/molecules/Toolbar";
import ContainedButton from "components/atoms/Buttons/ContainedButton";
import DialogTruspilot from "components/molecules/DialogTrustPilot";
import { Info, Instagram, StarRate } from "@mui/icons-material";
import { LIGHT_BUTTON_COLOR, MOBILE } from "utils/constants";

/********* MAIN COMPONENT ********* */


export default function Home(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const history:HistoryEventDetails[] = useSelector((state:RootState) => state.dashboard.history)
    const pending = useSelector((state:RootState)=> state.dashboard.requests.get === "pending")
    const openDemoUser:boolean = useSelector((state:RootState) => state.app.openUserDemoPresentation, shallowEqual)
    const [range, setRange] = useState<{start:Date, end: Date}>({start: addDays(new Date(), -3), end: new Date()})
    const [filter, setFilter] = useState<string>('3')
    const [openTruspilot, setOpenTrustPilot] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)



    /********** CREATION DE L'HISTORIQUE ******** */

    useEffect(()=>{
        dispatch(selectDrawerItem(0))
    },[dispatch])


    const handleChangeFilter = (event: SelectChangeEvent) =>{
        setFilter(event.target.value as string)
    }


    /********************************
     * Récupération de l'historique
     ********************************/

    useEffect(()=>{
        const startDate = addDays(new Date(range.end), -(parseInt(filter)+1))
        dispatch(getClientsHistory({start: startDate, end: new Date()}))
    },[filter])


    /***** MISE A JOUR DE L'HISTORIQUE SUITE A L'AJOUT DE DEMO USER ***** */
    useEffect(()=>{
        if(openDemoUser){
            const startDate = addDays(new Date(range.end), -parseInt(filter))
            dispatch(getClientsHistory({start: startDate, end: new Date()}))
        }
    },[openDemoUser])


    /********* FILTRAGE DES EVENTS ****** */
    const filteredEvents:HistoryEventDetails[] = useMemo(() => {
        
        var workouts:HistoryEventDetails[] = []
        var checkups:HistoryEventDetails[] = []
        var measures:HistoryEventDetails[] = []
        var meals:HistoryEventDetails[] = []

    
        workouts = history.filter((elem)=> {
            return elem.action === "workout"
        })
        

       
        checkups = history.filter((elem)=> {
            return elem.action === "questionnaire"
        })
        

       
        measures = history.filter((elem)=> {
            return elem.action === "measure"
        })


        meals = history.filter((elem)=> {
            return elem.action === "meal"
        })
        

        const events = workouts?.concat(checkups).concat(measures).concat(meals)

        const sortedEvents = [...events.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())]

        return sortedEvents


    },[history])



    useEffect(()=>{
        if(!user.trialIsDone){
            setTimeout(()=>{
                dispatch(handleSubscriptionPresentation({open:true}))
            },1000)
            
        }
    },[user])



    const onOpenTruspilot = () => {
        setOpenTrustPilot(true)
    }


    const goToInstagram = () => {
        window.open("https://www.instagram.com/traenerapp/", '_blank');
    }


    return(
        <>
            <Header>
                <Grid container justifyContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                            <Toolbar>
                                <PageTitle>
                                    Tableau de bord
                                </PageTitle>
                               
                                {!mobile && (
                                <Box>
                                    <ContainedButton 
                                        onClick={goToInstagram}
                                        startIcon={<Instagram />} 
                                        sx={{backgroundColor: LIGHT_BUTTON_COLOR,}}
                                    >
                                        Nous suivre
                                    </ContainedButton>

                                    {user.subscription?.current?.status === "active" && (
                                        <ContainedButton 
                                            onClick={onOpenTruspilot}
                                            startIcon={<StarRate />} 
                                            sx={{backgroundColor: LIGHT_BUTTON_COLOR,}}
                                        >
                                            Noter Traener
                                        </ContainedButton>
                                    )}
                                </Box>
                                )}
                            </Toolbar>
                        </Grid>
                </Grid>
            </Header>

            <MainContent
                header="simple"
            >   
                {mobile && (
                    <Box
                        sx={classes.mobileMessage}
                    >
                        <Info 
                            sx={{marginRight: 2,
                                color: "#549e9e"
                            }}
                        />
                        <Typography>
                            Traener est optimisé pour une utilisation sur tablette ou PC.
                        </Typography>
                    </Box>
                )}
                <Grid 
                    container 
                    justifyContent="center" 
                    spacing={0}
                >
                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                        <Box 
                            sx={classes.historyContainer}
                        >

                        {/********* EVENTS *********/}

                      
                            <Box 
                                sx={{display:"flex", flexDirection: "column", flex: 1}}
                            >
                                <Box 
                                    sx={classes.feedHeader}
                                >

                                    <Typography 
                                        sx={classes.sectionTitle}
                                    >
                                        Activité de tes clients
                                    </Typography>

                                    <Select 
                                        value={filter}
                                        size="small"
                                        onChange={handleChangeFilter}
                                        MenuProps={{
                                            disableScrollLock: true
                                        }}
                                    >
                                        <MenuItem value={3}>3 derniers jours</MenuItem>
                                        <MenuItem value={7}>7 derniers jours</MenuItem>
                                        <MenuItem value={15}>15 derniers jours</MenuItem>
                                    </Select>

                                </Box>


                                {/******** LOADER ****** */}

                                {pending && (
                                    <Loader />
                                )}

                                {/******** LISTE DES EVENNEMENTS ****** */}
                                {!pending && filteredEvents?.map((event, historyIndex:number) => {
                                    return(
                                        <Box 
                                            key={historyIndex} 
                                            sx={classes.eventContainer}
                                        >
                                            <Box 
                                                sx={classes.eventsTimeline}
                                            />
                                            <EventCard 
                                                event={event} 
                                            
                                            />
                                        </Box>
                                    )
                                })}


                                {/***** AUCUN EVENEMENT ***** */}
                                {!pending && filteredEvents?.length ===0 && (
                                    <EmptySearch
                                        description={`Tu n'as aucune activité client sur les ${filter} derniers jours`}
                                    />
                                    
                                )}

                            </Box>  
                 
                                
                    
                        </Box>
                    </Grid>
                </Grid>
            </MainContent>

            <DialogTruspilot
                open={openTruspilot}
                onClose={()=>setOpenTrustPilot(false)}
            />

           
        </>
    )
}
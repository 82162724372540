
import React, { useEffect, useMemo, useState } from "react"
// MUI
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
// REDUX
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "app/store"
// SHARED COMPONENTS
import SearchTextfield from "components/molecules/Search";
// LOCAL COMPONENTS
import SendInvitation from "components/organisms/DialogSendInvitation";
// REDUCERS
import { resetSearcClients, searchClients } from "features/clientsSlice";
import { handleSubscriptionPresentation, selectDrawerItem } from "features/appSlice";
import { MOBILE, TABLET } from "utils/constants";
import PageTitle from "components/atoms/Typography/PageTitle";
import { toast } from "react-toastify";
import Header from "components/molecules/Header";
import MainContent from "components/molecules/MainContent";
import User from "interfaces/User";
import DialogDeleteClient from "components/molecules/DialogDeleteClient";
import Invitation from "interfaces/ClientInvitation";
import DialogDeleteInvitation from "components/molecules/DialogDeleteInvitation";
import ClientCard from "components/organisms/ClientCard";
import DialogInvitationDescription from "components/molecules/DialogInvitationDescription";
import EmptyState from "components/molecules/EmptyState";
import ContainedPrimaryButton from "components/atoms/Buttons/ContainedButton";
import classes from "./styles";
import { Add } from "@mui/icons-material";
import MoreButton from "components/atoms/Buttons/MoreButton";
import { getClients } from "api/clients";
import Toolbar from "components/molecules/Toolbar";

/************************* CLIENTLIST *********************** */

export default function ClientsList() {

    const dispatch = useDispatch<AppDispatch>()
    const totalClients = useSelector((state:RootState)=> state.clients.list.clients.length + state.clients.list.invitations.length )
    const user = useSelector((state: RootState) => state.user.data)
    const [openInvitationDialog, setOpenInvitationDialog] = useState(false)
    const clients = useSelector((state:RootState)=> state.clients.searchResults.clients)
    const invitations = useSelector((state:RootState)=> state.clients.searchResults.invitations)
    const [clientSelected, setClientSelected] = useState<User>(null)
    const [invitationToDelete, setInvitationToDelete] = useState<Invitation>(null)
    const [openDeleteModalClient, setOpenDeleteModalClient] = useState(false)
    const [openDeleteModalInvitation, setOpenDeleteModalInvitation] = useState(false)
    const [openInvitationDescription, setOpenInvitationDescription] = useState<{name:string, email:string, open:boolean}>({open:false, name:"", email:""})


    const IS_TABLET = useMediaQuery(TABLET)
    const IS_MOBILE = useMediaQuery(MOBILE)


    useEffect(()=>{
        dispatch(getClients()) // récupération des clients
    },[dispatch])

    /******* DONNEES FORMATEES ***** */

    const allCards = useMemo(()=>{
        const elems:{client:User, invitation?:Invitation}[] = []
        clients.forEach((client)=> {
            elems.push({client: client})
        })

        invitations.forEach((invitation) => {
            const fakeUser:User = {
                ...invitation.to,
                isNotActivated: true
            }

            elems.push({client: fakeUser, invitation:invitation})
        })

        if(elems.length>1){

            return elems.sort((a,b)=> {
                if(a.client.firstname > b.client.firstname){
                    return 1
                }else if(a.client.lastname < b.client.firstname){
                    return -1
                } else return 0
            })
        } else {
            return elems
        }

    },[clients, invitations])

     /************ SELECTION D'UN CLIENT A SUPPRIMER ************* */

     const onSelectClientToDelete = (client:User) => {
        setClientSelected(client)
        setOpenDeleteModalClient(true)
    }


     /************ SELECTION D'UNE INVItATION A SUPPRIMER ************* */

    const onSelectInvitationToDelete = (invitation:Invitation) => {
        setInvitationToDelete(invitation)
        setOpenDeleteModalInvitation(true)
    }

    /********GESTION DE LA PAGE **** */
    useEffect(() => {
        dispatch(selectDrawerItem(1))

        return function cleanup(){
            dispatch(resetSearcClients())
        }
    }, [dispatch])


    
    /***** OUVERTURE DE l'INVITATION ***** */
    const onOpenInvitationDialog = () => {

        // Aucun abonnement en cours
        if(!user.subscription || !user.subscription?.current){
            dispatch(handleSubscriptionPresentation({open:true}))
        }

        // Vérification du nombre de clients - Abonnement en cours
        else if(user.subscription?.current?.clients === totalClients){
            toast.error('Tu as atteint le maximum de clients de ton abonnement')
            return
        }

        else if(user.subscription?.current?.status === "active" || user.subscription?.current?.status === "trialing"){
            setOpenInvitationDialog(true)
        }
        
    }

    /************************* RECHERCHE *********************** */

    const onSearch = (e:any) => {
        dispatch(searchClients({search:e.currentTarget.value}))
    }

    const onOpenInvitationDescription = (name:string, email:string) => {
        setOpenInvitationDescription({
            open: true,
            name,
            email
        })
    }


    /************************* JSX RENDER *********************** */

    return (
        
            <React.Fragment>

                {/********************
                 *      HEADER 
                 ********************/}
                <Header>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
                            <Toolbar>
                                <PageTitle>
                                    Mes clients
                                </PageTitle>
                                
                                {!IS_MOBILE && (
                                    <Box sx={{display:"flex", flexDirection: "row"}}>
                                        <SearchTextfield
                                            onChange={onSearch}
                                            placeholder={"Rechercher un client"}
                                        />

                                        {!IS_TABLET && (
                                            <ContainedPrimaryButton 
                                                variant="contained" 
                                                onClick={onOpenInvitationDialog}
                                            >
                                                Ajouter un client
                                            </ContainedPrimaryButton>
                                        )}
                                    </Box>
                                )}

                                {IS_MOBILE && (
                                    
                                    <MoreButton
                                        menuList={[{
                                            icon: <Add/>,
                                            label: "Ajouter un client",
                                            callback:()=>onOpenInvitationDialog()
                                        }]}
                                    />
                                
                                )}
                            </Toolbar>
                        </Grid>
                    </Grid>
                </Header>
                

                 {/********************
                 *      CONTENT 
                 ********************/}

                <MainContent
                    header={"simple"}
                >

                    {/******* EMPTY STATE ****** */}

                    {totalClients === 0 && (
                        <EmptyState
                            title="Aucun client"
                            description={"Tu n'as actuellement aucun client enregistré dans l'application Traener."}
                            image={"empty_clients.png"}
                            height={`calc(100vh - 48px - 110px)`}
                        />
                    )}


                    {/******* LISTE DES CLIENTS ****** */}

                    {totalClients > 0 && (

                        <Grid 
                            container 
                            justifyContent="center" 
                            sx={{paddingBottom: 10,}}
                        >
                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={12} 
                                lg={10} 
                                xl={8}
                            >
                                <Box 
                                    sx={classes.listHeader}
                                >
                                    <Typography>
                                        Tu as actuellement <strong>{totalClients} client{totalClients > 1 ? "s" : ""}</strong>
                                    </Typography>

                                    {/**** BOUTON ADD SUR TABLETTE ***** */}
                                    {(IS_TABLET && !IS_MOBILE) && (
                                        <Box>
                                            <ContainedPrimaryButton 
                                                variant="contained" 
                                                onClick={onOpenInvitationDialog}
                                            >
                                                Ajouter un client
                                            </ContainedPrimaryButton>

                                            <Box>
                                              
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                
                                <Grid container spacing={3}>
                                    {allCards.map((client)=>{

                                        return(
                                            <ClientCard
                                                key={client.client?._id ? client.client._id : client.invitation._id}
                                                client={client.client}
                                                onSelectClientToDelete={onSelectClientToDelete}
                                                onSelectInvitationToDelete={onSelectInvitationToDelete}
                                                onOpenInvitationDescription={onOpenInvitationDescription}
                                                invitation={client.invitation}
                                            />
                                        )

                                    })} 
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                

                </MainContent>

                {/********** Dialog d'envoie d'invitation ********** */}

                <SendInvitation
                    open={openInvitationDialog}
                    onClose={()=>setOpenInvitationDialog(false)}
                />


                {/********** DIALOG SUPP CLIENT ********** */}
                <DialogDeleteClient
                    open={openDeleteModalClient}
                    onClose={()=> setOpenDeleteModalClient(false)}
                    client={clientSelected}
                />

                {/********** DIALOG SUPP INVITATION ********** */}
                <DialogDeleteInvitation
                    open={openDeleteModalInvitation}
                    onClose={()=>setOpenDeleteModalInvitation(false)}
                    invitation={invitationToDelete}
                />
                

                <DialogInvitationDescription
                    open={openInvitationDescription.open}
                    name={openInvitationDescription.name}
                    email={openInvitationDescription.email}
                    onClose={()=>setOpenInvitationDescription({open: false, name:"", email: ""})}
                />


                {/********** TABLET ********** 

                <FixedFab
                    onClick={()=>setOpenInvitationDialog(true)}
                />

                */}

                
            </React.Fragment>
    

    )
}
import { configureStore } from '@reduxjs/toolkit';
import app from "features/appSlice"
import dashboard from "features/dashboardSlice"
import user from "features/userSlice"
import clients from "features/clientsSlice"
import subscriptions from "features/subscriptionsSlice"
import exercicesReducer from "features/exerciceSlice"
import workouts from "features/workoutSlice"
import checkups from 'features/checkupSlice';
import metricsReducer from "features/metricsSlice"
import programs from "features/programSlice"
import planning from 'features/planningSlice';
import foodReducer from "features/foodSlice";
import recipeReducer from "features/recipesSlice"
import mealPlanReducer from "features/mealPlanSlice"

export const store = configureStore({
  reducer: {
    app,
    dashboard,
    user,
    programs,
    clients,
    planning,
    subscriptions,
    exercices : exercicesReducer,
    workouts,
    checkups,
    metrics: metricsReducer,
    food: foodReducer,
    recipes: recipeReducer,
    mealPlans: mealPlanReducer

    
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

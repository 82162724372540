import { darken } from "@mui/material"
import { BORDER_COLOR, LIGHT_BUTTON_COLOR } from "utils/constants"


const classes = {

    block:{
        backgroundColor: "white",
        border: {
            xs: `none`,
            sm: `solid 1px ${BORDER_COLOR}`
        },
        marginBottom: 3
    },

    blockLetter : {
        display:"flex", 
        height: 25, 
        //width: 25,

        justifyContent:"center", 
        alignItems:"center", 
        //backgroundColor: "black",
        borderRadius: 100, 
        color: "rgba(0,0,0,0.85)", 
        marginRight: 2
    },

    emptyBlock: {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        //fontWeight: 400,
        //color: "rgb(0 0 0 / 48%)",
        //fontStyle: "italic",
        backgroundColor: "white",
        //height: 96.9,
    },


    trackingButton: {
        display:"flex", 
        flexDirection: "row", 
        backgroundColor: "#67c83c", 
        paddingLeft: {
            xs: 0,
            sm: 1
        }, 
        paddingRight: {
            xs: 0,
            sm: 1
        }, 
        paddingTop: {
            xs: 0,
            sm: 0.5
        }, 
        paddingBottom: {
            xs: 0,
            sm: 0.5
        }, 
        width: 115, 
        borderRadius: 2,
        cursor: "pointer",
        marginRight: 2,

        "&:hover" : {
            backgroundColor: darken("#67c83c",0.05)
        }
    },

    trackingButtonMobile : {
        borderRadius: 2,
        cursor: "pointer",
        width: 45,
        height: 45,
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    trackingButtonOff: {
        backgroundColor: "whitesmoke",
        "&:hover":{
            backgroundColor: darken("#ebebeb",0.02)
        }
    },

    trackingButtonLabel : {
        fontSize: 13, 
        fontWeight: 600,
        color: "white"
    },

    trackingButtonLabelOff : {
        color: "#bcbcbc"
    },


    supersetLabel : {
        color: "black", 
        textTransform: "uppercase", 
        fontWeight: "800", 
        marginTop: 2,
        marginBottom: 2, 
        marginLeft: 2,
        width: 200
    },

    typeButton: {
        backgroundColor: "whitesmoke", 
        borderRadius: "100px !important",
        //borderRadius: "5px 0px 0px 5px !important",
        //color: "rgb(0 0 0 / 26%)"
    },

    typeButtonLeft: {
        borderRadius: "5px 0px 0px 5px !important",
        marginLeft: 0
    },

    typeButtonRight: {
        borderRadius: "0px 5px 5px 0px !important"
    },

    typeButtonSelected:{
        backgroundColor: LIGHT_BUTTON_COLOR,
        color: "primary.main"
    },

    blockButtons: {
        display:"flex", 
        alignItems:"center",
        justifyContent:"flex-end",
        marginLeft: {
            xs: 0,
            lg: 3
        },
        marginTop: 0
    },

    editorToolbar : {
        display: "flex",
        flexDirection: "row",
    },

    toolbarButton : {
        backgroundColor: "whitesmoke",
        marginTop: 3,
        marginRight: 1
    },


    blockInstructions: {
        padding: 2,
        paddingTop: 0
    },
    

    dropZone:{
        minHeight: 96.9, 
        position:"relative",
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        //padding: 2
    },

    draggableElement: (isDragging:boolean, draggableStyle:any)=>({
        ...draggableStyle,
        width: "100%",
        overflow: "visible",
        paddingLeft: {
            xs: 0,
            md:2
        },
        paddingRight: {
            xs: 0,
            md:2
        },
       
        paddingTop: 1,
        paddingBottom: 1,
        backgroundColor: "white",
        borderTop: `solid 1px ${BORDER_COLOR}`,
    }),

    buttonDelete:{
        position:"absolute",
        right:-5,
        top: -5,
        backgroundColor: "whitesmoke",
        zIndex:2,
        borderRadius: "0 0 0 10px"
    },

    header:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"center",
        padding: 2,
        paddingRight: {
            xs: 2,
            sm: 3
        },
        paddingTop: 2,
        paddingBottom: 1
    },

    headerWithBorder : {
        borderBottom: `solid 1px ${BORDER_COLOR}`
    },

    partIndicator:{
        display: "inline-flex", 
        flexDirection: "row", 
        backgroundColor: "whitesmoke", 
        borderRadius: 10, 
        paddingRight: 2,
        alignItems: "center", 
        border: "solid 1px #f4f4f4"
    },

    partIndicatorLabel: {
        fontWeight: 700, 
        fontSize: 15
    },

    blockButton: {

    },

    group:{
        display:"flex",
        flexDirection: "column",
        position:"relative",
    
    },

   
    dragIndicator: {
        backgroundColor: "white",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        paddingTop:2,
        paddingBottom: 2,
        width: 30
    },

    

    groupContent:{
        display:"flex",
        flexDirection: "column",
        width: "100%"
    },
    
    addExerciceContainer: {
        display:"flex", 
        justifyContent: "center", 
        marginRight: 2
        //paddingBottom: 2, 
        //paddingTop: 2,
        //borderTop: `solid 1px ${BORDER_COLOR}`
    },

    addExerciceButton : {
        width: "100%", 
        height: 70, 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        borderTop: `solid 1px ${BORDER_COLOR}`
    }
}


export default classes
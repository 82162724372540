import PropagateLoader from "react-spinners/PropagateLoader";
import { Dialog, DialogContent,Box, Typography, useMediaQuery } from "@mui/material";
import {primaryColor} from "utils/theme"
import classes from "./styles";
import { MOBILE } from "utils/constants";


interface LoaderProps {
    open: boolean,
    text: string
}


export default function DialogLoader({open, text}:LoaderProps){

    const mobile = useMediaQuery(MOBILE)

    return(
        <Dialog 
            open={open}
            disableScrollLock
            
        >
            <DialogContent sx={{padding: mobile? 2: 8}}>
                <Box sx={classes.content}>
                    <PropagateLoader color={primaryColor} loading={true} size={15} />
                    <Typography sx={{mt:3, textAlign: "center"}} >
                        {text}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { createMealPlanTemplate } from "api/mealPlans";
import { AppDispatch, RootState } from "app/store";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


interface Props{
    open: boolean,
    onClose: ()=> void,
}


export default function DialogSaveMealPlanTemplate({open, onClose}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const mealPlan = useSelector((state:RootState) => state.mealPlans.mealPlan)
    const [templateName, setTemplateName] = useState<string>("")
    const pending = useSelector((state:RootState) => state.mealPlans.requests.create === "pending")
    const createdBy = useSelector((state:RootState) => state.user.data._id)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value)
    }

    const saveTemplate = () => {
        if(templateName === ""){
            toast.error('Le modèle doit avoir un nom')
        } else{
            dispatch(createMealPlanTemplate({
                mealPlan, 
                name:templateName, 
                createdBy
            })).unwrap().then((res:any)=>{
                if(res.mealPlan){
                    toast.success('Modèle enregistré')
                    onClose()
                } else {
                    toast.error("Erreur serveur - Impossible d'enregistrer ce modèle.")
                }
            })
        }
    }

    return(
        <Dialog
            open={open}
            fullWidth 
            maxWidth={"xs"}
            disableScrollLock
        >
            <DialogTitle>
                Enregistrer comme modèle
            </DialogTitle>
            <DialogContent>
                <TextField 
                    value={templateName}
                    onChange={onChange}
                    label={"Nom du modèle"}
                    fullWidth
                    sx={{mt:1}}

                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={pending}
                >
                    Fermer
                </Button>
                <LoadingButton 
                    variant="contained" 
                    onClick={saveTemplate}
                    loading={pending}
                >
                    Sauvegarder
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
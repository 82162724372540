import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { deleteMultipleWorkouts } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import { isWithinInterval } from "date-fns";
import { fr } from "date-fns/locale";
import Workout from "interfaces/Workout";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { toast } from "react-toastify";
import { useEffect, useMemo } from "react";


// REDUX SELECTORS

const weekWorkoutsSelector = createSelector([
    (state:RootState) => state.planning.builder,
    (_, weekStart:string) => new Date(weekStart),
    (_, __, weekEnd:string) => new Date(weekEnd),
],
    (planning, weekStart, weekEnd) => planning.workouts.filter((workout:Workout) => isWithinInterval(new Date(workout.schedule), {start: weekStart, end: weekEnd}))
)

interface Props{
    open: boolean,
    onClose: ()=> void,
    weekStart: string,
    weekEnd: string,
}

export default function DialogDeletePlanningWeek ({open, onClose, weekStart, weekEnd}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const workouts:Workout[] = useSelector((state:RootState)=> weekWorkoutsSelector(state,weekStart, weekEnd))
    const pendingWorkout = useSelector((state:RootState)=> state.workouts.requests.deleteMultiple === "pending")
    

    const filtered = useMemo(() => {
        if(open){
            return [...workouts].filter((elem)=> !elem.performed) // Suppression des workouts éffectues
        } else {
            return []
        }
    },[open])
    
    const onSubmit = ()=>{

        const noti = toast.loading("Suppression des séances ...")


        // Suppression des workouts
        dispatch(deleteMultipleWorkouts({workouts: filtered})).unwrap().then((res:any)=>{
            toast.update(noti, {type: toast.TYPE.SUCCESS, render:"Séances supprimées", isLoading: false, autoClose:1500})
        })


        onClose()

    }

    return(
        <Dialog open={open}>
            <DialogTitle>
                Supprimer le contenu de la semaine
            </DialogTitle>
            <DialogContent>
                Souhaites-tu supprimer l'intégralité des {filtered.length} séances non effectuées de la semaine allant du <strong>{format(new Date(weekStart), "dd MMMM yyyy",{locale:fr})}</strong> au <strong>{format(new Date(weekEnd), "dd MMMM yyyy",{locale:fr})}</strong> (Séances) ?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={pendingWorkout}>
                    Annuler
                </Button>
                <LoadingButton onClick={onSubmit} variant="contained" disabled={pendingWorkout} loading={pendingWorkout}>
                    {(pendingWorkout) ? "Suppression" : "Supprimer"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}